import { dangerColor } from "../../material-dashboard-react";

const modalStyle = {
    titleContainer: {
        padding: "5px 10px",
        marginBottom: 15,
        background: "linear-gradient(160deg, #CBE100 0%, #5C8BCD 50%)",
    },
    titleText: {
        fontSize: 20,
        fontWeight: 700,
    },
    bodyContainer: {
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    error: {
        color: dangerColor[1],
    },
    bodyText: {},
    footerContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
    button: {
        marginRight: 10,
    },
};

export default modalStyle;
