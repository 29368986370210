import React from "react";
import { Box, Typography } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomModal from "../../components/CustomModal/CustomModal";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Button from "../../components/CustomButtons/Button";

import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "../../assets/jss/material-dashboard-react/components/financeModal";

import resources from "../../core/Localization/Localization";
import { validateCorrectTokens } from "../../utils/finance_service";
import { correctionOptions } from "../../common/transactions/config";

const useStyles = makeStyles(modalStyle);

const UserFinanceCorrectionModal = ({ open, handleClose, handleCorrect }) => {
    const classes = useStyles();

    const [tokensToCorrect, setTokensToCorrect] = React.useState({});
    const [errors, setErrors] = React.useState([]);

    const options = React.useMemo(() => correctionOptions(), [resources]);

    const handleTakeValue = (e) => {
        setTokensToCorrect({ ...tokensToCorrect, [e.target.name]: e.target.value });
    };

    const handleTokensCorrection = () => {
        const err = validateCorrectTokens(tokensToCorrect);
        if (err.length) {
            setErrors([...err]);
            return;
        }
        setErrors([]);
        handleCorrect(tokensToCorrect);
    };

    React.useEffect(() => {
        setErrors([]);
        setTokensToCorrect({});
    }, [open]);

    return (
        <CustomModal
            open={open}
            handleClose={() => {
                handleClose();
            }}
        >
            <Box className={classes.root}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.titleText}>{resources.finance.correctUserTokens}</Typography>
                </Box>
                <Box className={classes.bodyContainer}>
                    <Box className={classes.amount}>
                        <CustomSelect
                            id="option"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText={resources.finance.datagrid.options}
                            options={options}
                            labelProps={{ shrink: true }}
                            inputProps={{
                                name: "option",
                                onChange: (e) => {
                                    handleTakeValue(e);
                                },
                                value: tokensToCorrect.option || undefined,
                            }}
                        />
                        <CustomInput
                            id="amount"
                            formControlProps={{ fullWidth: true }}
                            labelText={resources.finance.datagrid.amount}
                            inputProps={{
                                type: "number",
                                name: "amount",
                                onChange: (e) => {
                                    handleTakeValue(e);
                                },
                                value: tokensToCorrect.amount || undefined,
                            }}
                        />
                    </Box>

                    <Box className={classes.amount}>
                        <CustomInput
                            id="reason"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText={resources.finance.correctionReason}
                            inputProps={{
                                type: "string",
                                name: "reason",
                                onChange: (e) => {
                                    handleTakeValue(e);
                                },
                                value: tokensToCorrect.reason || undefined,
                            }}
                        />
                    </Box>
                </Box>
                {errors.length ? (
                    <Box className={classes.errorContainer}>
                        {[...new Set(errors)].map((x, i) => (
                            <p key={x + i} className="text-danger">
                                {x}
                            </p>
                        ))}
                    </Box>
                ) : null}
                <Box className={classes.footerContainer}>
                    <Button onClick={handleTokensCorrection} color="info">
                        {resources.form.ok}
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
};

export default UserFinanceCorrectionModal;
