import React, { useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import { colors } from "../../assets/jss/material-dashboard-react";
import CustomTablePagination from "./CustomTablePagination.js";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
    const classes = useStyles();
    const { tableHead, tableData, tableHeaderColor, paginationInfo, handleClick, tableRowColor, colorizeRow } = props;

    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(0);

    useLayoutEffect(() => {
        if (paginationInfo && tableData.length > paginationInfo.pageSize) {
            setPageData(tableData.slice(0, paginationInfo.pageSize));
        } else {
            setPageData(tableData);
        }
        setPage(0);
    }, [tableData, paginationInfo]);

    const onChangePage = (event, newPage) => {
        const direction = newPage > page ? 1 : 0;
        const fromPage = direction === 1 ? page : newPage;
        const toPage = direction === 1 ? newPage : page;
        const from = (fromPage + direction) * paginationInfo.pageSize;
        const to = (toPage + direction) * paginationInfo.pageSize;
        setPageData(tableData.slice(from, tableData.length > to ? to : tableData.length));
        setPage(newPage);
    };

    let colorizeRowCondition = (param) => {
        if (colorizeRow) {
            let condition = colorizeRow(param);
            if (condition) return `${tableRowColor}TableRow`;
        }

        return "";
    };

    return (
        <div className={classes.tableResponsive}>
            <TableContainer>
                <Table className={classes.table}>
                    {tableHead !== undefined ? (
                        <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
                            <TableRow className={classes.tableHeadRow}>
                                {tableHead.map((prop, key) => {
                                    return (
                                        <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                            {prop}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                    ) : null}
                    <TableBody>
                        {pageData.map((prop, key) => {
                            return (
                                <TableRow
                                    key={key}
                                    className={classes.tableBodyRow + " " + classes[`${colorizeRowCondition(prop)}`]}
                                    onClick={() => (!!handleClick ? handleClick(prop) : null)}
                                >
                                    {prop.map((prop, key) => {
                                        return (
                                            <TableCell className={classes.tableCell} key={key}>
                                                {prop}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            {paginationInfo && <CustomTablePagination count={tableData.length} page={page} rowsPerPage={paginationInfo.pageSize} onPageChange={onChangePage} />}
        </div>
    );
}

CustomTable.defaultProps = {
    tableHeaderColor: "gray",
};

CustomTable.propTypes = {
    colorizeRow: PropTypes.func,
    tableRowColor: PropTypes.oneOf(colors),
    tableHeaderColor: PropTypes.oneOf(colors),
    tableHead: PropTypes.arrayOf(PropTypes.string),
    tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    paginationInfo: PropTypes.shape({
        pageSize: PropTypes.number,
        pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    }),
};
