import { notificationTypes } from "./notificationTypes";

export const eventTopicRredirectRules = [
    {
        topic: notificationTypes.userCreated,
        handleRedirect: (payload, history) => {
            if (!history) return;
            const route = `/admin/users`;
            history.push(route);
        },
    },
    {
        topic: notificationTypes.userUpdated,
        handleRedirect: (payload, history) => {
            if (!history) return;
            const route = `/admin/users/${payload?.id ? payload.id : ""}`;
            history.push(route);
        },
    },
    {
        topic: notificationTypes.partRequestCreated,
        handleRedirect: (payload, history) => {
            if (!history) return;
            const route = `/admin/requests`;
            history.push(route);
        },
    },
    {
        topic: notificationTypes.partRequestCreated,
        handleRedirect: (payload, history) => {
            if (!history) return;
            const route = `/admin/requests/${payload?.id ? payload.id : ""}`;
            history.push(route);
        },
    },
    {
        topic: notificationTypes.partOfferCreated,
        handleRedirect: (payload, history) => {
            if (!history) return;
            const route = `/admin/requests`;
            history.push(route);
        },
    },
    {
        topic: notificationTypes.partOfferUpdated,
        handleRedirect: (payload, history) => {
            if (!history) return;
            const route = `/admin/offers/${payload?.id ? payload.id : ""}`;
            history.push(route);
        },
    },
];
