import React from "react";
import { Box, Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import modalStyle from "../../assets/jss/material-dashboard-react/components/modalStyle";

const useStyles = makeStyles(modalStyle);

export default function CustomModal(props) {
    const { open, handleClose, children } = props;

    const classes = useStyles();

    return (
        <Modal open={open} onClose={handleClose}>
            <Box color="primary" className={classes.root}>
                {children}
            </Box>
        </Modal>
    );
}
