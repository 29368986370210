import { createSlice } from "@reduxjs/toolkit";

const offers = createSlice({
    name: "offers",
    initialState: {
        offers: [],
        sentNewOffers: [],
        acceptedNewOffers: [],
        selectedUserOffers: [],
        dataLoaded: false,
        sentDataLoaded: false,
        acceptedDataLoaded: false,
        selectedUsersDataLoaded: false,
    },
    reducers: {
        createNewOffer() {},
        getOffers(state, action) {
            return { ...state, dataLoaded: false };
        },
        getSentNewOffers(state, action) {
            return { ...state, sentDataLoaded: false };
        },
        getAcceptedNewOffers(state, action) {
            return { ...state, acceptedDataLoaded: false };
        },
        getSelectedUserOffers(state, action) {
            return { ...state, selectedUsersDataLoaded: false };
        },
        setOffers(state, action) {
            const data = Object.values(action.payload);

            return { ...state, offers: data, dataLoaded: true };
        },
        setSentNewOffers(state, action) {
            const data = Object.values(action.payload);

            return { ...state, sentNewOffers: data, sentDataLoaded: true };
        },
        setAcceptedNewOffers(state, action) {
            const data = Object.values(action.payload);

            return {
                ...state,
                acceptedNewOffers: data,
                acceptedDataLoaded: true,
            };
        },
        setSelectedUserOffers(state, action) {
            const data = Object.values(action.payload);
            return {
                ...state,
                selectedUserOffers: data,
                selectedUsersDataLoaded: true,
            };
        },
        getPaginationOffers(state, action) {
            return { ...state, dataLoaded: false };
        },
        setPaginationOffers(state, action) {
            return {
                ...state,
                offers: action.payload.data,
                count: action.payload.count,
                dataLoaded: true,
            };
        },
    },
});

export const {
    getOffers,
    getSentNewOffers,
    getAcceptedNewOffers,
    getSelectedUserOffers,
    setOffers,
    setSentNewOffers,
    setAcceptedNewOffers,
    setSelectedUserOffers,
    createNewOffer,
    getPaginationOffers,
    setPaginationOffers,
} = offers.actions;

export default offers.reducer;
