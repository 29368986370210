import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import RequestImageList from "../../components/RequestImageList/RequestImageList";
import resources from "../../core/Localization/Localization";
import { deletePartrequestImage, getPartrequestImages } from "../../reduxStore/entities/partrequestImages";

const RequestImages = () => {
    const dispatch = useDispatch();
    const partrequest = useSelector((s) => s.partrequestDetails.partrequest);
    const images = useSelector((state) => state.partrequestImages.images);

    const handleDelete = (imageId) => {
        dispatch(deletePartrequestImage({ imageId, requestId: partrequest.part_request_id }));
    };

    useEffect(() => {
        if (partrequest.part_request_id) dispatch(getPartrequestImages(partrequest.part_request_id));
    }, [partrequest]);

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="danger">
                <h4>{resources.partRequests.sections.images}</h4>
            </CardHeader>
            <CardBody>
                <RequestImageList images={images} handleDelete={handleDelete} />
            </CardBody>
        </Card>
    );
};

export default RequestImages;
