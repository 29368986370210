export const notificationTypes = {
    userCreated: "USER_CREATED",
    userUpdated: "USER_UPDATED",
    partOfferCreated: "PART_OFFER_CREATED",
    partOfferUpdated: "PART_OFFER_UPDATED",
    partRequestCreated: "PART_REQUEST_CREATED",
    partRequestStateUpdate: "PART_REQUEST_STATE_UPDATE",
    partRequestsRead: "PART_REQUESTS_READ",
    partOffersRead: "PART_OFFERS_READ",
    transactionsRead: "TRANSACTIONS_READ",
};
