import { call, put } from "redux-saga/effects";
import {mapPartrequestsPartstate, mapPartrequestsState} from "../../../utils/mapRequestStates";
import { setPartrequests, setActivePartrequests, setSelectedUsersPartrequests, setPaginationPartrequests } from "../../entities/partrequests";
import { requestGetPartrequests, requestGetActivePartrequests, requestGetUserPartRequests, requestGetPaginationPartrequests } from "../requests/partrequests";

export function* handleGetPartrequests(action) {
    try {
        const response = yield call(requestGetPartrequests);
        const { data } = response;
        data.data = mapPartrequestsState(data.data);
        yield put(setPartrequests({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetActivePartrequests(action) {
    try {
        const response = yield call(requestGetActivePartrequests);
        const { data } = response;
        yield put(setActivePartrequests({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetUserPartRequests(action) {
    try {
        const response = yield call(requestGetUserPartRequests, action.payload);
        const { data } = response;
        const mappedData = mapPartrequestsState(data.data);
        yield put(setSelectedUsersPartrequests({ ...mappedData }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetPaginationPartrequests(action) {
    try {
        const response = yield call(requestGetPaginationPartrequests, action.payload);
        const { data } = response;
        //add readable labels for states
        data.data = mapPartrequestsState(data.data);
    //TODO    data.data = mapPartrequestsPartstate(data.data);

        yield put(setPaginationPartrequests({ ...data }));
    } catch (error) {
        console.log(error);
    }
}
