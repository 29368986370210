import React, { useEffect, useState } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { setLocalStorage, getLocalStorage } from '../../auth/helpers';

const styles = {
  grid: {
    padding: "0 15px !important",
    position: "relative"
  },
  resizeIcon: {
    position: "absolute",
    top: 28,
    right: 12,
    zIndex: 1,
    color: "#aaa",
    cursor: "pointer",
    width: "0.8em",
    height: "0.8em",
    background: "#eee"
  }
};

const useStyles = makeStyles(styles);

export default function GridItem(props) {
  const classes = useStyles();
  const { children, resizeable, md: origMd, name, ...rest } = props;
  const [md, setMd] = useState(origMd);
  const fullScreenMd = 12;

  useEffect(()=> {
    if (name) {
      const data = getLocalStorage(name);
      if (data && data.md) {
        setMd(data.md);
      }
    }
  }, [name]);

  const doResize = () => {
    const newMd = md === fullScreenMd ? origMd : fullScreenMd;
    setMd(newMd);

    if (name) {
      setLocalStorage(name, { md: newMd });
    }
  };

  return (
    <Grid item {...rest} md={md} className={classes.grid}>
      {resizeable && (md === origMd) && <AddIcon className={classes.resizeIcon} onClick={doResize} />}
      {resizeable && (md === fullScreenMd) && <RemoveIcon className={classes.resizeIcon} onClick={doResize} />}
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  children: PropTypes.node
};
