import React, { useEffect } from "react";
// @material-ui/core components
import { Box } from "@material-ui/core";
import { Typography, CircularProgress, useTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { GridToolbar } from "@material-ui/data-grid";
// third party components
import MUIDataTable from "mui-datatables";
// local components
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
// hooks
import { useQueryData } from "../../hooks/useQueryData";
import { useMediaQuery } from "@material-ui/core";

//utils
import { defaultPageSize, defaultSearch } from "../../common/DataGridColumns";
import { textLabels } from "./textLabelsOptions";
import { removeEmptyValues } from "../../utils/shallowObjectsCompare";
//styles
import datatableTheme from "../../assets/jss/material-dashboard-react/components/datatableTheme";

export default function CustomDataGrid2(props) {
    const {
        title,
        rows,
        columns,
        totalRows,
        loading,
        handleRowClick,
        handleFilterChange,
        handlePageChange,
        handleDataInit,
        initPageSize,
        ignoreUrl,
        getCellClassName,
        headerChildren,
        reset,
    } = props;

    const theme = useTheme();

    const [fixedQuery, setFixedQuery] = React.useState({});
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const { queryData, updateQuery } = useQueryData();
    const [rowsPerPage, setRowsPerPage] = React.useState(initPageSize ? initPageSize : defaultPageSize);
    const [page, setPage] = React.useState(0);
    const [customProps, setCustomProps] = React.useState(null);
    const [filters, setFilters] = React.useState({});
    const [timer, setTimer] = React.useState(null);

    useEffect(() => {
        if (ignoreUrl) {
            initializeWithoutUrl();
        } else {
            initializeWithUrl();
        }
    }, []);

    useEffect(() => {
        setFilters({ offset: 0, limit: 25 });
        handleFilterChange({ offset: 0, limit: 25 });
        tryToUpdateUrlQuery({ offset: 0, limit: 25 });
    }, [reset]);
    // Event handlers
    const onPageSizeChange = (event) => {
        const payload = { ...queryData, ...filters, offset: 0, limit: event };
        handlePageChange(payload);
        setRowsPerPage(event);
        setPage(0);
        tryToUpdateUrlQuery(payload);
    };

    const onPageChange = (newPage) => {
        const payload = { ...queryData, ...filters, offset: newPage * rowsPerPage, limit: rowsPerPage };
        handlePageChange(payload);
        setPage(newPage);
        tryToUpdateUrlQuery(payload);
        if (matches) window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const onSortModelChange = (changedColumn, direction) => {
        const sortData = { field: [changedColumn], sort: direction };
        const payload = {
            ...queryData,
            ...sortData,
            offset: 0,
            limit: rowsPerPage,
        };

        handleFilterChange(payload);
        tryToUpdateUrlQuery(payload);
    };

    const onFilterModelChange = (changedColumn, filterList, type, changedColumnIndex, displayData) => {
        //action => chip
        if (type === "chip" && changedColumn) {
            if (filterList[changedColumn]?.length && !filterList[changedColumnIndex].filter((x) => !!x)?.length) {
                filterList[changedColumnIndex] = [];
            }
            let transformedFilters = { ...queryData, ...filters };

            if (transformedFilters[changedColumn]) delete transformedFilters[changedColumn];
            setFilters({ ...transformedFilters });
            handleFilterChange({ ...transformedFilters, offset: 0, limit: rowsPerPage });
            tryToUpdateUrlQuery({ ...transformedFilters, offset: 0, limit: rowsPerPage });
            //action => reset
        } else if (type === "reset") {
            setFilters(null);

            handleFilterChange({ ...fixedQuery, offset: 0, limit: rowsPerPage });
            tryToUpdateUrlQuery({ offset: 0, limit: rowsPerPage });
            //action => other actions
        } else if (changedColumn) {
            let changedData;
            filterList[changedColumnIndex].filter((x) => !!x).length ? (changedData = filterList[changedColumnIndex]) : (changedData = []);
            setFilters({ ...filters, [changedColumn]: changedData.toString() });

            const payload = {
                ...filters,
                ...queryData,
                [changedColumn]: changedData.toString(),
                offset: 0,
                limit: rowsPerPage,
            };
            if (!changedData || changedData?.length < 1) delete payload[changedColumn];
            const filteredPayload = removeEmptyValues(payload);

            clearTimeout(timer);

            const newTimer = setTimeout(() => {
                handleFilterChange(filteredPayload);
                tryToUpdateUrlQuery(filteredPayload);
            }, 500);

            setTimer(newTimer);
        }
    };
    // Private fn
    const initializeWithUrl = () => {
        const payload = queryData && Object.keys(queryData).length > 0 ? { ...queryData } : defaultSearch;
        if (payload) setFixedQuery(payload);

        if (JSON.stringify(queryData) !== JSON.stringify(payload)) {
            updateQuery(payload);
        }
        if (handleDataInit) {
            handleDataInit(payload);
        }
        handleCustomProps(queryData);
    };

    const initializeWithoutUrl = () => {
        if (handleDataInit) {
            handleDataInit(defaultSearch);
        }
    };

    const handleCustomProps = (queryData) => {
        const qs = { ...queryData };
        delete qs["limit"];
        delete qs["offset"];
        if (Object.keys(qs).length > 0) {
            const props = {
                filterModel: {
                    items: [qs],
                },
            };
            setCustomProps(props);
        }
    };

    const tryToUpdateUrlQuery = (payload) => {
        if (!ignoreUrl) {
            updateQuery({ ...fixedQuery, ...payload });
        }
    };

    return (
        <Card>
            <CardHeader color="rose">
                <Typography variant="h6">
                    {title}
                    {loading && <CircularProgress color="inherit" size={24} style={{ marginLeft: 15, position: "relative", top: 4, zIndex: 9 }} />}
                    {headerChildren}
                </Typography>
            </CardHeader>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Box className={"data-grid no-border"} textAlign={"right"}>
                            {rows && (
                                <ThemeProvider theme={datatableTheme}>
                                    <MUIDataTable
                                        data={rows}
                                        getRowId={(row) => row.id}
                                        columns={columns}
                                        options={{
                                            autoHeight: true,
                                            pagination: true,
                                            rowsPerPageOptions: [25, 50, 100],
                                            selectableRows: "none",
                                            paginationMode: "server",
                                            serverSide: true,
                                            search: false,
                                            textLabels: textLabels(),
                                            onTableChange: (action, state) => {
                                                if (action === "sort") onSortModelChange(state.sortOrder.name || "", state.sortOrder.direction);
                                                if (action === "changeRowsPerPage") onPageSizeChange(state.rowsPerPage);
                                                if (action === "changePage") onPageChange(state.page);
                                            },
                                            rowsPerPage:rowsPerPage,
                                            //pageSize: rowsPerPage,
                                            onRowClick: handleRowClick,
                                            count: totalRows,
                                            loading: loading,
                                            components: { Toolbar: GridToolbar },

                                            onFilterChange: onFilterModelChange,
                                            //commented because this feature doesn't work - library bug
                                            // onFilterChipClose: (a, b, c) => {
                                            //     console.log(a);
                                            //     console.log(b);
                                            //     console.log(c);
                                            // },
                                            getCellClassName: getCellClassName,
                                            ...customProps,
                                        }}
                                    />
                                </ThemeProvider>
                            )}
                        </Box>
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    );
}
