import { createSlice } from "@reduxjs/toolkit";

const filters = createSlice({
    name: "filters",
    initialState: {
        filters: [],
        dataLoaded: false,
    },
    reducers: {
        getFilters(state, action) {
            return { ...state, dataLoaded: false };
        },
        setFilters(state, action) {
            const filtersData = Object.values(action.payload);
            return { ...state, filters: filtersData, dataLoaded: true };
        },
        getFilterUsers(state, action) {
            return { ...state, dataLoaded: false };
        },
        setFilterUsers(state, action) {
            const usersData = Object.values(action.payload);
            return { ...state, users: usersData, dataLoaded: true };
        },
    }
});

export const { getFilters, setFilters, getFilterUsers, setFilterUsers } = filters.actions;

export default filters.reducer;