import React from "react";
import Button from "../CustomButtons/Button";
import Table from "../Table/Table";
import { defaultTableSize } from "../../common/UserDetailsLayout";

// tabs -> [{name, state}]

const MappedTabs = (tabs, tableProps, handleViewMore, buttonLabel, forceShowButton, buttonColor, forceHideButton) => {
    const { tableData, tableHead, tableHeaderColor, onRowClick } = tableProps;

    const preparedTabs = [];
    tabs.forEach((t, i) => {
        preparedTabs.push({
            tabName: t.name,
            tabContent: (
                <>
                    <Table
                        tableHeaderColor={tableHeaderColor || "rose"}
                        tableHead={tableHead}
                        tableData={tableData}
                        handleClick={onRowClick ?? null}
                        paginationInfo={{
                            pageSize: defaultTableSize,
                            pageSizeOptions: [defaultTableSize],
                        }}
                    />
                    {(forceShowButton || tableData.length > 0) && !forceHideButton && (
                        <Button type="button" color={buttonColor || "info"} size="sm" onClick={() => handleViewMore(t.state, i)}>
                            {buttonLabel}
                        </Button>
                    )}
                </>
            ),
        });
    });
    return preparedTabs;
};

export default MappedTabs;
