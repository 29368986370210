import * as Yup from "yup";
import resources from "../../core/Localization/Localization";

const UserUpdateSchema = Yup.object().shape({
    firstname: Yup.string().required(resources.createUser.errors.firstNameRequired),
    lastname: Yup.string().required(resources.createUser.errors.lastNameRequired),
    shop_name:Yup.string().optional().nullable(),
    email: Yup.string().required(resources.createUser.errors.emailRequired),
    phone: Yup.string().optional(),
    city: Yup.string().optional(),
    country: Yup.string().optional(),
    address: Yup.string().optional(),
    seller_commission: Yup.number().transform((value, originalValue) => {
        if (originalValue.trim() === '') {
            return null; // Allow empty string to pass as null
        }
        return value;
    }).nullable(),
});

export default UserUpdateSchema;
