import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { mapOffersState } from "../../../utils/mapOfferStates";
import { setOffers, setSentNewOffers, setAcceptedNewOffers, setPaginationOffers, setSelectedUserOffers } from "../../entities/offers";
import {
    requestGetOffers,
    requestGetSentNewOffers,
    requestGetAcceptedNewOffers,
    requestCreateNewOffer,
    requestGetPaginationOffers,
    requestGetUserOffers,
} from "../requests/offers";

export function* handleGetOffers(action) {
    try {
        const response = yield call(requestGetOffers);
        const { data } = response;
        yield put(setOffers([...data]));
    } catch (error) {
        toast.error(error.message);
    }
}

export function* handleGetSentNewOffers(action) {
    try {
        const response = yield call(requestGetSentNewOffers);
        const { data } = response;
        yield put(setSentNewOffers({ ...data }));
    } catch (error) {
        toast.error(error.message);
    }
}

export function* handleGetAcceptedNewOffers(action) {
    try {
        const response = yield call(requestGetAcceptedNewOffers);
        const { data } = response;
        yield put(setAcceptedNewOffers({ ...data }));
    } catch (error) {
        toast.error(error.message);
    }
}

export function* handleCreateNewOffer(action) {
    try {
        const response = yield call(requestCreateNewOffer, action.payload.data);
        const { data } = response;
        yield put(setAcceptedNewOffers({ ...data }));
        if (data) {
            window.location.href = `/admin/requests/${action.payload.requestId}`;
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export function* handleGetUserOffers(action) {
    try {
        const response = yield call(requestGetUserOffers, action.payload);
        const { data } = response;
        const mappedData = mapOffersState(data.data);
        yield put(setSelectedUserOffers({ ...mappedData }));
    } catch (error) {
        toast.error(error.message);
    }
}

export function* handleGetPaginationOffers(action) {
    try {
        const response = yield call(requestGetPaginationOffers, action.payload);
        const { data } = response;
        data.data = mapOffersState(data.data);
        yield put(setPaginationOffers({ ...data }));
    } catch (error) {
        toast.error(error.message);
    }
}
