import resources from "../core/Localization/Localization.js";
import Warning from "@material-ui/icons/Warning";

import { correctionOptions, TransactionTypes } from "./transactions/config.js";
import moment from "moment";

import { divBy100, roundNumberToTwoDec } from "../utils/calculations.js";
import { activeStates, notAcceptedStates, acceptedStates } from "../utils/getRequestStatus";
import { accepted, declined, sent } from "../utils/mapOfferStates";
// USER PART REQUEST TABLE CONFIG
export const USER_PART_REQUEST_HEADERS = () => [
    resources.partRequests.datagrid.id,
    resources.partRequests.datagrid.title,
    resources.partRequests.datagrid.make,
    resources.partRequests.datagrid.model,
    resources.partRequests.datagrid.state,
    resources.partRequests.datagrid.offers,
    resources.partRequests.datagrid.created,
];

export const partRequestTabs = () => [
    {
        name: resources.partRequests.status.active,
        state: activeStates,
    },
    {
        name: resources.partRequests.status.failed,
        state: notAcceptedStates,
    },
    {
        name: resources.partRequests.status.completed,
        state: acceptedStates,
    },
];

export const mapPartrequestsInTableData = (partrequests) => {
    if (!partrequests) return [];

    return partrequests.map((x) => {
        return [
            x.part_request_id.toString(),
            x.title,
            x.Make?.name ? x.Make.name : "",
            x.Model?.name ? x.Model.name : "",
            x.state !== 10 ? x.state_label : <Warning color="error" />,
            [1, 2].includes(x.state) && !x.PartOffers.length ? <Warning color="warning" /> : x.PartOffers.length.toString(),
            moment(x.createdAt).format("DD/MM/YYYY hh:mm"),
        ];
    });
};

// USER OFFERS TABLE CONFIG

export const USER_OFFERS_HEADERS = () => [
    resources.partOffers.datagrid.id,
    resources.partOffers.datagrid.partname,
    resources.partOffers.datagrid.make,
    resources.partOffers.datagrid.model,
    resources.partOffers.datagrid.price,
    resources.partOffers.datagrid.type,
    resources.partOffers.datagrid.created,
];

export const offersTabs = () => [
    {
        name: resources.partOffers.status.sent,
        state: sent,
    },
    {
        name: resources.partOffers.status.accepted,
        state: accepted,
    },
    {
        name: resources.partOffers.status.declined,
        state: declined,
    },
];

export const mapOffersInTableData = (offers) => {
    if (!offers) return [];

    return offers.map((x) => {
        return [
            x.part_offer_id.toString(),
            x.PartRequest?.title ? x.PartRequest.title : x.title,
            x.PartRequest?.Make?.name ? x.PartRequest.Make.name : "",
            x.PartRequest?.Model?.name ? x.PartRequest.Model.name : "",
            roundNumberToTwoDec(divBy100(x.price)).toString(),
            x.type ? resources.partOffers.types[x.type] : "",
            moment(x.createdAt).format("DD/MM/YYYY hh:mm"),
        ];
    });
};
// USER VEHICLE TABLE CONFIG

export const USER_VEHICLES_HEADERS = () => [
    resources.userVehicles.datagrid.id,
    resources.userVehicles.datagrid.make,
    resources.userVehicles.datagrid.model,
    resources.userVehicles.datagrid.generation,
    resources.userVehicles.datagrid.motor,
    resources.userVehicles.datagrid.created,
];

// USER FILTERS TABLE CONFIG

export const USER_FILTERS_HEADERS = () => [
    resources.userFilters.datagrid.id,
    resources.userFilters.datagrid.make,
    resources.userFilters.datagrid.model,
    resources.userFilters.datagrid.generation,
];

export const mapUserFiltersInTableData = (userFilters) => {
    if (!userFilters) {
        return [];
    }
    return userFilters.map((x) => {
        return [x.user_filter_id.toString(), x.Make.name, x.Model.name, x.Generation.name];
    });
};

// USER FINANCE TABLE CONFIG

export const financeHistoryTabs = () => [
    {
        name: resources.finance.type.payin,
        state: TransactionTypes.PAYIN,
    },
    {
        name: resources.finance.type.reserved,
        state: TransactionTypes.RESERVED,
    },
    {
        name: resources.finance.type.charge,
        state: TransactionTypes.CHARGE,
    },
    {
        name: resources.finance.type.correction,
        state: TransactionTypes.CORRECTION,
    },
];

export const USER_FINANCE_HEADERS = (type) => {
    const headers = [
        resources.finance.datagrid.transactionId,
        resources.finance.datagrid.partOfferId,
        resources.finance.datagrid.amount,
        resources.finance.datagrid.currencyAmount,
        resources.finance.datagrid.currency,
        resources.finance.datagrid.refundedAmount,
        resources.finance.datagrid.createdAt,
    ];

    switch (type) {
        case resources.finance.type.payin:
            return headers.filter((h) => h !== resources.finance.datagrid.partOfferId && h !== resources.finance.datagrid.refundedAmount);
        case resources.finance.type.correction: {
            const corrected = [...headers];
            let deleted = corrected.filter((h) => h !== resources.finance.datagrid.partOfferId && h !== resources.finance.datagrid.refundedAmount);
            deleted.splice(deleted.length - 1, 0, resources.finance.datagrid.correctionType);
            return deleted;
        }

        default:
            return headers;
    }
};

export const mapFinanceHistoryInTableData = (data) => {
    if (!data) return [];
    return data.map((x) => {
        switch (x.type) {
            case TransactionTypes.PAYIN:
                return [
                    x.transaction_id.toString(),
                    x.amount ? Math.round(divBy100(x.amount)) : "",
                    x.currency_amount ? roundNumberToTwoDec(divBy100(x.currency_amount)).toString() : null,
                    x.currency.toUpperCase(),
                    moment(x.createdAt).format("DD/MM/YYYY hh:mm"),
                ];
            case TransactionTypes.CORRECTION:
                return [
                    x.transaction_id.toString(),
                    x.amount ? Math.round(divBy100(x.amount)) : "",
                    x.currency_amount ? roundNumberToTwoDec(divBy100(x.currency_amount)).toString() : null,
                    x.currency.toUpperCase(),
                    x.correction_type ? correctionOptions().filter((t) => t.value === x.correction_type)[0]?.label : "",
                    moment(x.createdAt).format("DD/MM/YYYY hh:mm"),
                ];
            default:
                return [
                    x.transaction_id.toString(),
                    x.part_offer_id ? x.part_offer_id : "",
                    x.amount ? Math.round(divBy100(x.amount)) : "",
                    x.currency_amount ? roundNumberToTwoDec(divBy100(x.currency_amount)).toString() : null,
                    x.currency.toUpperCase(),
                    x.refunded && x.refunded_amount ? roundNumberToTwoDec(divBy100(x.refunded_amount)).toString() : null,
                    moment(x.createdAt).format("DD/MM/YYYY hh:mm"),
                ];
        }
    });
};
