import React from "react";
import { useDispatch } from "react-redux";
import { getPaymentSettings } from "../../reduxStore/entities/settings";

import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";

import Layout from "../../core/Layout";
import Card from "../../components/Card/Card";
import CardAvatar from "../../components/Card/CardAvatar";
import SettingsPayment from "./SettingPayment";

//styles
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import SettingsMaintenance from "./Maintenance/SettingsMaintenance";
import SettingsConfiguration from "./Configuration/SettingsConfiguration";

import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

const useStyles = makeStyles(dashboardStyle);

const Settings = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getPaymentSettings());
    }, []);

    return (
        <Layout>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card profile className="user-profile-card">
                        <CardAvatar profile>
                            <SettingsIcon className={classes.profileIcon} />
                        </CardAvatar>
                        <SettingsPayment />
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card profile>
                        <SettingsMaintenance />
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <Card profile className={"user-profile-card"}>
                        <SettingsConfiguration />
                    </Card>
                </GridItem>
            </GridContainer>
        </Layout>
    );
};

export default Settings;
