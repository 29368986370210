import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/customInputStyle.js";
import ReactPhoneInput from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/style.css";
import { TextField } from "@material-ui/core";
import resources from "../../core/Localization/Localization.js";

const useStyles = makeStyles(styles);

export default function CustomPhoneInput(props) {
    const classes = useStyles();
    const { formControlProps, labelText, id, labelProps, value, onChange, inputProps, error, success } = props;

    const labelClasses = classNames({
        [" " + classes.labelRootError]: error,
        [" " + classes.labelRootSuccess]: success && !error,
    });
    const underlineClasses = classNames({
        [classes.underlineError]: error,
        [classes.underlineSuccess]: success && !error,
        [classes.underline]: true,
    });
    const marginTop = classNames({
        [classes.marginTop]: labelText === undefined,
    });

    const defaultValue = "381";
    const defaultCountry = "rs";

    return (
        <FormControl {...formControlProps} className={formControlProps.className + " " + classes.formControl}>
            {labelText !== undefined ? (
                <InputLabel className={classes.labelRoot + labelClasses} htmlFor={id} {...labelProps}>
                    {labelText}
                </InputLabel>
            ) : null}
            <ReactPhoneInput
                value={value || defaultValue}
                defaultCountry={defaultCountry}
                enableSearch={true}
                countryCodeEditable={false}
                onChange={(e) => (onChange ? onChange(e) : null)}
                component={TextField}
                inputProps={{
                    ...inputProps,
                    placeholder: resources.createUser.phone,
                }}
                disabled={!!inputProps.disabled}
                classes={{
                    root: marginTop,
                    disabled: classes.disabled,
                    underline: underlineClasses,
                }}
                id={id}
            />
            {error ? (
                <Clear className={classes.feedback + " " + classes.labelRootError} />
            ) : success ? (
                <Check className={classes.feedback + " " + classes.labelRootSuccess} />
            ) : null}
        </FormControl>
    );
}

CustomPhoneInput.propTypes = {
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
};
