import resources from "../core/Localization/Localization";
import { divBy100, roundNumberToTwoDec } from "../utils/calculations";

export const handleTableLoad = (offers) => {
    const preparedData = offers.map((offer) => {
        return {
            id: offer.part_offer_id,
            partname: offer.PartRequest?.title ? offer.PartRequest.title : offer.title,
            make: offer.PartRequest?.Make?.name ? offer.PartRequest.Make.name : "",
            model: offer.PartRequest?.Model?.name ? offer.PartRequest.Model.name : "",
            type: offer.type ? resources.partOffers.types[offer.type] : "",
            price: roundNumberToTwoDec(divBy100(offer.price)),
            status: offer.status_label,
            username: offer.User?.username ? offer.User.username : "Korisnik obrisan",
            createdAt: offer.createdAt,
        };
    });
    return preparedData;
};
