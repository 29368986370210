import React from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { Box, Typography, Switch } from "@material-ui/core";

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal.js";

//utils
import resources from "../../../core/Localization/Localization";
import { updateMaintenanceSettings } from "../../../reduxStore/entities/settings.js";
import { isAuth } from "../../../auth/helpers.js";

const MaintenanceOther = () => {
    const dispatch = useDispatch();
    const operator = isAuth();

    const maintenanceSettings = useSelector((state) => state.settings.maintenanceSettings);

    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [settings, setSettings] = React.useState(null);
    const [modalCaller, setModalCaller] = React.useState("");
    const [modalMessage, setModalMessage] = React.useState("");

    React.useLayoutEffect(() => {
        setSettings({ ...maintenanceSettings });
    }, [maintenanceSettings]);

    const handleClick = (caller) => {
        switch (caller) {
            case "notifications_disabled":
                setModalMessage(resources.settings.messages.confirmDisableNotifications);
                setModalCaller("notifications_disabled");
                break;
            case "part_offers_disabled":
                setModalMessage(resources.settings.messages.confirmPartOffersDisable);
                setModalCaller("part_offers_disabled");
                break;
            case "part_requests_disabled":
                setModalMessage(resources.settings.messages.confirmPartRequestDisable);
                setModalCaller("part_requests_disabled");
                break;

            default:
                break;
        }
        if (caller) setShowConfirmModal(true);
    };

    const handleConfirm = (caller) => {
        let initVal = { maintenance_config_id: settings.maintenance_config_id, updatedby: operator.userid };
        switch (caller) {
            case "notifications_disabled":
                dispatch(updateMaintenanceSettings({ ...initVal, notifications_disabled: !settings.notifications_disabled }));
                break;
            case "part_offers_disabled":
                dispatch(updateMaintenanceSettings({ ...initVal, part_offers_disabled: !settings.part_offers_disabled }));

                break;
            case "part_requests_disabled":
                dispatch(
                    updateMaintenanceSettings({
                        ...initVal,
                        part_requests_disabled: !settings.part_requests_disabled,
                    })
                );
                break;

            default:
                break;
        }
        setModalMessage("");
        setModalCaller("");
        setShowConfirmModal(false);
    };

    return settings ? (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Box className="d-flex align-items-center justify-between pt-3 pb-3">
                        <Typography>{resources.settings.disableNotifications}</Typography>
                        <Switch
                            color="primary"
                            size="medium"
                            name="notifications_disabled"
                            checked={settings.notifications_disabled || false}
                            onClick={(e) => {
                                handleClick(e.target.name);
                            }}
                        />
                    </Box>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Box className="d-flex align-items-center justify-between pt-3 pb-3">
                        <Typography>{resources.settings.disablePartOffers}</Typography>
                        <Switch
                            color="primary"
                            size="medium"
                            name="part_offers_disabled"
                            checked={settings.part_offers_disabled || false}
                            onClick={(e) => {
                                handleClick(e.target.name);
                            }}
                        />
                    </Box>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Box className="d-flex align-items-center justify-between pt-3 pb-3">
                        <Typography>{resources.settings.disablePartRequests}</Typography>
                        <Switch
                            color="primary"
                            size="medium"
                            name="part_requests_disabled"
                            checked={settings.part_requests_disabled || false}
                            onClick={(e) => {
                                handleClick(e.target.name);
                            }}
                        />
                    </Box>
                </GridItem>
                <ConfirmationModal
                    open={showConfirmModal}
                    handleClose={() => {
                        setModalMessage("");
                        setShowConfirmModal(false);
                    }}
                    buttonLabel={resources.form.confirm}
                    text={modalMessage}
                    onConfirmCallback={() => handleConfirm(modalCaller)}
                />
            </GridContainer>
        </>
    ) : null;
};

export default MaintenanceOther;
