import { Box, TableCell, Tooltip, Typography, Zoom } from "@material-ui/core";
import { customBodyRender, defaultCellStyle, defaultCustomBodyRender, defaultParentStyle, defaultWrapperStyle } from "./DataGridColumns";
import resources from "../../core/Localization/Localization";
import { dangerColor, warningColor } from "../../assets/jss/material-dashboard-react";
import { partRequestsStateOptions } from "../../utils/mapRequestStates";
import { acceptedStates, activeStates, getRequestStatusByStatusArray, notAcceptedStates } from "../../utils/getRequestStatus";
import { timeRangeColumn } from "./customColumns/timeRangeColumn";

const stateWrapperStyle = {
    ...defaultWrapperStyle,
    minWidth: "50px",
};

const offerWrapperStyle = {
    ...defaultWrapperStyle,
    minWidth: "20px",
};

const warningWrapperStyle = {
    ...offerWrapperStyle,
    opacity: 0.8,
    backgroundColor: warningColor[0],
};

const dangerWrapperStyle = {
    ...offerWrapperStyle,
    opacity: 0.8,
    backgroundColor: dangerColor[0],
};

const offerCellStyle = {
    ...defaultCellStyle,
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
};

export const partrequestColumns = () => [
    {
        name: "id",
        label: "ID",
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "title",
        label: resources.partRequests.datagrid.title,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    //TODO {
    //     name: "partstate",
    //     label: resources.partRequests.datagrid.partstate,
    //     options: {
    //         filter: true,
    //         sort: true,
    //         filterType: "textField",
    //         customBodyRender: defaultCustomBodyRender,
    //     },
    // },
    {
        name: "make",
        label: resources.partRequests.datagrid.make,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "model",
        label: resources.partRequests.datagrid.model,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "username",
        label: resources.partRequests.datagrid.username,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "state",
        label: resources.partRequests.datagrid.state,
        options: {
            filter: true,
            sort: true,
            filterType: "dropdown",
            filterOptions: {
                names: partRequestsStateOptions().map((x) => x.value),
                renderValue: (v) => partRequestsStateOptions().find((x) => x.value === v).label,
            },
            customFilterListOptions: {
                render: (v) => `${resources.partRequests.datagrid.state}: ${partRequestsStateOptions().find((x) => x.value === v).label}`,
            },

            customBodyRender: (val, row) => {
                let stateVal = row.rowData[5];
                if (stateVal === resources.partRequests.state.ten) {
                    return customBodyRender(val, row, {
                        wrapper: dangerWrapperStyle,
                        parent: defaultParentStyle,
                        cell: offerCellStyle,
                    });
                } else
                    return (
                        <div style={stateWrapperStyle} value={row.rowData}>
                            <div style={defaultParentStyle}>
                                <Tooltip TransitionComponent={Zoom} title={val} interactive placement="bottom-start">
                                    <div style={defaultCellStyle}>{val}</div>
                                </Tooltip>
                            </div>
                        </div>
                    );
            },
        },
    },
    {
        name: "states",
        label: resources.partRequests.datagrid.states,
        options: {
            filter: true,
            sort: true,
            filterType: "dropDown",

            filterOptions: {
                names: [`${JSON.stringify(activeStates)}`, `${JSON.stringify(acceptedStates)}`, `${JSON.stringify(notAcceptedStates)}`],
                renderValue: (v) => getRequestStatusByStatusArray(v),
            },
            customFilterListOptions: {
                render: (v) => `${resources.partRequests.datagrid.states}: ${getRequestStatusByStatusArray(v)}`,
            },

            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "offers",
        label: resources.partRequests.datagrid.offers,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: (val, row) => {
                let offersVal = row.rowData[7];
                let stateVal = row.rowData[5];

                if (offersVal === 0 && (stateVal === resources.partRequests.state.one || stateVal === resources.partRequests.state.two)) {
                    return customBodyRender(val, row, {
                        wrapper: dangerWrapperStyle,
                        parent: defaultParentStyle,
                        cell: offerCellStyle,
                    });
                }
                if (offersVal === 0 && stateVal === resources.partRequests.state.eleven) {
                    return customBodyRender(val, row, {
                        wrapper: warningWrapperStyle,
                        parent: defaultParentStyle,
                        cell: offerCellStyle,
                    });
                } else
                    return (
                        <div style={offerWrapperStyle} value={row.rowData}>
                            <div style={defaultParentStyle}>
                                <Tooltip TransitionComponent={Zoom} title={val} interactive placement="bottom-start">
                                    <div style={defaultCellStyle}>{val}</div>
                                </Tooltip>
                            </div>
                        </div>
                    );
            },
        },
    },

    { ...timeRangeColumn() },
];
