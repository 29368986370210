import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        transition: "all 0.3s",
        cursor: "pointer",
        "&:hover": {
            opacity: 0.7,
        },
    },
    img: {
        aspectRatio: "1 / 1",
        objectFit: "cover",
        objectPosition: "center",
        borderRadius: "5px",
    },
    titleBar: {
        position: "absolute",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        top: "0",
        left: "0",
        right: "0px",
        background: "linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.3) 40%, rgba(0,0,0,0) 100%)",
        zIndex: 3,
    },
    title: {
        color: "#fff",
        fontSize: "1rem",
        marginLeft: "5px",
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        gap: 10,
    },
    icon: {
        color: "white",
    },
    closeButton: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    nextButton: {
        position: "absolute",
        top: "50%",
        right: "0px",
    },
    previousButton: {
        position: "absolute",
        top: "50%",
        left: "0px",
    },
}));

export default useStyles;
