import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles, Typography } from "@material-ui/core";
import tncStyle from "../assets/jss/material-dashboard-react/components/tncStyle";

const useStyles = makeStyles(tncStyle);

const TermsAndConditions = () => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.tnc}>
                <Typography variant="h4" align="center">
                    Uslovi korišćenja aplikacije / Terms and Conditions
                </Typography>
                <Box className={classes.tncBoxWrapper}>
                    <Box className={classes.tncLeftBox}>
                        <Typography>
                            Na osnovu člana 41. Zakona o zaštiti podataka o ličnosti ("Sl, glasnik RS", br. 87/2018, dalje: Zakon),{" "}
                            <b>SMART PART DOO BEOGRAD</b>, ul. Borivoja Stevanovića br. 3, st. 30, 11050 Beograd - Voždovac, Srbija, matični broj: 21836265,
                            PIB: 113271683, adresa elektronske pošte: smartpart.app@gmail.com (dalje: Pružalac ili Rukovalac), koje zastupa direktor Vladimir
                            Jovičić, donosi sledeći
                        </Typography>
                        <br />
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            PRAVILNIK O ZAŠTITI PODATAKA O LIČNOSTI SA OBAVEŠTENJEM O PRIKUPLJANJU I OBRADI PODATAKA
                        </Typography>
                        <br />
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            1 - OBAVEŠTENJE O PRIKUPLJANJU I OBRADI PODATAKA O LIČNOSTI I OPŠTE NAPOMENE
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 1</Typography>
                            Pružalac usluge informacionog društva poštuje privatnost fizičkih lica i to: svojih zaposlenih (dalje: Zaposleni), kandidata za
                            zaposlenje (dalje: Kandidati), fizičkih lica koja stupaju u ugovorni odnos sa Pružaocem u skladu sa Uslovima korišćenja (dalje:
                            Korisnici), i posetilaca internet stranica Pružaoca (dalje: Posetioci) i vrši prikupljanje i obradu podataka o ličnosti isključivo u
                            skladu sa ovim Pravilnikom o zaštiti podataka o ličnosti (u daljem tekstu: Pravilnik)
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 2</Typography>
                            Putem ovog obaveštenja Pružalac obaveštava fizička lica o prikupljanju i obradi podataka o ličnosti koje sprovodi u skladu sa
                            Zakonom o zaštiti podataka o ličnosti i drugim propisima Republike Srbije, koji su usklađeni sa Opštom uredbom o zaštiti podataka
                            Evropske unije (Uredba (EU) 2016/679 Evropskog Parlamenta i Saveta od 27.04.2016. o zaštiti fizičkih lica u odnosu na obradu
                            podataka o ličnosti i o slobodnom kretanju takvih podataka i o stavljanju Direktive 95/46/EZ van snage, skr. GDPR)
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 3</Typography>U skladu sa Zakonom, Pružalac ima svojstvo Rukovaoca.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 4</Typography>U cilju zaštite podataka o ličnosti, Pružalac primenjuje odgovarajuće tehničke,
                            organizacione i kadrovske mere i konstantno radi na njihovom unapređenju kako bi obezbedila da se sa podacima o ličnosti postupa u
                            skladu sa važećim propisima i pristankom lica (onda kada je taj pristanak potreban u skladu sa zakonom). U vezi sa tim, Pružalac
                            može povremeno menjati odredbe ovog Pravilnika, u kom slučaju će obezbediti da ažurirana odnosno važeća verzija bude dostupna na
                            Oglasnoj tabli, odnosno elektronskom servisu koji se upodobljava oglasnoj tabli, sa jasno naznačenim početkom primene.
                            <br />
                            Ovim Pravilnikom se, pored ostalog uređuju obaveze Zaposlenih u pogledu zaštite podataka o ličnosti.
                            <br /> O promenama Pravilnika, Pružalac obaveštava Zaposlene direktno, dok su Kandidati dužni da se samostalno upoznaju sa odredbama
                            važećeg Pravilnika. <br />
                            <br /> Ukoliko Kandidat nije saglasan sa bilo kojom odredbom ovog Pravilnika, potrebno je da se uzdrži od dostavljanja ličnih
                            podataka na bilo koji način, odnosno potrebno je da Kandidat povuče saglasnost za obradu podataka o ličnosti u skladu sa
                            Pravilnikom.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            2 - PODACI O RUKOVAOCU
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 5</Typography>
                            Rukovalac podacima o ličnosti je:
                            <br />
                            Poslovno ime: <b>SMART PART DOO BEOGRAD</b>
                            <br />
                            Adresa sedišta: ul. Borivoja Stevanovića br. 3, st. 30, 11050 Beograd - Voždovac, Republika Srbija
                            <br />
                            Matični broj: 21836265
                            <br />
                            PIB: 113271683
                            <br />
                            E-pošta: smartpart.app@gmail.com
                            <br />
                            Šifra delatnosti: 6202 - Konsultantske delatnosti u oblasti informacione tehnologije
                            <br />
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 6</Typography>
                            Kontakt podaci rukovaoca za ostvarivanje prava na zaštitu podataka o ličnosti su:
                            <br />
                            <b>SMART PART DOO BEOGRAD</b>
                            <br />
                            ul. Borivoja Stevanovića br. 3, st. 30, 11050 Beograd - Voždovac, Republika Srbija
                            <br />
                            Radno vreme: od ponedeljka do petka od 9:00h do 17:00h
                            <br />
                            Tel: +381 61 185 1850 (od ponedeljka do petka od 9:00h do 17:00h)
                            <br />
                            Email: smartpart.app@gmail.com
                            <br />
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            3 - NAČIN PRIKUPLJANJA PODATAKA
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 7</Typography>
                            Podatke o ličnosti koji se odnose na <b>Zaposlene</b>, Pružalac prikuplja na sledeće načine:
                            <br />- <b>direktno od fizičkog lica, kada Zaposleni neposredno vrši dostavu podataka o ličnosti</b>, prilikom zaključenja ugovora o
                            radu, odnosno aneksa, kada Zaposleni neposredno vrše dostavu podataka o ličnosti koji su u vezi sa radom, usmenim ili pisanim putem
                            (uključujući i elektronski oblik), i<br />
                            <br />- <b>obradom podataka</b>, u kom slučaju Pružalac ili angažovana treća strane u ime Pružaoca (npr. računovođa) upotrebljavaju
                            podatke o ličnosti Zaposlenih radi obavezne prijave zaposlenih i obračuna zarade i plaćanja pripadajućih poreza i doprinosa na
                            zarade, u skladu sa ovim Pravilnikom, zakonima i podzakonskim aktima kojima su uređeni radni odnosi.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 8</Typography>
                            Podatke o ličnosti koji se odnose na <b>Kandidate</b>, Pružalac prikuplja na sledeće načine:
                            <br />- <b>direktno od fizičkog lica</b>, kada lice neposredno dostavlja svoje podatke Pružaocu, kada se fizičko lice u svrhu
                            kandidovanja za zaposlenje obrati Pružaocu poštom, elektronskom poštom, odnosno telefonskim putem, i<br />
                            <br />- <b>obradom podataka</b>, u slučaju kada je Kandidat sam o sebi javno objavilo lične podatke kada Pružalac preuzima
                            objavljene podatke, ili kada je angažovana treća strane u ime Pružaoca (npr. eksterna agencija za zapošljavanje) upotrebljavaju
                            podatke o ličnosti Kandidata radi sprovođenja procesa prijave, testiranja i ispitivanja kandidata i eventualnog zaključenja radnog
                            odnosa, u skladu sa ovim Pravilnikom, zakonima i podzakonskim aktima kojima su uređeni radni odnosi.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 9</Typography>
                            Podatke o ličnosti koji se odnose na <b>Korisnike</b>, Pružalac prikuplja na sledeće načine:
                            <br />- <b>direktno od fizičkog lica</b>, kada lice neposredno dostavlja svoje podatke Pružaocu, prilikom zaključenja određenog
                            pravnog posla (osim ugovora o radu), i <br />
                            <br />- <b>obradom podataka</b>, u slučaju kada je Korisnik sam o sebi javno objavio lične podatke kada Pružalac preuzima objavljene
                            podatke, ili kada je angažovana treća strane u ime Pružaoca (npr. posrednik) upotrebljavaju podatke o ličnosti Korisnika radi
                            dovođenja u vezu i eventualnog zaključenja određenog pravnog posla (npr. zaključenja određenog ugovora), u skladu sa ovim
                            Pravilnikom, Uslovima korišćenja Pružaoca, zakonima i podzakonskim aktima kojima su uređeni obligacioni odnos
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 10</Typography>
                            Podatke o ličnosti koji se odnose na <b>Posetioce</b> svojih internet stranica, Pružalac prikuplja na sledeće načine:
                            <br />- <b>automatski</b>,prilikom korišćenja internet stranica, u kom slučaju Pružalac ili angažovana treća strane u ime Pružaoca
                            upotrebljava tzv. „cookie“ tehnologije za adekvatno prikazivanje sadržaja i praćenje količine poseta i kretanja po internet
                            stranicama Pružaoca i slično (u daljem tekstu: „cookie” ili kolačić), u skladu sa ovim Pravilnikom.
                            <br />
                            <br />- <b>direktno od fizičkog lica</b>, kada lice neposredno dostavi svoje podatke Pružaocu, odnosno kada se fizičko lice obrati
                            Pružaocu putem internet sajta, elektronskom poštom, odnosno telefonskim putem
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            4 - PODACI KOJE PRUŽALAC OBRAĐUJE, NAČIN I OSNOV KORIŠĆENJA
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 11</Typography>
                            Pružalac prikuplja podatke koji su primereni, bitni i ograničeni na ono što je neophodno u odnosu na konkretnu svrhu obrade
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 12</Typography>
                            Podatke o ličnosti koji se odnose na Zaposlene, Pružalac obrađuje na sledeći način i po sledećem osnovu:
                            <br />
                            1) Pružalac koristi lične podatke o zaposlenim za postizanje svrhe koje su unapred određene u ovom Pravilniku, Pravilniku o radu,
                            Ugovoru o radu i zakonima i podzakonskim aktima. Za svaku obradu koju Pružalac preduzima postoji odgovarajući osnov koji je propisan
                            zakonima kojima se uređuje oblasti rada odnosno radnih odnosa, evidencija u oblasti rada, obaveznih socijalnih i zdravstvenih
                            osiguranja, poreza i računovodstva, kao i drugim zakonima Republike Srbije.
                            <br />
                            <br />
                            2) Pružalac prikuplja podatke o ličnosti Zaposlenih u svrhu poštovanja Ugovora o radu i ispunjavanja zakonskih obaveza i obrađuje ih
                            na zakonom propisane načine radi ostvarivanja prava zaposlenih iz radnog odnosa (kao što su npr. pravo na zaradu, godišnji odmor,
                            odsustvo zbog sprečenosti za rad, bezbednosti i zdravlja na radu, obračuna i plaćanja poreza i doprinosa i sl.), kao i urednog
                            vođenja zakonom obaveznih evidencija.
                            <br />
                            <br />
                            3) Zaposleni svojim potpisom na Ugovoru o radu potvrđuje da je saglasan da Poslodavac izvrši unos, kopiranje, prenos, korišćenje,
                            pohranjivanje i obradu njegovih podataka o ličnosti, bilo iz njegovog ličnog zapisa, bilo iz službene evidencije kao što su lična
                            karta, radna knjižica (ukoliko ju je Zaposleni dostavio), prijavni list za zdravstveno i penzijsko osiguranje, svedočanstva, diplome
                            i drugo; i to u svrhe ostvarenja prava iz radnog odnosa i prava na osnovu radnog odnosa, kao što su određivanje zarade, naknade
                            zarade i drugih primanja zaposlenog, vršenja obuke i stručnog usavršavanja, planiranja karijere, bezbednosti i zdravlja na radu,
                            upravljanje rizikom ili u slične svrhe. Ova saglasnost daje se u odnosu na Poslodavca i treća lica koje Poslodavac ovlasti, i to
                            naročito računovođama, pružaocima pravnih usluga, bankama, brokerima i organizacijama socijalnog osiguranja (ovlašćena treća lica)
                            <br />
                            <br />
                            4) u odnosu na <b>Zaposlene</b> pružalac obrađuje:
                            <br />
                            4.1) <b>Podatak koji se obrađuje</b>: ime, prezime, adresa prebivališta (mesto, poštanski broj, ulica, kućni broj), državljanstvo,
                            datum i mesto rođenja, pol, bračno stanje, JMBG, broj lične karte, broj zdravstvenog osiguranja (LBO), stepen i vrsta stručne
                            spreme, znanje stranih jezika, znanje stečeno na obukama, istorija zaposlenja, radna biografija, broj bankovnog računa, podaci o
                            zaradi i primanjima iz radnog odnosa, podaci o izvršenju radnih obaveza uključujući i ocene učinka, adresa elektronske pošte
                            dodeljena od strane poslodavca, IP adresa pristupa elektronskim servisima koje koristi poslodavac.
                            <br />
                            <b>Svrha obrade</b>: izvršenje Ugovora o radu i poštovanje zakonskih obaveza.
                            <br />
                            <b>Pravni osnov obrade</b>: Poštovanje pravnih obaveza koje proizilaze iz Ugovora o radu, Zakona o radu, Zakona o evidencijama u
                            oblasti rada, Zakona o doprinosima za obavezno socijalno osiguranje, Zakona o porezu na dohodak građana, Zakona o bezbednosti i
                            zdravlju na radu, i drugim propisima kojima se uređuju obaveze Pružaoca kao poslodavca (čl. 12, st. 1. tč. 3) Zakona).
                            <br />
                            4.2) <b>Podatak koji se obrađuje</b>: broj telefona, adresa elektronske pošte zaposlenog, kontakt bliske osobe za hitne slučajeve
                            <br />
                            <b>Svrha obrade</b>: komunikacija sa zaposlenim i komunikacija u hitnim slučajevima.
                            <br />
                            <b>Pravni osnov obrade</b>: pristanak Zaposlenog (čl. 12, st. 1. tč. 1) Zakona).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 13</Typography>
                            Podatke o ličnosti koji se odnose na <b>Kandidate</b>, Pružalac obrađuje na sledeći način i po sledećem osnovu:
                            <br />
                            <br />
                            1) Pružalac koristi lične podatke o Kandidatima za zasnivanje radnog odnosa za postizanje svrhe koje su unapred određene u ovom
                            Pravilniku, Pravilniku o radu, Ugovoru o radu i zakonima i podzakonskim aktima. Za svaku obradu koju Pružalac preduzima postoji
                            odgovarajući osnov koji je propisan zakonima kojima se uređuje oblasti rada odnosno u oblasti posredovanja u zaposlenju, kao i
                            drugim zakonima Republike Srbije.
                            <br />
                            <br />
                            2) Pružalac prikuplja podatke o ličnosti Kandidata za zasnivanje radnog odnosa u svrhu vođenja pregovora i pripreme za eventualno
                            zaključenje Ugovora o radu i ispunjavanja zakonskih obaveza i obrađuje ih na zakonom propisane načine radi ostvarivanja prava na
                            rad, kao i urednog vođenja sopstvenih evidencija potencijalnih Kandidata. Pružalac može da prikuplja podatke o ličnosti koje se
                            odnose na Kandidata za zasnivanje radnog odnosa, kao i da na njihov zahtev (npr. prijava na slobodno radno mesto) vrši obradu
                            podataka o ličnosti u svrhu odabira Kandidata pre zaključenja Ugovora o radu.
                            <br />
                            <br />
                            3) u odnosu na <b>Kandidate</b> pružalac obrađuje:
                            <br />
                            <b>Podatak koji se obrađuje</b>: ime, prezime, adresa prebivališta (mesto, poštanski broj, ulica, kućni broj), državljanstvo, JMBG,
                            stepen i vrsta stručne spreme, znanje stranih jezika, znanje stečeno na obukama, istorija zaposlenja, radna biografija, broj
                            telefona, adresa elektronske pošte. <br />
                            <b>Svrha obrade</b>: priprema za zaključenje Ugovora o radu, odnosno odabir kandidata za zaposlenje i komunikacija sa njim.
                            <br />
                            <br />
                            <b>Pravni osnov obrade</b>: pristanak Kandidata (čl. 12, st. 1. tč. 1) Zakona) kao i neophodnost obrade zahtev lica na koje se
                            podaci odnose, pre zaključenja ugovora (čl. 12, st. 1. tč. 2) Zakona).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 14</Typography>
                            Podatke o ličnosti koji se odnose na <b>Korisnika</b>, Pružalac obrađuje na sledeći način i po sledećem osnovu:
                            <br />
                            <br />
                            1) Pružalac koristi lične podatke o Korisniku za postizanje svrhe koje su unapred određene u ovom Pravilniku, Uslovima korišćenja
                            Pružaoca, i drugim ugovorima kojima se uređuju obligacioni odnosi, zakonima i podzakonskim aktima. Za svaku obradu koju Pružalac
                            preduzima postoji odgovarajući osnov koji je propisan zakonima kojima se uređuje oblast obligacionih odnosa, kao i drugim zakonima
                            Republike Srbije.
                            <br />
                            <br />
                            2) Pružalac prikuplja podatke o ličnosti Korisnika u svrhu vođenja pregovora i pripreme za eventualno zaključenje određenog pravnog
                            posla i ispunjavanja zakonskih obaveza i obrađuje ih na zakonom propisane načine ispunjenja preuzetih obaveza iz takvog ugovornog
                            odnosa. Pružalac može da prikuplja podatke o ličnosti koje se odnose na Korisnika za zaključenje ugovora, kao i da na njihov zahtev
                            vrši obradu podataka o ličnosti u svrhu odabira Korisnika pre zaključenja određenog ugovora
                            <br />
                            <br />
                            3) u odnosu na <b>Korisnike</b> Pružalac obrađuje:
                            <br />
                            <b>Podatak koji se obrađuje</b>: ime, prezime, adresa prebivališta (mesto, poštanski broj, ulica, kućni broj), broj mobilnog
                            telefona, adresa elektronske pošte. Pored navedenih, Pružalac obrađuje i podatke o automobilu (marka, model, godina proizvodnje,
                            agregat, broj šasije) koji se ne smatraju podacima o ličnosti kao takvim.
                            <br />
                            <b>Svrha obrade</b>: pružanje usluge informacionog društva u smislu oglašavanja određenih potreba Korisnika koja se odnose na
                            mogućnost kupovine/prodaje, kao i marketinške svrhe odnosno obaveštavanje Korisnika o novostima koje prenosi Pružalac.
                            <br />
                            <b>Pravni osnov obrade</b>: pristanak Korisnika (čl. 12, st. 1. tč. 1) Zakona).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 15</Typography>
                            Podatke o ličnosti koji se odnose na <b>Posetioce</b>, Pružalac obrađuje na sledeći način i po sledećem osnovu:
                            <br />
                            <br />
                            1) Podatke o Posetiocima Pružalac može da koristi za postizanje više svrha koje su unapred određene u ovom Pravilniku. Za svaku
                            obradu koju Pružalac preduzima postoji odgovarajući osnov koji je propisan Zakonom o zaštiti podataka o ličnosti i drugim zakonima
                            Republike Srbije
                            <br />
                            <br />
                            2) Pružalac može da obrađuje samo neke i/ili sve podatke koji su navedeni u ovom članu. Pružalac konstantno radi na razvoju internet
                            stranica i unapređivanju dostupnih funkcionalnosti svojih internet stranica, usled čega je moguće da obrada pojedinih podataka koji
                            su niže navedeni započne naknadno, kako se funkcionalnosti internet stranice menjale. Takođe, obim podataka koje Pružalac obrađuje
                            zavisi, na primer, i od toga na koji način i iz kojih razloga lice kontaktira Pružaoca ili na koji način koristi internet stranice
                            <br />
                            <br />
                            3) Podatke koje Pružalac neposredno prikuplja, može da upoređuje i kombinuje sa podacima koji su prikupljeni automatski, te na taj
                            način podaci koji su prikupljeni kao anonimni (na primer, podatak o učestalosti posete određenom sadržaju na internet stranici) mogu
                            postati podaci o ličnosti (na primer, koliko često korisnik posećuje određeni sadržaj na internet stranici). To Pružaocu omogućava
                            da pruži personalizovano iskustvo prilikom posete internet stranici.
                            <br />
                            <br />
                            4) u odnosu na <b>Posetioce</b> pružalac obrađuje:
                            <br />
                            4.1) <b>Podatak koji se obrađuje</b>: ime, prezime, adresa elektronske pošte.
                            <br />
                            <b>Svrha obrade</b>: komunikacija sa posetiocem – odgovaranje na pitanja i zahteve koje Pružalac prima (npr. elektronske pošte).
                            <br />
                            <b>Pravni osnov obrade</b>: pristanak Posetioca (čl. 12, st. 1. tč. 1) Zakona).
                            <br />
                            <br />
                            4.2) <b>Podatak koji se obrađuje</b>: ime, prezime, adresa prebivališta (mesto, poštanski broj, ulica, kućni broj) broj telefona,
                            adresa elektronske pošte.
                            <br />
                            <b>Svrha obrade</b>: rešavanje reklamacije u pogledu saobraznosti isporuke digitalnog sadržaja koji nije isporučen na trajnom nosaču
                            zapisa.
                            <br />
                            <b>Pravni osnov obrade</b>: poštovanje pravnih obaveza koje proizilaze iz oblasti zaštite potrošača (čl. 12, st. 1. tč. 3) Zakona).
                            <br />
                            <br />
                            4.3) <b>Podatak koji se obrađuje</b>: vrsta uređaja (računar, tablet, pametni telefon), IP adresa, operativni sistem uređaja,
                            veb-pregledač koji se koristi za pristup Internet stranici.
                            <br />
                            <b>Svrha obrade</b>: prilagođavanje prikaza informacija uređaju na kojem se prikazuju, odnosno da se sadržaj internet stranice
                            optimalno prikazuje na uređaju koji se koristi.
                            <br />
                            <b>Pravni osnov obrade</b>: poštovanje pravnih obaveza Pružaoca da se Posetiocu prikaže sadržaj odnosno prikažu tražene informacije
                            (čl. 12, st. 1. tč. 3) Zakona).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 16</Typography>
                            Ukoliko se naknadno pojavi potreba za obradom podataka o ličnosti u neku drugu svrhu koja je različita od one za koju su podaci
                            prikupljeni, Pružalac će licima, pre započinjanja dalje obrade, pružiti informacije o toj drugoj svrsi, kao i sve ostale informacije
                            u skladu sa zakonom
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 17</Typography>
                            Pružalac određene podatke može da anonimizuje i koristi za statističke i analitičke svrhe, tako da se pri tom pojedinačna lica na
                            koja se ti podaci odnose ne mogu ni posredno ni neposredno identifikovati.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            5 - „COOKIE“ PODACI – KOLAČIĆI
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 18</Typography>
                            Kolačić (cookie) je informacija sačuvana na računaru posetioca od strane internet stranice koju posećuje. Kolačići obično čuvaju
                            podešavanja posetioca, postavke za internet stranicu, kao što su preferirani jezik ili adresa. Kasnije, kada posetilac opet otvori
                            istu internet stranicu internet pregledač šalje natrag kolačiće koji pripadaju toj stranici. Ovo omogućava stranici da prepozna
                            posetioca i prikaže mu informacije prilagođene njegovim potrebama. Ukoliko posetilac ne želi da neki web sajt ima pristup njegovim
                            kolačićima, može ih blokirati. Većina web pregledača omogućava da blokiranje kolačića. To posetilac može uraditi putem Podešavanja
                            web pregledača
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 19</Typography>
                            Internet stranica Pružaoca koristi “cookie” podatke da bi posetiocima obezbedili najbolje korisničko iskustvo. Oni Pružaocu
                            omogućavaju da, na primer, prikaže sadržaj internet stranice na adekvatan način na svakom uređaju odnosno internet pregledaču (engl.
                            „web browser“) putem kojeg mu posetilac pristupa, ili da sadržaj koji Pružalac plasira na internet stranici bude prilagođen
                            interesovanjima posetiocima. Informacije koje na taj način Pružalac prikuplja u nekim slučajevima mogu, ali ne moraju, biti podaci o
                            ličnosti. Budući da Pružalac poštuje privatnost korisnika, u ovom odeljku se nalaze objašnjenja šta su i na koji način se koriste
                            “cookie” podaci, kao i da se posetiocima predoči da u određenim slučajevima svaki posetilac može da upravlja načinom na koji se
                            koriste “cookie-ji”, a u vezi sa upotrebom internet stranice Pružaoca. Kada posetiocu internet stranica omogući da ne izabere
                            odnosno isključi određene “cookie-je” i posetilac se odluči za tu opciju, to može umanjiti funkcionalnost internet stranice. Takođe,
                            potrebno je imati u vidu da će postavke, koje posetilac odluči da isključi, morati da isključite i za svaki drugi uređaj ili
                            internet pregledač posebno.
                            <br />
                            Kolačići su jednostavne tekstualne datoteke koje se čuvaju u internet pregledaču posetioca, odnosno na njegovom uređaju. Zadatak
                            “cookie-ja” je pre svega da omogući internet stranici da „prepozna“ posetioca kada joj isti posetilac naredni put pristupi. U toj
                            situaciji, internet stranica koristi podatke sačuvane u “cookie-ju” i tako automatski dobija informaciju o prethodnoj aktivnosti
                            posetioca na internet stranici. Kolačići ne mogu pristupe podacima koje posetilac čuva na uređajima, ali mogu da prikupljaju podatke
                            online aktivnostima posetioca. Kolačići nisu štetni po posetioca ili njegov uređaj kojim pristupa internetu i ne treba ih mešati sa
                            virusima. Kolačići ne sadrže viruse niti drugi zlonamerni kod. U zavisnosti od toga koliko dugo se zadržavaju u internet pregledaču
                            posetioca, razlikuju se “cookie” sesije (zadržavaju se samo u toku konkretne sesije pretraživanja i po njenom okončanju se
                            automatski brišu, a koriste se da bi omogućili pristup konkretnom sadržaju) ili trajne “cookie” (duži ili kraći period, „pamte“
                            informacije za naredne posete internet stranici, zadržavaju se dok ne budu izbrisani ručno ili dok ne isteknu prema predviđenom
                            vremenu trajanja, npr. “cookie” koji omogućavaju da internet stranica „pamti“ podatke za prijavljivanje u slučaju kreiranja naloga
                            ili “cookie” za praćenje).
                            <br />
                            Prema tome kome pripadaju, postoje “cookie” prve strane (“cookie” koje je postavio Pružalac kao vlasnik internet stranica kojima
                            posetioci pristupaju) i “cookie” treće strane (“cookie” koje postavljaju partnerske kompanije odabrane od Pružaoca koje pružaju
                            razne usluge, npr. analitičke jer prikupljaju i obrađuju određene podatke o načinu korišćenja internet stranica i dostavljaju
                            Pružaocu informacije u anonimizovanom obliku) koji omogućavaju da se posetiocima na internet stranicama partnerskih kompanija ili
                            drugim lokacijama na Internetu prikazuje povezani sadržaj.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 20</Typography>
                            Kolačići mogu da sadrže različite informacije i da se koriste za različite namene. Pružalac koristi sledeće vrste “cookie-ja”:
                            <br />
                            <br />
                            <b>OBAVEZNI – apsolutno neophodni “cookie-ji”</b> - Obavezni kolačići čine stranicu upotrebljivom omogućavajući osnovne funkcije kao
                            što su navigacija stranicom i pristup zaštićenim sadržajima. Pružalac koristi kolačiće koji su neophodni za ispravno funkcionisanje
                            web stranice Pružaoca, kako bi bile omogućene pojedine tehničke funkcije i tako posetiocima bilo omogućeno pozitivno iskustvo posete
                            stranicama.
                            <br />
                            <br />
                            <b>TRAJNI – neophodni radi olakšanog pristupa</b> - Ovi kolačići obično imaju datum isteka daleko u budućnosti i kao takvi će ostati
                            u internet pregledaču posetioca, dok ne isteknu, ili dok ne budu ručno izbrisani. Pružalac koristi trajne kolačiće za
                            funkcionalnosti kao što su “ostanite prijavljeni” i slično, što posetiocu olakšava pristup ukoliko postoji funkcionalnost prijave.
                            Takođe, Pružalac koristi trajne kolačiće kako bi imao bolje razumevanje navika posetilaca, da može da poboljša web stranicu prema
                            tom razumevanju. Ova informacija je anonimna –individualni podaci posetioca nisu vidljivi.
                            <br />
                            <br />
                            <b>STATISTIKA - statistički “cookie-ji”</b> - Statistički kolačići anonimnim prikupljanjem i slanjem podataka pomažu vlasnicima
                            stranice da shvate na koji način posetioci komuniciraju sa stranicom. Radi se o kolačićima koji Pružaocu omogućuju web analitiku,
                            tj. analizu upotrebe internet stranica i merenje posećenosti, koju Pružalac sprovodi kako bi poboljšao njihov kvalitet i sadržaj.
                            <br />
                            <br />
                            <b>MARKETING - “cookie” podaci za marketinške svrhe</b> - Marketinški kolačići koriste se za praćenje posetilaca kroz internet
                            stranice. Koriste se kako bi se posetiocima prikazivali relevantni oglasi i podstakli ih na učestvovanje, što je bitno za izdavače i
                            oglašivače trećih strana
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 21</Typography>
                            Posetioci mogu upravljati korišćenjem “cookie-ja” odabirom odgovarajućih podešavanja u svom internet pregledaču. Više informacija
                            mogu se naći na sledećim linkovima:
                            <br />
                            <br />
                            <b>- Google Chrome</b>:
                            <br />
                            „Brišite i omogućavajte kolačiće u Chrome-u i upravljajte njima“
                            <br />
                            srpski:{" "}
                            <a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=sr">
                                https://support.google.com/chrome/answer/95647?hl=sr
                            </a>
                            <br />
                            <br />
                            <b>- Firefox</b>:
                            <br />
                            „Omogućavanje i onemogućavanje kolačića” <br />
                            srpski:{" "}
                            <a target="_blank" href="https://support.mozilla.org/sr/kb/omogucavanje-i-onemogucavanje-kolacica">
                                https://support.mozilla.org/sr/kb/omogucavanje-i-onemogucavanje-kolacica
                            </a>
                            <br />
                            <br />
                            <b>- Internet Explorer / Microsoft Edge</b>:
                            <br />
                            „Brisanje kolačića i upravljanje kolačićima ” <br />
                            srpski:{" "}
                            <a
                                target="_blank"
                                href="https://support.microsoft.com/sr-latn-rs/windows/brisanje-kolačića-i-upravljanje-kolačićima-168dab11-0753-043d-7c16-ede5947fc64d"
                            >
                                https://support.microsoft.com/sr-latn-rs/windows/brisanje-kolačića-i-upravljanje-kolačićima-168dab11-0753-043d-7c16-ede5947fc64d
                            </a>
                            <br />
                            <br />
                            <b>- Safari</b>:
                            <br />
                            „Manage cookies and website data” <br />
                            engleski:{" "}
                            <a target="_blank" href="https://help.apple.com/safari/mac/8.0/#/sfri11471">
                                https://help.apple.com/safari/mac/8.0/#/sfri11471
                            </a>
                            <br />
                            <br />
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            6 - PROFILISANJE
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 22</Typography>
                            Profilisanje je oblik automatizovane obrade podataka koji se koristi da bi se ocenilo određeno svojstvo ličnosti, posebno u cilju
                            analize ili predviđanja ličnih sklonosti, interesa, ponašanja i dr. Na osnovu tako dobijenih podataka Pružalac može profilisati
                            Kandidate za zasnivanje radnog odnosa, kako bi se izvršila selekcija, odnosno odabir Kandidata.
                            <br />
                            Pružalac ne vrši bilo kakvo profilisanje Korisnika i/ili Posetilaca.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            7 - PODACI KOJE PRUŽALAC NE OBRAĐUJE
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 23</Typography>
                            Pružalac ne prikuplja i ne obrađuje posebne vrste podataka o ličnosti, kao što su podaci koji se odnose na rasno ili etničko
                            poreklo, političko mišljenje, versko ili filozofsko uverenje ili članstvo u sindikatu (osim kada je zakonom obavezna na to),
                            genetske podatke, biometrijske podatke u cilju jedinstvene identifikacije lica, podatke o seksualnom životu ili seksualnoj
                            orijentaciji, kao ni podatke koji se odnose na krivične presude, krivična dela i prekršaje, mere bezbednosti i druge mere koje
                            izriču sudovi. U tom smislu se fizička lica obaveštavaju da Pružaocu ne otkrivaju i ne šalju ove podatke.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 24</Typography>
                            Podatke o zdravstvenom stanju Pružalac ne prikuplja i ne obrađuje osim kada se radi o:
                            <br />
                            - Zaposlenima kod Pružaoca kod kojih je nastupila privremena sprečenost za rad u skladu sa propisima o zdravstvenom osiguranju u
                            svrhu ostvarivanja prava iz radnog odnosa – bolovanje,
                            <br />
                            <br />- Kandidatima za koji u skladu sa Zakonom o profesionalnoj rehabilitaciji i zapošljavanju osoba sa invaliditetom imaju pravo
                            na podsticaje za zapošljavanje radi stvaranja uslova za ravnopravno uključivanje osoba sa invaliditetom na tržište rada.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 25</Typography>
                            Pružalac neće nikada namerno niti ciljano prikupljati i obrađivati podatke o maloletnim licima, ali se ne može isključiti mogućnost
                            davanja netačnih podataka od strane fizičkih lica. Ukoliko roditelj ili zakonski zastupnik maloletnog lica dođe do informacije da su
                            Pružaocu dostavljeni podaci tog maloletnog lica, potrebno je da Pružaoca o tome bez odlaganja obavesti kako bi Pružalac preduzela
                            odgovarajuće mere i prekinuo dalju obradu ovih podataka, a prikupljene podatke uništi.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            8 - POSTOJANJE OBAVEZE DAVANJA PODATAKA
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 26</Typography>U najvećem broju slučajeva, ne postoji obaveza dostavljanja ličnih podataka Pružaocu
                            već to fizičko lice čini na dobrovoljnoj osnovi, i to u ograničenim slučajevima kada kontaktira Pružaoca i dostavlja sopstvene
                            kontakt podatke kako bi Pružalac mogla da odgovori na pitanje ili zahteve lica.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 27</Typography>U nekim slučajevima može postojati <b>zakonska obaveza</b> dostavljanja podataka o
                            ličnosti. Za fizička lica koja kod Pružaoca imaju status Zaposlenog, obaveze dostavljanja podataka propisane su:
                            <br />
                            <br />
                            - Zakonom o radu ("Sl. glasnik RS", br. 24/2005, 61/2005, 54/2009, 32/2013, 75/2014, 13/2017 - odluka US, 113/2017 i 95/2018 -
                            autentično tumačenje),
                            <br />
                            - Zakonom o evidencijama u oblasti rada ("Sl. list SRJ", br. 46/96 i "Sl. glasnik RS", br. 101/2005 - dr. zakon i 36/2009 - dr.
                            zakon),
                            <br />
                            - Zakonom o evidencijama u oblasti rada ("Sl. list SRJ", br. 46/96 i "Sl. glasnik RS", br. 101/2005 - dr. zakon i 36/2009 - dr.
                            zakon). <br />- Zakonom o doprinosima za obavezno socijalno osiguranje ("Sl. glasnik RS", br. 84/2004, 61/2005, 62/2006, 5/2009,
                            52/2011, 101/2011, 7/2012 - usklađeni din. izn., 8/2013 - usklađeni din. izn., 47/2013, 108/2013, 6/2014 - usklađeni din. izn.,
                            57/2014, 68/2014 - dr. zakon, 5/2015 - usklađeni din. izn., 112/2015, 5/2016 - usklađeni din. izn., 7/2017 - usklađeni din. izn.,
                            113/2017, 7/2018 - usklađeni din. izn., 95/2018, 4/2019 - usklađeni din. izn., 86/2019, 5/2020 - usklađeni din. izn., 153/2020,
                            6/2021 - usklađeni din. izn., 44/2021, 118/2021 i 10/2022 - usklađeni din. izn.)
                            <br />
                            <br />
                            Za fizička lica koja sa kojima Pružalac stupa u ugovorni odnos povodom pružanja svojih usluga (npr. Korisnici) ili zakupodavni odnos
                            ili drugi obligacioni odnos, obaveze dostavljanja podataka propisane su:
                            <br />
                            - Zakonom o obligacionim odnosima ("Sl. list SFRJ", br. 29/78, 39/85, 45/89 - odluka USJ i 57/89, "Sl. list SRJ", br. 31/93, "Sl.
                            list SCG", br. 1/2003 - Ustavna povelja i "Sl. glasnik RS", br. 18/2020). <br />- Zakonom o izvršenju i obezbeđenju ("Sl. glasnik
                            RS", br. 106/2015, 106/2016 - autentično tumačenje, 113/2017 - autentično tumačenje i 54/2019)
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            9 - PRIMAOCI PODATAKA O LIČNOSTI – OVLAŠĆENA TREĆA LICA
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 28</Typography>Podatke o ličnosti obrađuje i koristi prevashodno Pružalac.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 29</Typography>U nekim slučajevima može biti potrebno da Pružalac podatke učini dostupnim trećim
                            stranama. To su ovlašćena treća lica odnosno privredni subjekti koji su angažovani za kadrovske, računovodstvene, pravne ili
                            Pružaocu pružaju analitičke ili druge usluge. Kako bi Pružalac obezbedila da se sa podacima o ličnosti postupa na zakonit način, sa
                            ovlašćenim trećim licima Pružalac je zaključila odgovarajuće ugovore koji se odnose na obradu podataka o ličnosti kojima je, između
                            ostalog, propisana obaveza tih lica da sa podacima postupaju isključivo u skladu sa instrukcijama Pružaoca i ovim Pravilnikom, tako
                            da svakom licu, bez obzira na navedenu obradu pripadaju prava ustanovljena Zakonom i ovim Pravilnikom.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 30</Typography>Podatke koje Pružalac prikuplja i obrađuje od Zaposlenih u svrhu realizacije obaveza
                            iz radnog odnosa dostavlja privrednom društvu Enodatio doo Beograd-Voždovac, ul. Druge Srpske Armije br. 9, sp. prizemlje, st. 5
                            11000 Beograd – Voždovac, Srbija, adresa elektronske pošte: enodatiodoo@gmail.com, matični broj: 21125105, PIB: 109091282, pretežna
                            delatnost: 6920 - Računovodstveni, knjigovodstveni i revizorski poslovi; poresko savetovanje, radi izvršenja obračuna i plaćanja
                            zarada i pripadajućih poreza i doprinosa.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 31</Typography>Takođe, u određenim pravnim situacijama može postojati zakonska obaveza da Pružaoca
                            lične podatke učini dostupnim nadležnim organima (npr. sudu, tužilaštvu i sl.).
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            10 - IZNOŠENJE PODATAKA U INOSTRANSTVO
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 32</Typography>Lične podatke Pružalac koristi na teritoriji Republike Srbije.
                            <br />
                            <br /> Pored toga, podatke koje Pružalac prikuplja čuvaju se na serverima koji su u svojini i pod kontrolom hosting kompanije AWS
                            odnosno Amazon.com, Inc. adresa sedišta: 251 Little Falls Drive, Grad Wilmington, Okrug New Castle, Država Delaware 19808,
                            Sjedinjene Američke Države, U skladu sa politikom hosting kompanije:
                            <a href="https://docs.aws.amazon.com/whitepapers/latest/navigating-gdpr-compliance/welcome.html">
                                https://docs.aws.amazon.com/whitepapers/latest/navigating-gdpr-compliance/welcome.html
                            </a>
                            , obezbeđen je visok stepen tehničke i softverske zaštite ovih servera, dok pristup samim podacima koji su na njima pohranjeni nije
                            dozvoljen.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 33</Typography>
                            Iznošenje podataka u inostranstvo je moguće samo i isključivo kada se ugovorna obaveza Pružaoca može izvršiti samo i isključivo
                            dostavljanjem pojedinih podataka u inostranstvo, i to samo u obimu koji je neophodan za izvršenje te ugovorne obaveze.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 34</Typography>
                            Pružalac može koristiti eksterne dobavljače usluga kao što su Google, Slack i sl. sa kojima je zaključio odgovarajuće ugovore koji
                            omogućavaju obradu određenih podataka od strane tih dobavljača prema odredbama tih ugovora i GDPR, koji omogućavaju najviše stepen
                            zaštite podataka i usklađenost sa ovim Pravilnikom.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 35</Typography>U slučaju potrebe da lične podatke Pružalac učini dostupnim primaocima u državama za
                            koje se smatra da ne obezbeđuju primereni nivo zaštite podataka o ličnosti, Pružalac se obavezuje da obezbedi odgovarajući osnov za
                            takav prenos, uključujući moguću primenu odgovarajućih standardnih ugovornih klauzula izrađenim od strane nadležnog organa nadzora,
                            odnosno drugih odgovarajućih mera zaštite, kojima će primalac biti obavezan da zaštiti lične podatke na način koji je u skladu sa
                            standardima zaštite utvrđenim propisima Republike Srbije.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            11 - PERIOD ČUVANJA PODATAKA
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 36</Typography>Lične podatke Pružalac čuva samo onoliko koliko je neophodno za ostvarenje svrhe za
                            koju su ti podaci prikupljeni, a nakon toga ih bezbedno uklanja iz svojih sistema i briše
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 37</Typography>Podatke o ličnosti na koje se odnosi ovaj Pravilnik, Pružalac čuva najkraći potrebni
                            period, osim kada propisi obavezuju da se podaci čuvaju određeni vremenski period. Kada je rok čuvanja podataka o ličnosti propisan
                            zakonom, Rukovalac zadržaće podatke u datom zakonskom roku. Nakon ispunjenja svrhe, odnosno isteka zakonom propisanog roka za
                            čuvanje podataka, podaci će biti trajno obrisani.
                            <br />
                            Podaci o Zaposlenima se po sili zakona čuvaju trajno. Podaci o Kandidatima za zasnivanje radnog odnosa se u svrhu naknadne procene
                            potrebe za angažovanjem Kandidata na određenom radnom mestu čuvaju 2 godine od okončanja procesa odabira kandidata za to radno
                            mesto, nakon čega se trajno brišu.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            12 - PRAVA U VEZI SA OBRADOM PODATAKA
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 38</Typography>Fizičko lice na koje se podaci odnose pod određenim uslovima ima prava u vezi sa
                            obradom podataka o ličnosti, koja su garantovana zakonom, i to:
                            <br />
                            <br />
                            <b>- Pravo na pristup</b> – fizičko lice ima pravo da zahteva informaciju o tome da li Pružalac obrađuje podatke o ličnosti, kao i
                            pristup tim podacima. Na zahtev lica Pružalac će dostaviti kopiju podataka koje obrađuje.
                            <br />
                            <br />
                            <b>- Pravo na ispravku i dopunu</b> – fizičko lice ima pravo da se netačni podaci koji se odnose na njega isprave bez nepotrebnog
                            odlaganja, kao i da se nepotpuni podaci dopune, što uključuje i davanje dodatne izjave.
                            <br />
                            <br />
                            <b>- Pravo na brisanje podataka</b> – fizičko lice ima pravo da zahtevate brisanje svojih podataka, a naročito:
                            <br /> a) ako podaci više nisu neophodni za ostvarivanje svrhe zbog koje su prikupljeni ili na drugi način obrađivani;
                            <br /> b) ako je opozvan pristanak na osnovu kojeg se obrada vršila, a nema drugog pravnog osnova za obradu;
                            <br /> c) ako je podnet prigovor na obradu u skladu sa zakonom.
                            <br />
                            <br />
                            <b>- Pravo na ograničenje obrade</b> – fizičko lice ima pravo da se obrada podataka ograniči ako je ispunjen jedan od sledećih
                            slučajeva:
                            <br />
                            a) ako osporava tačnost podataka, u roku koji omogućava proveru tačnosti podataka o ličnosti; <br />
                            b) ako je obrada nezakonita, a lice se protivi brisanju podataka o ličnosti i umesto brisanja zahteva ograničenje upotrebe podataka;
                            <br />
                            c) ako Pružaocu više nisu potrebni podaci o ličnosti za ostvarivanje svrhe obrade, ali ih lice traži u cilju podnošenja,
                            ostvarivanja ili odbrane pravnog zahteva; <br />
                            d) ako je lice podnelo prigovor na obradu koja se vrši na osnovu legitimnog interesa Rukovaoca, a u toku je procenjivanje da li
                            pravni osnov za obradu od strane Pružaoca preteže nad interesima lica.
                            <br />
                            <br />
                            <b>- Pravo na prigovor</b> – ako lice smatra da je to opravdano u odnosu na posebnu situaciju u kojoj se nalazi, fizičko lice ima
                            pravo da u svakom trenutku podnese prigovor na obradu podataka o ličnosti koja se vrši na osnovu legitimnog interesa Rukovaoca,
                            uključujući i profilisanje koje se zasniva na tim odredbama.
                            <br />
                            <br />
                            <b>- Pravo na prenosivost podataka</b> – fizičko lice ima pravo da podatke o ličnosti koje je prethodno dostavilo Pružaocu dobije od
                            Pružaoca u strukturisanom, uobičajeno korišćenom i elektronski čitljivom obliku i pravo da ove podatke prenese drugom rukovaocu bez
                            ometanja, ako je obrada zasnovana na pristanku ili ugovoru i ako se vrši automatizovano. Takođe, fizičko lice ima pravo da ovi
                            podaci budu neposredno preneti drugom rukovaocu, ako je to tehnički izvodljivo.
                            <br />
                            <br />
                            <b>- Pravo na opoziv pristanka</b> – ako se obrada vrši na osnovu pristanka lica, fizičko lice ima pravo na opoziv pristanka u bilo
                            koje vreme, pri čemu opoziv pristanka ne utiče na dopuštenost obrade na osnovu pristanka pre opoziva.
                            <br />
                            <br />
                            <b>- Pravo na podnošenje pritužbe</b> – fizičko lice ima pravo da podnese pritužbu povodom obrade svojih podataka o ličnosti
                            Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti čiji su kontakt podaci:
                            <br />
                            <br />
                            <b>Poverenik za informacije od javnog značaja i zaštitu podataka o ličnosti</b>
                            <br />
                            Bulevar kralja Aleksandra 15, Beograd 11120
                            <br />
                            Radno vreme: od ponedeljka do petka od 7:30h do 15:30h
                            <br />
                            Tel: +38111 3408 900, Faks: +38111 3343 379
                            <br />
                            Email: office@poverenik.rs
                            <br />
                            Sajt: www.poverenik.rs
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            13 - BEZBEDNOST PODATAKA
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 39</Typography>Bezbednost podataka je veoma važna. Zbog toga Pružalac preduzima mere fizičke,
                            tehničke i elektronske zaštite kako bi sprečila slučajno ili nezakonito uništenje, gubitak, izmene, neovlašćeno otkrivanje ili
                            pristup podacima o ličnosti. Ove mere su usmerene kako na lica van Pružaoca, tako i na lica unutar Pružaoca, jer je pristup podacima
                            ograničen samo na one osobe čija zaduženja nužno zahtevaju takav pristup i koje su poučene o primeni propisa o zaštiti podataka o
                            ličnost
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 40</Typography>Kako ne postoje neprobojni sigurnosni sistemi, pa tako Pružalac ne može da garantuje
                            da bezbednost sistema nad kojima ima neposrednu kontrolu nikada neće biti ugrožena. U slučaju povrede podataka o ličnosti Pružalac
                            će preduzeti sve raspoložive mere i o tome obavestiti nadležne organe u skladu sa propisima, kao i pojedince (fizička lica) o čijim
                            podacima je reč, uvek kada je to moguće.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            14 - OBAVEZE ZAPOSLENIH
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 41</Typography>Zaposleni su dužni da poštuju i štite podatke o ličnosti koje obrađuju.
                            <br /> Zaposleni smeju da obrađuju samo one podatke kojima im je dozvoljen pristup, u skladu sa utvrđenim opisom posla, odnosno
                            radnim zadacima koje obavljaju.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            15 - ZAVRŠNE KONSTATACIJE
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 42</Typography>Konstatuje se da Pružalac nije u obavezi da vrši procena uticaja obrade na zaštitu
                            podataka o ličnosti i pribavi prethodno mišljenje Poverenika u smislu člana 54. Zakona, jer ne sprovodi ni 1) sistematske i
                            sveobuhvatne procene stanja i osobina fizičkog lica koja se vrši pomoću automatizovane obrade podataka o ličnosti, uključujući i
                            profilisanje, na osnovu koje se donose odluke od značaja za pravni položaj pojedinca ili na sličan način značajno utiču na njega;
                            niti 2) obrađuje posebne vrsta podataka o ličnosti iz člana 17. stav 1. i člana 18. stav 1. ili podataka o ličnosti u vezi sa
                            krivičnim presudama i kažnjivim delima iz člana 19. Zakona, u velikom obimu; nit vrši 3) sistematski nadzora nad javno dostupnim
                            površinama u velikoj meri
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 43</Typography>Pružalac ne vrši obradu podataka po pravnom osnovu ostvarivanja legitimnog interesa
                            iz čl. 12, st. 1. tč. 6) Zakona, ali radi celovitosti pravnog uređenja materije koja se odnosi na zaštitu podataka o ličnosti
                            konstatuje se da je Poverenik na svojoj internet prezentaciji na stranici pod nazivom „Legitimni interes kao pravni osnov za obradu
                            podataka o ličnosti“, koja se nalazi na adresi:
                            <br />
                            <a
                                target="_blank"
                                href="https://www.poverenik.rs/images/stories/dokumentacija-nova/ZZPLnajcescapitanja/latFAQ-Legitimni_interes_radna4.docx"
                            >
                                https://www.poverenik.rs/images/stories/dokumentacija-nova/ZZPLnajcescapitanja/latFAQ-Legitimni_interes_radna4.docx
                            </a>
                            <br />
                            objavio Dokument koji sadrži odgovore na najčešće postavljanja pitanja u vezi sa primenom legitimnog interesa, kao pravnog osnova za
                            obradu podataka o ličnosti u verziji 1.0 od 12.5.2020. godine (u daljem tekstu: Dokument), u kome je eksplicitno napisano: 1) da ne
                            postoji izričito propisana obaveza rukovaoca da pre započinjanja nameravane obrade podataka o ličnosti treba da izvrši pažljivu
                            procenu ispunjenosti uslova za primenu legitimnog interesa kao pravnog osnova za obradu, i 2) da ne postoji obavezujuća forma u
                            kojoj pomenuti akt treba da bude sačinjen. U skladu sa Zakonom i Dokumentom, konstatuje se da Pružalac ima pravnu mogućnost da vrši
                            obradu podataka o ličnosti na osnovu legitimnog interesa bez da donese bilo koji akt (u bilo kojoj formi) koji se odnosi na procenu
                            ispunjenosti uslova za primenu legitimnog interesa kao osnova za obradu podataka o ličnosti, jer takva obaveza ne postoji.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            16 - PRELAZNE I ZAVRŠNE ODREDBE
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 44</Typography>Stupanjem na snagu ovog Pravilnika, prestaje da važi prethodno doneti Pravilnik o
                            zaštiti podataka o ličnosti.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 45</Typography>Ovaj Pravilnik je sačinjen ravnoglasno na srpskom i engleskom jeziku od kojih
                            prednost u tumačenju ima srpski jezik.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Član 46</Typography>Ovaj Pravilnik stupa na snagu osmog dana od dana donošenja, odnosno objavljivanja na
                            oglasnoj tabli i/ili elektronskoj oglasnoj tabli Pružaoca koja se nalazi na adresi{" "}
                            <a target="_blank" href="https://www.domen.rs/dokumenti/">
                                https://www.domen.rs/dokumenti/
                            </a>{" "}
                            u skladu sa članovima 7-10 Zakona o elektronskom dokumentu, elektronskoj identifikaciji i uslugama od poverenja u elektronskom
                            poslovanju ("Sl. glasnik RS", br. 94/2017 i 52/2021), kao i na drugoj javno dostupnoj internet adresi.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            u Beogradu,
                            <br />
                            doneto i objavljeno dana 10.12.2022. godine
                            <br />
                            stupa na snagu dana 18.12.2022. godine
                        </Typography>
                    </Box>
                    <Box className={classes.tncRightBox}>
                        <Typography>
                            Pursuant to Article 41 of the Law on Personal Data Protection ("Official Gazette of RS", No. 87/2018, hereinafter: Law),{" "}
                            <b>SMART PART LLC BELGRADE</b>, 3 Borivoja Stevanovica str., apt. 30, 11050 Belgrade - Vozdovac, Serbia, Reg. no.: 21836265, TIN:
                            113271683, e-mail address: smartpart.app@gmail.com (hereinafter: Provider or Controller) represented by the director Vladimir
                            Jovičić, adopts the following:
                        </Typography>
                        <br />
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            RULEBOOK ON THE PROTECTION OF PERSONAL DATA WITH NOTICE OF DATA COLLECTION AND PROCESSING
                        </Typography>
                        <br />
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            1 - NOTICE ON COLLECTION AND PROCESSING OF PERSONAL DATA AND GENERAL NOTES
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 1</Typography>
                            The Provider of information society service respects the privacy of natural persons: its employees (hereinafter: Employees),
                            employment candidates (hereinafter: Candidates), natural persons entering into a contractual relationship with the Provider in
                            accordance with the Terms of Use (hereinafter: Users), and visitors to the Provider's website (hereinafter: Visitors) and perform
                            the collection and processing of personal data only in accordance with this Rulebook on the Protection of Personal Data
                            (hereinafter: Rulebook).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 2</Typography>
                            By this notice, the Provider notifies natural persons of the collection and processing of personal data it implements in accordance
                            with the Law on Personal Data Protection and other regulations of the Republic of Serbia, which are harmonized with the General
                            Regulation on Data Protection of the European Union (Regulation (EU) 2016/679 of the European Parliament and of the Council of
                            27.04.2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data
                            and repealing Directive 95/46/EC, hereinafter: GDPR)
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 3</Typography>In accordance with the Law, the Provider has the legal capacity of the Controller.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 4</Typography>U cilju zaštite podataka o ličnosti, Pružalac primenjuje odgovarajuće tehničke, In
                            order to protect personal data, the Provider applies appropriate technical, organizational and personnel measures and constantly
                            works on their improvement to ensure that personal data is handled in accordance with applicable regulations and the consent of the
                            person (when such consent is required in accordance with the law). In this regard, the Provider may periodically change the
                            provisions of this Rulebook, in which case it will ensure that an updated or valid version is available on the Bulletin board, or
                            electronic service that serves as the bulletin board, with a first date of application clearly stated
                            <br />
                            Among the rest, this Rulebook regulates the obligations of Employees regarding the protection of personal data.
                            <br /> The Provider informs the Employees about the changes to the Rulebook directly, while the Candidates are obliged to get
                            acquainted with the provisions of the current version of the Rulebook on their own. <br />
                            <br /> If the Candidate does not agree with any provision of this Rulebook, it is necessary to refrain from submitting personal data
                            in any way, i.e. it is necessary for the Candidate to withdraw the consent for the processing of personal data in accordance with
                            the Rulebook.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            2 - INFORMATION ON THE CONTROLLER
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 5</Typography>
                            The personal data Controller is:
                            <br />
                            Business name: <b>SMART PART DOO BEOGRAD</b>
                            <br />
                            Seat Address: 3 Borivoja Stevanovica str., apt. 30, 11050 Belgrade - Vozdovac, Republic of Serbia
                            <br />
                            Company no.: 21836265
                            <br />
                            TIN: 113271683
                            <br />
                            Email: smartpart.app@gmail.com
                            <br />
                            Business activity code: 6202 - Konsultantske delatnosti u oblasti informacione tehnologije
                            <br />
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 6</Typography>
                            Contact details of the Controller for exercising the right to protection of personal data are:
                            <br />
                            <b>SMART PART DOO BEOGRAD</b>
                            <br />
                            3 Borivoja Stevanovica str., apt. 30, 11050 Belgrade - Vozdovac, Republic of Serbia
                            <br />
                            Working hours: Monday to Friday 9:00-17:00h
                            <br />
                            Tel: +381 61 185 1850 (Monday to Friday 9:00-17:00h)
                            <br />
                            Email: smartpart.app@gmail.com
                            <br />
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            3 - METHOD OF DATA COLLECTION
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 7</Typography>
                            The personal data related to the <b>Employees</b>, are collected by the Provider as follows:
                            <br />- <b>directly from a natural person, when the Employee directly submits personal data</b>, when concluding an employment
                            contract or annex, when Employees directly submit personal data relating to work, verbally or in writing (including electronic
                            form), and
                            <br />
                            <br />- <b>by data processing</b>, in which case the Provider or an engaged third party on behalf of the Provider (e.g. accountant)
                            uses Employee data for mandatory registration of Employees and calculation of salaries and payment of taxes and contributions, in
                            accordance with this Rulebook, laws and regulations which regulate labour relations.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 8</Typography>
                            The personal data related to the <b>Candidates</b>, are collected by the Provider as follows:
                            <br />- <b>directly from a natural person</b>, when a person directly submits data to the Provider, when a natural person addresses
                            the Provider by mail, email or telephone, for the purpose of applying for employment, and
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 9</Typography>
                            The personal data related to the <b>Users</b>, are collected by the Provider as follows:
                            <br />- <b>directly from a natural person</b>, when a person directly submits their data to the Provider, when concluding a
                            particular legal transaction (except for a employment contract), and <br />
                            <br />- <b>by data processing</b>, in case the User has publicly disclosed personal data when the Provider processes the published
                            data, or when a third party is engaged on behalf of the Provider (eg agency, broker) use the User 's personal data to solicitation
                            and possibly enter into certain legal transaction (e.g., conclude certain agreement), in accordance with this Rulebook, the
                            Provider’s Terms of Use, and laws and bylaws which regulate the contractual relations.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 10</Typography>
                            The personal data related to the <b>Visitors</b> are collected by the Provider as follows:
                            <br />- <b>automatically</b>, when using websites, in which case the Provider or third party engaged on behalf of the Provider uses
                            so-called "cookie" technologies to adequately display content and track the amount of visits and movements on the Provider's
                            websites and so on (hereinafter: "cookie"), in accordance with this Rulebook.
                            <br />
                            <br />- <b>directly from a natural person</b>, when the person directly submits their data to the Provider, i.e., when a natural
                            person contacts the Provider via the website, by e-mail, or by telephone
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            4 - DATA PROCESSED BY THE PROVIDER, METHOD, AND BASIS OF USE
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 11</Typography>
                            The Provider collects data that is appropriate, relevant and limited to what is necessary in relation to the specific purpose of
                            processing.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 12</Typography>
                            The Provider processes personal data related to <b>Employees</b> in the following manner and on the following basis:
                            <br />
                            1) The Provider uses personal data of Employees to achieve the purpose specified in this Rulebook, the Labour Rulebook, the
                            Employment Agreement and laws and bylaws. For each processing undertaken by the Provider, there is an appropriate basis prescribed
                            by the laws governing the field of labour or labour relations, mandatory labour records, compulsory social and health insurance,
                            taxes and accounting, as well as other laws of the Republic of Serbia.
                            <br />
                            <br />
                            2) The Provider collects personal data of Employees in order to comply with the Employment Agreement and fulfil legal obligations
                            and processes them in the manner prescribed by law to exercise the labour rights of employees (such as the right to salary,
                            vacation, leave due to incapacity for work, safety and health at work, calculation and payment of taxes and contributions, etc.), as
                            well as to property keep legally required records.
                            <br />
                            <br />
                            3) By concluding the Employment Agreement, the Employee agrees that the Employer can enter, copy, transfer, use, store and process
                            his/her personally identifiable information, either from his/her personal record, or from official records such as personal identity
                            card, workbook (if the Employee provided it), application form for health and pension insurance, certificates, diplomas and other;
                            for the purpose of exercising employment rights and rights based on employment, such as determining salaries, wage allowances and
                            other earnings of the Employee, training and professional development, career planning, occupational safety and health at work, risk
                            management or similar purposes. This consent is given in relation to the Employer and third persons authorized by the Employer, in
                            particular to accountants, legal service providers, banks, brokers and social security organizations (authorized third parties).
                            <br />
                            <br />
                            4) in relation to <b>Employees</b> the provider processes:
                            <br />
                            4.1) <b>Data to be processed</b>: name, surname, address of residence (place, postal code, street, house number), citizenship, date
                            and place of birth, sex, marital status, unique identification number (JMBG), ID card number, health insurance number (LBO), degree
                            and type of education, knowledge of foreign languages, knowledge acquired in training, employment history, CV, bank account number,
                            data on earnings and income from employment, data on performance of work obligations including performance appraisals, email address
                            assigned by the employer, IP address of access to electronic services used by the employer.
                            <br />
                            <b>Purpose of processing</b>: execution of the employment contract and compliance with legal obligations.
                            <br />
                            <b>Legal basis of processing</b>: compliance with legal obligations arising from the Employment Agreement, the Labour Law, the Law
                            on Labour Records, the Law on Contributions for Compulsory Social Insurance, the Law on Personal Income Tax, the Law on Safety and
                            Health at Work, and other regulations governing obligations of the Provider as an Employer (Art. 12, par. 1, item 3) of the Law).
                            <br />
                            4.2) <b>Data to be processed</b>: telephone number, e-mail address of the employee, contact of a close person for emergencies.
                            <br />
                            <b>Purpose of processing</b>: communication with employee and communication in emergencies.
                            <br />
                            <b>Legal basis of processing</b>: consent of the Employee (Art. 12, par. 1, item 1) of the Law).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 13</Typography>
                            Personal data related to <b>Candidates</b>, the Provider processes as follows and on the following basis:
                            <br />
                            <br />
                            1) The Provider uses the personal data of the Candidates for employment to achieve the purpose specified in this Rulebook, the
                            Labour Rulebook, the Employment Agreement and the laws and bylaws. For each processing undertaken by the Provider, there is an
                            appropriate basis prescribed by the laws governing the field of work or employment brokerage, as well as other laws of the Republic
                            of Serbia.
                            <br />
                            <br />
                            2) The Provider collects personal data of the Candidate for employment for the purpose of negotiating and preparing for the
                            potential conclusion of the Employment Agreement and fulfilment of legal obligations and processes them in the manner prescribed by
                            law to exercise the right to work and keep proper records of potential candidates. The Provider may collect personal data related to
                            the Candidate for employment, as well as at their request (e.g., application for a vacancy) to process personal data for the purpose
                            of selecting the Candidate before concluding the Employment Agreement.
                            <br />
                            <br />
                            3) in relation to <b>Candidates</b>, the provider processes:
                            <br />
                            <b>Data to be processed</b>: name, surname, address of residence (place, postal code, street, house number), citizenship, unique
                            identification number (JMBG), degree and type of education, knowledge of foreign languages, knowledge acquired in training,
                            employment history, CV, telephone number, email address. <br />
                            <b>Purpose of processing</b>: Preparation for the conclusion of the employment contract, i.e., selection of Candidates for
                            employment and communication.
                            <br />
                            <br />
                            <b>Legal basis of processing</b>: Consent of the Candidate (Art. 2, par. 1, item 1) of the Law) as well as the necessity of
                            processing the request of the data subject, before concluding the contract (Art. 12, par. 1, item 2) of the Law).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 14</Typography>
                            Personal data relating to the <b>User</b>, the Provider processes as follows and on the following basis:
                            <br />
                            <br />
                            1) The Provider uses personal information about the User to achieve the purpose stipulated in this Rulebook, the Terms of Use of the
                            Provider, and other contracts governing obligations, laws, and bylaws. For each processing undertaken by the Provider, there is an
                            appropriate basis prescribed by the laws governing the area of obligations, as well as other laws of the Republic of Serbia.
                            <br />
                            <br />
                            2) The Provider collects the user's personal data for the purpose of conducting negotiations and preparing for the potential
                            conclusion of certain legal work and fulfilment of legal obligations and processes them in the legally prescribed ways of fulfilling
                            the assumed obligations under such a contractual relationship. The Provider may collect personal data relating to the User for the
                            conclusion of the contract, and at their request process personal data for the purpose of selecting the User before concluding a
                            particular contraca
                            <br />
                            <br />
                            3) in relation to <b>Users</b>, the Provider processes:
                            <br />
                            <b>Data to be processed</b>: name, surname, address of residence (place, postal code, street, house number), mobile phone number,
                            e-mail address. In addition to the above, the Provider also processes car data (make, model, year of manufacture, motor, chassis
                            number) which are not considered personal data as such.
                            <br />
                            <b>Purpose of processing</b>: provision of information society services in as the advertising certain User needs related to the
                            possibility of buying/selling a certain spare car part, as well as marketing purposes, i.e. informing the User about news
                            transmitted by the Provider.
                            <br />
                            <b>Legal basis of processing</b>: consent of the User (Art. 12, par. 1, item 1) of the Law).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 15</Typography>
                            Personal data relating to <b>Visitors</b>, the Provider processes as follows and on the following basis:
                            <br />
                            <br />
                            1) The Provider uses the personal data of the Visitors to achieve the purpose specified in this Rulebook. For each processing
                            undertaken by the Provider, there is an appropriate basis prescribed by the Law on Personal Data Protection and other laws of the
                            Republic of Serbia.
                            <br />
                            <br />
                            2) The Provider can process only some and/or all of the data specified in this article. The Provider is constantly working on
                            developing websites and improving the available functionality of its websites, allowing the processing of some of the data below to
                            begin later, as the functionality of the website has changed. Also, the volume of data processed by the Provider depends, for
                            example, on how and for what reasons the Visitor contacts the Provider or how the Visitor uses websites.
                            <br />
                            <br />
                            3) Data that are directly collected, the Provider may compare, and combine with data collected automatically, thus data collected as
                            anonymous (for example, the frequency of visits to certain content on a website) may become personal data (for example, how often a
                            user visits certain content on a website). This allows the Provider to provide a personalized experience when visiting the website.
                            <br />
                            <br />
                            4) in relation to <b>Visitors</b> the provider processes:
                            <br />
                            4.1) <b>Data to be processed</b>: name, surname, e-mail address.
                            <br />
                            <b>Purpose of processing</b>: to communicate with the Visitor – to answer the questions and requests that the Provider receives (for
                            instance via e-mail).
                            <br />
                            <b>Legal basis of processing</b>: consent of the Visitor (Art. 12, par. 1, item 1) of the Law).
                            <br />
                            <br />
                            4.2) <b>Data to be processed</b>: name, surname, address of residence (place, postal code, street, house number), telephone number,
                            e-mail address
                            <br />
                            <b>Purpose of processing</b>: resolving complaints regarding the appropriateness of delivery of digital content that was not
                            delivered on a permanent medium..
                            <br />
                            <b>Legal basis of processing</b>: compliance with legal obligations arising from the consumer protection (Art. 12, par. 1, Item 3)
                            of the Law).
                            <br />
                            <br />
                            4.3) <b>Data to be processed</b>: device type (computer, tablet, smartphone), IP address, device operating system, web browser used
                            to access the website.
                            <br />
                            <b>Purpose of processing</b>: adjusting the display of information to the device on which it is displayed, i.e., that the content of
                            the website is optimally displayed on the device being used.
                            <br />
                            <b>Legal basis of processing</b>: compliance with legal obligations to show the content to the Visitor and to display the requested
                            information (Art. 12, par. 1, item 3) of the Law).
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 16</Typography>
                            If there is a subsequent need to process personal data for a purpose other than that for which the data were collected, the Provider
                            will provide persons with information about that other purpose, as well as any other information in accordance with by law.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 17</Typography>
                            The Provider may anonymize and use certain data for statistical and analytical purposes, so that the individuals to whom the data
                            relate cannot be directly or indirectly identified.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            5 - "COOKIE" DATA – COOKIES
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 18</Typography>A cookie is information saved on the visitor's computer by the website they visit.
                            Cookies usually store the visitor's preferences, settings for the website, such as preferred language or address. Later, when the
                            visitor opens the same Internet page again, the Internet browser sends back the cookies belonging to that page. This allows the page
                            to recognize the visitor and show him information tailored to his needs. If the visitor does not want a website to have access to
                            his cookies, visitor can block them. Most web browsers allow cookies to be blocked. The visitor can do this through the web browser
                            settings.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 19</Typography>
                            The Provider's website uses "cookie" data to provide visitors with the best user experience. They enable the Provider to, for
                            example, display the content of the website in an adequate way on each device, i.e., web browser through which the visitor accesses
                            it, or for the content that Provider places on the website to be adapted to the interests of the visitors. The information collected
                            by the Provider in this way may or may not be personal data in some cases. Since the Provider respects user privacy, this section
                            contains explanations of what cookies are and how they are used, as well as to inform visitors that in certain cases each visitor
                            can manage the way cookies are used, and in connection with the use of the Provider's website. When the website allows the visitor
                            not to choose or turn off certain "cookies" and the visitor decides to use that option, it can reduce the functionality of the
                            website. Also, it is necessary to keep in mind that the settings, which the visitor decides to turn off, will have to be turned off
                            for each other device or Internet browser separately.
                            <br />
                            Cookies are simple text files that are stored in the visitor's internet browser, i.e., on visitor’s device. The task of the "cookie"
                            is first to enable the website to "recognize" the visitor when the same visitor accesses it the next time. In that situation, the
                            website uses the data saved in the "cookie" and thus automatically receives information about the previous activity of the visitor
                            on the website. Cookies cannot access the data that the visitor stores on the devices, but they can collect data about the visitor's
                            online activities. Cookies are not harmful to the visitor or visitor’s device used for accessing the Internet and should not be
                            confused with viruses. Cookies do not contain viruses or other malicious code. Depending on how long they remain in the visitor's
                            internet browser, they are differentiated as "session cookies" (they are kept only during a specific browsing session and are
                            automatically deleted after its end, and are used to enable access to specific content) or permanent "cookies" ” (longer or shorter
                            period, "remembers" information for subsequent visits to the website, is retained until it is deleted manually or until it expires
                            according to the expected duration, e.g. "cookie" that allows the website to "remember" data for logging in in the case of creating
                            an account or tracking cookie).
                            <br />
                            According to who they belong to, there are first-party "cookies" ("cookies" set by the Provider as the owner of the Internet pages
                            accessed by visitors) and third-party "cookies" ("cookies" set by partner companies selected by the Provider that provide various
                            services, e.g., analytical, because they collect and process certain data about the way the websites are used and deliver the
                            information to the Provider in an anonymized form) that enable visitors to be shown related content on the websites of partner
                            companies or other locations on the Internet.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 20</Typography>
                            Cookies can contain different information and be used for different purposes. The Provider uses the following types of cookies:
                            <br />
                            <br />
                            <b>MANDATORY - absolutely necessary cookies</b> - Mandatory cookies make the site usable by enabling basic functions such as site
                            navigation and access to protected content. The Provider uses cookies that are necessary for the proper functioning of the
                            Provider's website, to enable certain technical functions and thus enable visitors to have a positive experience visiting the pages.
                            <br />
                            <br />
                            <b>PERMANENT - necessary for easy access</b> - These cookies usually have an expiration date far in the future and as such will
                            remain in the visitor's internet browser until they expire, or until they are manually deleted. The provider uses permanent cookies
                            for functionalities such as "stay logged in" and the like, which makes it easier for the visitor to access if there is a login
                            functionality. Also, the Provider uses permanent cookies to have a better understanding of the visitor's habits, to be able to
                            improve the website according to this understanding. This information is anonymous - individual visitor data is not visible.
                            <br />
                            <br />
                            <b>STATISTICS - statistical cookies</b> - Statistical cookies, by anonymously collecting and sending data, help site owners to
                            understand how visitors interact with the site. These are cookies that enable the Provider to perform web analytics, i.e., analysis
                            of the use of websites and measurement of visits, which the Provider carries out to improve their quality and content.
                            <br />
                            <br />
                            <b>MARKETING - cookie data for marketing purposes</b> - Marketing cookies are used to track visitors through websites. They are used
                            to show relevant ads to the visitors and encourage them to participate, which is important to publishers and third-party
                            advertisers.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 21</Typography>
                            Visitors can manage the use of cookies by selecting the appropriate settings in their Internet browser. More information can be
                            found at the following links:
                            <br />
                            <br />
                            <b>- Google Chrome</b>:
                            <br />
                            "Clear, enable, and manage cookies in Chrome"
                            <br />
                            English:{" "}
                            <a target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en">
                                https://support.google.com/chrome/answer/95647?hl=en
                            </a>
                            <br />
                            <br />
                            <b>- Firefox</b>:
                            <br />
                            "Enable and disable cookies that websites use to track your preferences"
                            <br />
                            English:{" "}
                            <a target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
                                https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                            </a>
                            <br />
                            <br />
                            <b>- Internet Explorer / Microsoft Edge</b>:
                            <br />
                            "Delete and manage cookies"
                            <br />
                            English:{" "}
                            <a target="_blank" href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64">
                                https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64
                            </a>
                            <br />
                            <br />
                            <b>- Safari</b>:
                            <br />
                            „Manage cookies and website data”
                            <br />
                            English:{" "}
                            <a target="_blank" href="https://help.apple.com/safari/mac/8.0/#/sfri11471">
                                https://help.apple.com/safari/mac/8.0/#/sfri11471
                            </a>
                            <br />
                            <br />
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            6 - PROFILING
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 22</Typography>
                            Profiling is a form of automated data processing that is used to assess a certain personality trait, especially in order to analyse
                            or predict personal preferences, interests, behaviours, etc. Based on the data thus obtained, the Provider may profile Candidates
                            for employment, in order to pre-select or select Candidates.
                            <br />
                            The Provider does not perform profiling of the Users and/or Vistitors, whatsoever.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            7 - DATA THAT THE PROVIDER DOES NOT PROCESS
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 23</Typography>
                            The Provider does not collect or process special types of personal data, such as data relating to racial or ethnic origin, political
                            opinion, religious or philosophical beliefs or trade union membership (except as required by law), genetic data, biometric data for
                            the purpose of unique identification of persons, data on sexual life or sexual orientation, as well as data related to criminal
                            convictions, criminal offenses and misdemeanours, security measures and other measures imposed by courts. In that sense, natural
                            persons are informed that they shall not disclose or send this information to the Provider.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 24</Typography>
                            Health data is not collected or processed by the Provider except in the case of:
                            <br />
                            - Employees of the Provider who have been temporarily incapability in accordance with the regulations on health insurance for the
                            purpose of exercising employment rights - sick leave,
                            <br />
                            <br />- Candidates who are entitled to incentives for employment in order to create conditions for equal inclusion of persons with
                            disabilities in the labour market in accordance with the Professional Rehabilitation and Employment of Persons with Disabilities
                            Act.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 25</Typography>
                            The Provider shall never intentionally or purposefully collect and process data on minors, but the possibility of providing
                            inaccurate data by individuals cannot be ruled out. If the parent or legal guardian of a minor receives information that the data of
                            that minor have been submitted to the Provider, it is necessary to inform the Provider without delay so that the Provider can take
                            appropriate measures and stop further processing of this data and destroy such data.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            8 - OBLIGATION TO PROVIDE DATA
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 26</Typography>In most cases, there is no obligation to provide personal data to the Provider,
                            but the natural person does so on a voluntary basis, in limited cases when the person contacts the Company and provides own contact
                            information so that the Company can answer questions or requests.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 27</Typography>In some cases, <b>legal obligation</b> to provide personal information may exist.
                            For natural persons who have the status of an Employee with the Company, the obligations to submit data are prescribed by:
                            <br />
                            <br />
                            - Labour Law ("Official Gazette of RS", No. 24/2005, 61/2005, 54/2009, 32/2013, 75/2014, 13/2017 - CC decision, 113/2017 and 95/2018
                            - authentic interpretation),
                            <br />
                            - Law on Labour Records ("Official Gazette of the FRY", No. 46/96 and "Official Gazette of the RS", No. 101/2005 - other law and
                            36/2009 - other law),
                            <br />
                            - Law on Contributions for Compulsory Social Insurance "Official Gazette of RS", No. 84/2004, 61/2005, 62/2006, 5/2009, 52/2011,
                            101/2011, 7/2012 - adjusted amount, 8/2013 - adjusted amount, 47/2013, 108/2013, 6/2014 - adjusted amount, 57/2014, 68/2014 - other
                            law, 5/2015 - adjusted amount, 112/2015, 5/2016 - adjusted amount, 7/2017 - adjusted amount, 113/2017, 7/2018 - adjusted amount,
                            95/2018, 4/2019 - adjusted amount, 86/2019, 5/2020 - adjusted amount, 153/2020, 6/2021 - adjusted amount, 44/2021, 118/2021 and
                            10/2022 - adjusted amount)
                            <br />
                            <br />
                            For natural persons with whom the Provider enters into a contractual relationship regarding the provision of its services (e.g.,
                            Users) or a leasing contract or other obligation relationship, the obligations to provide data are prescribed:
                            <br />
                            - Law on Obligations and Torts ("Official Gazette of the SFRY", no. 29/78, 39/85, 45/89 - decision of the USJ and 57/89, "Official
                            Gazette of the SFRY", no. 31/93, "Official list SCG", No. 1/2003 - Constitutional Charter and "Official Gazette of RS", No.
                            18/2020). <br />- Law on Execution and Securities ("Official Gazette of RS", no. 106/2015, 106/2016 - authentic interpretation,
                            113/2017 - authentic interpretation and 54/2019).
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            9 - RECIPIENTS OF PERSONAL DATA – AUTHORIZED THIRD PARTIES
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 28</Typography>Personal data is processed and used primarily by the Provider.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 29</Typography>In some cases, it may be necessary for the Provider to make personal data
                            available to third parties. These are authorized third parties, i.e., economic entities that are engaged in personnel, accounting or
                            legal services, or provide analytical or other services to the Company. In order to ensure that the Provider handles personal data
                            in a lawful manner, the Provider has entered into adequate agreements with authorized third parties relating to the processing of
                            personal data which, inter alia, prescribe the obligation of such persons to handle data exclusively in accordance with instructions
                            of the Provider and this Rulebook, so that every person, regardless of the stated processing, has the rights established by the Law
                            and this Rulebook.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 30</Typography>Data collected and processed from Employees by the Provider for the purpose of
                            fulfilling employment obligations are being submitted to the Enodatio ltd Belgrade-Voždovac, Druge Srpske Armije 9 str., 00rd floor
                            0, apt. 5, 11000 Belgrade – Voždovac, Serbia, mail address: enodatiodoo@gmail.com, reg. no: 2112510, TIN: 109091282, main activity:
                            6920 - Accounting, bookkeeping and auditing activities; tax consultancy, for the purpose of calculation and payment of salaries and
                            related taxes and contributions.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 31</Typography>Also, in certain legal situations, there may be a legal obligation for the
                            Provider to make personal data available to the competent authorities (e.g., court, district attorney, etc.).
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            10 - TRANSFER OF DATA ABROAD
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 32</Typography>The Provider uses personal data on the territory of the Republic of Serbia.
                            <br />
                            <br /> In addition, the data collected by the Provider is stored on servers which are owned and controlled by the AWS hosting
                            company Amazon.com, Inc. seat address: 251 Little Falls Drive, City of Wilmington, County of New Castle, State of Delaware 19808,
                            USA. In accordance with the policy of the hosting company:
                            <a href="https://docs.aws.amazon.com/whitepapers/latest/navigating-gdpr-compliance/welcome.html">
                                https://docs.aws.amazon.com/whitepapers/latest/navigating-gdpr-compliance/welcome.html
                            </a>
                            , a high degree of technical and software protection of these servers is provided, while access to the data stored on them is not
                            allowed.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 33</Typography>
                            Transfer of data abroad is possible only and exclusively when the contractual obligation of the Provider can be fulfilled only and
                            exclusively by submitting certain data abroad, and only to the extent necessary for the fulfilment of that contractual obligation.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 34</Typography>
                            The Provider may use external service providers such as Google, Slack, etc. with which it has concluded appropriate contracts that
                            enable the processing of certain data by those suppliers according to the provisions of those contracts and the GDPR, which enable
                            the highest level of data protection and compliance with this Rulebook.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 35</Typography>In the event that the Provider needs to make personal data available to recipients
                            in countries deemed not to provide an adequate level of protection of personal data, the Provider undertakes to provide an
                            appropriate basis for such transfer, including the possible application of appropriate standard contractual clauses. supervision, or
                            other appropriate protection measures, by which the recipient will be obliged to protect personal data in a manner that is in
                            accordance with the standards of protection established by the regulations of the Republic of Serbia.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            11 - DATA RETENTION PERIOD
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 36</Typography>The Provider stores personal data only for the period that is necessary to fulfil
                            the purpose for which the data was collected, and then safely removes it from its systems and deletes it.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 37</Typography>The personal data referred to in this Rulebook shall be kept by the Provider for
                            the shortest necessary period, except when the regulations require that the data be kept for a certain period. When the retention
                            period for keeping personal data is prescribed by law, the Controller will keep the data within the given legal deadline. After
                            fulfilling the purpose, like the expiration of the legally prescribed period for data retention, the data will be permanently
                            deleted. Law mandates employment data to be kept permanently.
                            <br />
                            For the purpose of subsequent assessment of the need to hire Candidate for a certain position, data on Candidates for employment are
                            retained for 2 years, from the end of the process of selecting candidates for that position, after which data is permanently
                            deleted.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            12 - DATA PROCESSING RIGHTS
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 38</Typography>The natural person to whose personal data is being processed, under certain
                            conditions, has rights related to the processing of personal data, which are guaranteed by law, namely:
                            <br />
                            <br />
                            <b>- Right of access</b> – a natural person has the right to request information on whether the Provider processes personal data, as
                            well as access to such data. At the request of the person, the Provider will provide a copy of the data it processes.
                            <br />
                            <br />
                            <b>- Right to correction and amendment</b> – a natural person has the right to correct inaccurate data related to him/her without
                            undue delay, as well as to amend incomplete data, which includes giving an additional statement
                            <br />
                            <br />
                            <b>- Right to delete data</b> – a natural person has the right to request the deletion of his data, in particular:
                            <br /> a) if the data is no longer necessary for the purpose for which they were collected or otherwise processed;
                            <br /> b) if the consent on the basis of which the processing was performed has been revoked, and there is no other legal basis for
                            the processing;
                            <br /> c) if an objection to processing has been filed in accordance with the law.
                            <br />
                            <br />
                            <b>- Right to limit processing</b> – a natural person has the right to restrict data processing if one of the following cases is
                            met:
                            <br />
                            a) if person disputes the accuracy of the data, within the time limit that enables the accuracy of the personal data to be verified;{" "}
                            <br />
                            b) if the processing is illegal and the person opposes the deletion of personal data and instead of deleting requests a restriction
                            on the use of data;
                            <br />
                            c) if the Provider no longer needs personal data for the purpose of processing, but the person requests data in order to submit,
                            realize or defend a legal claim; <br />
                            d) if the person has filed an objection to the processing carried out on the basis of the legitimate interest of the Controller, and
                            the assessment of whether the legal basis for processing by the Provider outweighs the interests of the person is in progress.
                            <br />
                            <br />
                            <b>- Right to object</b> – if the person deems it is justified in relation to the special situation in which he finds
                            himself/herself, the natural person has the right to object at any time to the processing of personal data based on the legitimate
                            interest of the Controller, including profiling based on those provisions.
                            <br />
                            <br />
                            <b>- Data portability right</b> – for the personal data previously submitted to the Provider, a natural person has the right to
                            receive from the Provider in a structured, commonly used and electronically readable form, and the right to transfer this data to
                            another controller without interference, if processing is based on consent or contract and if it is done automatically. Also, a
                            natural person has the right to have this data transferred directly to another controller, if it is technically feasible
                            <br />
                            <br />
                            <b>- Right to revoke consent</b> – if the processing is performed with the consent of the person, the natural person has the right
                            to revoke the consent at any time, where the revocation of consent does not affect the validity of processing on the basis of
                            consent before revocation.
                            <br />
                            <br />
                            <b>- Right to file a complaint</b> – a natural person has the right to file a complaint regarding the processing of his/her personal
                            data to the Commissioner for Information of Public Importance and Personal Data Protection whose contact details are:
                            <br />
                            <br />
                            <b>Commissioner for Information of Public Importance and Personal Data Protection</b>
                            <br />
                            15, Bulevar kralja Aleksandra str, Belgrade 11120
                            <br />
                            Working hours: Monday to Friday 7:30-15:30h
                            <br />
                            Tel: +381 11 3408 900, Fax: +381 11 3343 379
                            <br />
                            Email: office@poverenik.rs
                            <br />
                            Website: www.poverenik.rs
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            13 - DATA SECURITY
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 39</Typography>Data security is very important. Therefore, the Provider takes physical,
                            technical, and electronic protection measures to prevent accidental or unlawful destruction, loss, alteration, unauthorized
                            disclosure or access to personal data. These measures are aimed at both persons outside the Provider and persons within the
                            Provider, because access to data is limited only to those persons whose duties necessarily require such access and who have been
                            instructed in the application of regulations on personal data protection.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 40</Typography>As there are no impenetrable security systems, the Provider cannot guarantee that
                            the security of systems over which it has direct control will never be compromised. In case of violation of personal data, the
                            Provider will take all available measures and inform the competent authorities in accordance with regulations, as well as
                            individuals (natural persons) whose data are in question, whenever possible.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            14 - OBLIGATIONS OF EMPLOYEES
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 41</Typography>Employees are obliged to respect and protect the personal data they process.
                            <br /> Employees may process only those data to which they are allowed access, in accordance with the established job description,
                            ie work tasks they perform.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            15 - FINAL FINDINGS
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 42</Typography>Hereby it is concluded that the Provider is not obliged to assess the impact of
                            processing on personal data protection and obtain the prior opinion of the Commissioner in terms of Article 54 of the Law, because
                            it does not conduct 1) systematic and comprehensive assessment of the condition and characteristics of individuals. processing of
                            personal data, including profiling, on the basis of which decisions are made that are important for the legal position of an natural
                            person or in a similar way can significantly affect that person; nor 2) processes special types of personal data referred to in Art.
                            17, par. 1 and Art. 18, par. 1, or personal data related to criminal convictions and criminal offenses referred to in Art. 19 of the
                            Law, to a large extent; nor does it perform 3) systematic supervision of publicly accessible areas to a large extent.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 43</Typography>The Provider does not process data on the legal basis of the legitimate interest
                            referred to in Art. 12, par. 1. item 6) of the Law, but for the sake of completeness of the legal regulation of the matter related
                            to the protection of personal data, hereby it is concluded that the Commissioner is on his website on the page entitled "Legitimate
                            interest as a legal basis for processing personal data", which is located at URL:
                            <br />
                            <a
                                target="_blank"
                                href="https://www.poverenik.rs/images/stories/dokumentacija-nova/ZZPLnajcescapitanja/latFAQ-Legitimni_interes_radna4.docx"
                            >
                                https://www.poverenik.rs/images/stories/dokumentacija-nova/ZZPLnajcescapitanja/latFAQ-Legitimni_interes_radna4.docx
                            </a>
                            <br />
                            published a document containing answers to the most frequently asked questions regarding the application of legitimate interest, as
                            a legal basis for processing personal data in the version 1.0 from 12.5.2020. (hereinafter: the Document), in which it is explicitly
                            written: 1) that there is no explicit obligation of the controller to perform a careful assessment of the fulfilment of conditions
                            for the application of legitimate interest as a legal basis for processing before starting the intended processing of personal data,
                            and 2) that there is no binding/legal form in which the mentioned act should be made. In accordance with the Law and the Document,
                            it is stated that the Provider has the legal ability to process personal data based on legitimate interest without adopting any act
                            (in any form) relating to the assessment of eligibility for legitimate interest as a basis for processing of personal data, because
                            such an obligation does not exist.
                        </Typography>
                        <Typography align="center" variant="h6" className={classes.beBold}>
                            16 - TRANSITIONAL AND FINAL PROVISIONS
                        </Typography>
                        <br />
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 44</Typography>By entering into legal force of this Rulebook, the previously adopted Rulebook on
                            Personal Data Protection shall cease to be valid.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 45</Typography>This Rulebook is made equal in Serbian and English language, of which Serbian
                            language shall prevail.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            <Typography align="center">Article 46</Typography>Article 46 This Rulebook shall enter into force on the eighth day from the day of
                            adoption and publication on the Bulletin board and/or electronic Bulletin board of the Provider located on the address
                            https://www.domen.rs/dokumenti/ in accordance with Articles 7-10 of the Law on Electronic Document, Electronic Identification and
                            Trust Services in Electronic Business ("Official Gazette of RS", No. 94/2017 and 52/2021), as well as on another publicly accessible
                            internet address.{" "}
                            <a target="_blank" href="https://www.domen.rs/dokumenti/">
                                https://www.domen.rs/dokumenti/
                            </a>{" "}
                            in accordance with Articles 7-10 of the Law on Electronic Document, Electronic Identification and Trust Services in Electronic
                            Business ("Official Gazette of RS", No. 94/2017 and 52/2021), as well as on another publicly accessible internet address.
                        </Typography>
                        <Typography className={classes.articleWrapper}>
                            in Belgrade,
                            <br />
                            issued and published on 10.12.2022.
                            <br />
                            effective on 18.12.2022.
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h6" align="center">
                        SMART PART DOO BEOGRAD
                    </Typography>
                    <br />
                    <Typography className={classes.tncText} align="center">
                        Vladimir Jovičić, direktor
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default TermsAndConditions;
