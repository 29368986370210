import { createSlice } from "@reduxjs/toolkit";

const versions = createSlice({
    name: "versions",
    initialState: {
        versions: [],
        paginationVersions: [],
        count: 0,
        dataLoaded: false,
    },
    reducers: {
        getVersions(state, action) {
            return { ...state, dataLoaded: false };
        },
        getPaginationVersions(state, action) {
            return { ...state, dataLoaded: false };
        },
        setVersions(state, action) {
            const versionsData = action.payload;
            return { ...state, versions: versionsData, dataLoaded: true };
        },
        setPaginationVersions(state, action) {

            return { ...state, versions: action.payload.data,count: action.payload.count, dataLoaded: true };
        },
        createVersion() {},
        
    },
});

export const { getVersions, setVersions, createVersion,getPaginationVersions,setPaginationVersions } = versions.actions;

export default versions.reducer;