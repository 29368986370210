import LocalizedStrings from "react-localization";
import time from "./time";
import createUser from "./createUser";
import finance from "./finance";
import form from "./form";
import historyLogs from "./historyLogs";
import partOffers from "./partOffers";
import partRequests from "./partRequests";
import sidebar from "./sidebar";
import userAccess from "./userAccess";
import userList from "./userList";
import userRoles from "./userRoles";
import userSettings from "./userSettings";
import userVehicles from "./userVehicles";
import userNotifications from "./userNotifications";
import userFilters from "./userFilters";
import settings from "./settings";
import tableTextLabels from "./tableTextLabels";
import userStatistics from "./userStatistics";
import userReviews from "./userReviews";
import userInquiry from "./userInquiry";
import versionList from "./versionList";
import createVersion from "./createVersion";

const resources = new LocalizedStrings({
    en: {
        ...time.en,
        ...form.en,
        ...sidebar.en,
        ...userRoles.en,
        ...userList.en,
        ...createUser.en,
        ...finance.en,
        ...userSettings.en,
        ...userInquiry.en,
        ...userAccess.en,
        ...userVehicles.en,
        ...userNotifications.en,
        ...userStatistics.en,
        ...userFilters.en,
        ...partRequests.en,
        ...partOffers.en,
        ...historyLogs.en,
        ...settings.en,
        ...tableTextLabels.en,
        ...userReviews.en,
        ...versionList.en,
        ...createVersion.en
    },
    rs: {
        ...time.rs,
        ...form.rs,
        ...sidebar.rs,
        ...userRoles.rs,
        ...userList.rs,
        ...createUser.rs,
        ...finance.rs,
        ...userSettings.rs,
        ...userInquiry.rs,
        ...userAccess.rs,
        ...userVehicles.rs,
        ...userNotifications.rs,
        ...userFilters.rs,
        ...userStatistics.rs,
        ...partRequests.rs,
        ...partOffers.rs,
        ...historyLogs.rs,
        ...settings.rs,
        ...tableTextLabels.rs,
        ...userReviews.rs,
        ...versionList.rs,
        ...createVersion.rs
    },
});

resources.setLanguage(process.env.REACT_APP_DEFAULT_LANGUAGE);

export default resources;
