import resources from "../core/Localization/Localization";
import moment from "moment";
import { isWholeNumber } from "./calculations";

//checking errors for settings config
export const checkForErrors = (config) => {
    const settingsConfigData = { ...config };
    let errors = {};
    if (
        settingsConfigData.part_request_accepting_offers_time_value &&
        (parseFloat(settingsConfigData.part_request_accepting_offers_time_value) < 1 ||
            isWholeNumber(parseFloat(settingsConfigData.part_request_accepting_offers_time_value)))
    ) {
        errors.part_request_accepting_offers_time_value = resources.form.errors.isNotWholeNumber;
    }
    if (
        settingsConfigData.part_request_selecting_offer_time_value &&
        (parseFloat(settingsConfigData.part_request_selecting_offer_time_value) < 1 ||
            isWholeNumber(settingsConfigData.part_request_selecting_offer_time_value))
    ) {
        errors.part_request_selecting_offer_time_value = resources.form.errors.isNotWholeNumber;
    }
    return errors;
};

export const mapMaintenanceSettingsToUI = (config) => {
    const maintenanceConfigData = { ...config };
    if (maintenanceConfigData.part_request_accepting_offers_time_value)
        maintenanceConfigData.part_request_accepting_offers_time_value = Math.round(maintenanceConfigData.part_request_accepting_offers_time_value);
    if (maintenanceConfigData.part_request_selecting_offer_time_value)
        maintenanceConfigData.part_request_selecting_offer_time_value = Math.round(maintenanceConfigData.part_request_selecting_offer_time_value);

    return maintenanceConfigData;
};

export const getCurrentTimeInString = () => {
    const time = new Date();
    let year = time.getFullYear();
    let month = time.getMonth() + 1;
    let day = time.getDate();
    let hours = time.getHours();
    let minutes = time.getMinutes();

    return `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day}T${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
    }`;
};

export const dateIsInFuture = (date) => {
    let timeNow = new Date().getTime();
    let timeToCompare = moment(date).toDate().getTime();
    return timeToCompare > timeNow;
};

export const isValidDate = (date) => {
    if (!isNaN(moment(date).toDate().getTime())) {
        return true;
    }
    return;
};

// maps time to year, month, day, hours and minutes number value
// returns object with key(date unit) - value pairs
export const mapTimeToAPI = (data) => {
    if (!isValidDate(data)) {
        return;
    }
    const payloadArray = ["year", "month", "date", "hours", "minutes"];
    const dataArray = moment(data).toArray().slice(0, 5);

    let payload = {};

    payloadArray.forEach((x, i) => {
        payload[x] = dataArray[i];
    });
    return payload;
};
