import resources from "../../core/Localization/Localization";
import { defaultCustomBodyRender } from "./DataGridColumns";
import { partOfferTypes } from "../../variables/partOfferTypes";
import { partOfferStatuses, partOfferStatusOptions } from "../../variables/partOfferStatusOptions";
import { timeRangeColumn } from "./customColumns/timeRangeColumn";

export const offersColumns = () => [
    {
        name: "id",
        label: resources.partOffers.datagrid.id,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "partname",
        label: resources.partOffers.datagrid.partname,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "make",
        label: resources.partOffers.datagrid.make,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "model",
        label: resources.partOffers.datagrid.model,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "price",
        label: resources.partOffers.datagrid.price,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "type",
        label: resources.partOffers.datagrid.type,
        options: {
            filter: true,
            sort: true,
            filterType: "dropdown",
            filterOptions: {
                names: Object.values(partOfferTypes),
                renderValue: (v) => resources.partOffers.types[v],
            },
            customFilterListOptions: { render: (v) => `${resources.partOffers.datagrid.type}: ${resources.partOffers.types[v]}` },
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "status",
        label: resources.partOffers.datagrid.status,
        options: {
            filter: true,
            sort: true,
            filterType: "dropdown",
            filterOptions: {
                names: Object.values(partOfferStatusOptions),
                renderValue: (v) => {
                    let val = Object.values(partOfferStatuses()).find((x) => x.value === v);
                    return val.label;
                },
            },
            customFilterListOptions: {
                render: (v) => {
                    let val = Object.values(partOfferStatuses()).find((x) => x.value === v);
                    return `${resources.partOffers.datagrid.status}: ${val.label}`;
                },
            },
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "username",
        label: resources.partOffers.datagrid.username,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        ...timeRangeColumn(),
    },
];
