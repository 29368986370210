import React from 'react';
import Layout from './core/Layout';
import { Helmet } from 'react-helmet';


const App = () => {
  const head = () => (
      <Helmet>
        <meta charSet="utf-8" />
        <title>Smartpart - Admin</title>
        <link rel="canonical" href="https://localhost" />

      </Helmet>
  );
  return (
      <Layout>
        {head()}
          { /* <div className="col-md-6 offset-md-3 text-center">
          <h1 className="p-5">Admin panel</h1>
          <h2>Admin</h2>
          <hr />
          <p className="lead">
            Admin
          </p>

        </div>*/}
      </Layout>
  );
};

export default App;