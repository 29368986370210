import React from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { Checkbox, Divider, FormControlLabel, Switch } from "@material-ui/core";
// core components
import {
    blockUser,
    deleteUser,
    getVerificationRequests,
    logoutUser,
    sendEmailVerification,
    sendPhoneVerification,
    userUnblock,
    updateUser,
    verifyAsSeller,
    updateBetaUser,
    verifyAsSellerPlus,
} from "../../reduxStore/entities/userDetails";

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import Button from "../../components/CustomButtons/Button";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import resources from "../../core/Localization/Localization.js";
import moment from "moment";
import { userSettigOptions, initialModalData, accessControl } from "../../common/UserSettingOptions";
import UserRoles from "../../auth/UserRoles";

export default function UserSettings() {
    const verificationRequests = useSelector((s) => s.userDetails.verificationRequests);
    const user = useSelector((s) => s.userDetails.user);
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = React.useState(false);
    const [modalData, setModalData] = React.useState(initialModalData);
    const [userSettings, setUserSettings] = React.useState([]);

    React.useEffect(() => {
        if (user) {
            dispatch(getVerificationRequests(user.userid));
        }
    }, [user]);

    React.useEffect(() => {
        const preparedData = [...userSettigOptions];

        preparedData.forEach((x, i, arr) => {
            if (user) {
                const value = user.role === UserRoles.SELLER_PLUS && x.name === 'seller_plus_verified' ? true : user[x.name];
                x.value = !!value;
            }
            if (verificationRequests) {
                const vr = verificationRequests.find((vr) => `${vr.type}_verified` === x.name);
                x.lastRequest = vr;
            }
            x.showButton = !x.lastRequest && x.showCheckbox && !x.value;
        });

        setUserSettings(preparedData);
    }, [verificationRequests, user]);

    const onActionConfirm = (control) => {
        setModalData(control);
        setOpenModal(!openModal);
    };

    const onActionTrigger = (action) => {
        switch (action.name) {
            case "email_verified":
                dispatch(sendEmailVerification({ userid: user.userid }));
                break;
            case "phone_verified":
                dispatch(sendPhoneVerification({ userid: user.userid }));
                break;
            case "seller_verified":
                dispatch(verifyAsSeller({ userid: user.userid }));
                break;
            case "seller_plus_verified":
                dispatch(verifyAsSellerPlus({ userid: user.userid }));
                break;
            case "logoutUser":
                dispatch(logoutUser(user.userid));
                break;
            case "blockUser":
                dispatch(blockUser(user.userid));
                break;
            case "userUnblock":
                dispatch(userUnblock(user.userid));
                break;
            case "deleteUser":
                dispatch(deleteUser(user.userid));
                break;
            default:
                break;
        }
        if (openModal) {
            setOpenModal(false);
        }
    };

    const handleControl = (x) => {
        if (x.showCheckbox) {
            return <Checkbox checked={x.value} onChange={() => {}} name="email-verified" color="primary" disabled={x.disabled} />;
        }
        if (x.showSwitch) {
            return (
                <Switch
                    checked={x.value}
                    onChange={() => {
                        const payload = { userid: user.userid };
                        payload[x.name] = !x.value;
                        if (x.name === "betatester") {
                            dispatch(updateBetaUser(payload));
                        } else {
                            dispatch(updateUser(payload));
                        }
                    }}
                    name="email- verified"
                    color="primary"
                />
            );
        }
    };

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="warning">
                <h4>{resources.userSettings.title}</h4>
            </CardHeader>
            <CardBody>
                {userSettings.map((x) => {
                    return (
                        <GridContainer key={x.name} direction={"row"} align={"center"}>
                            <GridItem xs={6} sm={6} md={4} align={"center"}>
                                <div className={"card-list-label"}>{resources.userSettings[x.name]}</div>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4} align={"center"}>
                                <FormControlLabel style={{ margin: 0 }} control={handleControl(x)} />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={4} align={"center"}>
                                {x.showButton && (
                                    <Button
                                        type={"button"}
                                        color={"info"}
                                        size={"sm"}
                                        onClick={() => (x.confirmation ? onActionConfirm(x) : onActionTrigger(x))}
                                    >
                                        {resources.userSettings[x.buttonLabel]}
                                    </Button>
                                )}
                                {x.lastRequest && (
                                    <div
                                        className={"card-list-label"}
                                        title={`${resources.userSettings.requestedAt}: ${moment(x.lastRequest.createdAt).format("DD/MM/YYYY hh:mm")}`}
                                    >
                                        {resources.userSettings.requestSent}
                                        <span className={"tooltip-info-icon"}>&#8505;</span>
                                    </div>
                                )}
                            </GridItem>
                        </GridContainer>
                    );
                })}
                <Divider style={{ margin: 15 }}></Divider>
                {accessControl.map((x) => {
                    if (x.visible(user)) {
                        return (
                            <GridContainer key={x.name} direction={"row"} align={"center"}>
                                <GridItem xs={6} sm={6} md={4} align={"center"}>
                                    {resources.userAccess[x.name]}
                                </GridItem>
                                <GridItem xs={6} sm={6} md={4}></GridItem>
                                <GridItem xs={6} sm={6} md={4} align={"center"}>
                                    <Button type={"button"} color={x.buttonColor} size={"sm"} onClick={() => onActionConfirm(x)}>
                                        {resources.userAccess[x.buttonLabel]}
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        );
                    }
                })}
                <ConfirmationModal
                    open={openModal}
                    handleClose={() => {
                        setOpenModal(!openModal);
                        setModalData(initialModalData);
                    }}
                    title={resources.userAccess[modalData.name]}
                    text={resources.userAccess[modalData.text]}
                    buttonLabel={resources.userAccess[modalData.buttonLabel]}
                    onConfirmCallback={() => onActionTrigger(modalData)}
                />
            </CardBody>
        </Card>
    );
}
