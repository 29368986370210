import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { isAuth, signout } from "../auth/helpers";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SidebarAdmin from "../components/Sidebar/SidebarAdmin";
import routes from "../adminRoutes.js";
import logo from "../assets/img/smartpart.png";
import bgImage from "../assets/img/sidebar-2.jpg";
import Grid from "@material-ui/core/Grid";
import GridItem from "../components/Grid/GridItem";
import resources from "../core//Localization/Localization.js";
import { ToastContainer } from "react-toastify";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import Button from "../components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { primaryColor } from "../assets/jss/material-dashboard-react";
import { Box, IconButton } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { setOpenDrawer } from "../reduxStore/entities/dashboard";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justify: "flex-end",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: "auto",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    container: {
        marginTop: 20,
    },
    iconStyle: {
        // marginRight: 5,
        cursor: "pointer",
    },
    addButton: {
        position: "fixed",
        bottom: 5,
        right: 10,
    },
}));

const Layout = ({ children, match, history, showAddButton, onAdd }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const matches = useMediaQuery(theme.breakpoints.down("xs"));
    //ref and state hooks
    const mounted = React.useRef(false);
    const openDrawer = useSelector((state) => state.dashboard.openDrawer);

    const isActive = (path) => {
        if (match.path === path) {
            return { color: "#000" };
        } else {
            return { color: "#fff" };
        }
    };
    const setActiveLang = (val) => {
        if (resources.getLanguage() === val) {
            return { color: primaryColor[0] };
        } else {
            return { color: "#fff" };
        }
    };
    const rest = [];
    const changeLanguage = (lng) => {
        resources.setLanguage(lng);
    };

    const handleDrawerToggle = () => {
        dispatch(setOpenDrawer(!openDrawer));
    };

    React.useEffect(() => {
        mounted.current = true;
        return () => (mounted.current = false);
    });
    React.useEffect(() => {
        if (matches && openDrawer && mounted.current) {
            handleDrawerToggle();
        }
    }, [mounted.current, matches]);

    React.useEffect(() => {
        //setTimeout and value of time in ms is very important because of css animation transition
        setTimeout(() => window.dispatchEvent(new Event("resize")), 300);
    }, [openDrawer]);
    const nav = () => (
        <AppBar position="static" color="primary" className={classes.root}>
            <Toolbar variant="regular" disableGutters>
                <Box className="nav-link" onClick={handleDrawerToggle}>
                    {openDrawer ? <MenuOpenIcon className={classes.iconStyle} /> : <MenuIcon className={classes.iconStyle} />}
                </Box>

                <Grid justify="flex-end" alignItems="center" wrap="nowrap" container spacing={0}>
                    <GridItem>
                        <Link className="nav-link" onClick={() => changeLanguage("en")} to="/" style={setActiveLang("en")}>
                            <LanguageIcon className={classes.iconStyle} />
                            EN
                        </Link>
                    </GridItem>

                    <GridItem>
                        <Link className="nav-link" onClick={() => changeLanguage("rs")} to="/" style={setActiveLang("rs")}>
                            <LanguageIcon className={classes.iconStyle} />
                            RS
                        </Link>
                    </GridItem>

                    <GridItem>
                        <Link className="nav-link" style={isActive("/profile")} to="/profile">
                            <PermIdentityOutlinedIcon className={classes.iconStyle} />
                            {isAuth().firstname}
                        </Link>
                    </GridItem>

                    {isAuth() && (
                        <GridItem>
                            <span
                                style={{ cursor: "pointer", color: "#fff" }}
                                onClick={() => {
                                    signout(() => {
                                        history.push("/signin");
                                    });
                                }}
                            >
                                <ExitToAppIcon className={classes.iconStyle} />
                                {matches ? "" : resources.signout}
                            </span>
                        </GridItem>
                    )}
                </Grid>
            </Toolbar>
        </AppBar>
    );

    return (
        <Fragment>
            <SidebarAdmin
                routes={routes}
                logoText={"Dashboard"}
                logo={logo}
                image={bgImage}
                open={openDrawer}
                color={"purple"}
                font={"darkFont"}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
            />
            <div className="panel-container" style={openDrawer ? { marginLeft: 260 } : { marginLeft: 0 }}>
                {nav()}
                <ToastContainer />
                <div className={"panel"}>{children}</div>
            </div>

            {showAddButton && (
                <Button
                    className={classes.addButton}
                    size={"lg"}
                    color={"info"}
                    type={"button"}
                    justIcon={true}
                    round={true}
                    onClick={() => (onAdd ? onAdd() : null)}
                >
                    <AddIcon></AddIcon>
                </Button>
            )}
        </Fragment>
    );
};

export default withRouter(Layout);
