// IMPORTANT - if there are changes inside the defaultLayout object, FILTER_DETAILS_LAYOUT_KEY "V#" value needs to be incremented!!!

const FILTER_DETAILS_LAYOUT_KEY = "FILTER_DETAILS_LAYOUT_V2";
const userBoxHeight = 14;
const defaultLayout = {
    lg: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            static: true,
            minW: 8,
            maxW: 12,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
    ],
    md: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            static: true,
            minW: 8,
            maxW: 12,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
    ],
    sm: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            static: true,
            minW: 8,
            maxW: 12,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            minW: 4,
            maxW: 12,
        },
    ],
};
const defaultTableSize = 5;

export { FILTER_DETAILS_LAYOUT_KEY, defaultLayout, defaultTableSize };
