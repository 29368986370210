import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import { getCar } from "../../reduxStore/entities/carDetails";
import Layout from "../../core/Layout";
import useLocalStorage from "../../hooks/useLocalStorage.js";
import { CAR_DETAILS_LAYOUT_KEY, defaultLayout } from "../../common/CarDetailsLayout";
import CarBasicInfo from "./CarBasicInfo";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function CarDetails(props) {
    const dispatch = useDispatch();
    const id = props.match.params.id;

    const [lsLayout, setLsLayout] = useLocalStorage(CAR_DETAILS_LAYOUT_KEY, defaultLayout);
    const car = useSelector((state) => state.carDetails.car);

    useEffect(() => {
        dispatch(getCar(id));
        return () => {};
    }, []);

    return (
        <Layout>
            <ResponsiveGridLayout
                className="layout"
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                rowHeight={30}
                layouts={lsLayout}
                onLayoutChange={(layout, layouts) => {
                    setLsLayout(layouts);
                }}
            >
                <div key="1">
                    <CarBasicInfo car={car} />
                </div>
            </ResponsiveGridLayout>
        </Layout>
    );
}
