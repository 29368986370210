import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    wrapper: { margin: "20px 10px" },
    data: {
        padding: "16px 8px",
        lineHeight: 1.5,
        fontSize: "1rem",
    },
    dataTitle: {
        flex: 1,
        textTransform: "capitalize",
    },
    dataBody: {
        flex: 4,
    },
});

export default useStyles;
