import resources from "../../core/Localization/Localization";

export const textLabels = () => {
    return {
        body: {
            noMatch: resources.tableTextLabels.body.noMatch,
            toolTip: resources.tableTextLabels.body.toolTip,
            columnHeaderTooltip: (column) => `${resources.tableTextLabels.body.columnHeaderTooltip} ${column.label}`,
        },
        pagination: {
            next: resources.tableTextLabels.pagination.next,
            previous: resources.tableTextLabels.pagination.previous,
            rowsPerPage: resources.tableTextLabels.pagination.rowsPerPage,
            displayRows: resources.tableTextLabels.pagination.displayRows,
        },
        toolbar: {
            search: resources.tableTextLabels.toolbar.search,
            downloadCsv: resources.tableTextLabels.toolbar.downloadCsv,
            print: resources.tableTextLabels.toolbar.print,
            viewColumns: resources.tableTextLabels.toolbar.viewColumns,
            filterTable: resources.tableTextLabels.toolbar.filterTable,
        },
        filter: {
            all: resources.tableTextLabels.filter.all,
            title: resources.tableTextLabels.filter.title,
            reset: resources.tableTextLabels.filter.reset,
        },
        viewColumns: {
            title: resources.tableTextLabels.viewColumns.title,
            titleAria: resources.tableTextLabels.viewColumns.titleAria,
        },
        selectedRows: {
            text: resources.tableTextLabels.selectedRows.text,
            delete: resources.tableTextLabels.selectedRows.delete,
            deleteAria: resources.tableTextLabels.selectedRows.deleteAria,
        },
    };
};
