const userNotifications = {
    en: {
        userNotifications: {
            title: "Notifications",
            push: "Push",
            email: "Email",
            title: "Title",
            message: "Message",
            sent_at: "Sent At",
            delivered: "Delivered",
            successfully: "Successfully",
            unsuccessfully: "unsuccessfully",
            notes: "Notes",
            send: "Send",
            send_notification: "Send Notification",
            send_push_notification: "Send a PUSH notification",
            send_email_notification: "Send a notification via EMAIL",
            errors: {
                messageValidation: "Title and message text are required!",
            },
        },
    },
    rs: {
        userNotifications: {
            title: "Notifikacije",
            push: "Push",
            email: "Email",
            title: "Naslov",
            message: "Poruka",
            sent_at: "Poslata",
            delivered: "Dostavljena",
            successfully: "Uspešno",
            unsuccessfully: "Neuspešno",
            notes: "Beleška",
            send: "Pošalji",
            send_notification: "Pošalji Obaveštenje",
            send_push_notification: "Pošalji PUSH notifikaciju",
            send_email_notification: "Pošalji EMAIL notifikaciju",
            errors: {
                messageValidation: "Naslov i tekst poruke su obavezni!",
            },
        },
    },
};

export default userNotifications;
