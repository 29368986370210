const sidebar = {
    en: {
        sideBar: {
            dashboard: "Dashboard",
            users: "Users",
            requests: "Requests",
            offers: "Offers",
            cars: "Cars",
            garage: "Garage",
            versions :"Mobile Versions",
            settings: "Settings",
            historyLogs: "History",
            transactions: "Transactions",
            close: "Close",
            open: "Open",
        },
    },
    rs: {
        sideBar: {
            dashboard: "Dashboard",
            users: "Korisnici",
            requests: "Zahtevi",
            offers: "Ponude",
            cars: "Automobili",
            garage: "Garaža",
            versions: "Mobilne Verzije",
            settings: "Podešavanja",
            historyLogs: "Istorijat",
            transactions: "Transakcije",
            close: "Zatvori",
            open: "Otvori",
        },
    },
};

export default sidebar;
