/*

state:
*      1 - Active:
*       case 1: return "Primanje ponuda";
        case 2: return "Vreme za odabiranje ponude";
        case 5: return "Odabran pobednik";
        case 6: return "Prodavac je poslao deo kupac još nije primio";
        case 8: return "Prodavac nije naznačio da je poslao deo, ali je kupac primio";
       2 - Accepted:
        case 7: return "Transakcija je obavljena";
        case 9: return "Transakcija je označena kao završena pošto se niko nije žalio 7 dana";
        case 12: return "Spor rešen i trgovina uspešna";
       3 - Not Accepted:
        case 3: return "Završeno bez ponuda";
        case 4: return "Završeno nije odabrana ponuda";
        case 10: return "Zahtev u sporu";
        case 11: return "Uklonjen";
        case 13: return "Spor rešen i trgovina neuspešna ni zbog koga";
        case 14: return "Spor rešen i trgovina neuspešna zbog oboje";
        case 15: return "Spor rešen i trgovina neuspešna zbog kupca";
        case 16: return "Spor rešen i trgovina neuspešna zbog prodavca";
        type:
        1 - Active
        2 - Accepted
        3 - Not accepted
*
*
* */

import resources from "../core/Localization/Localization.js";

export const mapPartrequestsState = (data) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.map((d) => {
        switch (d.state) {
            case 1:
                d.state_label = resources.partRequests.state.one;
                d.status_label = resources.partRequests.status.active_single;
                break;
            case 2:
                d.state_label = resources.partRequests.state.two;
                d.status_label = resources.partRequests.status.active_single;
                break;
            case 3:
                d.state_label = resources.partRequests.state.three;
                d.status_label = resources.partRequests.status.failed_single;

                break;
            case 4:
                d.state_label = resources.partRequests.state.four;
                d.status_label = resources.partRequests.status.failed_single;
                break;
            case 5:
                d.state_label = resources.partRequests.state.five;
                d.status_label = resources.partRequests.status.active_single;
                break;
            case 6:
                d.state_label = resources.partRequests.state.six;
                d.status_label = resources.partRequests.status.active_single;
                break;
            case 7:
                d.state_label = resources.partRequests.state.seven;
                d.status_label = resources.partRequests.status.completed_single;
                break;
            case 8:
                d.state_label = resources.partRequests.state.eight;
                d.status_label = resources.partRequests.status.completed_single;
                break;
            case 9:
                d.state_label = resources.partRequests.state.nine;
                d.status_label = resources.partRequests.status.completed_single;
                break;
            case 10:
                d.state_label = resources.partRequests.state.ten;
                d.status_label = resources.partRequests.status.active_single;
                break;
            case 11:
                d.state_label = resources.partRequests.state.eleven;
                d.status_label = resources.partRequests.status.failed_single;
                break;
            case 12:
                d.state_label = resources.partRequests.state.twelve;
                d.status_label = resources.partRequests.status.active_single;
                break;
            case 13:
                d.state_label = resources.partRequests.state.thirteen;
                d.status_label = resources.partRequests.status.failed_single;
                break;
            case 14:
                d.state_label = resources.partRequests.state.fourteen;
                d.status_label = resources.partRequests.status.failed_single;
                break;
            case 15:
                d.state_label = resources.partRequests.state.fifteen;
                d.status_label = resources.partRequests.status.failed_single;
                break;
            case 16:
                d.state_label = resources.partRequests.state.sixteen;
                d.status_label = resources.partRequests.status.failed_single;
                break;

            default:
                break;
        }
    });
    return newData;
};

export const mapPartrequestsPartstate = (data) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.map((d) => {
        switch (d.partstate) {
            case 0:
                d.partstate_label=resources.partRequests.partstate.zero;
                break;
            case 1:
                d.partstate_label=resources.partRequests.partstate.one;
                break;
            case 2:
                d.partstate_label=resources.partRequests.partstate.two;
                break;
            default:
                break;
        }
    });
    return newData;
};


export const partRequestsStateOptions = () => {
    return [
        {
            value: 1,
            label: resources.partRequests.state.one,
        },
        {
            value: 2,
            label: resources.partRequests.state.two,
        },
        {
            value: 3,
            label: resources.partRequests.state.three,
        },
        {
            value: 4,
            label: resources.partRequests.state.four,
        },
        {
            value: 5,
            label: resources.partRequests.state.five,
        },
        {
            value: 6,
            label: resources.partRequests.state.six,
        },
        {
            value: 7,
            label: resources.partRequests.state.seven,
        },
        {
            value: 8,
            label: resources.partRequests.state.eight,
        },
        {
            value: 9,
            label: resources.partRequests.state.nine,
        },
        {
            value: 10,
            label: resources.partRequests.state.ten,
        },
        {
            value: 11,
            label: resources.partRequests.state.eleven,
        },
        {
            value: 12,
            label: resources.partRequests.state.twelve,
        },
        {
            value: 13,
            label: resources.partRequests.state.thirteen,
        },
        {
            value: 14,
            label: resources.partRequests.state.fourteen,
        },
        {
            value: 15,
            label: resources.partRequests.state.fifteen,
        },
        {
            value: 16,
            label: resources.partRequests.state.sixteen,
        },
    ];
};
export const partRequestsPartstateOptions = () => {
    return [
        {
            value: 0,
            label: resources.partRequests.partstate.zero,
        },
        {
            value: 1,
            label: resources.partRequests.partstate.one,
        },
        {
            value: 2,
            label: resources.partRequests.partstate.two,
        }
        ];
};