import resources from "../core/Localization/Localization";
import moment from "moment";

export const NOTIFICATION_TYPE = {
    push: "push",
    email: "email",
};

export const notificationsColumns = () => [
    resources.userNotifications.title,
    resources.userNotifications.message,
    resources.userNotifications.sent_at,
    resources.userNotifications.delivered,
];

export const notificationsTabs = () => [
    {
        name: resources.userNotifications.push,
        state: NOTIFICATION_TYPE.push,
    },
    {
        name: resources.userNotifications.email,
        state: NOTIFICATION_TYPE.email,
    },
];

export const mapNotificationsInTableData = (userNotifications) => {
    if (!userNotifications) {
        return [];
    }
    return userNotifications.map((x) => {
        return [
            x.title,
            x.message,
            moment(x.createdAt).format("DD/MM/YYYY hh:mm"),
            x.is_sent ? resources.userNotifications.successfully : resources.userNotifications.unsuccessfully,
        ];
    });
};

export const notificationControl = [
    {
        name: "send_push_notification",
        buttonLabel: "send",
        buttonColor: "warning",
        visible: (user) => true,
    },
    {
        name: "send_email_notification",
        buttonLabel: "send",
        buttonColor: "rose",
        visible: (user) => true,
    },
];

export const initialModalData = {
    title: "",
    text: "",
    createdAt: "",
    buttonLabel: "",
};
