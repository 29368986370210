import resources from "../core/Localization/Localization.js";

export const activeStates = [1, 2, 5, 6, 10];
export const acceptedStates = [7, 8, 9, 12];
export const notAcceptedStates = [3, 4, 11, 13];

export const getRequestStatus = (state) => {
    if (activeStates.includes(state)) return resources.partRequests.status.active_single;
    if (acceptedStates.includes(state)) return resources.partRequests.status.completed_single;
    if (notAcceptedStates.includes(state)) return resources.partRequests.status.failed_single;
};

export const getRequestStatusClass = (state) => {
    if (activeStates.includes(state)) return "info-label warning";
    if (acceptedStates.includes(state)) return "info-label success";
    if (notAcceptedStates.includes(state)) return "info-label error";
};

export const getRequestStatesArray = (param) => {
    switch (param) {
        case resources.partRequests.status.active_single:
            return activeStates;
        case resources.partRequests.status.active:
            return activeStates;
        case resources.partRequests.status.completed_single:
            return acceptedStates;
        case resources.partRequests.status.completed:
            return acceptedStates;
        case resources.partRequests.status.failed_single:
            return notAcceptedStates;
        case resources.partRequests.status.failed:
            return notAcceptedStates;

        default:
            return;
    }
};

export const getRequestStatusByStatusArray = (arr) => {
    let compareArr;
    if (typeof arr === "string" && JSON.parse(arr)) {
        compareArr = JSON.parse(arr);
    } else {
        compareArr = [...arr];
    }

    if (activeStates.some((x) => compareArr.includes(x))) return resources.partRequests.status.active_single;
    if (acceptedStates.some((x) => compareArr.includes(x))) return resources.partRequests.status.completed_single;
    if (notAcceptedStates.some((x) => compareArr.includes(x))) return resources.partRequests.status.failed_single;
};
