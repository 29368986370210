import React from "react";
import { useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

// core components
import CardBody from "../../../components/Card/CardBody.js";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

//styles
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

//utils
import resources from "../../../core/Localization/Localization";
import { isAuth } from "../../../auth/helpers.js";
import UserRoles from "../../../auth/UserRoles.js";
import { getMaintenanceSettings } from "../../../reduxStore/entities/settings.js";
import MaintenanceUsers from "./MaintenanceUsers.js";
import MaintenanceOther from "./MaintenanceOther.js";
import MaintenanceMode from "./MaintenanceMode.js";

const useStyles = makeStyles(dashboardStyle);

const SettingsMaintenance = () => {
    const operator = isAuth();
    const classes = useStyles();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getMaintenanceSettings());
    }, []);

    return operator.role === UserRoles.ADMIN ? (
        <CardBody profile>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h6 className={classes.cardCategory}>{resources.settings.maintenanceSettings}</h6>
                </GridItem>
            </GridContainer>
            <MaintenanceUsers />
            <Divider light className={classes.divider} />
            <MaintenanceOther />
            <Divider light className={classes.divider} />
            <MaintenanceMode />
        </CardBody>
    ) : null;
};

export default SettingsMaintenance;
