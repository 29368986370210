import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getSelectedUsersPartRequests } from "../../reduxStore/entities/partrequests.js";
// core components
import MappedTabs from "../../components/MappedTabs/MappedTabs";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
//common
import resources from "../../core/Localization/Localization.js";
import UserRoles from "../../auth/UserRoles";
import { mapPartrequestsInTableData, USER_PART_REQUEST_HEADERS, partRequestTabs } from "../../common/UserDetailsTabs";
// utils
import getQueryWithPagination from "../../utils/getQueryWithPagination";
import { activeStates, acceptedStates, notAcceptedStates } from "../../utils/getRequestStatus";

export default function UserRequests() {
    const history = useHistory();

    // Store hooks
    const user = useSelector((x) => x.userDetails.user);
    const partrequests = useSelector((state) => state.partrequests.selectedUsersPartrequests);
    const dispatch = useDispatch();

    // State hooks
    const [tableData, setTableData] = React.useState([]);

    // React hooks
    const headers = React.useMemo(USER_PART_REQUEST_HEADERS, [resources]);
    const tabs = React.useMemo(partRequestTabs, [resources]);

    useEffect(() => {
        if (user.role === UserRoles.USER) dispatch(getSelectedUsersPartRequests(getQueryWithPagination({ userid: user.userid, states: activeStates })));
        return () => {};
    }, []);

    useEffect(() => {
        if (user.role === UserRoles.USER) setTableData(mapPartrequestsInTableData(partrequests));
    }, [partrequests]);

    // Event handlers
    const handleTabChange = (e) => {
        switch (e) {
            case 0:
                dispatch(getSelectedUsersPartRequests(getQueryWithPagination({ userid: user.userid, states: activeStates })));
                break;
            case 1:
                dispatch(getSelectedUsersPartRequests(getQueryWithPagination({ userid: user.userid, states: notAcceptedStates })));
                break;
            case 2:
                dispatch(getSelectedUsersPartRequests(getQueryWithPagination({ userid: user.userid, states: acceptedStates })));
                break;
            default:
                break;
        }
    };
    const handleViewMore = (sStates) => history.push(`/admin/requests?userid=${user.userid}&states=${sStates}`);

    const onRowClick = (params) => {
        if (params.length) {
            history.push("/admin/requests/" + params[0]);
        }
    };
    return (
        <CustomTabs
            className={"user-profile-card"}
            title={resources.partRequests.title}
            headerColor="rose"
            onTabChange={handleTabChange}
            tabs={MappedTabs(tabs, { tableHead: headers, tableData, onRowClick }, handleViewMore, resources.partRequests.viewMore)}
        />
    );
}
