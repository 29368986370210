import { call, put } from "redux-saga/effects";
import { setFilter } from "../../entities/filterDetails";
import { requestGetFilter } from "../requests/filterDetails";

export function* handleGetFilter(action) {
    try {
        const response = yield call(requestGetFilter, action.payload);
        const { data } = response;
        yield put(setFilter({ ...data }));
    } catch (error) {
        console.log(error);
    }
}
