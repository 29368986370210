export const userSettigOptions = [
    {
        name: "email_verified",
        showCheckbox: true,
        disabled: true,
        confirmation: false,
        buttonLabel: "resend",
    },
    {
        name: "phone_verified",
        showCheckbox: true,
        disabled: true,
        confirmation: false,
        buttonLabel: "resend",
    },
    {
        name: "seller_verified",
        showCheckbox: true,
        disabled: true,
        confirmation: true,
        text: "sellerVerificationText",
        buttonLabel: "startVendorVerification",
    },
    {
        name: "seller_plus_verified",
        showCheckbox: true,
        disabled: true,
        confirmation: true,
        text: "sellerPlusVerificationText",
        buttonLabel: "startVendorVerification",
    },
    {
        name: "email_notifications_enabled",
        showSwitch: true,
    },
    {
        name: "push_notifications_enabled",
        showSwitch: true,
    },
    {
        name: "betatester",
        showSwitch: true
    }
];

export const accessControl = [
    {
        name: "logoutUser",
        buttonLabel: "logout",
        buttonColor: "warning",
        text: "logoutUserText",
        visible: (user) => true
    },
    {
        name: "blockUser",
        buttonLabel: "block",
        buttonColor: "rose",
        text: "blockUserText",
        visible: (user) => user.banned !== 1
    },
    {
        name: "userUnblock",
        buttonLabel: "unblock",
        buttonColor: "rose",
        text: "unblockUserText",
        visible: (user) => user.banned === 1
    },
    {
        name: "deleteUser",
        buttonLabel: "delete",
        buttonColor: "danger",
        text: "deleteUserText",
        visible: (user) => true
    },
];

export const initialModalData = {
    title: "",
    text: "",
    buttonLabel: "",
};