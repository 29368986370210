import { createSlice } from "@reduxjs/toolkit";

const carDetails = createSlice({
    name: "carDetails",
    initialState: {
        car: {},
        dataLoaded: false,
    },
    reducers: {
        getCar() {},
        setCar(state, action) {
            const carData = action.payload;
            return { ...state, car: carData, dataLoaded: true };
        },
    },
});

export const { getCar, setCar } = carDetails.actions;

export default carDetails.reducer;
