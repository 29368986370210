const userReviews = {
    en: {
        userReviews: {
            title: "Reviews",
            received: "Received",
            posted: "Posted",
            id: "ID",
            username: "Name",
            comment: "Comment",
            rating: "Rating",
            add_review: "Add reivew",
            created: "Date",
            positive: "positive",
            negative: "negative",
            delete: "Delete review",
            confirmDeleteReview: "Are you sure you want to delete this review?",
        },
    },
    rs: {
        userReviews: {
            title: "Recenzije",
            received: "Primljene",
            posted: "Postavljene",
            id: "ID",
            username: "Ime korisnika",
            comment: "Komentar",
            rating: "Ocena",
            add_review: "Postavi recenziju",
            created: "Datum",
            positive: "pozitivna",
            negative: "negativna",
            delete: "Obriši recenziju",
            confirmDeleteReview: "Da li ste sigurni da želite da obrišete ovu recenziju?",
        },
    },
};

export default userReviews;
