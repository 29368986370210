import { createSlice } from "@reduxjs/toolkit";

const offerImages = createSlice({
    name: "offerImages",
    initialState: {
        images: [],
        dataLoaded: true,
    },
    reducers: {
        getOfferImages(state, action) {
            return { ...state, dataLoaded: false };
        },
        setOfferImages(state, action) {
            const offerData = action.payload;
            return { ...state, images: offerData, dataLoaded: true };
        },
        addOfferImages(state, action) {
            return { ...state, dataLoaded: false };
        },
        deleteOfferImage(state, action) {
            return { ...state, dataLoaded: false };
        },
    },
});

export const { getOfferImages, setOfferImages, addOfferImages, deleteOfferImage } = offerImages.actions;

export default offerImages.reducer;
