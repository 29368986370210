import api from "../../../api";

export function requestGetPaymentSettings() {
    return api.get("/admin/payment-config");
}

export function requestUpdatePaymentSettings(payload) {
    return api.put("/admin/payment-config/" + payload.payment_config_id, payload);
}

export function requestLogoutAllUsers() {
    return api.post("/admin/users/logout");
}

export function requestScheduleMaintenanceMode(payload) {
    return api.post("/admin/scheduled-maintenance", payload);
}

export function requestCallEmergencyMaintenanceMode(payload) {
    return api.put("/admin/emergency-maintenance/" + payload.maintenance_config_id, payload);
}

export function requestGetMaintenanceSettings() {
    return api.get("/admin/maintenance-config");
}

export function requestUpdateMaintenanceSettings(payload) {
    return api.put("/admin/maintenance-config/" + payload.maintenance_config_id, payload);
}

export function requestGetConfigurationSettings() {
    return api.get("/admin/settings-config");
}

export function requestUpdateConfigurationSettings(payload) {
    return api.put("/admin/settings-config/" + payload.settings_config_id, payload);
}
