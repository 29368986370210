const historyLogs = {
    en: {
        historyLogs: {
            header: "History Logs",
            subheader: "History Log",
            datagrid: {
                id: "ID",
                action: "Action",
                modelId: "Model ID",
                type: "Type",
                path: "Path",
                date: "Datum",
            },
            revision: "Data change",
            action: "Activity",
            offers: {
                update: "Update Offer",
                create: "Create A New Offer",
            },
            partoffer: {
                create: "Create A New Offer",
                update: "Update Part Offer",
            },
            partrequest: {
                edit: "Edit Part Request",
                update: "Part Request Update",
                create: "Create A New Part Request",
                indicatepartreceived: "Indicate That Part Is Received",
                indicatepartsent: "Indicate That Part Is Sent",
                selectwinner: "Select Part Request Winner",
                inDispute: "Issue reported. Request moved in dispute",
            },
            partofferreview: {
                create: "Create new Part Offer Review",
                edit: "Edit Part Offer Review",
                update: "Part Offer Review Update",
                delete: "Part Offer Review Deleted",
            },
            partrequestreview: {
                create: "Create new Part Request Review",
                edit: "Edit Part Request Review",
                update: "Part Request Review Update",
                delete: "Part Request Review Deleted",
            },
            usercars: {
                create: "Create User Car",
                edit: "Edit User Car",
                delete: "Delete User Car",
            },
            cars: {
                create: "Create User Car",
                edit: "Edit User Car",
                delete: "Delete User Car",
            },
            userfilters: {
                userfilter: "Create A New User Filter",
                create: "Create A New User Filter",
                edit: "Edit User Filter",
                delete: "Delete User Filter",
            },
            users: {
                create: "Create A New User",
                delete: "Delete User",
                update: "Update",
                edit: "Edit User",
                logout: "Logout User",
                signin: "Sign In User",
                block: "Block User",
                unblock: "Unblock User",
                beta_tester: "Set User As A Beta Tester",
                send_email_verification: "Send Email Verification Code",
                email_verified: "Email Verified",
                send_phone_verification: "Send Phone Verification Code",
                send_system_message: "Send System Message To The User",
                verify_phone_number: "Verify User Phone Number",
                verifyphonenumber: "Verify User Phone Number",
                verify_as_seller: "Verify User As A Seller",
            },
            transaction: {
                add_tokens: "Add Credits",
                create: "Create New Transaction",
                refund: "Refund Transaction",
                correction: "Create New Correction",
            },
            payments: {
                payment_config: "Payment Settings Configuration",
            },
            type: {
                revision: "Revision",
                action: "Action",
            },
            settings: {
                settings_config: "Configuration Settings Updated",
                update: "Configuration Settings Updated",
            },
            maintenance: {
                update: "Maintenance Settings Update",
                maintenance_config: "Configuration Maintenance Settings",
                emergency: "Emergent Activated Maintenance Mode",
            },
        },
    },
    rs: {
        historyLogs: {
            header: "Istorijat",
            subheader: "Aktivnosti",
            datagrid: {
                id: "ID",
                action: "Aktivnost",
                modelId: "Model ID",
                type: "Tip",
                path: "Promena",
                date: "Datum",
            },
            revision: "Izmena",
            action: "Aktivnost",
            offers: {
                update: "Ažuriranje Ponude",
                create: "Kreiranje Ponude",
            },
            partoffer: {
                create: "Kreiranje Ponude",
                update: "Ažuriranje Ponude",
            },
            partrequest: {
                edit: "Uređivanje Zahteva Za Deo",
                create: "Kreiranje Novog Zahteva Za Deo",
                indicatepartreceived: "Označi Da Je Deo Primljen",
                indicatepartsent: "Označi Da Je Deo Poslat",
                selectwinner: "Izaberi Pobednika Zahteva Za Deo",
                update: "Zahtev Za Deo Ažuriran",
                inDispute: "Prijavljen problem. Zahtev prebačen u Spor",
            },
            partofferreview: {
                create: "Kreiranje Nove Ocene za Ponudu",
                edit: "Uređivanje Ocene za Ponudu",
                update: "Ažuriranje Ocene za Ponudu",
                delete: "Brisanje Ocene za Ponudu",
            },
            partrequestreview: {
                create: "Kreiranje Nove Ocene za Zahtev",
                edit: "Uređivanje Ocene za Zahtev",
                update: "Ažuriranje Ocene za Zahtev",
                delete: "Brisanje Ocene za Zahtev",
            },
            usercars: {
                create: "Kreiranje Korisničkog Automobila",
                edit: "Uređivanje Korisničkog Automobila",
                delete: "Izbriši Korisnikov Automobil",
            },
            cars: {
                create: "Kreiranje Korisničkog Automobila",
                edit: "Uređivanje Korisničkog Automobila",
                delete: "Izbriši Korisnikov Automobil",
            },
            userfilters: {
                userfilter: "Kreiranje Novog Filtera za korisnika",
                create: "Kreiranje Novog Filtera za korisnika",
                edit: "Uređivanje Filtera za korisnika",
                delete: "Izbriši Filter za korisnika",
            },
            user_filter: {
                user_filter: "Kreiranje Novog Filtera za korisnika",
                create: "Kreiranje Novog Filtera za korisnika",
                edit: "Uređivanje Filtera za korisnika",
                delete: "Izbriši Filter za korisnika",
            },
            users: {
                create: "Kreiraj Novog Korisnika",
                delete: "Izbriši Korisnika",
                update: "Ažuriranje Korisnika",
                edit: "Uređivanje Korisnika",
                logout: "Izloguj Korisnika",
                signin: "Prijavljivanje Korisnika",
                block: "Blokiraj Korisnika",
                unblock: "Deblokiraj Korisnika",
                beta_tester: "Postavi Korsinika Za Beta Testera",
                send_email_verification: "Pošalji Verifikacioni Kod Za Email",
                email_verified: "Email Verifikovan",
                send_phone_verification: "Pošalji Verifikacioni Kod Za Broj Telefona",
                send_system_message: "Pošalji Sistemsku Poruku Korisniku",
                verify_phone_number: "Verifikuj Korisnički Broj Telefona",
                verifyphonenumber: "Verifikuj Korisnički Broj Telefona",
                verify_as_seller: "Verifikuj Korisnika Kao Prodavca",
            },
            transaction: {
                add_tokens: "Dodavanje kredita",
                create: "Kreiranje Nove Transakcije",
                refund: "Refundiranje Transakcije",
                correction: "Kreiranje Nove Korekcije",
            },
            payments: {
                payment_config: "Konfigurisanje Opcija Za Plaćanje",
            },
            type: {
                revision: "Revizija",
                action: "Akcija",
            },
            settings: {
                settings_config: "Ažuriranje Opcija Za Konfigurisanje",
                update: "Ažuriranje Opcija Za Konfigurisanje",
            },
            maintenance: {
                update: "Ažuriranje Opcija Za Održavanje",
                maintenance_config: "Konfigurisanje Opcija Za Održavanje",
                emergency: "Hitno Aktiviran Režim Za Održavanje",
            },
        },
    },
};

export default historyLogs;
