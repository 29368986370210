import { configureStore, combineReducers, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

import { watcherSaga } from "./sagas/rootSaga";
import carsReducer from "./entities/cars";
import usersReducer from "./entities/users";
import userDetailsReducer from "./entities/userDetails";
import partrequestsReducer from "./entities/partrequests";
import partrequestDetailsReducer from "./entities/partrequestDetails";
import offersReducer from "./entities/offers";
import offerDetailsReducer from "./entities/offerDetails";
import dashboardReducer from "./entities/dashboard";
import settingsReducer from "./entities/settings";
import countryReducer from "./entities/country";
import historyLogReducer from "./entities/historylogs";
import carDetailsReducer from "./entities/carDetails";
import filterDetailsReducer from "./entities/filterDetails";
import financeReducer from "./entities/finance";
import transactionDetails from "./entities/transactionDetails";
import reviewsReducer from "./entities/reviews";
import partrequestImagesReducer from "./entities/partrequestImages";
import offerImagesReducer from "./entities/offerImages";
import filtersReducer from "./entities/filters";
import versionsReducer from "./entities/versions";
import versionDetailsReducer from "./entities/versionDetails";

const sagaMiddleware = createSagaMiddleware();

const history = createBrowserHistory();
const middlewareHistory = routerMiddleware(history);

const reducer = combineReducers({
    router: connectRouter(history),
    userDetails: userDetailsReducer,
    offerDetails: offerDetailsReducer,
    partrequestDetails: partrequestDetailsReducer,
    users: usersReducer,
    cars: carsReducer,
    offers: offersReducer,
    partrequests: partrequestsReducer,
    dashboard: dashboardReducer,
    settings: settingsReducer,
    country: countryReducer,
    historyLogs: historyLogReducer,
    carDetails: carDetailsReducer,
    filterDetails: filterDetailsReducer,
    finance: financeReducer,
    transactionDetails: transactionDetails,
    reviews: reviewsReducer,
    partrequestImages: partrequestImagesReducer,
    offerImages: offerImagesReducer,
    filters: filtersReducer,
    versions: versionsReducer,
    versionDetails:versionDetailsReducer
    // routing: routerReducer
});

const store = configureStore({
    reducer,
    middleware: [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware, middlewareHistory],
});
sagaMiddleware.run(watcherSaga);

export { store, history };
