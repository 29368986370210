import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isAuth } from './helpers';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                isAuth() && (!roles || !roles.length || (roles.indexOf(isAuth().role) !== -1)) ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/forbidden',
                            state: { from: props.location }
                        }}
                    />
                )
            }
        ></Route>
    )
};

export default PrivateRoute;
