import moment from "moment";
import resources from "../core/Localization/Localization";
import { divBy100, roundNumberToTwoDec } from "../utils/calculations";
import { correctionOptions, TransactionTypes } from "./transactions/config";

export const handleTableLoad = (transactions) => {
    const preparedData = transactions.map((transaction) => {
        let data = {
            id: transaction.transaction_id,
            username: transaction.User?.username ? transaction.User.username : "Korisnik obrisan",
            part_offer_id: transaction.part_offer_id || null,
            amount: Math.round(divBy100(transaction.amount)) || "0",
            currency_amount: roundNumberToTwoDec(divBy100(transaction.currency_amount)) || "0",
            currency: transaction.currency.toUpperCase() || "",
            refunded: transaction.refunded ? resources.form.yes : resources.form.no,
            refunded_amount: transaction.refunded_amount ? roundNumberToTwoDec(divBy100(transaction.refunded_amount)) : 0,
        };
        switch (transaction.type) {
            case TransactionTypes.PAYIN:
                delete data.part_offer_id;
                delete data.refunded;
                delete data.refunded_amount;
                break;
            case TransactionTypes.CORRECTION:
                delete data.part_offer_id;
                delete data.refunded;
                delete data.refunded_amount;
                data.correction_type = correctionOptions().filter((t) => t.value === transaction.correction_type)[0]?.label;
                break;
            default:
                break;
        }
        data.createdAt = moment(transaction.createdAt);
        return data;
    });
    return preparedData;
};
