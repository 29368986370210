import resources from "../core/Localization/Localization";

export const timeTypes = {
    YEARS: "years",
    MONTHS: "months",
    DAYS: "days",
    HOURS: "hours",
    MINUTES: "minutes",
    SECONDS: "seconds",
};

export const timeOptions = () => [
    {
        label: resources.time.years,
        value: timeTypes.YEARS,
    },
    {
        label: resources.time.months,
        value: timeTypes.MONTHS,
    },
    {
        label: resources.time.days,
        value: timeTypes.DAYS,
    },
    {
        label: resources.time.hours,
        value: timeTypes.HOURS,
    },
    {
        label: resources.time.minutes,
        value: timeTypes.MINUTES,
    },
    {
        label: resources.time.seconds,
        value: timeTypes.SECONDS,
    },
];
