const tncStyle = (theme) => ({
    tnc: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100wv",
        flexDirection: "column",
        padding: "40px 60px",
        gap: 30,
        [theme.breakpoints.down("xs")]: {
            gap: 0,
            padding: "30px 10px",
            minWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    },
    tncBoxWrapper: {
        display: "flex",
        flex: 1,
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 10,
        [theme.breakpoints.down("xs")]: {
            padding: "30px 10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    },
    tncRightBox: {
        paddingLeft: 20,
        flex: 1, 
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0
        },
    },
    tncLeftBox: {
        flex: 1,
        paddingRight: 10,
        borderRight: "1px solid black",
        [theme.breakpoints.down("xs")]: {
            borderRight: "none",
            paddingRight: 0
        },
    },
    tncText: {
        marginTop: 15,
        borderTop: "1px solid black"
    },
    articleWrapper: {
        paddingTop: 20,
        marginBottom: 40,
    },
    beBold: {
        fontWeight: "bold",
    },
});

export default tncStyle;