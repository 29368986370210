import resources from "../core/Localization/Localization";
import moment from "moment";

export const REVIEW_TYPE = {
    received: "received",
    posted: "posted",
};

export const USER_REVIEWS_HEADERS = () => [
    resources.userReviews.id,
    resources.userReviews.username,
    resources.userReviews.comment,
    resources.userReviews.rating,
    resources.userReviews.created,
];

export const reviewTabs = () => [
    {
        name: resources.userReviews.received,
        state: REVIEW_TYPE.received,
    },
    {
        name: resources.userReviews.posted,
        state: REVIEW_TYPE.posted,
    },
];

export const mapReviewsInTableData = (reviews) => {
    if (!reviews) return [];

    return reviews.map((x) => {
        const id = x.part_request_review_id ? x.part_request_review_id : x.part_offer_review_id;
        const name = x.sellerid ? x.sellerid.username : x.buyerid ? x.buyerid.username : "";
        return [
            id,
            name,
            x.comment,
            x.rating === 1? resources.userReviews.positive : resources.userReviews.negative,
            moment(x.createdAt).format("DD/MM/YYYY hh:mm"),
        ]
    })
}

export const initialModalData = {
    username: "",
    comment: "",
    rating: "",
    createdAt: ""
};