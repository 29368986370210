import {appTypes,releaseTypes,platformTypes,releaseStatusTypes} from "./VersionTypes"
import resources from "../core/Localization/Localization.js";

export const appTypesOptions = [
    { value: appTypes.BUYER_APP, label: appTypes.BUYER_APP },
    { value: appTypes.SELLER_APP, label: appTypes.SELLER_APP },

];

export const releaseTypesOptions = [
    { value: releaseTypes.STORE, label: releaseTypes.STORE },
    { value: releaseTypes.OTA, label: releaseTypes.OTA },
];
export const platformTypesOptions = [
    { value: platformTypes.ANDROID, label: platformTypes.ANDROID },
    { value: platformTypes.IOS,label: platformTypes.IOS },
];
export const releaseStatusTypesOptions = [
    { value: releaseStatusTypes.RELEASED, label: releaseStatusTypes.RELEASED },
    { value: releaseStatusTypes.PENDING, label: releaseStatusTypes.PENDING },
];