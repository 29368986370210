import resources from "../../core/Localization/Localization";
import { correctionOptions, currencyTypes, refundedOptions, tokenCorrectionTypes, TransactionTypes } from "../transactions/config";
import { timeRangeColumn } from "./customColumns/timeRangeColumn";
import { defaultCustomBodyRender } from "./DataGridColumns";

export const transactionColumns = (type) => {
    let columns = [
        {
            name: "id",
            label: resources.finance.datagrid.transactionId,
            options: {
                filter: true,
                sort: true,
                filterType: "textField",
                customBodyRender: defaultCustomBodyRender,
            },
        },
        {
            name: "username",
            label: resources.finance.datagrid.user,
            options: {
                filter: true,
                sort: true,
                filterType: "textField",
                customBodyRender: defaultCustomBodyRender,
            },
        },
        {
            name: "amount",
            label: resources.finance.datagrid.amount,
            options: {
                filter: true,
                sort: true,
                filterType: "textField",
                customBodyRender: defaultCustomBodyRender,
            },
        },
        {
            name: "currency_amount",
            label: resources.finance.datagrid.currencyAmount,
            options: {
                filter: true,
                sort: true,
                filterType: "textField",
                customBodyRender: defaultCustomBodyRender,
            },
        },
        {
            name: "currency",
            label: resources.finance.datagrid.currency,
            options: {
                filter: true,
                sort: true,
                filterType: "dropdown",
                filterOptions: {
                    names: Object.values(currencyTypes),
                    renderValue: (v) => v.toUpperCase(),
                },
                customBodyRender: defaultCustomBodyRender,
            },
        },
    ];

    switch (type) {
        case TransactionTypes.PAYIN:
            columns = columns.concat([]);
            break;
        case TransactionTypes.CORRECTION:
            columns = columns.concat([
                {
                    name: "correction_type",
                    label: resources.finance.datagrid.correctionType,
                    options: {
                        filter: true,
                        sort: true,
                        filterType: "dropdown",
                        filterOptions: {
                            names: Object.values(tokenCorrectionTypes),
                            renderValue: (v) => correctionOptions().find((x) => x.value === v).label,
                        },
                        customFilterListOptions: {
                            render: (v) => `${resources.finance.datagrid.correctionType}: ${correctionOptions().find((x) => x.value === v).label}`,
                        },
                        customBodyRender: defaultCustomBodyRender,
                    },
                },
            ]);
            break;

        default:
            columns = columns.concat([
                {
                    name: "part_offer_id",
                    label: resources.finance.datagrid.partOfferId,
                    options: {
                        filter: true,
                        sort: true,
                        filterType: "textField",
                        customBodyRender: defaultCustomBodyRender,
                    },
                },
                {
                    name: "refunded",
                    label: resources.finance.datagrid.refunded,
                    options: {
                        filter: true,
                        sort: true,
                        filterType: "dropdown",
                        filterOptions: {
                            names: [0, 1],
                            renderValue: (v) => refundedOptions().find((x) => x.value === v).label,
                        },
                        customFilterListOptions: {
                            render: (v) => `${resources.finance.datagrid.refunded}: ${refundedOptions().find((x) => x.value === v).label}`,
                        },
                        customBodyRender: defaultCustomBodyRender,
                    },
                },
                {
                    name: "refunded_amount",
                    label: resources.finance.datagrid.refundedAmount,
                    options: {
                        filter: true,
                        sort: true,
                        filterType: "textField",
                        customBodyRender: defaultCustomBodyRender,
                    },
                },
            ]);
            break;
    }
    columns.push({
        ...timeRangeColumn(),
    });

    return columns;
};
