const userInquiry = {
    en: {
        userInquiry: {
            inquiry: "Inquiry",
            guarantee: "guarantee",
            companyAge: "company age",
            description: "description",
            monthlyAmount: "monthly amount sold",
        },
    },
    rs: {
        userInquiry: {
            inquiry: "Upitnik",
            guarantee: "garancija",
            companyAge: "starost biznisa",
            description: "opis",
            monthlyAmount: "količina mesečne prodaje",
        },
    },
};

export default userInquiry;
