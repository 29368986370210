import React from "react";
//@material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { DriveEta } from "@material-ui/icons";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardAvatar from "../../components/Card/CardAvatar.js";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import resources from "../../core/Localization/Localization.js";

const useStyles = makeStyles(dashboardStyle);

const FilterBasicInfo = (props) => {
    const { filter } = props;
    const classes = useStyles();

    return (
        !!filter && (
            <Card className={"user-profile-card"}>
                <CardAvatar profile>
                    <DriveEta className={classes.profileIcon} />
                </CardAvatar>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6 className={classes.cardId}>ID: {filter.user_filter_id}</h6>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={resources.userFilters.datagrid.make}
                                inputProps={{
                                    value: filter.Make?.name ? filter.Make.name : "",
                                    disabled: true,
                                }}
                                id="filter-make"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.model}
                                inputProps={{
                                    value: filter.Model?.name ? filter.Model.name : "",
                                    disabled: true,
                                }}
                                id="filter-model"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.generation}
                                inputProps={{
                                    value: filter.Generation?.name ? filter.Generation.name : "",
                                    disabled: true,
                                }}
                                id="filter-generation"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={resources.userFilters.datagrid.user}
                                inputProps={{
                                    value: filter.User?.username ? filter.User.username : "",
                                    disabled: true,
                                }}
                                id="filter-username"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        )
    );
};

export default FilterBasicInfo;
