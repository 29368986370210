import { toast } from "react-toastify";
import api from "../../../api";

export const requestGetOffer = (id) => api.get(`/admin/offer/${id}`);

export const requestUpdateOffer = async (payload) => {
    try {
        return await api.put(`/admin/offers/${payload.id}`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};
