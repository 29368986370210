const userList = {
    en: {
        userList: {
            title: "Users",
            datagrid: {
                firstname: "First Name",
                lastname: "Last Name",
                shop_name: "Shop Name",
                email: "Email",
                role: "Role",
                created: "Created",
            },
        },
        userInfo: {
            blocked: "Blocked",
            active: "Active",
            phone: "Phone",
            city: "City",
            address: "Address",
            balance: "Balance",
            offers: "Offers",
            wins: "Wins",
            finished_trades: "Finished Trades",
            positive_ratings: "Positive Ratings",
            negative_ratings: "Negative Ratings",
        },
    },
    rs: {
        userList: {
            title: "Korisnici",
            datagrid: {
                firstname: "Ime",
                lastname: "Prezime",
                shop_name: "Ime Prodavnice",
                email: "Email",
                role: "Uloga",
                created: "Datum kreiranja",
            },
        },
        userInfo: {
            blocked: "Blokiran",
            active: "Aktivan",
            phone: "Telefon",
            city: "Grad",
            address: "Adresa",
            balance: "Tokeni",
            offers: "Ponude",
            wins: "Pobeda",
            finished_trades: "Završenih Trgovina",
            positive_ratings: "Pozitivne Ocene",
            negative_ratings: "Negativne Ocene",
        },
    },
};

export default userList;
