const userRoles = {
    en: {
        userRoles: {
            admin: "Administrator",
            operater: "Operator",
            seller: "Seller",
            customer: "Customer",
            user: "User",
            unverified: "Unverified Seller",
            seller_plus: "Seller Plus",
        },
    },
    rs: {
        userRoles: {
            admin: "Administrator",
            operater: "Operater",
            seller: "Prodavac",
            customer: "Kupac",
            user: "Kupac",
            unverified: "Neverifikovan Prodavac",
            seller_plus: "Super Prodavac",
        },
    },
};

export default userRoles;
