const userVehicles = {
    en: {
        userVehicles: {
            header: "Vehicles",
            subheader: "Vehicle",
            datagrid: {
                id: "ID",
                make: "Make",
                model: "Model",
                generation: "Generation",
                serie: "Serie",
                trim: "Trim",
                motor: "Engine",
                title: "Car name",
                vin: "Vin",
                owner: "Owner",
                created: "Created",
            },
        },
    },
    rs: {
        userVehicles: {
            header: "Automobili",
            subheader: "Automobil",
            datagrid: {
                id: "ID",
                make: "Marka",
                model: "Model",
                generation: "Generacija",
                serie: "Karoserija",
                trim: "Motor",
                motor: "Motor",
                title: "Ime auta",
                vin: "Šasija",
                owner: "Vlasnik",
                created: "Datum kreiranja",
            },
        },
    },
};

export default userVehicles;
