import React from "react";
import { Box, IconButton, Tooltip, Typography } from "@material-ui/core";
import { CloseOutlined, Delete, SkipNext, SkipPrevious } from "@material-ui/icons";
import CustomModal from "../CustomModal/CustomModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import useStyles from "../../assets/jss/material-dashboard-react/components/customImageItemStyles";
import resources from "../../core/Localization/Localization";
import { useSelector } from "react-redux";
import ModalGallery from "../ModalGallery/ModalGallery";

const RequestImage = ({ image, height, title, handleDelete, buttons, index }) => {
    const classes = useStyles();
    const images = useSelector((state) => state.partrequestImages.images);
    const [open, setOpen] = React.useState(false);
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const modalGalleryImages = React.useMemo(() => {
        return images.map((image) => image.large_part_request_image_url);
    }, [images]);

    return (
        <>
            <Box
                position="relative"
                className={classes.root}
                onClick={(e) => {
                    setOpen(true);
                }}
            >
                <img src={image?.medium_part_request_image_url} alt={title || ""} className={classes.img} height={height || 240} />
                <Box className={classes.titleBar}>
                    <Typography className={classes.title}>{title || ""}</Typography>
                    <Box className={classes.buttonWrapper}>
                        {buttons}
                        {handleDelete ? (
                            <Tooltip title={resources.form.delete}>
                                <IconButton
                                    size="medium"
                                    variant="contained"
                                    color="secondary"
                                    aria-label={resources.form.delete}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setOpenConfirm(true);
                                    }}
                                >
                                    <Delete fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        ) : null}
                    </Box>
                </Box>
            </Box>
            <ModalGallery images={modalGalleryImages} index={index} open={open} setOpen={setOpen} />
            <ConfirmationModal
                open={openConfirm}
                handleClose={() => setOpenConfirm(false)}
                title={resources.form.delete}
                text={resources.partRequests.deleteImage}
                onConfirmCallback={() => {
                    handleDelete();
                    setOpenConfirm(false);
                }}
                buttonLabel={resources.form.delete}
            />
        </>
    );
};

export default RequestImage;
