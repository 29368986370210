import { infoColor } from "../../material-dashboard-react";

const filtersStyle = {
    folderNavigationWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
    },
    folderNavigationButton: {
        backgroundColor: "transparent",
        borderWidth: 0,
        color: "white",
    },
    divider: {
        paddingLeft: 5,
        paddingRight: 5,
    },
    gridItem: {
        padding: 15,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    itemText: {
        paddingRight: 5,
        fontWeight: "bold",
    },
    loader: {
        marginLeft: 15,
        position: "relative",
        top: 4,
        zIndex: 9,
    },
    inputContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "10px 0px 20px 0px",
    },
    sellerCount: {
        marginLeft: 15,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 5,
        backgroundColor: infoColor[0],
        fontWeight: "bold",
    },
    exportContainer: {
        marginBottom: 20,
        marginRight: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    exportButton: {
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 5,
        backgroundColor: infoColor[0],
        fontWeight: "bold",
    },
};

export default filtersStyle;
