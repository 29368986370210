const partOffers = {
    en: {
        partOffers: {
            header: "Offers",
            subheader: "Offer",
            sections: {
                images: "Images",
            },
            datagrid: {
                id: "ID",
                title: "Title",
                partname: "Part name",
                make: "Make",
                model: "Model",
                generation: "Generation",
                trim: "Trim",
                description: "Description",
                price: "Price",
                type: "Type",
                status: "Status",
                username: "Users name",
                buyername: "Buyer's name",
                created: "Created",
                currency: "Currency",
                markAndModel: "Mark and Model",
                delivery: "Delivery",
                guarantee: "Guarantee",
                workGuarantee: "Work Guarantee",
            },
            status: {
                sent: "Sent",
                accepted: "Accepted",
                declined: "Declined",
                completed: "Completed",
            },
            statuslabel: {
                sent: "Sent",
                accepted: "Accepted",
                declined: "Declined",
                completed: "Part sent",
            },
            types: {
                used: "Used",
                new: "New",
            },
            guarantee: {
                sevenDays: "7 days",
                thirtyDays: "30 days",
                threeMonths: "3 months",
                sixMonths: "6 months",
                twelveMonths: "12 months",
                twoYears: "2 years",
            },
            delivery: {
                courier: "Courier",
                inPerson: "In person",
                both: "Courier | In person",
            },
            id: "ID",
            title: "Title",
            description: "Description",
            price: "Price",
            type: "Type",
            deleteImage: "Are you sure you want to delete selected image?",
            maxImagesMessage: "Maximum amount of images per offer is 3",
            limitImagesMessage: "Allowed images to upload is",
        },
        createOffer: {
            id: "ID",
            createdAt: "Created At",
            modifedAt: "Last updated",
            createNewOffer: "Create new Offer",
            status: "Status",
            price: "Price",
            type: "Type",
            userName: "Users name",
            description: "Description",
            add: "Add",
        },
    },
    rs: {
        partOffers: {
            header: "Ponude",
            subheader: "Ponuda",
            sections: {
                images: "Slike",
            },
            datagrid: {
                id: "ID",
                title: "Naziv",
                partname: "Ime dela",
                make: "Marka",
                model: "Model",
                trim: "Motor",
                generation: "Generacija",
                description: "Opis",
                price: "Cena",
                type: "Tip",
                status: "Stanje",
                username: "Ime korisnika",
                buyername: "Ime kupca",
                created: "Datum kreiranja",
                currency: "Valuta",
                markAndModel: "Marka i Model",
                guarantee: "Garancija",
                workGuarantee: "Radna garancija",
                delivery: "Dostava",
            },
            status: {
                sent: "Poslate",
                accepted: "Prihvaćene",
                declined: "Odbijene",
                completed: "Kompletirane",
            },
            statuslabel: {
                sent: "Poslata",
                accepted: "Prihvaćena",
                declined: "Odbijena",
                completed: "Deo poslat",
            },
            types: {
                used: "Polovan",
                new: "Nov",
            },
            guarantee: {
                sevenDays: "7 dana",
                thirtyDays: "30 dana",
                threeMonths: "3 meseca",
                sixMonths: "6 meseci",
                twelveMonths: "12 meseci",
                twoYears: "2 godine"
            },
            delivery: {
                courier: "Kurir",
                inPerson: "Lično",
                both: "Kurir | Lično",
            },
            id: "ID",
            title: "Naziv",
            description: "Opis",
            price: "Cena",
            type: "Tip",
            deleteImage: "Da li ste sigurni da želite da obrišete odabranu sliku?",
            maxImagesMessage: "Maksimalan broj slika za ponudu je 3",
            limitImagesMessage: "Dozvoljen broj slika za otpremanje je",
        },
        createOffer: {
            id: "ID",
            createdAt: "Ponuda kreirana",
            modifedAt: "Ponuda ažurirana",
            createNewOffer: "Dodaj novu Ponudu",
            status: "Status",
            price: "Cena",
            type: "Tip",
            userName: "Ime korisnika",
            description: "Opis",
            add: "Dodaj",
        },
    },
};

export default partOffers;
