const form = {
    en: {
        form: {
            activate: "Activate",
            edit: "Edit",
            disable: "Disable",
            save: "Save",
            schedule: "Schedule",
            delete: "Delete",
            cancel: "Cancel",
            add: "Add",
            yes: "Yes",
            no: "No",
            reset: "Reset",
            inDispute: "In Dispute",
            remove: "Remove",
            confirm: "Confirm",
            confirming: "Confirming",
            confirmed: "Confirmed",
            ok: "Ok",
            viewMore: "View more",
            message: "Message",
            createdAt: "Created At",
            start: "Start",
            to: "To",
            from: "From",
            close: "Close",
            next: "Next",
            previous: "Previous",
            images: {
                add: "Add Image",
                addMultiple: "Add Images",
                remove: "Remove Image",
                removeMultiple: "Remove Images",
            },
            errors: {
                firstname: "First name is required",
                lastname: "Last name is required",
                email: "Email is required",
                password: "Password is required",
                passwordMin: "Password must be at least 6 characters",
                passwordMax: "Password must not exceed 40 characters",
                repeatPassword: "Confirm Password is required",
                doesntMatchPassword: "Confirm Password does not match",
                role: "Role is required",
                requestTitle: "Name of part is required",
                requestState: "State of request must be selected",
                isNotWholeNumber: "Value must be a whole number",
                offer: {
                    title: "Title is required",
                    description: "Description is required",
                    status: "State is required",
                    price: "Price is required",
                    type: "Type is required",
                    userid: "User is required",
                    buyerid: "Buyer is required",
                    partRequestId: "Part request is required",
                    currency: "Currency is required",
                    delivery: "Delivery option is required",
                },
                version:{
                    app_name: "Application name is required",
                    version: "Version is required",
                    build_number: "Build number is required",
                    commit_ref: "Commit reference is required",
                    release_type: "Release type is required",
                    release_status: "Release status is required",
                    platform: "Platform is required",
                    supported_store_versions: "Podržane verzije",
                    description: "Description is required",
                }
            },
        },
        signout: "Signout",
        cancel: "Cancel",
    },
    rs: {
        form: {
            activate: "Aktiviraj",
            edit: "Izmeni",
            disable: "Onemogući",
            save: "Sačuvaj",
            schedule: "Zakaži",
            delete: "Obriši",
            cancel: "Odustani",
            add: "Dodaj",
            yes: "Da",
            no: "Ne",
            reset: "Resetuj",
            inDispute: "U spor",
            remove: "Ukloni",
            confirm: "Potvrdi",
            confirming: "Potvrđivanje",
            confirmed: "Potvrđeno",
            ok: "U redu",
            viewMore: "Detaljnije",
            message: "Poruka",
            createdAt: "Datum kreiranja",
            start: "Početak",
            to: "Do",
            from: "Od",
            close: "Zatvori",
            next: "Sledeće",
            previous: "Prethodno",
            images: {
                add: "Dodaj sliku",
                addMultiple: "Dodaj slike",
                remove: "Ukloni sliku",
                removeMultiple: "Ukoloni slike",
            },
            errors: {
                firstname: "Ime je obavezno",
                lastname: "Prezime je obavezno",
                email: "Email je obavezan",
                password: "Šifra je obavezna",
                passwordMin: "Šifra mora imati minimum 6 karaktera",
                passwordMax: "Šifra ne sme imati više od 40 karaktera",
                repeatPassword: "Potvrda šifre je obavezna",
                doesntMatchPassword: "Šifra i potvrda šifre nisu iste",
                role: "Uloga je obavezna",
                requestTitle: "Ime dela je obavezno",
                requestState: "Status dela je obavezno",
                isNotWholeNumber: "Vrednost mora biti ceo broj",

                offer: {
                    title: "Naslov je obavezan",
                    description: "Opis ponude je obavezan",
                    status: "Stanje je obavezno",
                    price: "Cena je obavezna",
                    type: "Tip je obavezan",
                    userid: "Korisnik je obavezan",
                    buyerid: "Kupac je obavezan",
                    partRequestId: "Zahtev za deo je obavezan",
                    currency: "Odabir valute je obavezan",
                    delivery: "Odabir načina dostae je obavezan",
                },
                version:{
                    app_name: "Ime aplikacije je obavezno",
                    version: "Verzija je obavezna",
                    build_number: "Build broj je obavezan",
                    commit_ref: "Commit referenca je obavezna",
                    release_type: "Release tip",
                    release_status: "Release status je obavezan",
                    platform: "Platforma je obavezna",
                    supported_store_versions: "Podržane verzije",
                    description: "Opis je obavezan",
                }
            },
        },
        signout: "Odjavi se",
        cancel: "Poništi",
    },
};

export default form;
