import React from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { Box, Typography, TextField, Divider } from "@material-ui/core";

//styles
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

// core components
import Button from "../../../components/CustomButtons/Button.js";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal.js";
import CustomTextArea from "../../../components/CustomTextArea/CustomTextArea";

//utils
import resources from "../../../core/Localization/Localization";
import { isAuth } from "../../../auth/helpers.js";
import { dateIsInFuture, getCurrentTimeInString, isValidDate, mapTimeToAPI } from "../../../utils/settings_service";
import { callEmergencyMaintenanceMode, scheduleMaintenanceMode, updateMaintenanceSettings } from "../../../reduxStore/entities/settings.js";
import { defaultMaintenanceConfig } from "../../../common/settings/MaintenanceSettings.js";

const useStyles = makeStyles(dashboardStyle);

const MaintenanceMode = () => {
    const dispatch = useDispatch();
    const operator = isAuth();
    const classes = useStyles();
    const maintenanceSettings = useSelector((state) => state.settings.maintenanceSettings);

    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [scheduleTime, setScheduleTime] = React.useState(null);
    const [error, setError] = React.useState("");
    const [scheduleMessage, setScheduleMessage] = React.useState("");
    const [modalMessage, setModalMessage] = React.useState("");
    const [modalCaller, setModalCaller] = React.useState(null);

    const handleReset = () => {
        setScheduleTime(getCurrentTimeInString());
        setScheduleMessage("");
        setError("");
    };

    const scheduleMaintenance = () => {
        if (scheduleTime && isValidDate(scheduleTime) && dateIsInFuture(scheduleTime)) {
            setError("");
            let payload = mapTimeToAPI(scheduleTime);
            // should be updated
            //  if (scheduleMessage) payload.custom_message = scheduleMessage;
            payload.updatedby = operator.userid;
            dispatch(scheduleMaintenanceMode(payload));
            handleReset();
        } else {
            setError("Invalid date input!");
        }
        setShowConfirmModal(false);
    };

    const callEmergencyMaintenance = () => {
        if (maintenanceSettings?.maintenance_config_id)
            dispatch(callEmergencyMaintenanceMode({ maintenance_config_id: maintenanceSettings.maintenance_config_id, updatedby: operator.userid }));
    };

    const resetMaintenanceToDefault = () => {
        if (maintenanceSettings?.maintenance_config_id)
            dispatch(
                updateMaintenanceSettings({
                    maintenance_config_id: maintenanceSettings.maintenance_config_id,
                    updatedby: operator.userid,
                    ...defaultMaintenanceConfig,
                })
            );
    };

    const handleClick = (caller) => {
        switch (caller) {
            case "scheduleMaintenance":
                setModalMessage(resources.settings.messages.confirmScheduleMaintenanceMode);
                setModalCaller("scheduleMaintenance");
                break;
            case "emergencyMaintenance":
                setModalMessage(resources.settings.messages.confirmEmergencyMaintenance);
                setModalCaller("emergencyMaintenance");
                break;
            case "resetMaintenanceConfig":
                setModalMessage(resources.settings.messages.confirmResetMaintenance);
                setModalCaller("resetMaintenanceConfig");
                break;

            default:
                break;
        }
        if (caller) setShowConfirmModal(true);
    };

    const handleConfirm = (caller) => {
        switch (caller) {
            case "scheduleMaintenance":
                scheduleMaintenance();
                break;

            case "emergencyMaintenance":
                callEmergencyMaintenance();
                break;
            case "resetMaintenanceConfig":
                resetMaintenanceToDefault();
                break;
            default:
                break;
        }
        setShowConfirmModal(false);
    };

    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Box className="column align-items-start  pt-3 pb-3">
                        <TextField
                            className="text-left pt-3"
                            id="datetime"
                            label={resources.settings.scheduleMaintenanceTime}
                            type="datetime-local"
                            defaultValue={getCurrentTimeInString()}
                            value={scheduleTime}
                            onChange={(e) => setScheduleTime(e.target.value)}
                            error={error}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        {error ? <p className="text-danger">{error}</p> : null}
                    </Box>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <CustomTextArea
                        labelText={resources.settings.scheduleMaintenanceMessage}
                        inputProps={{
                            value: scheduleMessage || "",
                            onChange: (e) => setScheduleMessage(e.target.value),
                        }}
                        formControlProps={{
                            fullWidth: true,
                        }}
                    />
                </GridItem>
            </GridContainer>

            <Box className={classes.buttonContainer}>
                <Box className={classes.confirmButton}>
                    <Button
                        name="scheduleMaintenance"
                        type={"button"}
                        color={"info"}
                        size={"sm"}
                        className={classes.buttonSeparator}
                        onClick={() => handleClick("scheduleMaintenance")}
                    >
                        {resources.form.schedule}
                    </Button>
                </Box>
                <Box className={classes.confirmButton}>
                    <Button type={"button"} color={"danger"} size={"sm"} className={classes.buttonSeparator} onClick={handleReset}>
                        {resources.form.cancel}
                    </Button>
                </Box>
            </Box>

            <Divider light className={classes.divider} />
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Box className="d-flex align-items-center justify-between pt-3 pb-3 mt-5">
                        <Typography className="text-danger font-weight-bold text-uppercase">{resources.settings.emergencyMaintenance}</Typography>
                        <Button
                            name="emergencyMaintenance"
                            color="danger"
                            size="sm"
                            onClick={() => {
                                handleClick("emergencyMaintenance");
                            }}
                        >
                            {resources.form.activate}
                        </Button>
                    </Box>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Box className="d-flex align-items-center justify-between pt-3 pb-3">
                        <Typography className="text-uppercase">{resources.settings.resetMaintenanceToDefault}</Typography>
                        <Button
                            name="resetMaintenanceConfig"
                            color="danger"
                            size="sm"
                            onClick={() => {
                                handleClick("resetMaintenanceConfig");
                            }}
                        >
                            {resources.form.reset}
                        </Button>
                    </Box>
                </GridItem>
            </GridContainer>
            <ConfirmationModal
                open={showConfirmModal}
                handleClose={() => {
                    setShowConfirmModal(false);
                }}
                buttonLabel={resources.form.confirm}
                text={modalMessage}
                onConfirmCallback={() => handleConfirm(modalCaller)}
            />
        </>
    );
};

export default MaintenanceMode;
