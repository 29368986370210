import React from "react";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import ReactJson from "react-json-view";

export default function HistoryLogBasicInfo(props) {
    const { historyLog } = props;

    const logInfo = { ...historyLog };
    delete logInfo.details;

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="info">
                <h4>Info</h4>
            </CardHeader>
            <CardBody>
                <ReactJson src={logInfo} displayDataTypes={false} />
            </CardBody>
        </Card>
    );
}
