import * as Yup from "yup";
import resources from "../../core/Localization/Localization.js";

const OfferCreateSchema = Yup.object().shape({
    description: Yup.string().required(resources.form.errors.offer.description),
    price: Yup.string().required(resources.form.errors.offer.price),
    type: Yup.string().required(resources.form.errors.offer.type),
    currency: Yup.string().required(resources.form.errors.offer.currency),
    delivery: Yup.string().required(resources.form.errors.offer.delivery),
});

export default OfferCreateSchema;
