const ADMIN_DETAILS_LAYOUT_KEY = "ADMIN_DETAILS_LAYOUT_V5";
const userBoxHeight = 14;
const adminDetailsLayout = {
    lg: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            static: true,
            minW: 8,
            maxW: 12,
        },
        {
            i: "2",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
        {
            i: "3",
            w: 6,
            h: userBoxHeight,
            x: 6,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
    ],
    md: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: false,
            static: true,
            minW: 8,
            maxW: 12,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isResizable: false,
            isDraggable: false,
            minW: 4,
            maxW: 12,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 2,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isResizable: false,
            isDraggable: false,
            minW: 4,
            maxW: 12,
        },
    ],
    xs: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: false,
            static: true,
            minW: 8,
            maxW: 12,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isResizable: false,
            isDraggable: false,
            minW: 4,
            maxW: 12,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 2,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isResizable: false,
            isDraggable: false,
            minW: 4,
            maxW: 12,
        },
    ],
};

export { ADMIN_DETAILS_LAYOUT_KEY, adminDetailsLayout };
