import React from "react";
//redux
import { useSelector } from "react-redux";

import { Responsive, WidthProvider } from "react-grid-layout";
import useLocalStorage from "../../hooks/useLocalStorage.js";
import { HISTORY_LOG_DETAILS_LAYOUT, defaultLayout } from "../../common/HistoryLogDetailsLayout";
import HistoryLogBasicInfo from "./HstoryLogBasicInfo";
import ActionLogInputInfo from "./ActionLogInputInfo";
import ActionLogBasicInfo from "./ActionLogBasicInfo";
import ActionLogOutputInfo from "./ActionLogOutputInfo";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function ActionLogDetails() {
    const historyLog = useSelector((state) => state.historyLogs.historyLogDetails);

    const [lsLayout, setLsLayout] = useLocalStorage(HISTORY_LOG_DETAILS_LAYOUT, defaultLayout);

    return (
        historyLog && (
            <ResponsiveGridLayout
                className="layout"
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                rowHeight={30}
                layouts={lsLayout}
                onLayoutChange={(layout, layouts) => {
                    setLsLayout(layouts);
                }}
            >
                <div key="1">
                    <HistoryLogBasicInfo historyLog={historyLog}></HistoryLogBasicInfo>
                </div>
                <div key="2">
                    <ActionLogBasicInfo historyLog={historyLog}></ActionLogBasicInfo>
                </div>
                <div key="3">
                    <ActionLogInputInfo historyLog={historyLog}></ActionLogInputInfo>
                </div>
                <div key="4">
                    <ActionLogOutputInfo historyLog={historyLog}></ActionLogOutputInfo>
                </div>
            </ResponsiveGridLayout>
        )
    );
}
