const createUser = {
    en: {
        createUser: {
            id: "ID",
            createdAt: "Created At",
            modifedAt: "Last updated",
            createNewUser: "Create new User",
            firstName: "First Name",
            lastName: "Last Name",
            shop_name: "Shop Name",
            email: "Email",
            password: "Password",
            repeatPassword: "Repeat Password",
            role: "Role",
            phone: "Phone",
            city: "City",
            doCreate: "Create",
            success: "User created successfully",
            emailExists: "Email already exists",
            country: "Country",
            address: "Address",
            sellerCommission: "Commission",
            errors: { firstNameRequired: "Name is required", lastNameRequired: "Last name is required", emailRequired: "Email is required" },
        },
        deleteUser: {
            success: "User deleted successfully",
        },
    },
    rs: {
        createUser: {
            id: "ID",
            createdAt: "Nalog kreiran",
            modifedAt: "Nalog dopunjen",
            createNewUser: "Dodaj novog Korisnika",
            firstName: "Ime",
            lastName: "Prezime",
            shop_name: "Ime Prodavnice",
            email: "Email",
            password: "Šifra",
            repeatPassword: "Ponovi šifru",
            role: "Rola",
            phone: "Telefon",
            city: "Grad",
            doCreate: "Dodaj",
            success: "Korisnik uspešno dodat",
            emailExists: "Email adresa vec postoji",
            country: "Država",
            address: "Adresa",
            sellerCommission: "Provizija",
            errors: { firstNameRequired: "Ime je obavezno", lastNameRequired: "Prezime je obavezno", emailRequired: "Email je obavezan" },
        },
        deleteUser: {
            success: "Korisnik uspešno obrisan",
        },
    },
};

export default createUser;
