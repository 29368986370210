import React, { useEffect } from "react";
import Layout from "../../core/Layout";
import { useSelector, useDispatch } from "react-redux";

import {
    getHistoryLogById,
    setHistoryLog,
} from "../../reduxStore/entities/historylogs";
import ActionLogDetails from "./ActionLogDetails";
import RevisionLogDetails from "./RevisionLogDetails";

export default function HistoryLogDetails(props) {
    const id = props.match.params.id;

    const historyLog = useSelector(
        (state) => state.historyLogs.historyLogDetails
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getHistoryLogById(id));

        return () => {
            dispatch(setHistoryLog(null));
        };
    }, []);

    return (
        <Layout>
            {historyLog &&
                historyLog.history_log_id &&
                historyLog.type === "ACTION" && (
                    <ActionLogDetails></ActionLogDetails>
                )}
            {historyLog &&
                historyLog.history_log_id &&
                historyLog.type === "REVISION" && (
                    <RevisionLogDetails></RevisionLogDetails>
                )}
        </Layout>
    );
}
