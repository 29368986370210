import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { signout } from './helpers';

const Forbidden = () => {

    const logout = () => {
        signout(() => {});
    }

    return (<Grid container spacing={4} >
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={3}>
        </Grid>
        <Grid item xs={6}>
            <Paper elevation={3}>
                <Box p={20} textAlign="center">
                    <Typography variant="h3">
                        Nemate dozvolu da pristupite ovom resursu
                    </Typography>
                    <Link href="/">
                        <Typography variant="body1">
                            Nazad
                        </Typography>
                    </Link>
                    <Link href="/signin">
                        <Typography variant="body1" onClick={logout}>
                            Odjava
                        </Typography>
                    </Link>
                </Box>
            </Paper>
        </Grid>
    </Grid>);
}

export default Forbidden;