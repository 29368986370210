const userAccess = {
    en: {
        userAccess: {
            logout: "Logout",
            logoutUser: "Logout user",
            logoutUserText: "Are you sure you want to logout this user?",
            block: "Block",
            blockUser: "Block user",
            blockUserText: "Are you sure you want to block this user?",
            unblock: "Unblock",
            userUnblock: "Unblock user",
            unblockUserText: "Are you sure you want to unblock this user?",
            delete: "Delete",
            deleteUser: "DeleteUser",
            deleteUserText: "Are you sure you want to delete this user?",
            seller_verified: "Seller verification",
            seller_plus_verified: "Seller plus verification",
            sellerVerificationText: "Are you sure you want to verify this user as Seller?",
            sellerPlusVerificationText: "Are you sure you want to verify this user as Seller Plus?",
            startVendorVerification: "Confirm",
            userSuccessfullyBlocked: "User successfully blocked",
            userSuccessfullyUnblocked: "User successfully unblocked",
        },
    },
    rs: {
        userAccess: {
            logout: "Izloguj",
            logoutUser: "Izloguj korisnika",
            logoutUserText: "Da li ste sigurni da želite da izlogujete korisnika?",
            block: "Blokiraj",
            blockUser: "Blokiraj korisnika",
            blockUserText: "Da li ste sigurni da želite da blokirate korisnika?",
            unblock: "Odblokiraj",
            userUnblock: "Odblokiraj korisnika",
            unblockUserText: "Da li ste sigurni da želite da odblokirate korisnika?",
            delete: "Obriši",
            deleteUser: "Obriši korisnika",
            deleteUserText: "Da li ste sigurni da želite da obrišete korisnika?",
            seller_verified: "Verifikacija prodavca",
            seller_plus_verified: "Verifikacija super prodavca",
            sellerVerificationText: "Da li ste sigurni da želite da verifikujete ovog korisnika kao Prodavca?",
            sellerPlusVerificationText: "Da li ste sigurni da želite da verifikujete ovog korisnika kao Super Prodavca?",
            startVendorVerification: "Potvrdi",
            userSuccessfullyBlocked: "Korisnik uspešno blokiran",
            userSuccessfullyUnblocked: "Korisnik uspešno odblokiran",
        },
    },
};

export default userAccess;
