import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPaginationOffers } from "../../reduxStore/entities/offers";
import Layout from "../../core/Layout";
import resources from "../../core/Localization/Localization.js";
import CustomDataGrid from "../../components/CustomDataGrid/CustomDataGrid2";
import { offersColumns } from "../../common/DataGridColumns/Offers";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { notificationTypes } from "../../variables/notificationTypes";
import { handleTableLoad } from "../../common/OffersTableData";

export default function Offers() {
    let history = useHistory();
    const notifications = useNotificationMessages();
    const columns = React.useMemo(offersColumns, [resources]);

    // Store hooks
    const offers = useSelector((state) => state.offers.offers);
    const count = useSelector((state) => state.offers.count);
    const dataLoaded = useSelector((state) => state.offers.dataLoaded);
    const dispatch = useDispatch();

    // State hooks
    const [rows, setRows] = React.useState(handleTableLoad(offers));
    const [totalRows, setTotalRows] = React.useState(count);
    const [currentFilters, setCurrentFilters] = React.useState(null);

    // React hooks

    useEffect(() => {
        notifications.publish({
            title: "Part offers read",
            body: "Part offers read",
            payload: {
                id: "null",
                topic: notificationTypes.partOffersRead,
            },
        });
    }, []);

    useEffect(() => setTotalRows(count), [count]);
    useEffect(() => setRows(handleTableLoad(offers)), [offers, dataLoaded]);

    useEffect(() => {
        const notificationListener = notifications.subscribe(notificationTypes.partOfferCreated, handler);
        return () => {
            notificationListener.unsubscribe();
        };
    }, [currentFilters]);

    const handler = (message) => {
        currentFilters ? dispatch(getPaginationOffers(currentFilters)) : dispatch(getPaginationOffers());
    };

    // Event handlers
    const onFiltersChanged = (filters) => doSearchOffers(filters);

    const onPageChange = (filters) => {
        doSearchOffers(filters);
        setRows(handleTableLoad(offers));
    };

    const onRowClick = (rowData, rowMeta) => {
        if (rowMeta && rowMeta.dataIndex >= 0) {
            window.open("/admin/offers/" + rows[rowMeta.dataIndex].id, "_blank");
        }
    };

    // Private (helper) functions
    const doSearchOffers = (filters) => {
        setCurrentFilters({ ...filters });
        dispatch(getPaginationOffers(filters));
    };

    return (
        <Layout>
            <CustomDataGrid
                title={resources.partOffers.header}
                rows={rows}
                columns={columns}
                totalRows={totalRows}
                loading={!dataLoaded}
                handleRowClick={onRowClick}
                handleFilterChange={onFiltersChanged}
                handlePageChange={onPageChange}
                handleDataInit={doSearchOffers}
                ignoreUrl={false}
            />
        </Layout>
    );
}
