import api from "../../../api";
import { toast } from 'react-toastify';

export const requestGetCountryList = async () => {
    try {
        return await api.get(`/admin/country-list`);
    } catch (error) {
        toast.error(error.response.data.error);
        return [];
    }
}