import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { setConfigurationSettings, setMaintenanceSettings, setPaymentSettings } from "../../entities/settings";
import {
    requestCallEmergencyMaintenanceMode,
    requestGetConfigurationSettings,
    requestGetMaintenanceSettings,
    requestGetPaymentSettings,
    requestLogoutAllUsers,
    requestScheduleMaintenanceMode,
    requestUpdateConfigurationSettings,
    requestUpdateMaintenanceSettings,
    requestUpdatePaymentSettings,
} from "../requests/settings";
import resources from "../../../core/Localization/Localization";

export function* handleGetPaymentSettings(action) {
    try {
        const response = yield call(requestGetPaymentSettings);
        const { data } = response;
        yield put(setPaymentSettings({ ...data }));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleUpdatePaymentSettings(action) {
    try {
        const response = yield call(requestUpdatePaymentSettings, action.payload);
        const { data } = response;
        if (data) toast.success(resources.settings.messages.updatePaymentSettingsSuccess);
        yield put(setPaymentSettings({ ...data }));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleLogoutAllUsers(action) {
    try {
        const response = yield call(requestLogoutAllUsers);
        const { data } = response;
        data?.success ? toast.success(resources.settings.messages.logoutUsersSuccess) : toast.error(resources.settings.messages.logoutUsersFailure);
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}
export function* handleScheduleMaintenanceMode(action) {
    try {
        const response = yield call(requestScheduleMaintenanceMode, action.payload);
        const { data } = response;
        if (data?.success) toast.success(data?.message || "");
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleCallEmergencyMaintenanceMode(action) {
    try {
        const response = yield call(requestCallEmergencyMaintenanceMode, action.payload);
        const { data } = response;
        if (data) toast.success(resources.settings.messages.emergencyMaintenanceSuccess);
        yield put(setMaintenanceSettings({ ...data }));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleGetMaintenanceSettings(action) {
    try {
        const response = yield call(requestGetMaintenanceSettings);
        const { data } = response;
        yield put(setMaintenanceSettings({ ...data }));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleUpdateMaintenanceSettings(action) {
    try {
        const response = yield call(requestUpdateMaintenanceSettings, action.payload);
        const { data } = response;
        yield put(setMaintenanceSettings({ ...data }));
        if (response.statusText === "OK") {
            toast.success(resources.settings.messages.maintenanceSettingsUpdated);
        }
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleGetConfigurationSettings(action) {
    try {
        const response = yield call(requestGetConfigurationSettings);
        const { data } = response;
        yield put(setConfigurationSettings({ ...data }));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleUpdateConfigurationSettings(action) {
    try {
        const response = yield call(requestUpdateConfigurationSettings, action.payload);
        const { data } = response;
        yield put(setConfigurationSettings({ ...data }));
        if (response.statusText === "OK") {
            toast.success(resources.settings.messages.configurationSettingsUpdated);
        }
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}
