import React from "react";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import resources from "../../core/Localization/Localization.js";
import moment from "moment";

export default function CarBasicInfo(props) {
    const { car } = props;

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="info">
                <h4>{resources.userVehicles.subheader}</h4>
            </CardHeader>
            <CardBody>
                {!!car && (
                    <GridContainer>
                        <GridItem xs={12} sm={4} md={2}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.id}
                                inputProps={{
                                    value: car.user_car_id,
                                    disabled: true,
                                }}
                                id="car-id"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={5}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.make}
                                inputProps={{
                                    value: car.Make?.name ? car.Make.name : "",
                                    disabled: true,
                                }}
                                id="car-make"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={4} md={5}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.model}
                                inputProps={{
                                    value: car.Model?.name ? car.Model.name : "",
                                    disabled: true,
                                }}
                                id="car-model"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.generation}
                                inputProps={{
                                    value: car.Generation?.name ? car.Generation.name : "",
                                    disabled: true,
                                }}
                                id="car-generation"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.motor}
                                inputProps={{
                                    value: car.Motor?.name ? car.Motor.name : "",
                                    disabled: true,
                                }}
                                id="car-motor"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.title}
                                inputProps={{
                                    value: car.title,
                                    disabled: true,
                                }}
                                id="car-title"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.vin}
                                inputProps={{
                                    value: car.vin,
                                    disabled: true,
                                }}
                                id="car-id"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.owner}
                                inputProps={{
                                    value: car.User?.username || "",
                                    disabled: true,
                                }}
                                id="car-owner"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.created}
                                inputProps={{
                                    value: car.createdAt ? moment(car.createdAt).format("DD/MM/YYYY hh:mm") : "",
                                    disabled: true,
                                }}
                                id="car-createdAt"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                )}
            </CardBody>
        </Card>
    );
}
