import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { setPartrequestImages } from "../../entities/partrequestImages";

import { requestDeletePartrequestImage, requestGetPartRequestImages } from "../requests/partrequestImages";

export function* handleGetPartrequestImages(action) {
    try {
        const response = yield call(requestGetPartRequestImages, action.payload);
        let { data } = response;
        yield put(setPartrequestImages([...data]));
    } catch (error) {
        toast.error(error.message);
    }
}

export function* handleDeletePartrequestImage(action) {
    try {
        const deleteResponse = yield call(requestDeletePartrequestImage, action.payload.imageId);

        if (deleteResponse) {
            const response = yield call(requestGetPartRequestImages, action.payload.requestId);

            let { data } = response;
            if (data) yield put(setPartrequestImages([...data]));
        }
    } catch (error) {
        toast.error(error.message);
    }
}
