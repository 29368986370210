import resources from "../core/Localization/Localization";

export const filterUserColumns = [
    {
        name: "userid",
        label: "ID",
    },
    {
        name: "firstname",
        label: resources.userList.datagrid.firstname,
    },
    {
        name: "lastname",
        label: resources.userList.datagrid.lastname,
    },
    {
        name: "phone",
        label: resources.userInfo.phone,
    },
    {
        name: "email",
        label: resources.userList.datagrid.email,
    },
    {
        name: "city",
        label: resources.userInfo.city,
    },
    {
        name: "address",
        label: resources.userInfo.address,
    },
    {
        name: "balance",
        label: resources.userInfo.balance,
    },
    {
        name: "total_offers",
        label: resources.userInfo.offers,
    },
    {
        name: "total_wins",
        label: resources.userInfo.wins,
    },
    {
        name: "total_finished_trades",
        label: resources.userInfo.finished_trades,
    },
    {
        name: "positive_rated_as_seller",
        label: resources.userInfo.positive_ratings,
    },
    {
        name: "negative_rated_as_seller",
        label: resources.userInfo.negative_ratings,
    },
];