import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";


import avatar from "../../assets/img/faces/marc.jpg";
import Layout from "../../core/Layout";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function Credit() {
    const classes = useStyles();
    return (
        <Layout>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        Credit strana
                    </GridItem>
                </GridContainer>
            </div>
        </Layout>
    );
}
