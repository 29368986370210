import { call, put } from "redux-saga/effects";
import { mapOffersState } from "../../../utils/mapOfferStates";
import { mapPartrequestsState } from "../../../utils/mapRequestStates";
import { setPartrequest } from "../../entities/partrequestDetails";
import {
    requestGetPartrequest,
    requestUpdatePartrequest,
} from "../requests/partrequestDetails";

export function* handleGetPartrequest(action) {
    try {
        const response = yield call(requestGetPartrequest, action.payload);
        let { data } = response;
        data = mapPartrequestsState([data])[0];
        data.PartOffers = mapOffersState(data.PartOffers);
        yield put(setPartrequest({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleUpdatePartrequest(action) {
    try {
        const response = yield call(requestUpdatePartrequest, action.payload);
        let { data } = response;
        data = mapPartrequestsState([data])[0];
        data.PartOffers = mapOffersState(data.PartOffers);
        yield put(setPartrequest({ ...data }));
    } catch (error) {
        console.log(error);
    }
}
