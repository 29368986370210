const partRequests = {
    en: {
        partRequests: {
            title: "Requests",
            subtitle: "Request Info",
            sections: {
                vehicle: "Vehicle",
                offers: "Offers",
                history: "History",
                images: "Images",
            },
            status: {
                active: "Active",
                completed: "Completed",
                failed: "Failed",
                active_single: "Active",
                completed_single: "Completed",
                failed_single: "Failed",
            },
            datagrid: {
                id: "ID",
                title: "Part",
                make: "Make",
                model: "Model",
                description: "Description",
                userid: "User ID",
                username: "Users name",
                partstate: "Part state",
                state: "State",
                states: "Status",
                offers: "Offers",
                created: "Date",
                vin: "Vin",
                ownername: "Owner name",
                ownerid: "Owner ID",
                issue_reason: "Issue reason",
                issue_solution: "Issue solution",
                issue_reported: "Issue reported by",
                address: "Delivery address",
                city: "City",
            },
            viewMore: "View more",
            block: "In dispute",
            remove: "Remove",
            success: "Success",
            failure: "Failure",
            resolvePartRequestRemoveText: "Are you sure you want to remove the part request?",
            resolvePartRequestBlockText: "Are you sure you want to change the state of the part request to the 'in dispute'?",
            resolvePartRequestSuccessText: "Are you sure you want to change the state of the part request to the 'Dispute finished, trade successful'?",
            resolvePartRequestFailure13Text:
                "Are you sure you want to change the state of the part request to the 'Dispute finished, trade failed due to no one'?",
            resolvePartRequestFailure14Text:
                "Are you sure you want to change the state of the part request to the 'Dispute finished, trade failed due to both'?",
            resolvePartRequestFailure15Text:
                "Are you sure you want to change the state of the part request to the 'Dispute finished, trade failed due to buyer'?",
            resolvePartRequestFailure16Text:
                "Are you sure you want to change the state of the part request to the 'Dispute finished, trade failed due to seller'?",
            resolvePartRequestRefund: "Are you sure you want to refund the amount for this part request?",
            deleteImage: "Are you sure you want to delete selected image?",
            state: {
                one: "Accepting offers",
                two: "Time for choosing offers",
                three: "Finished without offers",
                four: "Finished without choosing offer",
                five: "Winner chosen",
                six: "The seller has sent the part, but the user hasn't received the part yet",
                seven: "Transaction completed",
                eight: "The buyer received the part, but the seller did not indicate that he did send it",
                nine: "The transaction was marked as completed as no one complained for 7 days",
                ten: "Request in dispute",
                eleven: "Removed",
                twelve: "Dispute resolved and trade successful",
                thirteen: "Dispute resolved and trade unsuccessful due to no one",
                fourteen: "Dispute resolved and trade unsuccessful due to both",
                fifteen: "Dispute resolved and trade unsuccessful due to buyer",
                sixteen: "Dispute resolved and trade unsuccessful due to seller",
            },
            partstate:{
                zero:"New and used",
                one:"Only new",
                two:"Only used"
            }
        },
    },
    rs: {
        partRequests: {
            title: "Zahtevi",
            subtitle: "Informacije",
            sections: {
                vehicle: "Automobili",
                offers: "Ponude",
                history: "Istorija",
                images: "Slike",
            },
            status: {
                active: "Aktivni",
                completed: "Realizaovani",
                failed: "Nerealizovani",
                active_single: "Aktivan",
                completed_single: "Realizovan",
                failed_single: "Nerealizovan",
            },
            datagrid: {
                id: "ID",
                title: "Deo",
                make: "Marka",
                model: "Model",
                description: "Opis",
                userid: "ID korisnika",
                username: "Ime korisnika",
                partstate: "Stanje dela",
                state: "Stanje",
                states: "Status",
                offers: "Br. ponuda",
                created: "Datum",
                vin: "Broj šasije",
                ownername: "Ime vlasnika",
                ownerid: "ID vlasnika",
                issue_reason: "Razlog prijave",
                issue_solution: "Željeno rešenje",
                issue_reported: "Problem prijavio",
                address: "Adresa za dostavu",
                city: "Grad",
            },
            viewMore: "Detaljnije",
            block: "U sporu",
            remove: "Ukloni",
            success: "Uspešno",
            failure: "Neuspešno",
            resolvePartRequestRemoveText: "Da li ste sigurni da želite da uklonite zahtev?",
            resolvePartRequestBlockText: "Da li ste sigurni da želite da promenite status zahteva u 'zahtev u sporu'?",
            resolvePartRequestSuccessText: "Da li ste sigurni da želite da promenite status zahteva u 'spor rešen i trgovina uspešna'?",
            resolvePartRequestFailure13Text: "Da li ste sigurni da želite da promenite status zahteva u 'spor rešen i trgovina neuspešna ni zbog koga'?",
            resolvePartRequestFailure14Text: "Da li ste sigurni da želite da promenite status zahteva u 'spor rešen i trgovina neuspešna zbog oboje'?",
            resolvePartRequestFailure15Text: "Da li ste sigurni da želite da promenite status zahteva u 'spor rešen i trgovina neuspešna zbog kupca'?",
            resolvePartRequestFailure16Text: "Da li ste sigurni da želite da promenite status zahteva u 'spor rešen i trgovina neuspešna zbog prodavca'?",
            resolvePartRequestRefund: "Da ki ste sigurni da želite da refundirate sredstva za ovaj zahtev?",
            deleteImage: "Da li ste sigurni da želite da obrišete odabranu sliku?",
            state: {
                one: "Primanje ponuda",
                two: "Vreme za odabiranje ponude",
                three: "Završeno bez ponuda",
                four: "Završeno nije odabrana ponuda",
                five: "Odabran pobednik",
                six: "Prodavac je poslao deo kupac još nije primio",
                seven: "Transakcija je obavljena",
                eight: "Kupac je primio deo, prodavac nije naznačio da je poslao",
                nine: "Transakcija je označena kao završena pošto se niko nije žalio 7 dana",
                ten: "Zahtev u sporu",
                eleven: "Uklonjen",
                twelve: "Spor rešen i trgovina uspešna",
                thirteen: "Spor rešen i trgovina neuspešna ni zbog koga",
                fourteen: "Spor rešen i trgovina neuspešna zbog oboje",
                fifteen: "Spor rešen i trgovina neuspešna zbog kupca",
                sixteen: "Spor rešen i trgovina neuspešna zbog prodavca",
            },
            partstate:{
                zero:"Nov ili polovan",
                one:"Samo nov",
                two:"Samo polovan"
            }
        },
    },
};

export default partRequests;
