import api from "../../../api";

export const requestGetOffers = () => api.get("/admin/offers");

export const requestGetSentNewOffers = () => api.get("/admin/offers/sent");

export const requestGetAcceptedNewOffers = () => api.get("/admin/offers/accepted");

export const requestGetUserOffers = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/user-status-offers${query ? query : ""}`);
};

export const requestGetPaginationOffers = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/pagination-offers${query ? query : ""}`);
};

export const requestCreateNewOffer = (data) => api.put(`/admin/newpartoffer`, data);
