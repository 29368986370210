import React from "react";
import { Box, Typography } from "@material-ui/core";
import CustomModal from "../CustomModal/CustomModal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-dashboard-react/components/messageModalStyle";
import { DateRange } from "@material-ui/icons";
import resources from "../../core/Localization/Localization.js";

const useStyles = makeStyles(modalStyle);

export default function MessageModal(props) {
    const { open, handleClose, title, text, buttonLabel, createdAt, customButton, customButtonLabel, customOnClick } = props;

    const classes = useStyles();

    return (
        <CustomModal open={open} onClose={handleClose}>
            <Box className={classes.root}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.titleText}>{title}</Typography>
                </Box>
                <Box className={classes.bodyContainer}>
                    {createdAt && (
                        <Box className={classes.bodyTime}>
                            <DateRange />
                            <Typography>{createdAt}</Typography>
                        </Box>
                    )}
                    <Typography className={classes.bodyText}>{text}</Typography>
                </Box>
                <Box className={classes.footerContainer}>
                    {customButton && (
                        <Button type={"button"} size={"sm"} color="danger" className={classes.button} onClick={customOnClick}>
                            {customButtonLabel}
                        </Button>
                    )}
                    <Button type={"button"} size={"sm"} color="info" className={classes.button} onClick={handleClose}>
                        {buttonLabel}
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
}
