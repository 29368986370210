import { infoColor } from "../../material-dashboard-react";

const modalStyle = {
    root: { maxWidth: 600, width: "80vw", minWidth: 280, paddingBottom: 10 },
    titleContainer: {
        padding: "5px 10px",
        marginBottom: 15,
        background: "linear-gradient(160deg, #CBE100 0%, #5C8BCD 50%)",
    },
    titleText: {
        fontSize: 20,
        fontWeight: 700,
    },
    bodyContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
    },
    bonusContainer: {
        marginTop: 10,
        width: "calc(50% - 10px)",
        borderBottom: "1px solid " + infoColor[0],
        "& h6": {
            fontSize: 14,
            color: "#AAAAAA",
            transform: "translate(0, 1.5px) scale(0.75)",
            transformOrigin: "top left",
        },
    },
    amount: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 30,
        width: "100%",
    },
    errorContainer: {
        padding: 20,
    },
    footerContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
    button: {
        marginRight: 10,
    },
};

export default modalStyle;
