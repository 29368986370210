import { call, put, take } from "redux-saga/effects";
import {createVersion, getPaginationVersions, getVersionById, getVersions} from "../requests/versions";
import {setPaginationVersions, setVersions} from "../../entities/versions";
import {createUser, getUserByEmail} from "../requests/users";
import {toast} from "react-toastify";
import resources from "../../../core/Localization/Localization";
import {setUser} from "../../entities/users";
import {push} from "connected-react-router";

export function* handleGetVersions(action) {
    try {
        const response = yield call(getVersions);
        const { data } = response;
        yield put(setVersions({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleCreateVersion(action) {
    try {

        const response = yield call(createVersion, action.payload);
        const { data } = response;
      //  yield put(setUser({ ...data }));
        yield put(push(`/admin/versions`));
        toast.success(resources.createVersion.success);
    } catch (error) {
        console.log(error);
    }

}
export function* handleGetPaginationVersions(action) {
    try {
        const response = yield call(getPaginationVersions);
        const { data } = response;
        yield put(setPaginationVersions({ ...data }));
    } catch (error) {
        console.log(error);
    }

}
