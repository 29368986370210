import { createSlice } from "@reduxjs/toolkit";

const users = createSlice({
    name: "users",
    initialState: {
        users: [],
        paginationUsers: [],
        count: 0,
        dataLoaded: false,
    },
    reducers: {
        getUsers(state, action) {
            return { ...state, dataLoaded: false };
        },
        setUsers(state, action) {
            const userData = Object.values(action.payload);
            return { ...state, users: userData, dataLoaded: true };
        },
        setUser(state, action) {
            const users = [...state.users];
            users.push(action.payload);
            return { ...state, users, dataLoaded: true };
        },
        createNewUser() {},
        getPaginationUsers(state, action) {
            return { ...state, dataLoaded: false };
        },
        setPaginationUsers(state, action) {
            return { ...state, users: action.payload.data, count: action.payload.count, dataLoaded: true };
        },
    },
});

export const { getUsers, setUsers, createNewUser, setUser, getPaginationUsers, setPaginationUsers } = users.actions;

export default users.reducer;
