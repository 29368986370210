import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";
import { setOfferImages } from "../../entities/offerImages";
import { requestAddOfferImages, requestDeleteOfferImage, requestGetOfferImages } from "../requests/offerImages";

export function* handleGetOfferImages(action) {
    try {
        const response = yield call(requestGetOfferImages, action.payload);
        let { data } = response;
        yield put(setOfferImages([...data]));
    } catch (error) {
        toast.error(error.message);
    }
}

export function* handleDeleteOfferImage(action) {
    try {
        const deleteImg = yield call(requestDeleteOfferImage, action.payload.imageId);
        if (deleteImg) {
            const response = yield call(requestGetOfferImages, action.payload.offerId);

            let { data } = response;
            yield put(setOfferImages([...data]));
        }
    } catch (error) {
        toast.error(error.message);
    }
}

export function* handleAddOfferImages(action) {
    try {
        const response = yield call(requestAddOfferImages, action.payload.images);
        if (response) {
            const images = yield call(requestGetOfferImages, action.payload.offerId);
            let { data } = images;
            yield put(setOfferImages([...data]));
        }
    } catch (error) {
        toast.error(error.message);
    }
}
