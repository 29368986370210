import { call, put } from "redux-saga/effects";
import { setDashboard } from "../../entities/dashboard";
import { requestGetDashboard } from "../requests/dashboard";

export function* handleGetDashboard(action) {
    try {
        const response = yield call(requestGetDashboard);
        const { data } = response;
        yield put(setDashboard({ ...data }));
    } catch (error) {
        console.log(error);
    }
}