import { createSlice } from "@reduxjs/toolkit";

const country = createSlice({
    name: "country",
    initialState: {
        country: [],
        dataLoaded: false
    },
    reducers: {
        getCountryList() {},
        setCountryList(state, action) {
            const data = action.payload;

            return { ...state, country: data, dataLoaded: true };
        }
    }
});

export const { getCountryList, setCountryList } = country.actions;

export default country.reducer;
