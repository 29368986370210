import UserRoles from "../auth/UserRoles";
import resources from "../core/Localization/Localization";

export const handleTableLoad = (users) => {
    const getUserRole = (user) => {
        if (![UserRoles.SELLER, UserRoles.SELLER_PLUS].includes(user.role) || user.seller_verified) {
            return resources.userRoles[user.role] 
        } else {
            return resources.userRoles["unverified"]
        }
    };

    const preparedData = users.map((user) => {
        return {
            id: user.userid,
            firstname: user.firstname,
            lastname: user.lastname,
            shop_name:user.shop_name,
            email: user.email,
            role: getUserRole(user),
            createdAt: user.createdAt,
        };
    });
    return preparedData;
};
