const financeCreditStyle = {
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "100%",
        minHeight: 300,
        gap: 20,
    },

    legendContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: 20,
        marginLeft: 20,
    },
};

export default financeCreditStyle;
