import * as Yup from "yup";
import resources from "../../core/Localization/Localization.js";
import {appTypes,releaseTypes,platformTypes,releaseStatusTypes} from "../../common/VersionTypes";

const VersionCreateSchema = Yup.object().shape({
    app_name: Yup.string().required(resources.form.errors.version.app_name),
    version: Yup.string().required(resources.form.errors.version.version),
    build_number: Yup.string().required(resources.form.errors.version.build_number),
    commit_ref:Yup.string().required(resources.form.errors.version.commit_ref),
    release_type: Yup.string().required(resources.form.errors.version.release_type),
    platform: Yup.string().required(resources.form.errors.version.platform),
    supported_store_versions: Yup.array().of(Yup.string()).optional().nullable(),
    release_status: Yup.string().required(resources.form.errors.version.release_status),
    description: Yup.string().required(resources.form.errors.version.description),
});

export default VersionCreateSchema;




