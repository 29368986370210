import { createSlice } from "@reduxjs/toolkit";


const versionDetails = createSlice({
    name: "versionDetails",
    initialState: {
        version: {},
        dataLoaded: false,
    },
    reducers: {
        getVersion(state) {
            return { ...state, dataLoaded: false };
        },
        setVersion(state, action) {
            const payload = action.payload;
            return { ...state, version: payload, dataLoaded: true };
        },
        updateVersion(state) {
            return { ...state, dataLoaded: false };
        },
        requestFailed(state) {
            return { ...state, dataLoaded: true };
        },
    },
});

export const { getVersion,setVersion,updateVersion, requestFailed} = versionDetails.actions;

export default versionDetails.reducer;