const userFilters = {
    en: {
        userFilters: {
            header: "Garage",
            title: "All Makes",
            subheader: "Vehicle",
            datagrid: {
                id: "ID",
                make: "Make",
                model: "Model",
                generation: "Generation",
                user: "User",
            },
        },
    },
    rs: {
        userFilters: {
            header: "Garaža",
            title: "Sve Marke",
            subheader: "Automobil",
            datagrid: {
                id: "ID",
                make: "Marka",
                model: "Model",
                generation: "Generacija",
                user: "Korisnik",
            },
        },
    },
};

export default userFilters;
