import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import Button from "../../components/CustomButtons/Button";

import { getUserCars } from "../../reduxStore/entities/userDetails.js";
import { defaultTableSize } from "../../common/UserDetailsLayout";
import resources from "../../core/Localization/Localization.js";
import { USER_VEHICLES_HEADERS } from "../../common/UserDetailsTabs.js";

export default function UserVehicles() {
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((x) => x.userDetails.user);
    const userCars = useSelector((state) => state.userDetails.userCars);

    const [tableData, setTableData] = useState([]);

    const headers = React.useMemo(USER_VEHICLES_HEADERS, [resources]);

    useEffect(() => {
        dispatch(getUserCars(user.userid));
    }, [dispatch]);

    useEffect(() => {
        setTableData(mapVehiclesInTableData(userCars));
    }, [userCars]);

    /////////////

    const mapVehiclesInTableData = (userVehicles) => {
        if (!userVehicles) {
            return [];
        }
        return userVehicles.map((x) => {
            return [x.user_car_id.toString(), x.Make.name, x.Model.name, x.Generation.name, x.Motor?.name ? x.Motor.name : ""];
        });
    };

    const handleViewMore = () => history.push(`/admin/usercars?userid=${user.userid}`);

    const onRowClick = (params) => {
        if (params.length) {
            history.push("/admin/usercars/" + params[0]);
        }
    };

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="info">
                <h4>{resources.userVehicles.header}</h4>
            </CardHeader>
            <CardBody>
                <Table
                    tableHeaderColor="info"
                    tableHead={headers}
                    tableData={tableData}
                    handleClick={onRowClick}
                    paginationInfo={{
                        pageSize: defaultTableSize,
                        pageSizeOptions: [defaultTableSize],
                    }}
                />
                {tableData.length > 0 && (
                    <Button type="button" color="info" size="sm" onClick={handleViewMore}>
                        {resources.partRequests.viewMore}
                    </Button>
                )}
            </CardBody>
        </Card>
    );
}
