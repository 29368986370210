import React from "react";
import { useSelector, useDispatch } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// core components
import CardBody from "../../../components/Card/CardBody.js";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button.js";

//styles
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

//utils
import resources from "../../../core/Localization/Localization";
import { isAuth } from "../../../auth/helpers.js";
import UserRoles from "../../../auth/UserRoles.js";
import { getConfigurationSettings } from "../../../reduxStore/entities/settings.js";
import RequestsAndOffers from "./RequestsAndOffers.js";

const useStyles = makeStyles(dashboardStyle);

const SettingsConfiguration = () => {
    const operator = isAuth();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [editMode, setEditMode] = React.useState(false);

    const enableEditMode = () => {
        setEditMode(!editMode);
    };

    React.useEffect(() => {
        dispatch(getConfigurationSettings());
    }, []);

    return operator.role === UserRoles.ADMIN ? (
        <>
            <CardBody profile>
                <Box className={classes.absoluteContainer}>
                    <Button
                        type={"button"}
                        color={!editMode ? "info" : "danger"}
                        size={"sm"}
                        className={classes.buttonSeparator}
                        onClick={(e) => {
                            enableEditMode();
                        }}
                    >
                        {!editMode ? resources.form.edit : resources.form.disable}
                    </Button>
                </Box>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h6 className={classes.cardCategory}>{resources.settings.configurationSettings}</h6>
                    </GridItem>
                </GridContainer>
                <RequestsAndOffers editMode={editMode} />
            </CardBody>
        </>
    ) : null;
};

export default SettingsConfiguration;
