import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Chat from "@material-ui/icons/Chat";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
import GroupWork from "@material-ui/icons/GroupWork";
import Airplay from "@material-ui/icons/Airplay";
import Group from "@material-ui/icons/Group";
import Euro from "@material-ui/icons/Euro";
import Extension from "@material-ui/icons/Extension";
import ChildFriendly from "@material-ui/icons/ChildFriendly";
import WatchLater from "@material-ui/icons/WatchLater";
import Code from "@material-ui/icons/Code";
import FormatPaint from "@material-ui/icons/FormatPaint";
import Dashboard from "@material-ui/icons/Dashboard";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccessTime from "@material-ui/icons/AccessTime";
import AttachMoney from "@material-ui/icons/AttachMoney";
// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import InfoArea from "../../../components/InfoArea/InfoArea.js";

import featuresStyle from "../../../assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";

import iphone from "../../../assets/img/sections/iphone.png";
import iphone2 from "../../../assets/img/sections/iphone2.png";
import appstore from "../../../assets/img/appstore.png";
import playstore from "../../../assets/img/playstore.png";
import bg from "../../../assets/img/backgroundEmpty.png";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 2 START */}
        <div className={classes.features2}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.titleCenter}>Preuzmite Smart Part aplikaciju!</h2>
              {/*<h5 className={classes.descriptionCenter}>*/}
              {/*  Vaš digitalni asistent za sve sto Vam je potrebno u vezi*/}
              {/*  Vašeg automobila.*/}
              {/*</h5>*/}
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={3} md={3}>

            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
              <a
                  href="https://play.google.com/store/apps/details?id=app.smartpart.smartpartbuyerapp"
                  target="_blank"
                  rel="noreferrer"
              >
                <img src={playstore} alt="..."  height={80} />
              </a>
            </GridItem>


            <GridItem xs={12} sm={3} md={3} >
              <a
                  href="https://apps.apple.com/us/app/smart-part/id1644323936"
                  target="_blank"
                  rel="noreferrer"

              >
                <img src={appstore} alt="..." height={81} />
              </a>
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>

            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 2 END */}
        {/* Feature 3 START */}
        <div className={classes.features3}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <div className={classes.phoneContainer}>
                <img src={iphone} alt="..." />
              </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={5}>
              <h2 className={classes.title}>Polovni i novi delovi bez muke</h2>
              <InfoArea
                className={classes.infoArea}
                icon={Group}
                title="Stotine partnera"
                description="Svaki zahtev se automatski šalje da adrese naših prodajnih partnera."
                iconColor="secoundary"
              />
              <InfoArea
                className={classes.infoArea}
                icon={Euro}
                title="Ušteda novca"
                description="Partneri šalju svoju najbolju ponudu s obziriom da ne vide ostale ponude koje su vam poslate."
                iconColor="secoundary"
              />
              <InfoArea
                className={classes.infoArea}
                icon={WatchLater}
                title="Ušteda vremena"
                description="Umesto da Vi provedete ceo dan tražeći delove naš sistem to radi u samo nekoliko sekundi umesto vas."
                iconColor="secoundary"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 3 END */}
        {/* Feature 4 START */}
        <div className={classes.features4}>
          {/*<GridContainer>*/}
          {/*  <GridItem*/}
          {/*    xs={12}*/}
          {/*    sm={8}*/}
          {/*    md={8}*/}
          {/*    className={*/}
          {/*      classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <h2 className={classes.title}>Your life will be much easier</h2>*/}
          {/*    <h5 className={classes.description}>*/}
          {/*      This is the paragraph where you can write more details about*/}
          {/*      your product. Keep you user engaged by providing meaningful*/}
          {/*      information.*/}
          {/*    </h5>*/}
          {/*  </GridItem>*/}
          {/*</GridContainer>*/}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mlAuto}>
              <InfoArea
                icon={Code}
                title="Brzo i lako do potrebnog dela"
                description="Na osnovu upita, broja šasije, modela ili fotografije prodavci sami šalju svoje ponude "
                iconColor="info"
              />
              <InfoArea
                icon={FormatPaint}
                title="Značajna ušteda"
                description="Konkurentne cene zbog velikog broja prodavaca. Direktna kupovina od prodavca."
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={4}>
              <div className={classes.phoneContainer}>
                <img src={iphone2} alt="..." />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={3} className={classes.mrAuto}>
              <InfoArea
                icon={Dashboard}
                title="Virtuelni asistent"
                description="Asistencija pri održavanju vozila, odabiru servisa. "
                iconColor="primary"
              />
              <InfoArea
                icon={ViewCarousel}
                title="Sigurna kupovina"
                description="Stručna pomoć pri kupovini, korišćenju aplikacije i odabiru delova. Jednostavno plaćanje."
                iconColor="success"
              />
            </GridItem>
          </GridContainer>
        </div>
        {/* Feature 4 END */}
      </div>
      {/*/!* Feature 5 START *!/*/}
      {/*<div*/}
      {/*  className={classes.features5}*/}
      {/*  style={{ backgroundImage: `url(${bg})` }}*/}
      {/*>*/}
      {/*  <GridContainer>*/}
      {/*    <GridItem*/}
      {/*      xs={12}*/}
      {/*      sm={8}*/}
      {/*      md={8}*/}
      {/*      className={*/}
      {/*        classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter*/}
      {/*      }*/}
      {/*    >*/}
      {/*      /!*<h2 className={classes.title}>Your life will be much easier</h2>*!/*/}
      {/*    </GridItem>*/}
      {/*    <div className={classes.container}>*/}
      {/*      <GridContainer className={classes.gridContainer}>*/}
      {/*        <GridItem xs={12} sm={4} className={classes.gridItem}>*/}
      {/*          <InfoArea*/}
      {/*            vertical*/}
      {/*            className={classes.infoArea5}*/}
      {/*            icon={Code}*/}
      {/*            title="For Developers"*/}
      {/*            description={*/}
      {/*              <p>*/}
      {/*                The moment you use Material Kit, you know you’ve never*/}
      {/*                felt anything like it. With a single use, this powerfull*/}
      {/*                UI Kit lets you do more than ever before.*/}
      {/*              </p>*/}
      {/*            }*/}
      {/*            iconColor="info"*/}
      {/*          />*/}
      {/*        </GridItem>*/}
      {/*        <GridItem xs={12} sm={4} className={classes.gridItem}>*/}
      {/*          <InfoArea*/}
      {/*            vertical*/}
      {/*            className={classes.infoArea5}*/}
      {/*            icon={FormatPaint}*/}
      {/*            title="For Designers"*/}
      {/*            description={*/}
      {/*              <p>*/}
      {/*                Divide details about your product or agency work into*/}
      {/*                parts. Write a few lines about each one. A paragraph*/}
      {/*                describing a feature will be enough.*/}
      {/*              </p>*/}
      {/*            }*/}
      {/*            iconColor="danger"*/}
      {/*          />*/}
      {/*        </GridItem>*/}
      {/*        <GridItem xs={12} sm={4} className={classes.gridItem}>*/}
      {/*          <InfoArea*/}
      {/*            vertical*/}
      {/*            className={classes.infoArea5}*/}
      {/*            icon={Dashboard}*/}
      {/*            title="Material-UI Grid"*/}
      {/*            description={*/}
      {/*              <p>*/}
      {/*                Divide details about your product or agency work into*/}
      {/*                parts. Write a few lines about each one. A paragraph*/}
      {/*                describing a feature will be enough.*/}
      {/*              </p>*/}
      {/*            }*/}
      {/*            iconColor="primary"*/}
      {/*          />*/}
      {/*        </GridItem>*/}
      {/*      </GridContainer>*/}
      {/*      <GridContainer className={classes.gridContainer}>*/}
      {/*        <GridItem xs={12} sm={4} className={classes.gridItem}>*/}
      {/*          <InfoArea*/}
      {/*            vertical*/}
      {/*            className={classes.infoArea5}*/}
      {/*            icon={ViewCarousel}*/}
      {/*            title="Example Pages Included"*/}
      {/*            description={*/}
      {/*              <p>*/}
      {/*                The moment you use Material Kit, you know you’ve never*/}
      {/*                felt anything like it. With a single use, this powerfull*/}
      {/*                UI Kit lets you do more than ever before.*/}
      {/*              </p>*/}
      {/*            }*/}
      {/*          />*/}
      {/*        </GridItem>*/}
      {/*        <GridItem xs={12} sm={4} className={classes.gridItem}>*/}
      {/*          <InfoArea*/}
      {/*            vertical*/}
      {/*            className={classes.infoArea5}*/}
      {/*            icon={AccessTime}*/}
      {/*            title="Save Time"*/}
      {/*            description={*/}
      {/*              <p>*/}
      {/*                The moment you use Material Kit, you know you’ve never*/}
      {/*                felt anything like it. With a single use, this powerfull*/}
      {/*                UI Kit lets you do more than ever before.*/}
      {/*              </p>*/}
      {/*            }*/}
      {/*          />*/}
      {/*        </GridItem>*/}
      {/*        <GridItem xs={12} sm={4} className={classes.gridItem}>*/}
      {/*          <InfoArea*/}
      {/*            vertical*/}
      {/*            className={classes.infoArea5}*/}
      {/*            icon={AttachMoney}*/}
      {/*            title="Save Money"*/}
      {/*            description={*/}
      {/*              <p>*/}
      {/*                The moment you use Material Kit, you know you’ve never*/}
      {/*                felt anything like it. With a single use, this powerfull*/}
      {/*                UI Kit lets you do more than ever before.*/}
      {/*              </p>*/}
      {/*            }*/}
      {/*          />*/}
      {/*        </GridItem>*/}
      {/*      </GridContainer>*/}
      {/*    </div>*/}
      {/*  </GridContainer>*/}
      {/*</div>*/}
      {/*/!* Feature 5 END *!/*/}
    </div>
  );
}
