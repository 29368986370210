import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Layout from "../../core/Layout";
import resources from "../../core/Localization/Localization.js";
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

import { Box, Divider, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import CustomSelect from "../../components/CustomSelect/CustomSelect.js";
import CustomTextArea from "../../components/CustomTextArea/CustomTextArea";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import AddImages from "../../components/AddImages/AddImages";

import { createNewOffer } from "../../reduxStore/entities/offers";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import OfferCreateSchema from "./OfferCreateSchema";
import { offersTypeOptions, offersCurrencyOptions, offersGuaranteeOptions, offersDeliveryOptions } from "../../utils/mapOfferStates";
import { isAuth } from "../../auth/helpers";
import Remove from "@material-ui/icons/Remove";
import { infoColor } from "../../assets/jss/material-dashboard-react";
import {ulid} from 'ulid';


const useStyles = makeStyles(dashboardStyle);

const OfferCreate = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(OfferCreateSchema),
    });

    //state hooks
    const [images, setImages] = React.useState([]);
    const [preview, setPreview] = React.useState([]);
    const [partType, setPartType] = React.useState(null);
    
    // constants
    const user = isAuth();
    const typeOptions = offersTypeOptions();
    const currencyOptions = offersCurrencyOptions();
    const guaranteeOptions = offersGuaranteeOptions(partType);
    const deliveryOptions = offersDeliveryOptions();
    const partRequest = location.state;
    const formData = new FormData();
    const ulidValue = ulid();
    // handlers
    const createOffer = (data) => {
        // Form is valid, submit is called
        if (!user.userid || !partRequest.part_request_id) return;
        formData.append("createdby_id", user.userid);
        formData.append("userid", user.userid);
        formData.append("part_request_id", parseInt(partRequest.part_request_id));
        formData.append("price", parseFloat(data.price));
        formData.append("description", data.description);
        formData.append("currency", data.currency);
        formData.append("type", data.type);
        formData.append("delivery", data.delivery);
        formData.append("guarantee", data.guarantee);
     //TODO   formData.append("ulid", ulidValue);
        images.forEach((file) => formData.append("images", file));
        // if (images.length === 0) {
        //     formData.append("images", []);
        // }
        //formData.append("images", []);
        dispatch(createNewOffer({ data: formData, requestId: partRequest.part_request_id }));
    };
    const handleAddImages = (images) => {
        setImages(images);
    };

    React.useEffect(() => {
        if (images.length) {
            setPreview(images.map((img) => URL.createObjectURL(img)));
        } else if (preview.length) {
            preview.map((img) => URL.revokeObjectURL(img));
            setPreview([]);
        }
        return () => {
            preview.map((img) => URL.revokeObjectURL(img));
        };
    }, [images]);

    return (
        <Layout>
            <Card>
                <CardHeader color="info">
                    <h4 className={classes.cardTitleWhite}>{resources.createOffer.createNewOffer}</h4>
                </CardHeader>
                <CardBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: partRequest.part_request_id,
                                    disabled: true,
                                }}
                                labelText={resources.partOffers.datagrid.id}
                                id="id"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: partRequest.User?.username ? partRequest.User.username : "Korisnik obrisan",
                                    disabled: true,
                                }}
                                labelText={resources.partOffers.datagrid.buyername}
                                id="buyer"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: partRequest.title,
                                    disabled: true,
                                }}
                                labelText={resources.partOffers.datagrid.title}
                                id="title"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: (partRequest.Make?.name || "") + " " + (partRequest.Model?.name || ""),
                                    disabled: true,
                                }}
                                labelText={resources.partOffers.datagrid.markAndModel}
                                id="mark-and-model"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: partRequest.Generation?.name || "",
                                    disabled: true,
                                }}
                                labelText={resources.partOffers.datagrid.generation}
                                id="generation"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: partRequest.Trim?.name || "",
                                    disabled: true,
                                }}
                                labelText={resources.partOffers.datagrid.trim}
                                id="trim"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Divider light className={classes.divider} />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                            <Controller
                                control={control}
                                name="type"
                                defaultValue=""
                                inputRef={register()}
                                render={(props) => (
                                    <CustomSelect
                                        inputProps={{
                                            ...register("type"),
                                            onChange: (e) => setPartType(e.target.value)
                                        }}
                                        value={props.field.value}
                                        error={!!errors.type}
                                        labelText={resources.partOffers.datagrid.type}
                                        id="type"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        options={typeOptions}
                                    />
                                )}
                            />

                            {errors?.type?.message && <p>{errors.type.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("price"),
                                    type: "number",
                                }}
                                error={!!errors.price}
                                labelText={resources.partOffers.datagrid.price}
                                id="price"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.price?.message && <p>{errors.price.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Controller
                                control={control}
                                name="currency"
                                defaultValue=""
                                inputRef={register()}
                                render={(props) => (
                                    <CustomSelect
                                        inputProps={{
                                            ...register("currency"),
                                        }}
                                        value={props.field.value}
                                        error={!!errors.currency}
                                        labelText={resources.partOffers.datagrid.currency}
                                        id="currency"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        options={currencyOptions}
                                    />
                                )}
                            />

                            {errors?.currency?.message && <p>{errors.currency.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Controller
                                control={control}
                                name="guarantee"
                                defaultValue=""
                                inputRef={register()}
                                render={(props) => (
                                    <CustomSelect
                                        inputProps={{
                                            ...register("guarantee"),
                                        }}
                                        value={props.field.value}
                                        labelText={partType === "used" ? resources.partOffers.datagrid.workGuarantee : resources.partOffers.datagrid.guarantee}
                                        id="guarantee"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        options={guaranteeOptions}
                                    />
                                )}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Controller
                                control={control}
                                name="delivery"
                                defaultValue=""
                                inputRef={register()}
                                render={(props) => (
                                    <CustomSelect
                                        inputProps={{
                                            ...register("delivery"),
                                        }}
                                        value={props.field.value}
                                        error={!!errors.delivery}
                                        labelText={resources.partOffers.datagrid.delivery}
                                        id="delivery"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        options={deliveryOptions}
                                    />
                                )}
                            />

                            {errors?.delivery?.message && <p>{errors.delivery.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomTextArea
                                labelText={resources.partOffers.datagrid.description}
                                inputProps={{
                                    ...register("description"),
                                }}
                                error={!!errors.description}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.description?.message ? <p>{errors.description.message}</p> : null}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <Box className={classes.addImageSection}>
                                <Typography className={classes.addImgTitle}>Add Images</Typography>
                                <Grid container spacing={4} direction="row" alignItems="flex-end" justify="flex-start">
                                    {preview.map((img, index) => (
                                        <Grid item key={img + index + ""}>
                                            <img style={{ aspectRatio: "1 / 1", objectFit: "cover" }} width={80} src={img} alt={index + "img to choose"} />
                                        </Grid>
                                    ))}
                                </Grid>
                                <AddImages color={infoColor[0]} handleAddImages={handleAddImages} limit={3} classes="absolute top-right" />

                                <IconButton
                                    className={classes.removeImgs}
                                    size="medium"
                                    disabled={!images.length}
                                    onClick={() => {
                                        setImages([]);
                                    }}
                                >
                                    <Tooltip title={resources.form.images.removeMultiple}>
                                        <Remove />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        </GridItem>
                    </GridContainer>
                </CardBody>
                <CardFooter>
                    <Button color="info" onClick={handleSubmit(createOffer)}>
                        {resources.form.confirm}
                    </Button>
                </CardFooter>
            </Card>
        </Layout>
    );
};

export default OfferCreate;
