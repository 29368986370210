import { call, put, take } from "redux-saga/effects";
import { setUser, setUsers, setPaginationUsers } from "../../entities/users";
import { requestGetUsers, createUser, getUserByEmail, requestGetPaginationUsers } from "../requests/users";
import { push } from "connected-react-router";
import { toast } from "react-toastify";
import resources from "../../../core/Localization/Localization.js";

export function* handleGetUsers(action) {
    try {
        const response = yield call(requestGetUsers);
        const { data } = response;
        yield put(setUsers({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleCreateNewUser(action) {
    try {
        const existingUser = yield call(getUserByEmail, action.payload.email);
        if (existingUser.data) {
            toast.error(resources.createUser.emailExists);
            return;
        }
        const response = yield call(createUser, action.payload);
        const { data } = response;
        yield put(setUser({ ...data }));
        yield put(push(`/admin/users`));
        toast.success(resources.createUser.success);
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetPaginationUsers(action) {
    try {
        const response = yield call(requestGetPaginationUsers, action.payload);
        const { data } = response;
        yield put(setPaginationUsers({ ...data }));
    } catch (error) {
        console.log(error);
    }
}
