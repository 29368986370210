import React from "react";
//redux
import { useSelector } from "react-redux";

import { Responsive, WidthProvider } from "react-grid-layout";
import useLocalStorage from "../../hooks/useLocalStorage.js";
import { REVISION_LOG_DETAILS_LAYOUT, defaultLayout } from "../../common/HistoryLogDetailsLayout";
import HistoryLogBasicInfo from "./HstoryLogBasicInfo";
import RevisionLogBasicInfo from "./RevisionLogBasicInfo.js";
import RevisionLogChanges from "./RevisionLogChanges.js";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function RevisionLogDetails() {
    const historyLog = useSelector((state) => state.historyLogs.historyLogDetails);

    const [lsLayout, setLsLayout] = useLocalStorage(REVISION_LOG_DETAILS_LAYOUT, defaultLayout);

    return (
        historyLog && (
            <ResponsiveGridLayout
                className="layout"
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                rowHeight={30}
                layouts={lsLayout}
                onLayoutChange={(layout, layouts) => {
                    setLsLayout(layouts);
                }}
            >
                <div key="1">
                    <HistoryLogBasicInfo historyLog={historyLog}></HistoryLogBasicInfo>
                </div>
                <div key="2">
                    <RevisionLogBasicInfo historyLog={historyLog}></RevisionLogBasicInfo>
                </div>
                <div key="3">
                    <RevisionLogChanges historyLog={historyLog}></RevisionLogChanges>
                </div>
            </ResponsiveGridLayout>
        )
    );
}
