import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// local components
import Layout from "../../core/Layout";
import { getPaginationUsers } from "../../reduxStore/entities/users";

import resources from "../../core/Localization/Localization.js";
import CustomDataGrid from "../../components/CustomDataGrid/CustomDataGrid2";
import { versionColumns } from "../../common/DataGridColumns/Versions";
import { handleTableLoad } from "../../common/VersionsTableData";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { notificationTypes } from "../../variables/notificationTypes";

import filterUserRoles from "../../utils/filterUserRoles";
import {getPaginationVersions} from "../../reduxStore/entities/versions";

export default function Versions() {
    const history = useHistory();
    const notifications = useNotificationMessages();
    const columns = React.useMemo(versionColumns, [resources]);

    // Store hooks
    const versions = useSelector((state) => state.versions.versions);
    const count = useSelector((state) => state.versions.count);
    const dataLoaded = useSelector((state) => state.versions.dataLoaded);
    const dispatch = useDispatch();

    // State hooks
    const [rows, setRows] = React.useState(handleTableLoad(versions));
    const [totalRows, setTotalRows] = React.useState(count);
    const [currentFilters, setCurrentFilters] = React.useState(null);

    // React hooks
    useEffect(() => setTotalRows(count), [count]);
    useEffect(() => setRows(handleTableLoad(versions)), [versions, dataLoaded]);
    // useEffect(() => {
    //     const notificationListener = notifications.subscribe(notificationTypes.userCreated, handler);
    //     return () => {
    //         notificationListener.unsubscribe();
    //     };
    // }, [currentFilters]);

    const handler = (message) => {
        currentFilters ? dispatch(getPaginationVersions(currentFilters)) : dispatch(getPaginationVersions());
    };

    // Event handlers
    const onFiltersChanged = (filters) => doSearchVersions(filters);

    const onPageChange = (filters) => {
        doSearchVersions(filters);
    };

    const onRowClick = (rowData, rowMeta) => {
        if (rowMeta && rowMeta.dataIndex >= 0) {
            window.open("/admin/versions/" + rows[rowMeta.dataIndex].id, "_blank");
        }
    };

    const onAddButtonClick = () => history.push("/admin/versions/create");

    // Private (helper) functions
    const doSearchVersions = (filters) => {
        // if (filters?.columnField === "role" && !!filters?.value) {
        //     filters.value = filterUserRoles(filters.value);
        // }
        setCurrentFilters({ ...filters });
        dispatch(getPaginationVersions(filters));
        setRows(handleTableLoad(versions));
    };

    return (
        <Layout showAddButton={true} onAdd={() => onAddButtonClick()}>
            <CustomDataGrid
                title={resources.versionList.title}
                rows={rows}
                columns={columns}
                totalRows={totalRows}
                loading={!dataLoaded}
                handleRowClick={onRowClick}
                handleFilterChange={onFiltersChanged}
                handlePageChange={onPageChange}
                handleDataInit={doSearchVersions}
                ignoreUrl={false}
            />
        </Layout>
    );
}
