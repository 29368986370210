import React, { useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { searchTransactions } from "../../reduxStore/entities/finance";
// mui components
import { Box } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
// core components
import Layout from "../../core/Layout";
import CustomDataGrid from "../../components/CustomDataGrid/CustomDataGrid2";
import { transactionColumns } from "../../common/DataGridColumns/Transactions";
import resources from "../../core/Localization/Localization.js";
//common
import { notificationTypes } from "../../variables/notificationTypes";
import { TransactionTypes } from "../../common/transactions/config";
import { handleTableLoad } from "../../common/Transactions";
//utils
import finance from "../../core/Localization/finance";
import TransactionsTable from "./TransactionsTable";

export default function Transactions() {
    const history = useHistory();
    const historyState = history.location.state;

    // Store hooks
    const dispatch = useDispatch();

    // State hooks
    const [type, setType] = React.useState(TransactionTypes.PAYIN);

    // React hooks
    useEffect(() => {
        if (Object.values(TransactionTypes).includes(historyState)) setType(historyState);
    }, [historyState, history]);

    // Private (helper) functions
    const handleTabChange = (type) => {
        setType(type);
    };

    return (
        <Layout>
            <Box className="p-1">
                <Box>
                    <Button
                        onClick={() => handleTabChange(TransactionTypes.PAYIN)}
                        color={`${type === finance.en.finance.type.payin.toLowerCase() ? "success" : "rose"}`}
                        className="mr-3"
                    >
                        {resources.finance.type.payin}
                    </Button>
                    <Button
                        onClick={() => handleTabChange(TransactionTypes.CHARGE)}
                        color={`${type === finance.en.finance.type.charge.toLowerCase() ? "success" : "rose"}`}
                        className="mr-3"
                    >
                        {resources.finance.type.charge}
                    </Button>
                    <Button
                        onClick={() => handleTabChange(TransactionTypes.RESERVED)}
                        color={`${type === finance.en.finance.type.reserved.toLowerCase() ? "success" : "rose"}`}
                        className="mr-3"
                    >
                        {resources.finance.type.reserved}
                    </Button>
                    <Button
                        onClick={() => handleTabChange(TransactionTypes.CORRECTION)}
                        color={`${type === finance.en.finance.type.correction.toLowerCase() ? "success" : "rose"}`}
                        className="mr-3"
                    >
                        {resources.finance.type.correction}
                    </Button>
                </Box>
                <TransactionsTable type={type} />
            </Box>
        </Layout>
    );
}
