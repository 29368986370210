import React from "react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
// @material-ui/core components
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import CustomSelect from "../../components/CustomSelect/CustomSelect.js";
import CustomPhoneInput from "../../components/CustomPhoneInput/CustomPhoneInput.js";
//utils
import resources from "../../core/Localization/Localization.js";
import PersonIcon from "@material-ui/icons/Person";
import UserUpdateSchema from "../UserCreate/UserUpdateSchema.js";
import {getUser, updateUser} from "../../reduxStore/entities/userDetails";
import { getCountryList } from "../../reduxStore/entities/country";
import shallowObjectsCompare from "../../utils/shallowObjectsCompare.js";
import UserRoles from "../../auth/UserRoles";
import { toPercentage } from "../../utils/calculations.js";
import VersionUpdateSchema from "./VersionUpdateSchema";
import {getVersion, updateVersion} from "../../reduxStore/entities/versionDetails";

const useStyles = makeStyles(dashboardStyle);

export default function VersionBasicInfo({id}) {
    const {
        handleSubmit,
        control,
        formState: { errors },
        register,
        clearErrors,
        reset,
    } = useForm({
        resolver: yupResolver(VersionUpdateSchema),
    });
    const classes = useStyles();
    const dispatch = useDispatch();

    const version = useSelector((x) => x.versionDetails.version);
    const dataLoaded = useSelector((x) => x.versionDetails.dataLoaded);
    // const countryList = useSelector((state) => state.country.country);
    // if (!countryList.length) {
    //     dispatch(getCountryList());
    // }

    const [editMode, setEditMode] = React.useState(false);
    const [currentVersion, setCurrentVersion] = React.useState({ ...version });
    //const [country, setCountry] = React.useState(user.country || "");
    React.useEffect(() => {
       // dispatch(getVersion(id));
        reset(version);
        setCurrentVersion({ ...version });

        return () => {};
    }, [dataLoaded]);
    const enableEditMode = () => {
        setEditMode((prev) => !prev);
    };

    const handleUpdate = (data) => {
        let payload = shallowObjectsCompare({ ...currentVersion }, data);
        // if (payload?.seller_commission) {
        //     payload.seller_commission /= 100;
        // }
        const updatedData = { id: currentVersion.mobile_app_version_id, ...payload };
        dispatch(updateVersion(updatedData));
        setEditMode((prev) => !prev);
    };

    const handleCancel = () => {
        setEditMode((prev) => !prev);
        reset(version);
      //  setCountry(user.country || "");
        setCurrentVersion({ ...version });
        clearErrors();
    };

    // const getUserStatus = () => {
    //     if (user.banned === 1) {
    //         return resources.userInfo.blocked;
    //     }
    //     return resources.userInfo.active;
    // };

    // const getUserStatusClass = () => {
    //     if (user.banned === 1) {
    //         return "info-label warning";
    //     }
    //     return "info-label success";
    // };

    return (
       // version && version.mobile_app_version_id &&
         (
            <Card profile className={"user-profile-card"}>
                {/*<CardAvatar profile>*/}
                {/*    <PersonIcon className={classes.profileIcon} />*/}
                {/*</CardAvatar>*/}
                {editMode ? (
                    <Box className={classes.absoluteContainer}>
                        <Button type={"button"} color={"info"} size={"sm"} className={classes.buttonSeparator} onClick={handleSubmit(handleUpdate)}>
                            {resources.form.save}
                        </Button>
                        <Button type={"button"} color={"danger"} size={"sm"} onClick={handleCancel}>
                            {resources.form.cancel}
                        </Button>
                    </Box>
                ) : (
                    <Button type={"button"} color={"info"} size={"sm"} className={classes.absoluteContainer} onClick={enableEditMode}>
                        {resources.form.edit}
                    </Button>
                )}
                <CardBody profile>
                    {/*<GridContainer>*/}
                    {/*    <GridItem xs={12} sm={12} md={12}>*/}
                    {/*        <h6 className={classes.cardCategory}>*/}
                    {/*            {resources.userRoles[user.role]}*/}
                    {/*            <span className={getUserStatusClass()}>{getUserStatus()}</span>*/}
                    {/*        </h6>*/}
                    {/*    </GridItem>*/}
                    {/*</GridContainer>*/}
                    <GridContainer>
                        <GridItem xs={3} sm={4} md={1}>
                            <CustomInput
                                inputProps={{
                                    value: currentVersion.mobile_app_version_id,
                                    disabled: true,
                                }}
                                labelText={resources.createVersion.id}
                                id="versionid"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={9} sm={8} md={3}>
                            <CustomInput
                                inputProps={{
                                    ...register("app_name", {
                                        value: currentVersion.app_name,
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.app_name}
                                labelText={resources.createVersion.app_name}
                                id="app_name"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.app_name?.message && <p>{errors.app_name.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("version", {
                                        value: currentVersion.version,
                                    }),
                                    disabled: !editMode,
                                }}
                                labelText={resources.createVersion.version}
                                id="version"
                                error={!!errors.version}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.version?.message && <p>{errors.version.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("build_number", {
                                        value: currentVersion.build_number,
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.build_number}
                                labelText={resources.createVersion.build_number}
                                id="build_number"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.build_number?.message && <p>{errors.build_number.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("commit_ref", {
                                        value: currentVersion.commit_ref,
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.commit_ref}
                                labelText={resources.createVersion.commit_ref}
                                id="commit_ref"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.commit_ref?.message && <p>{errors.commit_ref.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: moment(currentVersion.createdAt).format("DD/MM/YYYY hh:mm"),
                                    disabled: true,
                                }}
                                labelText={resources.createVersion.createdAt}
                                id="created-at"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: moment(currentVersion.updatedAt).format("DD/MM/YYYY hh:mm"),
                                    disabled: true,
                                }}
                                labelText={resources.createVersion.modifedAt}
                                id="updated-at"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("release_type", {
                                        value: currentVersion.release_type || "",
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.release_type}
                                labelText={resources.createVersion.release_type}
                                id="address"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("platform", {
                                        value: currentVersion.platform || "",
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.platform}
                                labelText={resources.createVersion.platform}
                                id="platform"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("supported_store_versions", {
                                        value: currentVersion.supported_store_versions || "",
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.supported_store_versions}
                                labelText={resources.createVersion.supported_store_versions}
                                id="supported_store_versions"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("release_status", {
                                        value: currentVersion.release_status || "",
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.release_status}
                                labelText={resources.createVersion.release_status}
                                id="release_status"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("description", {
                                        value: currentVersion.description || "",
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.description}
                                labelText={resources.createVersion.description}
                                id="description"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    </GridContainer>

                </CardBody>
            </Card>
        )
    );
}
