import { primaryColor, dangerColor, successColor, grayColor, defaultFont } from "../../material-dashboard-react.js";

const customInputStyle = (theme) => ({
    disabled: {
        "&:before": {
            backgroundColor: "transparent !important",
        },
    },
    underline: {
        "&:hover:not($disabled):before,&:before": {
            borderColor: grayColor[4],
            borderWidth: "1px !important",
        },
        "&:after": {
            borderColor: primaryColor[0],
        },
    },
    underlineError: {
        "&:after": {
            borderColor: dangerColor[0] + " !important",
        },
    },
    underlineSuccess: {
        "&:after": {
            borderColor: successColor[0] + " !important",
        },
    },
    labelRoot: {
        ...defaultFont,
        color: grayColor[3] + " !important",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        letterSpacing: "unset",
    },
    labelRootError: {
        color: dangerColor[0],
    },
    labelRootSuccess: {
        color: successColor[0],
    },
    feedback: {
        position: "absolute",
        top: "18px",
        right: "0",
        zIndex: "2",
        display: "block",
        width: "24px",
        height: "24px",
        textAlign: "center",
        pointerEvents: "none",
    },
    root: {
        paddingTop: "10px",
    },
    formControl: {
        paddingBottom: "10px",
        margin: "20px 0 0 0",
        position: "relative",
        verticalAlign: "unset",
    },
});

export default customInputStyle;
