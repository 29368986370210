import { setHistoryLogs, setHistoryLog, setUserHistory, setPartRequestHistory, setOfferHistory, setFilterHistory } from "../../entities/historylogs";
import {
    requestGetFiltertHistoryLogById,
    requestGetHistoryLogById,
    requestGetOffertHistoryLogById,
    requestGetPartRequestHistoryLogById,
    requestGetUserHistoryLogs,
    requestSearchHistoryLogs,
} from "../requests/historylogs";
import { toast } from "react-toastify";
import { call, put } from "redux-saga/effects";

export function* handleSearchHistoryLogsRequest(action) {
    try {
        const response = yield call(requestSearchHistoryLogs, action.payload);
        const { data } = response;
        yield put(setHistoryLogs({ ...data }));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleGetHistoryLogById(action) {
    try {
        const response = yield call(requestGetHistoryLogById, action.payload);
        const { data } = response;
        yield put(setHistoryLog({ ...data }));
    } catch (error) {}
}

export function* handleGetUserHistoryLogsRequest(action) {
    try {
        const response = yield call(requestGetUserHistoryLogs, action.payload);
        const { data } = response;
        yield put(setUserHistory([...data]));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleGetPartRequestHistoryLogsRequest(action) {
    try {
        const response = yield call(requestGetPartRequestHistoryLogById, action.payload);
        const { data } = response;
        yield put(setPartRequestHistory([...data]));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleGetOfferHistoryLogsRequest(action) {
    try {
        const response = yield call(requestGetOffertHistoryLogById, action.payload);
        const { data } = response;
        yield put(setOfferHistory([...data]));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}

export function* handleGetFiltersHistoryLogsRequest(action) {
    try {
        // const response = yield call(requestGetFiltertHistoryLogById, action.payload);
        // const { data } = response;
        // yield put(setFilterHistory([...data]));
        yield put(setFilterHistory([]));
    } catch (error) {
        toast.error(error.response.data.error);
        console.log(error);
    }
}
