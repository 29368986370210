import React from "react";
// core components
import Box from "@material-ui/core/Box";
import CustomChart from "../../components/CustomChart/CustomChart";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
// utils
import { financeChart } from "../../variables/charts";
import { dangerColor, infoColor, primaryColor } from "../../assets/jss/material-dashboard-react";
import resources from "../../core/Localization/Localization";
import { useSelector } from "react-redux";
//styles
import styles from "../../assets/jss/material-dashboard-react/components/financeCredit";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(styles);

const UserFinanceCredit = () => {
    const classes = useStyles();
    const { reserved, available } = useSelector((s) => s.userDetails.user);
    const financeChartData = React.useMemo(
        () =>
            financeChart(
                [[reserved ? Math.round(reserved) : ""], [available ? Math.round(available) : ""]],
                [
                    { value: reserved, className: "yellow-pie" },
                    { value: available, className: "blue-pie" },
                ]
            ),
        [reserved, available]
    );

    return (
        <Box className={classes.container}>
            {reserved || available ? (
                <CustomChart
                    className="ct-chart ct-series-x ct-slice-pie"
                    type="Pie"
                    data={financeChartData.data}
                    options={financeChartData.options}
                    responsiveOptions={financeChartData.responsiveOptions}
                />
            ) : null}
            <Box className={classes.legendContainer}>
                <Box className="row gap-20 justify-content-start align-items-center">
                    <Box style={{ backgroundColor: primaryColor[0], padding: 5, borderRadius: 5 }}>
                        <MoneyOffIcon fontSize="large" />
                    </Box>
                    <p className="text-uppercase">
                        {resources.finance.reserved} : <span className="text-bold">{Math.round(reserved)}</span>
                    </p>
                </Box>
                <Box className="row gap-20 justify-start align-center">
                    <Box style={{ backgroundColor: infoColor[2], padding: 5, borderRadius: 5 }}>
                        <AttachMoneyIcon fontSize="large" style={{ color: "white" }} />
                    </Box>
                    <p className="text-uppercase">
                        {resources.finance.available} : <span className="text-bold">{Math.round(available)}</span>
                    </p>
                </Box>
                <Box className="row gap-20 justify-start align-center">
                    <Box style={{ backgroundColor: dangerColor[0], padding: 5, borderRadius: 5 }}>
                        <AccountBalanceWalletOutlinedIcon fontSize="large" style={{ color: "white" }} />
                    </Box>
                    <p className="text-uppercase">
                        {resources.finance.total} : <span className="text-bold">{Math.round(reserved + available)}</span>
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default UserFinanceCredit;
