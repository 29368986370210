const userSettings = {
    en: {
        userSettings: {
            title: "User settings",
            email_verified: "Email verified",
            seller_verified: "Verified as Seller",
            seller_plus_verified: "Verified as Seller Plus",
            phone_verified: "Phone verified",
            email_notifications_enabled: "Email notifications",
            push_notifications_enabled: "Push notifications",
            resend: "Resend",
            startVendorVerification: "Verify",
            sendEmailVerification: "Email verification sent successfully",
            sendPhoneVerification: "Phone verification sent successfully",
            requestSent: "Request sent",
            requestedAt: "Requested at",
            betatester: "Beta user",
        },
    },
    rs: {
        userSettings: {
            title: "Korisnička podešavanja",
            email_verified: "Email verifikovan",
            seller_verified: "Verifikovan kao Prodavac",
            seller_plus_verified: "Verifikovan kao Super Prodavac",
            phone_verified: "Telefon verifikovan",
            email_notifications_enabled: "Email notifikacije",
            push_notifications_enabled: "Push notifikacije",
            resend: "Pošalji",
            startVendorVerification: "Verifikuj",
            sendEmailVerification: "Email verifikacija uspešno poslata",
            sendPhoneVerification: "Verifikacija telefona uspešno poslata",
            requestSent: "Zahtev poslat",
            requestedAt: "Zahtev poslat u",
            betatester: "Beta korisnik",
        },
    },
};

export default userSettings;
