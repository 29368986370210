import api from "../../../api";

export const requestSearchHistoryLogs = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/history-logs${query ? query : ""}`);
};

export const requestGetHistoryLogById = (id) => {
    return api.get(`/admin/history-logs/${id}`);
};

export const requestGetUserHistoryLogs = (id) => {
    return api.get(`/admin/users/${id}/history-logs`);
};

export const requestGetPartRequestHistoryLogById = (id) => {
    return api.get(`/admin/partrequests/${id}/history-logs`);
};

export const requestGetOffertHistoryLogById = (id) => {
    return api.get(`/admin/offers/${id}/history-logs`);
};

export const requestGetFiltertHistoryLogById = (id) => {
    //return api.get(`/admin/user-filters/${id}/history-logs`);
    return [];
};
