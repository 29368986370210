import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import Layout from "../../core/Layout";
import { getPartrequest, setPartrequest } from "../../reduxStore/entities/partrequestDetails";
import useLocalStorage from "../../hooks/useLocalStorage.js";
import RequestBasicInfo from "./RequestBasicInfo.js";
import RequestOffers from "./RequestOffers.js";
import RequestVehicleInfo from "./RequestVehicleInfo.js";
import RequestHistory from "./RequestHistory";
import RequestImages from "./RequestImages";
import { REQUEST_DETAILS_LAYOUT_KEY, defaultLayout } from "../../common/RequestDetailsLayout";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { notificationTypes } from "../../variables/notificationTypes";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function RequestDetails(props) {
    const dispatch = useDispatch();
    let history = useHistory();
    const notifications = useNotificationMessages();
    const id = props.match.params.id;

    const [lsLayout, setLsLayout] = useLocalStorage(REQUEST_DETAILS_LAYOUT_KEY, defaultLayout);
    const partrequest = useSelector((state) => state.partrequestDetails.partrequest);

    useEffect(() => {
        dispatch(getPartrequest(id));
        return () => {
            dispatch(setPartrequest(null));
        };
    }, []);

    useEffect(() => {
        const notificationListener = notifications.subscribe(notificationTypes.partRequestStateUpdate, handler);
        return () => {
            notificationListener.unsubscribe();
        };
    }, []);

    const handler = (message) => {
        dispatch(getPartrequest(id));
    };

    function handleOfferClick(offerData) {
        history.push("/admin/offers/" + offerData[0]);
    }

    return (
        <Layout>
            {partrequest && partrequest.part_request_id && (
                <ResponsiveGridLayout
                    className="layout"
                    cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                    rowHeight={30}
                    layouts={lsLayout}
                    onLayoutChange={(layout, layouts) => {
                        setLsLayout(layouts);
                    }}
                >
                    <div key="1">
                        <RequestBasicInfo />
                    </div>
                    <div key="2">
                        <RequestVehicleInfo />
                    </div>
                    <div key="3">
                        <RequestOffers openOffer={handleOfferClick} />
                    </div>
                    <div key="4">
                        <RequestHistory />
                    </div>
                    <div key="5">
                        <RequestImages />
                    </div>
                </ResponsiveGridLayout>
            )}
        </Layout>
    );
}
