// function filters input role and returns valid backend value for given argument

import userRoles from "../core/Localization/userRoles";

const filterEngUserRole = (role) => {
    if (!role) return;

    switch (role.toLowerCase()) {
        //admin
        case userRoles.en.userRoles.admin.toLowerCase():
            return userRoles.en.userRoles.admin.toLowerCase();
        case userRoles.rs.userRoles.admin.toLowerCase():
            return userRoles.en.userRoles.admin.toLowerCase();
        //operater
        case userRoles.en.userRoles.operater.toLowerCase():
            return userRoles.en.userRoles.operater.toLowerCase();
        case userRoles.rs.userRoles.operater.toLowerCase():
            return userRoles.en.userRoles.operater.toLowerCase();
        // user
        case userRoles.en.userRoles.user.toLowerCase():
            return userRoles.en.userRoles.user.toLowerCase();
        case userRoles.rs.userRoles.user.toLowerCase():
            return userRoles.en.userRoles.user.toLowerCase();
        // seller
        case userRoles.en.userRoles.seller.toLowerCase():
            return userRoles.en.userRoles.seller.toLowerCase();
        case userRoles.rs.userRoles.seller.toLowerCase():
            return userRoles.en.userRoles.seller.toLowerCase();

        default:
            break;
    }
};

const filterUserRoles = (role) => {
    let filter = [];
    if (!role) {
        return;
    }
    filter = [...Object.values(userRoles.en.userRoles), ...Object.values(userRoles.rs.userRoles)].filter((x) => x.toLowerCase().includes(role.toLowerCase()));

    let value = filterEngUserRole(filter[0]);

    return value;
};

export default filterUserRoles;
