import { createSlice } from "@reduxjs/toolkit";

const filterDetails = createSlice({
    name: "filterDetails",
    initialState: {
        filter: {},
        dataLoaded: false,
    },
    reducers: {
        getFilter() {},
        setFilter(state, action) {
            const filterData = action.payload;
            return { ...state, filter: filterData, dataLoaded: true };
        },
    },
});

export const { getFilter, setFilter } = filterDetails.actions;

export default filterDetails.reducer;
