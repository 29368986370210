import { call, put } from "redux-saga/effects";
import { setCars, setPaginationCars } from "../../entities/cars";
import { requestGetCars, requestGetPaginationCars } from "../requests/cars";

export function* handleGetCars(action) {
    try {
        const response = yield call(requestGetCars);
        const { data } = response;
        yield put(setCars({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetPaginationCars(action) {
    try {
        const response = yield call(requestGetPaginationCars, action.payload);
        const { data } = response;
        yield put(setPaginationCars({ ...data }));
    } catch (error) {
        console.log(error);
    }
}
