import api from "../../../api";

export const requestSearchUserFilters = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/search-user-filters${query ? query : ""}`);
};

export const requestGetFilterUsers = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/userfilters/users${query ? query : ""}`);
}
