const modalStyle = {
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "#F5F5F5",
    },
};

export default modalStyle;
