import React from "react";
import { Box, Typography } from "@material-ui/core";
import CustomModal from "../CustomModal/CustomModal";
import CustomTextArea from "../CustomTextArea/CustomTextArea";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-dashboard-react/components/notificationModalStyle";
import resources from "../../core/Localization/Localization.js";
import CustomInput from "../CustomInput/CustomInput";

const useStyles = makeStyles(modalStyle);

export default function NotificationModal(props) {
    const { open, handleClose, title, text, buttonLabel, onConfirmCallback, error } = props;

    const classes = useStyles();

    const [messageText, setMessageText] = React.useState("");
    const [titleText, setTitleText] = React.useState("");

    const resetValues = () => {
        setMessageText("");
        setTitleText("");
    };

    return (
        <CustomModal open={open} onClose={handleClose}>
            <Box className={classes.titleContainer}>
                <Typography className={classes.titleText}>{title}</Typography>
            </Box>
            <Box className={classes.bodyContainer}>
                <CustomInput
                    labelText={resources.userNotifications.title}
                    id="title"
                    inputProps={{
                        value: titleText,
                        onChange: (e) => setTitleText(e.target.value),
                    }}
                    formControlProps={{
                        fullWidth: true,
                    }}
                ></CustomInput>
                <Typography className={classes.bodyText}>{text}</Typography>
                <CustomTextArea
                    labelText={resources.userNotifications.message}
                    // error={!!errors.description}
                    id="message"
                    inputProps={{
                        value: messageText,
                        onChange: (e) => setMessageText(e.target.value),
                    }}
                    formControlProps={{
                        fullWidth: true,
                    }}
                />
                {error ? <p className={classes.error}>{error}</p> : null}
            </Box>
            <Box className={classes.footerContainer}>
                <Button
                    type={"button"}
                    size={"sm"}
                    className={classes.button}
                    onClick={() => {
                        resetValues();
                        handleClose();
                    }}
                >
                    {resources.cancel}
                </Button>
                <Button
                    type={"button"}
                    color={"info"}
                    size={"sm"}
                    onClick={() => {
                        if (titleText && messageText) resetValues();
                        onConfirmCallback(titleText, messageText);
                    }}
                >
                    {buttonLabel}
                </Button>
            </Box>
        </CustomModal>
    );
}
