import api from "../../../api";

export const requestGetPartrequests = () => api.get("/admin/partrequests");

export const requestGetActivePartrequests = () => api.get("/admin/partrequests/active");

export const requestGetUserPartRequests = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/user-state-partrequests${query ? query : ""}`);
};

export const requestGetPaginationPartrequests = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/pagination-partrequests${query ? query : ""}`);
};
