import { isAuth } from './helpers';
import UserRoles from './UserRoles';

export default function RoleGate({ children, roles = [UserRoles.ALL]}) {
    const auth = isAuth();
    const accessGranted = roles && roles.length ? ((roles.indexOf(auth.role) !== -1) || roles.indexOf('*') !== -1) : true;

    if (!accessGranted) {
        return <></>;
    }

    return <>{children}</>;
}
