import { takeLatest, all } from "redux-saga/effects";

//handlers
import { handleGetCars, handleGetPaginationCars } from "./handlers/cars";
import { handleGetUsers, handleCreateNewUser, handleGetPaginationUsers } from "./handlers/users";
import {
    handleGetUser,
    handleDeleteUser,
    handleUpdateUser,
    handleSendEmailVerification,
    handleSendPhoneVerification,
    handleGetUserCars,
    handleVerifyAsSeller,
    handleGetVerificationRequests,
    handleLogoutUser,
    handleBlockUser,
    handleUnblockUser,
    handleSetBetaUser,
    handleGetUserNotifications,
    handleGetUserFilters,
    handleSendUserSystemMessage,
    handleAddTokensToUser,
    handleCorrectUserTokens,
    handleVerifyAsSellerPlus,
} from "./handlers/userDetails";
import {
    handleGetOffers,
    handleGetSentNewOffers,
    handleGetAcceptedNewOffers,
    handleCreateNewOffer,
    handleGetPaginationOffers,
    handleGetUserOffers,
} from "./handlers/offers";
import { handleGetOffer, handleUpdateOffer } from "./handlers/offerDetails";
import { handleGetPartrequests, handleGetActivePartrequests, handleGetPaginationPartrequests, handleGetUserPartRequests } from "./handlers/partrequests";
import { handleGetPartrequest, handleUpdatePartrequest } from "./handlers/partrequestDetails";
import { handleGetDashboard } from "./handlers/dashboard";
import {
    handleGetPaymentSettings,
    handleUpdatePaymentSettings,
    handleLogoutAllUsers,
    handleGetMaintenanceSettings,
    handleUpdateMaintenanceSettings,
    handleGetConfigurationSettings,
    handleUpdateConfigurationSettings,
    handleScheduleMaintenanceMode,
    handleCallEmergencyMaintenanceMode,
} from "./handlers/settings";
import { handleGetCountryList } from "./handlers/country";
import {
    handleGetHistoryLogById,
    handleGetOfferHistoryLogsRequest,
    handleGetPartRequestHistoryLogsRequest,
    handleGetUserHistoryLogsRequest,
    handleSearchHistoryLogsRequest,
    handleGetFiltersHistoryLogsRequest,
} from "./handlers/historylogs";
import { getCar } from "../entities/carDetails";
import { handleGetCar } from "./handlers/carDetails";
import { getFilter } from "../entities/filterDetails";
import { handleGetFilter } from "./handlers/filterDetails";
import { handleGetUserTransactions, handleSearchTransactions, handlerRefundByTransactionId } from "./handlers/finance";
import { handleGetTransaction } from "../sagas/handlers/transactionDetails";

//actions
import { getCars, getPaginationCars } from "../entities/cars";
import { getUsers, createNewUser, getPaginationUsers } from "../entities/users";
import {
    getUser,
    deleteUser,
    updateUser,
    sendEmailVerification,
    sendPhoneVerification,
    getUserCars,
    verifyAsSeller,
    verifyAsSellerPlus,
    getVerificationRequests,
    logoutUser,
    blockUser,
    userUnblock,
    updateBetaUser,
    getUserNotifications,
    getUserFilters,
    sendUserSystemMessage,
    addTokensToUser,
    correctUserTokens,
} from "../entities/userDetails";
import { getOffers, getSentNewOffers, getAcceptedNewOffers, createNewOffer, getPaginationOffers, getSelectedUserOffers } from "../entities/offers";
import { getOffer, updateOffer } from "../entities/offerDetails";
import { getPartrequests, getActivePartrequests, getPaginationPartrequests, getSelectedUsersPartRequests } from "../entities/partrequests";
import { getPartrequest, updatePartrequest } from "../entities/partrequestDetails";
import { getDashboard } from "../entities/dashboard";
import {
    getPaymentSettings,
    updatePaymentSettings,
    logoutAllUsers,
    getMaintenanceSettings,
    updateMaintenanceSettings,
    getConfigurationSettings,
    updateConfigurationSettings,
    scheduleMaintenanceMode,
    callEmergencyMaintenanceMode,
} from "../entities/settings";
import { getCountryList } from "../entities/country";
import { getFilterHistory, getHistoryLogById, getOfferHistory, getPartRequestHistory, getUserHistory, searchHistoryLogs } from "../entities/historylogs";
import { getUserTransactions, searchTransactions, refundByTransactionId } from "../entities/finance";
import { getTransaction } from "../entities/transactionDetails";
import { deleteUserReview, getSelectedUserReviews } from "../entities/reviews";
import { handleDeleteUserReview, handleGetUserReviews } from "./handlers/reviews";
import { deletePartrequestImage, getPartrequestImages } from "../entities/partrequestImages";
import { handleDeletePartrequestImage, handleGetPartrequestImages } from "./handlers/partrequestImages";
import { addOfferImages, deleteOfferImage, getOfferImages } from "../entities/offerImages";
import { handleAddOfferImages, handleDeleteOfferImage, handleGetOfferImages } from "./handlers/offerImages";
import { getFilters, getFilterUsers } from "../entities/filters";
import { handleGetFilterUsers, handleSearchUserFilters } from "./handlers/filters";
import {createVersion, getPaginationVersions} from "../entities/versions";
import {handleCreateVersion, handleGetPaginationVersions} from "./handlers/versions";
import {getVersion, updateVersion} from "../entities/versionDetails";
import {handleGetVersionById, handleUpdateVersion} from "./handlers/versionDetails";

export function* watcherSaga() {
    yield all([
        takeLatest(getCars.type, handleGetCars),
        takeLatest(getPaginationCars.type, handleGetPaginationCars),
        takeLatest(getUsers.type, handleGetUsers),
        takeLatest(getPaginationUsers.type, handleGetPaginationUsers),
        takeLatest(getOffers.type, handleGetOffers),
        takeLatest(getPaginationOffers.type, handleGetPaginationOffers),
        takeLatest(getSelectedUserOffers.type, handleGetUserOffers),
        takeLatest(getSentNewOffers.type, handleGetSentNewOffers),
        takeLatest(getAcceptedNewOffers.type, handleGetAcceptedNewOffers),
        takeLatest(createNewOffer.type, handleCreateNewOffer),
        takeLatest(getPartrequests.type, handleGetPartrequests),
        takeLatest(getActivePartrequests.type, handleGetActivePartrequests),
        takeLatest(getSelectedUsersPartRequests.type, handleGetUserPartRequests),
        takeLatest(updatePartrequest.type, handleUpdatePartrequest),
        takeLatest(getPaginationPartrequests.type, handleGetPaginationPartrequests),
        takeLatest(getUser.type, handleGetUser),
        takeLatest(addTokensToUser.type, handleAddTokensToUser),
        takeLatest(correctUserTokens.type, handleCorrectUserTokens),
        takeLatest(getOffer.type, handleGetOffer),
        takeLatest(updateOffer.type, handleUpdateOffer),
        takeLatest(getPartrequest.type, handleGetPartrequest),
        takeLatest(getDashboard.type, handleGetDashboard),
        takeLatest(getPaymentSettings.type, handleGetPaymentSettings),
        takeLatest(scheduleMaintenanceMode.type, handleScheduleMaintenanceMode),
        takeLatest(callEmergencyMaintenanceMode.type, handleCallEmergencyMaintenanceMode),
        takeLatest(getMaintenanceSettings.type, handleGetMaintenanceSettings),
        takeLatest(getConfigurationSettings.type, handleGetConfigurationSettings),
        takeLatest(updateConfigurationSettings.type, handleUpdateConfigurationSettings),
        takeLatest(updateMaintenanceSettings.type, handleUpdateMaintenanceSettings),
        takeLatest(updatePaymentSettings.type, handleUpdatePaymentSettings),
        takeLatest(logoutAllUsers.type, handleLogoutAllUsers),
        takeLatest(createNewUser.type, handleCreateNewUser),
        takeLatest(deleteUser.type, handleDeleteUser),
        takeLatest(updateUser.type, handleUpdateUser),
        takeLatest(getCountryList.type, handleGetCountryList),
        takeLatest(sendEmailVerification.type, handleSendEmailVerification),
        takeLatest(sendPhoneVerification.type, handleSendPhoneVerification),
        takeLatest(getUserCars.type, handleGetUserCars),
        takeLatest(getUserFilters.type, handleGetUserFilters),
        takeLatest(getUserTransactions.type, handleGetUserTransactions),
        takeLatest(searchTransactions.type, handleSearchTransactions),
        takeLatest(getTransaction.type, handleGetTransaction),
        takeLatest(refundByTransactionId.type, handlerRefundByTransactionId),
        takeLatest(verifyAsSeller.type, handleVerifyAsSeller),
        takeLatest(verifyAsSellerPlus.type, handleVerifyAsSellerPlus),
        takeLatest(getVerificationRequests.type, handleGetVerificationRequests),
        takeLatest(getUserNotifications.type, handleGetUserNotifications),
        takeLatest(logoutUser.type, handleLogoutUser),
        takeLatest(blockUser.type, handleBlockUser),
        takeLatest(userUnblock.type, handleUnblockUser),
        takeLatest(updateBetaUser.type, handleSetBetaUser),
        takeLatest(searchHistoryLogs.type, handleSearchHistoryLogsRequest),
        takeLatest(getHistoryLogById.type, handleGetHistoryLogById),
        takeLatest(getUserHistory.type, handleGetUserHistoryLogsRequest),
        takeLatest(getPartRequestHistory.type, handleGetPartRequestHistoryLogsRequest),
        takeLatest(getOfferHistory.type, handleGetOfferHistoryLogsRequest),
        takeLatest(getFilterHistory.type, handleGetFiltersHistoryLogsRequest),
        takeLatest(getCar.type, handleGetCar),
        takeLatest(getFilter.type, handleGetFilter),
        takeLatest(sendUserSystemMessage.type, handleSendUserSystemMessage),
        takeLatest(getSelectedUserReviews.type, handleGetUserReviews),
        takeLatest(deleteUserReview.type, handleDeleteUserReview),
        takeLatest(getPartrequestImages.type, handleGetPartrequestImages),
        takeLatest(getOfferImages.type, handleGetOfferImages),
        takeLatest(deletePartrequestImage.type, handleDeletePartrequestImage),
        takeLatest(deleteOfferImage.type, handleDeleteOfferImage),
        takeLatest(addOfferImages.type, handleAddOfferImages),
        takeLatest(getFilters.type, handleSearchUserFilters),
        takeLatest(getFilterUsers.type, handleGetFilterUsers),

        takeLatest(getPaginationVersions.type, handleGetPaginationVersions),
        takeLatest(createVersion.type,handleCreateVersion),
        takeLatest(getVersion.type,handleGetVersionById),
        takeLatest(updateVersion.type,handleUpdateVersion)
    ]);
}
