const isWholeNumber = (number) => {
    if (number - Math.floor(number) !== 0) return true;
    return false;
};

const divBy100 = (number) => {
    return number / 100;
};

const roundNumberToTwoDec = (number, fixed = 2) => {
    let m = Number((Math.abs(number) * 100).toPrecision(15));
    return ((Math.round(m) / 100) * Math.sign(number)).toFixed(fixed);
};

const addPercentage = (number = 0, percentage = 0) => {
    const result = Math.round(roundNumberToTwoDec(parseFloat(number)) * (1 + parseFloat(percentage) / 100));
    return result;
};

const toPercentage = (number, fixed) => {
    return roundNumberToTwoDec(number * 100, fixed);
};

export { isWholeNumber, divBy100, roundNumberToTwoDec, addPercentage, toPercentage };
