const tableTextLabels = {
    en: {
        tableTextLabels: {
            body: {
                noMatch: "Sorry, no matching records found",
                toolTip: "Sort",
                columnHeaderTooltip: "Sort for ",
            },
            pagination: {
                next: "Next Page",
                previous: "Previous Page",
                rowsPerPage: "Rows per page:",
                displayRows: "of",
            },
            toolbar: {
                search: "Search",
                downloadCsv: "Download CSV",
                print: "Print",
                viewColumns: "View Columns",
                filterTable: "Filter Table",
            },
            filter: {
                all: "All",
                title: "FILTERS",
                reset: "RESET",
            },
            viewColumns: {
                title: "Show Columns",
                titleAria: "Show/Hide Table Columns",
            },
            selectedRows: {
                text: "row(s) selected",
                delete: "Delete",
                deleteAria: "Delete Selected Rows",
            },
        },
    },
    rs: {
        tableTextLabels: {
            body: {
                noMatch: "Žao nam je, nisu pronađena odgovarajuća podudaranja",
                toolTip: "Sortiraj",
                columnHeaderTooltip: "Sortiraj za ",
            },
            pagination: {
                next: "Sledeća stranica",
                previous: "Prethodna stranica",
                rowsPerPage: "Redova po stranici:",
                displayRows: "od",
            },
            toolbar: {
                search: "Pretraži",
                downloadCsv: "Preuzmi CSV",
                print: "Štampaj",
                viewColumns: "Pogledaj Kolone",
                filterTable: "Filtriraj Tabelu",
            },
            filter: {
                all: "SVE",
                title: "FILTERI",
                reset: "RESETUJ",
            },
            viewColumns: {
                title: "Prikaži Kolone",
                titleAria: "Prikaži/Sakrij Kolone",
            },
            selectedRows: {
                text: "red(ova) selektovan(o)",
                delete: "Obriši",
                deleteAria: "Obriši selektovane redove",
            },
        },
    },
};

export default tableTextLabels;
