import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import CustomPhoneInput from "../../components/CustomPhoneInput/CustomPhoneInput.js";
import CustomSelect from "../../components/CustomSelect/CustomSelect.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Layout from "../../core/Layout";
import resources from "../../core/Localization/Localization.js";
import {UserRoleOptions, OperaterUserRoleOptions} from "../../common/UserRoleOptions";
import { createNewUser } from "../../reduxStore/entities/users.js";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UserCreateSchema from "./UserCreateSchema";
import { getLocalStorage } from "../../auth/helpers.js";
import UserRoles from "../../auth/UserRoles.js";
import {ulid} from 'ulid';

const useStyles = makeStyles();

export default function UserCreate() {
    const classes = useStyles();
    const user = getLocalStorage("user");
    const dispatch = useDispatch();
    
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(UserCreateSchema),
    });

    const createUser = (data) => {
        // Form is valid, submit is called
        data.shop_name = data?.shop_name ? data.shop_name: null;
        data.phone = data?.phone ? data.phone : null;
       //TODO data.ulid = ulid();
        dispatch(createNewUser(data));
    };

    return (
        <Layout>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>{resources.createUser.createNewUser}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("firstname"),
                                            }}
                                            error={!!errors.firstname}
                                            labelText={resources.createUser.firstName}
                                            id="first-name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.firstname?.message && <p>{errors.firstname.message}</p>}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("lastname"),
                                            }}
                                            error={!!errors.lastname}
                                            labelText={resources.createUser.lastName}
                                            id="lastname"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.lastname?.message && <p>{errors.lastname.message}</p>}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("shop_name"),
                                            }}
                                            error={!!errors.shop_name}
                                            labelText={resources.createUser.shop_name}
                                            id="shop_name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.shop_name?.message && <p>{errors.shop_name.message}</p>}
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("email"),
                                            }}
                                            error={!!errors.email}
                                            labelText={resources.createUser.email}
                                            id="email-address"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.email?.message && <p>{errors.email.message}</p>}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <Controller
                                            control={control}
                                            name="phone"
                                            defaultValue=""
                                            inputRef={register()}
                                            render={(props) => (
                                                <CustomPhoneInput
                                                    inputProps={{
                                                        type: "phone",
                                                    }}
                                                    value={props.field.value}
                                                    onChange={(x) => {
                                                        props.field.onChange(x);
                                                    }}
                                                    labelText={resources.createUser.phone}
                                                    id="phone"
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("city"),
                                            }}
                                            labelText={resources.createUser.city}
                                            id="city"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                type: "password",
                                                ...register("password"),
                                            }}
                                            error={!!errors.password}
                                            labelText={resources.createUser.password}
                                            id="password"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.password?.message && <p>{errors.password.message}</p>}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                type: "password",
                                                ...register("repeatPassword"),
                                            }}
                                            error={!!errors.repeatPassword}
                                            labelText={resources.createUser.repeatPassword}
                                            id="repeat-password"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.repeatPassword?.message && <p>{errors.repeatPassword.message}</p>}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomSelect
                                            inputProps={{
                                                ...register("role"),
                                            }}
                                            error={!!errors.role}
                                            labelText={resources.createUser.role}
                                            id="role"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            options={user.role === UserRoles.ADMIN ? UserRoleOptions : OperaterUserRoleOptions}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="info" onClick={handleSubmit(createUser)}>
                                    {resources.createUser.doCreate}
                                </Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </Layout>
    );
}
