import { call, put } from "redux-saga/effects";
import { mapOffersState } from "../../../utils/mapOfferStates";
import { setOffer } from "../../entities/offerDetails";
import { requestGetOffer, requestUpdateOffer } from "../requests/offerDetails";

export function* handleGetOffer(action) {
    try {
        const response = yield call(requestGetOffer,action.payload);
        let { data } = response;
        data = mapOffersState([data])[0];
        yield put(setOffer({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleUpdateOffer(action) {
    const response = yield call(requestUpdateOffer, action.payload);
    if (response) {
        let { data } = response;
        data = mapOffersState([data])[0];
        yield put(setOffer({ ...data }));
    }
}