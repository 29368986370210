import { createSlice } from "@reduxjs/toolkit";

const historyLogs = createSlice({
    name: "historyLogs",
    initialState: {
        historyLogs: [],
        userHistory: [],
        partRequestHistory: [],
        filterHistory: [],
        offerHistory: [],
        historyLogDetails: null,
        count: 0,
        dataLoaded: false,
    },
    reducers: {
        searchHistoryLogs(state, action) {
            return { ...state, dataLoaded: false };
        },
        setHistoryLogs(state, action) {
            return { ...state, historyLogs: [...action.payload.data], count: action.payload.count, dataLoaded: true };
        },
        getHistoryLogById(state, action) {
            return { ...state, dataLoaded: false };
        },
        setHistoryLog(state, action) {
            return { ...state, historyLogDetails: action.payload, dataLoaded: true };
        },
        getUserHistory(state, action) {
            return { ...state, dataLoaded: false };
        },
        setUserHistory(state, action) {
            return { ...state, userHistory: [...action.payload], dataLoaded: true };
        },
        getPartRequestHistory(state, action) {
            return { ...state, dataLoaded: false };
        },
        setPartRequestHistory(state, action) {
            return { ...state, partRequestHistory: [...action.payload], dataLoaded: true };
        },
        getOfferHistory(state, action) {
            return { ...state, dataLoaded: false };
        },
        setOfferHistory(state, action) {
            return { ...state, offerHistory: [...action.payload], dataLoaded: true };
        },
        getFilterHistory(state, action) {
            return { ...state, dataLoaded: false };
        },
        setFilterHistory(state, action) {
            return { ...state, filterHistory: [...action.payload], dataLoaded: true };
        },
    },
});

export const {
    searchHistoryLogs,
    setHistoryLogs,
    setHistoryLog,
    getHistoryLogById,
    getUserHistory,
    setUserHistory,
    getPartRequestHistory,
    setPartRequestHistory,
    getOfferHistory,
    setOfferHistory,
    getFilterHistory,
    setFilterHistory,
} = historyLogs.actions;

export default historyLogs.reducer;
