import api from "../../../api";

export const requestGetUsers = () => api.get(`/admin/users`);

export const createUser = (data) => api.post(`/admin/users`, data);

export const getUserByEmail = (email) => api.get(`/admin/users/${email}`);

export const requestGetPaginationUsers = (props) => {
  const query = `?${new URLSearchParams(props).toString()}`;
  return api.get(`/admin/pagination-users${query ? query : ""}`);
};
