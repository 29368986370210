import React from "react";
import { Box, Typography } from "@material-ui/core";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomModal from "../../components/CustomModal/CustomModal";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Button from "../../components/CustomButtons/Button";

import { makeStyles } from "@material-ui/core/styles";
import modalStyle from "../../assets/jss/material-dashboard-react/components/financeModal";

import resources from "../../core/Localization/Localization";
import { validateAddTokens } from "../../utils/finance_service";
import { addPercentage } from "../../utils/calculations";
import { bonusCoefficientsOptions } from "../../common/transactions/config";

const useStyles = makeStyles(modalStyle);

const UserFinanceModal = ({ open, handleClose, handleAdd }) => {
    const classes = useStyles();

    const [tokensToAdd, setTokensToAdd] = React.useState({});
    const [errors, setErrors] = React.useState([]);

    const handleTakeValue = (e) => {
        setTokensToAdd({ ...tokensToAdd, [e.target.name]: e.target.value });
    };

    const handleAddTokens = () => {
        const err = validateAddTokens(tokensToAdd);
        if (err.length) {
            setErrors([...err]);
            return;
        }
        setErrors([]);
        handleAdd(tokensToAdd);
    };

    React.useEffect(() => {
        setErrors([]);
        setTokensToAdd({ bonus_coefficient: 0, payment_basis: resources.finance.paymentBasis.addingTokens });
    }, [open]);

    React.useEffect(() => {
        setTokensToAdd({ ...tokensToAdd, amount: addPercentage(tokensToAdd.currency_amount, tokensToAdd.bonus_coefficient) });
    }, [tokensToAdd.currency_amount, tokensToAdd.bonus_coefficient]);

    return (
        <CustomModal
            open={open}
            handleClose={() => {
                handleClose();
            }}
        >
            <Box className={classes.root}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.titleText}>{resources.finance.addTokensToUser}</Typography>
                </Box>
                <Box className={classes.bodyContainer}>
                    <Box className={classes.amount}>
                        <CustomInput
                            id="currency_amount"
                            formControlProps={{ fullWidth: true }}
                            labelText={resources.finance.datagrid.currencyAmount}
                            inputProps={{
                                type: "number",
                                name: "currency_amount",
                                onChange: (e) => {
                                    handleTakeValue(e);
                                },
                                value: tokensToAdd.currency_amount || undefined,
                            }}
                        />
                        <CustomSelect
                            id="bonus_coefficient"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText={resources.finance.datagrid.bonus_coefficient}
                            options={bonusCoefficientsOptions}
                            labelProps={{ shrink: true }}
                            inputProps={{
                                name: "bonus_coefficient",
                                onChange: (e) => {
                                    handleTakeValue(e);
                                },
                                value: tokensToAdd.bonus_coefficient || 0,
                            }}
                        />
                    </Box>
                    <Box className={classes.bonusContainer}>
                        <h6>{resources.finance.amountWithBonus}</h6>
                        <p>{tokensToAdd.amount}</p>
                    </Box>
                    <Box className={classes.amount}>
                        <CustomInput
                            id="payment_basis"
                            formControlProps={{
                                fullWidth: true,
                            }}
                            labelText={resources.finance.paymentBasis.paymentBasis}
                            inputProps={{
                                type: "string",
                                name: "payment_basis",
                                onChange: (e) => {
                                    handleTakeValue(e);
                                },
                                value: tokensToAdd.payment_basis || undefined,
                            }}
                        />
                    </Box>
                </Box>
                {errors.length ? (
                    <Box className={classes.errorContainer}>
                        {[...new Set(errors)].map((x, i) => (
                            <p key={x + i} className="text-danger">
                                {x}
                            </p>
                        ))}
                    </Box>
                ) : null}
                <Box className={classes.footerContainer}>
                    <Button onClick={handleAddTokens} color="info">
                        {resources.form.add}
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
};

export default UserFinanceModal;
