const finance = {
    en: {
        finance: {
            title: "Finances",
            credit: "Credit balance",
            refund: "Refund",
            payments: "Pay Ins",
            transactions: "Transactions",
            commission: "Commission",
            reserved: "Reserved",
            reservedAmount: "Reserved amount",
            available: "Available",
            total: "Total",
            addTokens: "Add credits",
            removeTokens: "Remove credits",
            addTokensToUser: "Add credits to the user",
            correctTokens: "Correct credits",
            correctUserTokens: "User credit correction",
            confirmAddTokensText: "Please confirm before adding credits to the user",
            confirmCorrectTokensText: "Please confirm before correcting credits to the user",
            amountWithBonus: "Amount with added bonus coefficient",
            correctionReason: "Reason of the correction",
            paymentBasis: {
                paymentBasis: "Basis of the payment",
                addingTokens: "Adding credits",
            },
            datagrid: {
                transactionId: "ID",
                user: "User",
                userid: "User ID",
                partOfferId: "Part Offer ID",
                type: "Type",
                amount: "Amount",
                currencyAmount: "Currency amount",
                currency: "Currency",
                createdAt: "Created at",
                createdBy: "Created by",
                updatedAt: "Updated at",
                refunded: "Refunded",
                refundedAmount: "Refunded amount",
                bonusCoefficient: "Bonus coefficient",
                correctionType: "Correction Type",
                correctionReason: "Correction reason",
                options: "Options",
            },
            type: {
                payin: "Payin",
                charge: "Charge",
                reserved: "Reserved",
                correction: "Correction",
            },
            errors: {
                notWholeNumber: "Number of credits must be a whole number",
                tokensRequired: "Number of credits to add is required",
                paymentRequired: "Payment amount is required",
                currencyRequired: "Currency is required",
                paymentBasisRequired: "Basis of the payment is required",
                correctOptionRequired: "Option for the correction is required",
                correctionReasonRequired: "Reason of the correction is required",
            },
        },
    },
    rs: {
        finance: {
            title: "Finansije",
            credit: "Stanje kredita",
            refund: "Refundiraj",
            payments: "Uplate",
            transactions: "Transakcije",
            commission: "Provizija",
            reserved: "Rezervisano",
            reservedAmount: "Rezervisana sredstva",
            available: "Raspoloživo",
            total: "Ukupno",
            addTokens: "Uplati kredit",
            removeTokens: "Oduzmi kredit",
            addTokensToUser: "Uplati kredit korisniku",
            correctTokens: "Koriguj kredit",
            correctUserTokens: "Koriguj kredit korisnika",
            confirmAddTokensText: "Potvrdite da želite da dodate kredite korisniku",
            confirmCorrectTokensText: "Potvrdite da želite da korigujete kredite korisniku",
            amountWithBonus: "Iznos sa uračunatim bonusom",
            correctionReason: "Razlog korekcije",
            paymentBasis: {
                paymentBasis: "Osnov uplate",
                addingTokens: "Dodavanje kredita",
            },
            datagrid: {
                transactionId: "ID",
                userid: "ID korisnika",
                user: "Korisnik",
                partOfferId: "ID ponude",
                type: "Tip",
                amount: "Iznos Kredita",
                currencyAmount: "Iznos u valuti",
                currency: "Valuta",
                createdAt: "Vreme kreiranja",
                createdBy: "Kreirao",
                updatedAt: "Vreme ažuriranja",
                refunded: "Refundirano",
                refundedAmount: "Refundirani iznos",
                bonusCoefficient: "Bonus koeficijent",
                correctionType: "Tip korekcije",
                correctionReason: "Razlog korekcije",
                options: "Opcije",
            },
            type: {
                payin: "Uplata",
                charge: "Naplata",
                reserved: "Rezervisano",
                correction: "Korigovano",
            },
            errors: {
                notWholeNumber: "Broj kredita mora biti ceo broj",
                tokensRequired: "Broj kredita je obavezan",
                paymentRequired: "Uplaćena sredstva su obavezna",
                currencyRequired: "Valuta je obavezna",
                paymentBasisRequired: "Osnov uplate je obavezan",
                correctOptionRequired: "Tip korekcije je obavezan",
                correctionReasonRequired: "Razlog korekcije je obavezan",
            },
        },
    },
};

export default finance;
