const sidebar = {
    en: {
        time: {
            year: "year",
            years: "years",
            month: "month",
            months: "months",
            day: "day",
            days: "days",
            hour: "hour",
            hours: "hours",
            minute: "minute",
            minutes: "minutes",
            second: "second",
            seconds: "seconds",
        },
    },
    rs: {
        time: {
            year: "godina",
            years: "godine",
            month: "mesec",
            months: "meseci",
            day: "dan",
            days: "dana",
            hour: "sat",
            hours: "sati",
            minute: "minut",
            minutes: "minuta",
            second: "sekund",
            seconds: "sekundi",
        },
    },
};

export default sidebar;
