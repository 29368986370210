import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSelectedUserOffers } from "../../reduxStore/entities/offers";
// core components
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
// common
import resources from "../../core/Localization/Localization.js";
import UserRoles from "../../auth/UserRoles";
import { mapOffersInTableData, offersTabs, USER_OFFERS_HEADERS } from "../../common/UserDetailsTabs";
import MappedTabs from "../../components/MappedTabs/MappedTabs";
//utils
import { accepted, declined, sent } from "../../utils/mapOfferStates";
import getQueryWithPagination from "../../utils/getQueryWithPagination";

export default function UserOffers() {
    const history = useHistory();

    // Store hooks
    const user = useSelector((x) => x.userDetails.user);
    const offers = useSelector((state) => state.offers.selectedUserOffers);
    const dispatch = useDispatch();

    // State hooks
    const [tableData, setTableData] = React.useState([]);

    // React hooks
    const headers = React.useMemo(USER_OFFERS_HEADERS, [resources]);
    const tabs = React.useMemo(offersTabs, [resources]);

    useEffect(() => {
        if (user.role === UserRoles.SELLER) dispatch(getSelectedUserOffers(getQueryWithPagination({ userid: user.userid, statuses: sent })));
        return () => {};
    }, []);

    useEffect(() => {
        if (user.role === UserRoles.SELLER) setTableData(mapOffersInTableData(offers));
    }, [offers]);

    // Event handlers
    const handleTabChange = (e) => {
        switch (e) {
            case 0:
                dispatch(getSelectedUserOffers(getQueryWithPagination({ userid: user.userid, statuses: sent })));
                break;
            case 1:
                dispatch(getSelectedUserOffers(getQueryWithPagination({ userid: user.userid, statuses: accepted })));
                break;
            case 2:
                dispatch(getSelectedUserOffers(getQueryWithPagination({ userid: user.userid, statuses: declined })));
                break;
            default:
                break;
        }
    };

    const handleViewMore = (sStates) => history.push(`/admin/offers?userid=${user.userid}&states=${sStates}`);

    const onRowClick = (params) => {
        if (params.length) {
            history.push("/admin/offers/" + params[0]);
        }
    };

    return (
        <CustomTabs
            className={"user-profile-card"}
            title={resources.partOffers.header}
            headerColor="rose"
            onTabChange={handleTabChange}
            tabs={MappedTabs(tabs, { tableHead: headers, tableData, onRowClick }, handleViewMore, resources.partRequests.viewMore)}
        />
    );
}
