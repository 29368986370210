import { defaultCustomBodyRender } from "./DataGridColumns";
import resources from "../../core/Localization/Localization";
import { historyLogTypes } from "../../variables/historyLogTypes";
import { timeRangeColumn } from "./customColumns/timeRangeColumn";
export const historyLogColumns = () => [
    {
        name: "id",
        label: resources.historyLogs.datagrid.id,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "action",
        label: resources.historyLogs.datagrid.action,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "model_id",
        label: resources.historyLogs.datagrid.modelId,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "type",
        label: resources.historyLogs.datagrid.type,
        options: {
            filter: true,
            sort: true,
            filterType: "dropdown",

            filterOptions: {
                names: Object.values(historyLogTypes),
                renderValue: (v) => resources.historyLogs.type[v].toUpperCase(),
            },
            customFilterListOptions: { render: (v) => `${resources.historyLogs.datagrid.type}: ${resources.historyLogs.type[v].toUpperCase()}` },
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "path",
        label: resources.historyLogs.datagrid.path,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        ...timeRangeColumn(),
    },
];
