import React from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
//@material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Euro } from "@material-ui/icons";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
// core components
import Card from "../../components/Card/Card";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button.js";
import CustomSelect from "../../components/CustomSelect/CustomSelect.js";
import CustomTextArea from "../../components/CustomTextArea/CustomTextArea.js";
// utils and helpers
import resources from "../../core/Localization/Localization.js";
import { updateOffer } from "../../reduxStore/entities/offerDetails";
import OfferUpdateSchema from "../OfferCreate/OfferUpdateSchema.js";
import { offersDeliveryOptions, offersGuaranteeOptions, offersStateOptions, offersTypeOptions } from "../../utils/mapOfferStates.js";
import getOfferStatusClass from "../../utils/getOfferStatusClass.js";
import { isAuth } from "../../auth/helpers.js";
import { divBy100, roundNumberToTwoDec } from "../../utils/calculations.js";

const useStyles = makeStyles(dashboardStyle);

export default function OfferBasicInfo(props) {
    const {
        handleSubmit,
        formState: { errors },
        register,
        clearErrors,
        reset,
    } = useForm({
        resolver: yupResolver(OfferUpdateSchema),
    });

    const dispatch = useDispatch();
    const classes = useStyles();
    const { offer } = props;

    const user = isAuth();

    const [offerInfo, setOfferInfo] = React.useState({ ...offer });

    const deleteOffer = async () => {
        // to be developed
    };

    const markPartOfferAsSent = async () => {
        const data = {
            id: offer.part_offer_id,
            title: offer.title,
            status: 3,
            description: offer.description,
            type: offer.type,
            price: offer.price,
        };
        dispatch(updateOffer(data));
        const newOfferInfo = { ...offerInfo };
        newOfferInfo.status = 3;
        setOfferInfo(newOfferInfo);
    };

    return (
        !!offer &&
        !!offer.part_offer_id && (
            <Card profile className={"user-profile-card"}>
                <CardAvatar profile>
                    <Euro className={classes.profileIcon} />
                </CardAvatar>
                {offerInfo.status === 0 && (
                    <Button type={"button"} color={"danger"} size={"sm"} className={classes.absoluteContainer} onClick={deleteOffer}>
                        {resources.form.remove}
                    </Button>
                )}
                {offerInfo.status === 1 && (
                    <Button type={"button"} color={"info"} size={"sm"} className={classes.absoluteContainer} onClick={markPartOfferAsSent}>
                        {"Posalji deo"}
                    </Button>
                )}
                <CardBody profile>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6 className={classes.cardCategory}>
                                ID: {offer.part_offer_id}
                                <span className={getOfferStatusClass(offer.status)}>{offer.status_label}</span>
                            </h6>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                labelText={resources.partOffers.datagrid.created}
                                inputProps={{
                                    value: moment(offer.createdAt).format("DD/MM/YYYY hh:mm"),
                                    disabled: true,
                                }}
                                id="offer-created-at"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                labelText={resources.partOffers.datagrid.price}
                                inputProps={{
                                    ...register("price", {
                                        value: roundNumberToTwoDec(divBy100(offerInfo.price)),
                                    }),
                                    disabled: true,
                                }}
                                id="offer-price"
                                error={!!errors.price}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.price?.message && <p>{errors.price.message}</p>}
                        </GridItem>
                        <GridItem xs={6} sm={6} md={4}>
                            <CustomInput
                                labelText={resources.partOffers.datagrid.username}
                                inputProps={{
                                    value: offer.User?.username || "",
                                    disabled: true,
                                }}
                                id="offer-username"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                                labelText={resources.partOffers.datagrid.status}
                                inputProps={{
                                    ...register("status", {
                                        value: offerInfo.status,
                                    }),
                                    value: offerInfo.status,
                                    onChange: (event) => {
                                        setOfferInfo({
                                            ...offerInfo,
                                            status: event.target.value,
                                        });
                                    },
                                    disabled: true,
                                }}
                                id="offer-status"
                                error={!!errors.status}
                                options={offersStateOptions()}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.status?.message && <p>{errors.status.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                                labelText={resources.partOffers.datagrid.type}
                                inputProps={{
                                    ...register("type", {
                                        value: offerInfo.type,
                                    }),
                                    value: offerInfo.type,
                                    onChange: (event) => {
                                        setOfferInfo({
                                            ...offerInfo,
                                            type: event.target.value,
                                        });
                                    },
                                    disabled: true,
                                }}
                                error={!!errors.type}
                                id="offer-type"
                                options={offersTypeOptions()}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.type?.message && <p>{errors.type.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                                labelText={offerInfo.type === "used" ? resources.partOffers.datagrid.workGuarantee : resources.partOffers.datagrid.guarantee}
                                inputProps={{
                                    ...register("guarantee", {
                                        value: offerInfo.guarantee,
                                    }),
                                    value: offerInfo.guarantee,
                                    onChange: (event) => {
                                        setOfferInfo({
                                            ...offerInfo,
                                            guarantee: event.target.value,
                                        });
                                    },
                                    disabled: true,
                                }}
                                id="offer-guarantee"
                                options={offersGuaranteeOptions()}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomSelect
                                labelText={resources.partOffers.datagrid.delivery}
                                inputProps={{
                                    ...register("delivery", {
                                        value: offerInfo.delivery,
                                    }),
                                    value: offerInfo.delivery,
                                    onChange: (event) => {
                                        setOfferInfo({
                                            ...offerInfo,
                                            delivery: event.target.value,
                                        });
                                    },
                                    disabled: true,
                                }}
                                error={!!errors.delivery}
                                id="offer-delivery"
                                options={offersDeliveryOptions()}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.delivery?.message && <p>{errors.delivery.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomTextArea
                                labelText={resources.partOffers.datagrid.description}
                                inputProps={{
                                    ...register("description", {
                                        value: offerInfo.description,
                                    }),
                                    disabled: true,
                                }}
                                error={!!errors.description}
                                id="offer-description"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.description?.message && <p>{errors.description.message}</p>}
                        </GridItem>
                    </GridContainer>
                </CardBody>
            </Card>
        )
    );
}
