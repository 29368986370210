import { primaryColor, grayColor } from "../../material-dashboard-react";

const modalStyle = {
    root: { maxWidth: 600, width: "80vw", minWidth: 280, paddingBottom: 10 },
    titleContainer: {
        padding: "5px 10px",
        marginBottom: 15,
        background: "linear-gradient(160deg, #CBE100 0%, #5C8BCD 50%)",
    },
    titleText: {
        fontSize: 20,
        fontWeight: 700,
    },
    bodyContainer: {
        paddingLeft: 10,
        paddingRight: 10,
        marginBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
    },
    bodyTime: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "5px",
        color: grayColor[0],
        paddingLeft: 10,
        paddingRight: 10,
    },
    bodyText: { paddingLeft: 10, paddingTop: 20 },
    footerContainer: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
    },
    button: {
        marginRight: 10,
    },
};

export default modalStyle;
