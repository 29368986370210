import React from 'react';
import { filterUserColumns } from '../../common/FilterUsersColumns';
import { makeStyles } from "@material-ui/core/styles";
import filterUsersTableStyle from '../../assets/jss/material-dashboard-react/views/filterUsersTableStyle';

const useStyle = makeStyles(filterUsersTableStyle);

const FilterUsersTable = ({ users }) => {
  const headers = filterUserColumns;
  const classes = useStyle();

  return (
    <table className={classes.usersTable}>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th className={classes.th} key={index}>{header.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <tr className={classes.tr} key={index}>
            {headers.map((header, index) => (
              <td className={classes.td} key={index}>{user[header.name]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default FilterUsersTable;
