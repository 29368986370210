const versionList = {
    en: {
        versionList: {
            title: "Mobile Versions",
            datagrid: {
                app_name: "App name",
                version: "Version",
                build_number: "Build number",
                platform: "Platform",
                release_type: "Release type",
                release_status:"Release status",
                created: "Created",
            },
        },
        // userInfo: {
        //     blocked: "Blocked",
        //     active: "Active",
        //     phone: "Phone",
        //     city: "City",
        //     address: "Address",
        //     balance: "Balance",
        //     offers: "Offers",
        //     wins: "Wins",
        //     finished_trades: "Finished Trades",
        //     positive_ratings: "Positive Ratings",
        //     negative_ratings: "Negative Ratings",
        // },
    },
    rs: {
        versionList: {
            title: "Mobilne Verzije",
            datagrid: {
                app_name: "Ime aplikacije",
                version: "Verzija",
                build_number: "Build broj",
                platform: "Platforma",
                release_type: "Tip izdanja",
                release_status:"Status izdanja",
                created: "Kreirano",
            },
        },
        // userInfo: {
        //     blocked: "Blokiran",
        //     active: "Aktivan",
        //     phone: "Telefon",
        //     city: "Grad",
        //     address: "Adresa",
        //     balance: "Tokeni",
        //     offers: "Ponude",
        //     wins: "Pobeda",
        //     finished_trades: "Završenih Trgovina",
        //     positive_ratings: "Pozitivne Ocene",
        //     negative_ratings: "Negativne Ocene",
        // },
    },
};

export default versionList;
