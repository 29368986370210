import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { searchTransactions } from "../../reduxStore/entities/finance";
// core components
import CustomDataGrid from "../../components/CustomDataGrid/CustomDataGrid2";
import { transactionColumns } from "../../common/DataGridColumns/Transactions";
import resources from "../../core/Localization/Localization.js";
//common
import { notificationTypes } from "../../variables/notificationTypes";
import { handleTableLoad } from "../../common/Transactions";

function TransactionsTable({ type }) {
    const history = useHistory();
    const notifications = useNotificationMessages();

    // Store hooks
    const transactions = useSelector((state) => state.finance.transactions);
    const count = useSelector((state) => state.finance.count);
    const dataLoaded = useSelector((state) => state.finance.dataLoaded);
    const dispatch = useDispatch();
    // State hooks
    const [rows, setRows] = React.useState(handleTableLoad(transactions));
    const [totalRows, setTotalRows] = React.useState(count);
    const [loading, setLoading] = React.useState(false);
    const [currentFilters, setCurrentFilters] = React.useState(null);

    const columns = React.useMemo(() => transactionColumns(type), [type]);

    // React hooks

    useEffect(() => {
        notifications.publish({
            title: "Transaction read",
            body: "Transaction read",
            payload: {
                id: "null",
                topic: notificationTypes.transactionsRead,
            },
        });
    }, []);

    useEffect(() => setTotalRows(count), [count, type]);

    useEffect(() => {
        setRows(handleTableLoad(transactions));
        setCurrentFilters({ ...currentFilters, type: type });
    }, [transactions, type]);

    useEffect(() => {
        const transactionListener = notifications.subscribe(notificationTypes.partRequestCreated, handler);
        return () => {
            transactionListener.unsubscribe();
        };
    }, [currentFilters]);

    // useEffect(() => {
    //     if (Object.values(TransactionTypes).includes(historyState));

    //     doSearchTransactions({ offset: 0, limit: 10, type: historyState });
    // }, [historyState, history]);

    const handler = (message) => {
        currentFilters ? doSearchTransactions(currentFilters) : doSearchTransactions();
    };

    // Event handlers
    const onFiltersChanged = (filters) => doSearchTransactions(filters);

    const onPageChange = (filters) => {
        setLoading(true);
        doSearchTransactions(filters);
        setRows(handleTableLoad(transactions));
        setLoading(false);
    };

    const onRowClick = (rowData, rowMeta) => {
        if (rowMeta && rowMeta.dataIndex >= 0) {
            window.open("/admin/transactions/" + rows[rowMeta.dataIndex].id, "_blank");
        }
    };

    // Private (helper) functions
    const doSearchTransactions = (filters) => {
        setCurrentFilters({ ...currentFilters, ...filters, type: type });
        dispatch(searchTransactions({ ...filters, type: type }));
    };

    return (
        <CustomDataGrid
            title={resources.finance.title}
            rows={rows}
            columns={columns}
            totalRows={totalRows}
            loading={loading}
            handleRowClick={onRowClick}
            handleFilterChange={onFiltersChanged}
            handlePageChange={onPageChange}
            handleDataInit={doSearchTransactions}
            ignoreUrl={false}
            reset={type}
        />
    );
}

export default React.memo(TransactionsTable);
