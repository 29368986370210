import { customBodyRender, defaultCellStyle, defaultCustomBodyRender, defaultParentStyle, defaultWrapperStyle } from "./DataGridColumns";
import resources from "../../core/Localization/Localization";
import { dangerColor } from "../../assets/jss/material-dashboard-react";
import UserRoles from "../../auth/UserRoles";
import { timeRangeColumn } from "./customColumns/timeRangeColumn";
import { Tooltip, Zoom } from "@material-ui/core";

const stateWrapperStyle = {
    ...defaultWrapperStyle,
    minWidth: "50px",
};

const dangerWrapperStyle = {
    ...defaultWrapperStyle,
    minWidth: "20px",
    opacity: 0.8,
    backgroundColor: dangerColor[0],
};

const offerCellStyle = {
    ...defaultCellStyle,
    textAlign: "center",
    fontWeight: "bold",
    color: "white",
};

export const userColumns = () => [
    {
        name: "id",
        label: "ID",
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "firstname",
        label: resources.userList.datagrid.firstname,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "lastname",
        label: resources.userList.datagrid.lastname,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "shop_name",
        label: resources.userList.datagrid.shop_name,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "email",
        label: resources.userList.datagrid.email,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "role",
        label: resources.userList.datagrid.role,
        options: {
            filter: true,
            sort: true,
            filterType: "dropDown",

            filterOptions: {
                names: Object.values(UserRoles).slice(1, Object.values(UserRoles).length),
                renderValue: (v) => resources.userRoles[v],
            },

            customFilterListOptions: {
                render: (v) => {
                    return `${resources.userList.datagrid.role}: ${resources.userRoles[v]}`;
                },
            },
            customBodyRender: (val, row) => {
                let stateVal = row?.rowData[4];
                if (stateVal === 'Neverifikovan Prodavac' && !row.rowData[6]) {
                    return customBodyRender(val, row, {
                        wrapper: dangerWrapperStyle,
                        parent: defaultParentStyle,
                        cell: offerCellStyle,
                    });
                } else return (
                    <div style={stateWrapperStyle} value={row.rowData}>
                        <div style={defaultParentStyle}>
                            <Tooltip TransitionComponent={Zoom} title={val} interactive placement="bottom-start">
                                <div style={defaultCellStyle}>{val}</div>
                            </Tooltip>
                        </div>
                    </div>
                );
            },
        },
    },
    {
        ...timeRangeColumn(),
    },
];
