import { makeStyles } from "@material-ui/core/styles";
import { dangerColor, successColor, grayColor, defaultFont, infoColor } from "../../material-dashboard-react.js";

const addImagesStyle = makeStyles((theme) => ({
    addIconButton: {
        backgroundColor: dangerColor[2],
        "&:hover": {
            backgroundColor: dangerColor[2],
            opacity: 0.7,
        },
    },
    disabled: {
        opacity: 0.3,
        "&:hover": {
            opacity: 0.3,
        },
    },
    addIcon: {
        color: "#fff",
    },
    addFilesInput: {
        color: "transparent",
        width: 0,
        height: 0,
    },
    galleryContainer: {
        overflow: "auto",
        width: "80vw",
    },
    previewWrapper: {
        overflow: "auto",
        maxHeight: "90vh",
        width: "100%",
    },
    imgContainer: {
        position: "relative",
    },
    closeButton: {
        backgroundColor: dangerColor[2],
        width: "2rem",
        height: "2rem",
        position: "absolute",
        top: "5px",
        right: "5px",
        "&:hover": {
            backgroundColor: dangerColor[2],
            opacity: 0.7,
        },
    },
    closeIcon: {
        color: "#fff",
    },
    confirmButton: {
        backgroundColor: infoColor[1],
        position: "absolute",
        bottom: "1rem",
        right: "1rem",
        "&:hover": {
            backgroundColor: infoColor[1],
            opacity: 0.7,
        },
    },
    confirmIcon: {
        color: "#fff",
    },
}));

export default addImagesStyle;
