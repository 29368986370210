//status:
// 0 - sent
// 1 - accepted
// 2 - not accepted

import resources from "../core/Localization/Localization.js";

//offers statuses
export const sent = 0;
export const accepted = 1;
export const declined = 2;
export const completed = 3;

export const mapOffersState = (data) => {
    const newData = JSON.parse(JSON.stringify(data));
    newData.map((d) => {
        switch (d.status) {
            case 0:
                d.status_label = resources.partOffers.statuslabel.sent;
                break;
            case 1:
                d.status_label = resources.partOffers.statuslabel.accepted;
                break;
            case 2:
                d.status_label = resources.partOffers.statuslabel.declined;
                break;
            case 3:
                d.status_label = resources.partOffers.statuslabel.completed;
                break;

            default:
                break;
        }
    });
    return newData;
};

export const offersStateOptions = () => {
    return [
        {
            value: 0,
            label: resources.partOffers.statuslabel.sent,
        },
        {
            value: 1,
            label: resources.partOffers.statuslabel.accepted,
        },
        {
            value: 2,
            label: resources.partOffers.statuslabel.declined,
        },
        {
            value: 3,
            label: resources.partOffers.statuslabel.completed,
        },
    ];
};

export const offersTypeOptions = () => {
    return [
        {
            value: "used",
            label: resources.partOffers.types.used,
        },
        {
            value: "new",
            label: resources.partOffers.types.new,
        },
    ];
};

export const offersCurrencyOptions = () => {
    return [
        {
            value: "rsd",
            label: "RSD",
        },
        {
            value: "eur",
            label: "EUR",
        },
    ];
};

export const offersGuaranteeOptions = (partType) => {
    if (!partType) return [];
    if (partType === "new") {
        return [
            {
                value: "2 years",
                label: resources.partOffers.guarantee.twoYears,
            },
        ];
    }
    return [
        {
            value: "7 days",
            label: resources.partOffers.guarantee.sevenDays,
        },
        {
            value: "30 days",
            label: resources.partOffers.guarantee.thirtyDays,
        },
        {
            value: "3 months",
            label: resources.partOffers.guarantee.threeMonths,
        },
        {
            value: "6 months",
            label: resources.partOffers.guarantee.sixMonths,
        },
        {
            value: "12 months",
            label: resources.partOffers.guarantee.twelveMonths,
        },
    ];
};

export const offersDeliveryOptions = () => {
    return [
        {
            value: "kurir",
            label: resources.partOffers.delivery.courier,
        },
        {
            value: "lično",
            label: resources.partOffers.delivery.inPerson,
        },
        {
            value: "oba",
            label: resources.partOffers.delivery.both,
        }
    ]
}
