import resources from "../core/Localization/Localization";

const mapHistoryLogs = (historyLogs) => {
    const preparedData = historyLogs.map((x) => {
        return {
            id: x.history_log_id,
            action: resources.historyLogs[x.model.toLowerCase()][x.action.toLowerCase()],
            model_id: x.model_id,
            type: resources.historyLogs.type[x.type.toLowerCase()].toUpperCase(),
            path: x.path,
            createdAt: x.createdAt,
        };
    });
    return preparedData;
};

export default mapHistoryLogs;
