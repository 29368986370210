import React, {useEffect} from "react";

const QR = () => {

    useEffect(()=>{
       const userAgent = window.navigator.userAgent.toLowerCase();
       if(userAgent.includes('android')){
           window.location.href = 'https://play.google.com/store/apps/details?id=app.smartpart.smartpartbuyerapp';
       }else if(userAgent.includes('iphone') || userAgent.includes('ipad')){
           window.location.href = 'https://apps.apple.com/us/app/smart-part/id1644323936';
       }else{
           window.location.href ='https://smartpart.app/';
       }


    },[]);

    return (
      <div>
          Redirecting...
      </div>
    );

}

export default QR;