import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { initialModalData, reviewTabs, REVIEW_TYPE, USER_REVIEWS_HEADERS, mapReviewsInTableData } from "../../common/ReviewsTabs";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import MappedTabs from "../../components/MappedTabs/MappedTabs";
import MessageModal from "../../components/MessageModal/MessageModal";
import resources from "../../core/Localization/Localization";
import { getSelectedUserReviews, deleteUserReview } from "../../reduxStore/entities/reviews";
import getQueryWithPagination from "../../utils/getQueryWithPagination";

export default function UserReviews() {
    const dispatch = useDispatch();

    // Store hooks
    const user = useSelector((x) => x.userDetails.user);
    const reviews = useSelector((r) => r.reviews.selectedUsersReviews);

    // State hooks
    const [tableData, setTableData] = React.useState([]);
    const [currentTab, setCurrentTab] = React.useState(REVIEW_TYPE.received);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [openMessageModal, setOpenMessageModal] = React.useState(false);
    const [modalData, setModalData] = React.useState(initialModalData);

    // React hooks
    const headers = React.useMemo(USER_REVIEWS_HEADERS, [resources]);
    const tabs = React.useMemo(reviewTabs, [resources]);

    React.useEffect(() => {
        dispatch(getSelectedUserReviews(getQueryWithPagination({ userid: user.userid, type: REVIEW_TYPE.received })));
        return () => {};
    }, []);

    React.useEffect(() => {
        setTableData(mapReviewsInTableData(reviews));
    }, [reviews]);

    const handleTabChange = (e) => {
        const type = e === 0 ? REVIEW_TYPE.received : REVIEW_TYPE.posted;
        setCurrentTab(type);
        dispatch(getSelectedUserReviews(getQueryWithPagination({ userid: user.userid, type: type })));
    };

    const onRowClick = (params) => {
        if (params.length) {
            setModalData({ ...modalData, review_id: params[0] || 0, username: params[1] || "", comment: params[2] || "", rating: params[3] || "", createdAt: params[4] || "" });
            setOpenMessageModal(!openMessageModal);
        }
    };

    const handleConfirm = () => {
        dispatch(deleteUserReview({ review_id: modalData.review_id, type: currentTab, userid: user.userid }));
        setShowConfirmModal(false);
        setOpenMessageModal(!openMessageModal);
        setModalData(initialModalData);
    }

    return (
        <>
            <CustomTabs
                className={"user-profile-card"}
                title={resources.userReviews.title}
                headerColor={"success"}
                onTabChange={handleTabChange}
                tabs={MappedTabs(tabs, { tableHead: headers, tableData, onRowClick }, null, null, null, null, true)}
            />
            <MessageModal
                open={openMessageModal}
                handleClose={() => {
                    setOpenMessageModal(!openMessageModal);
                    setModalData(initialModalData);
                }}
                title={`${modalData.username} - ${modalData.rating}`}
                text={modalData.comment}
                createdAt={modalData.createdAt}
                buttonLabel={resources.form.ok}
                customButton={true}
                customButtonLabel={resources.userReviews.delete}
                customOnClick={() => {
                    setShowConfirmModal(true);
                }}
            />
            <ConfirmationModal
                open={showConfirmModal}
                handleClose={() => {
                    setShowConfirmModal(false);
                }}
                buttonLabel={resources.form.confirm}
                text={resources.userReviews.confirmDeleteReview}
                onConfirmCallback={handleConfirm}
            />
        </>
    );
}
