import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import { getFilter } from "../../reduxStore/entities/filterDetails";
import Layout from "../../core/Layout";
import useLocalStorage from "../../hooks/useLocalStorage.js";
import { FILTER_DETAILS_LAYOUT_KEY, defaultLayout } from "../../common/FilterDetailsLayout";
import FilterBasicInfo from "./FilterBasicInfo";
import FilterHistory from "./FilterHistory";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function FilterDetails(props) {
    const dispatch = useDispatch();
    const id = props.match.params.id;

    const [lsLayout, setLsLayout] = useLocalStorage(FILTER_DETAILS_LAYOUT_KEY, defaultLayout);
    const filter = useSelector((state) => state.filterDetails.filter);

    React.useEffect(() => {
        dispatch(getFilter(id));
        return () => {};
    }, []);

    return (
        <Layout>
            <ResponsiveGridLayout
                className="layout"
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                rowHeight={30}
                layouts={lsLayout}
                onLayoutChange={(layout, layouts) => {
                    setLsLayout(layouts);
                }}
            >
                <div key="1">
                    <FilterBasicInfo filter={filter} />
                </div>
                <div key="2">
                    <FilterHistory filter={filter} />
                </div>
            </ResponsiveGridLayout>
        </Layout>
    );
}
