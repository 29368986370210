import api from "../../../api";

export const requestGetUserTransactions = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/user-transactions-by-type${query ? query : ""}`);
};

export const requestSearchTransactions = (props) => {
    console.log(props);
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/search-transactions${query ? query : ""}`);
};

export const requestRefundByTransactionId = (payload) => api.put(`/admin/transactions/${payload.id}/refund`, payload);
