const signInStyle = (theme) => ({
    mainWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100wv",
        height: "100vh",
    },
    formWrapper: {
        padding: "40px 60px",
        gap: 30,
        [theme.breakpoints.down("xs")]: {
            padding: 10,
            minWidth: "100%",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    },
    tnc: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100wv",
        flexDirection: "column",
        padding: "40px 60px",
        gap: 30,
        [theme.breakpoints.down("xs")]: {
            gap: 0,
            padding: "30px 10px",
            minWidth: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
    },
    title: {},
});

export default signInStyle;
