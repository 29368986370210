import { Tooltip, Zoom } from "@material-ui/core";

export const defaultPageSize = 25;

export const defaultSearch = { offset: 0, limit: defaultPageSize };

export const defaultCellStyle = {
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
};
export const defaultParentStyle = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    boxSizing: "border-box",
    display: "block",
    width: "100%",
};

export const defaultWrapperStyle = {
    position: "relative",
    height: "20px",
};

export const defaultCustomBodyRender = (val, row) => {
    let rowData = row?.rowData;
    return (
        <div style={defaultWrapperStyle} value={rowData}>
            <div style={defaultParentStyle}>
                <Tooltip TransitionComponent={Zoom} title={val} interactive placement="bottom-start">
                    <div style={defaultCellStyle}>{val}</div>
                </Tooltip>
            </div>
        </div>
    );
};

export const customBodyRender = (val, row, styles) => {
    let rowData = row?.rowData;
    return (
        <div style={styles.wrapper} value={rowData}>
            <div style={styles.parent}>
                <Tooltip TransitionComponent={Zoom} title={val} interactive placement="bottom-start">
                    <div style={styles.cell}>{val}</div>
                </Tooltip>
            </div>
        </div>
    );
};
