import * as Yup from "yup";
import resources from "../../core/Localization/Localization";

const PaymentSettingsUpdateSchema = Yup.object().shape({
    eur_to_rsd: Yup.number().optional(),
    token_to_rsd: Yup.number().optional(),
    default_commission: Yup.number().optional(),
});

export default PaymentSettingsUpdateSchema;
