import { call, put } from "redux-saga/effects";
import { setTransaction } from "../../entities/transactionDetails";
import { requestGetTransaction } from "../requests/transactionDetails";

export function* handleGetTransaction(action) {
    try {
        const response = yield call(requestGetTransaction, action.payload);
        const { data } = response;
        yield put(setTransaction({ ...data }));
    } catch (error) {
        console.log(error);
    }
}
