import resources from "../core/Localization/Localization";
import { divBy100, isWholeNumber, roundNumberToTwoDec, toPercentage } from "./calculations";

const validateAddTokens = (data) => {
    let errors = [];
    if (!data.amount) {
        errors = [...errors, resources.finance.errors.tokensRequired];
    }
    if (isWholeNumber(data.amount)) {
        errors = [...errors, resources.finance.errors.notWholeNumber];
    }
    if (!data.currency_amount) {
        errors = [...errors, resources.finance.errors.paymentRequired];
    }
    if (!data.payment_basis || data.payment_basis.length < 1) {
        errors = [...errors, resources.finance.errors.paymentBasisRequired];
    }
    return errors;
};

const validateCorrectTokens = (data) => {
    let errors = [];
    if (!data.amount) {
        errors = [...errors, resources.finance.errors.tokensRequired];
    }
    if (isWholeNumber(data.amount)) {
        errors = [...errors, resources.finance.errors.notWholeNumber];
    }
    if (!data.option) {
        errors = [...errors, resources.finance.errors.correctOptionRequired];
    }
    if (!data.reason || data.reason < 1) {
        errors = [...errors, resources.finance.errors.correctionReasonRequired];
    }
    return errors;
};

const mapUserFinanceProps = (user) => {
    let userObj = { ...user };
    if (userObj.reserved) {
        userObj.reserved = divBy100(userObj.reserved);
    }
    if (userObj.available) {
        userObj.available = divBy100(userObj.available);
    }
    if (userObj.balance) {
        userObj.balance = divBy100(userObj.balance);
    }
    return userObj;
};

const mapAddTokensPayload = (payload) => {
    const tokenData = { ...payload };
    if (tokenData.amount) tokenData.amount = parseInt(tokenData.amount);
    if (tokenData.currency_amount) tokenData.currency_amount = roundNumberToTwoDec(parseFloat(tokenData.currency_amount));
    return tokenData;
};

const mapPaymentSettingsToUI = (paymentConfig) => {
    const paymentConfigData = { ...paymentConfig };
    const mappedData = {
        ...paymentConfigData,
        default_commission: toPercentage(roundNumberToTwoDec(paymentConfigData.default_commission), 0),
        eur_to_rsd: roundNumberToTwoDec(divBy100(paymentConfigData.eur_to_rsd)),
        token_to_rsd: roundNumberToTwoDec(divBy100(paymentConfigData.token_to_rsd)),
    };
    return mappedData;
};

const mapPaymentSettingsToAPI = (paymentConfig) => {
    const paymentConfigData = { ...paymentConfig };
    if (paymentConfig?.default_commission) paymentConfigData.default_commission = roundNumberToTwoDec(divBy100(paymentConfig.default_commission));
    if (paymentConfig?.eur_to_rsd) paymentConfigData.eur_to_rsd = paymentConfigData.eur_to_rsd * 100;
    if (paymentConfig?.token_to_rsd) paymentConfigData.token_to_rsd = paymentConfigData.token_to_rsd * 100;

    return paymentConfigData;
};

export { validateAddTokens, validateCorrectTokens, mapUserFinanceProps, mapAddTokensPayload, mapPaymentSettingsToUI, mapPaymentSettingsToAPI };
