import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Share from "@material-ui/icons/Share";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
// core components
import Header from "../../../components/Header/Header.js";
import Button from "../../../components/CustomButtons/Button.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import headersStyle from "../../../assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import office2 from "../../../assets/img/headerBG.png";


const useStyles = makeStyles(headersStyle);

export default function SectionHeader({ ...rest }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };
  return (
    // we've set the className to cd-section so we can make smooth scroll to it{/*...rest*/}
    <div className="c" >
      {/*<div className={classes.sectionBlank} id="blanksection" />*/}

      {/* HEADER 2 START  .MuiAppBar-colorPrimary */}
      <div>
        <Header

          absolute
          brand="Smart Part"
          color="transparent"

          links={
            <div className={classes.collapse}>
              <List className={classes.list + " " + classes.mlAuto}>
                <ListItem className={classes.listItem}>
                  <Button
                    href="/landing"
                    className={classes.navLink}
                    //onClick={e => e.preventDefault()}
                    color="transparent"
                  >
                    Home
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                      href="/support"
                      className={classes.navLink}
                      //onClick={e => e.preventDefault()}
                      color="transparent"
                  >
                    Podrška
                  </Button>
                </ListItem>
                {/*<ListItem className={classes.listItem}>*/}
                {/*  <Button*/}
                {/*    href="#pablo"*/}
                {/*    className={classes.navLink}*/}
                {/*    onClick={e => e.preventDefault()}*/}
                {/*    color="transparent"*/}
                {/*  >*/}
                {/*    About us*/}
                {/*  </Button>*/}
                {/*</ListItem>*/}
                {/*<ListItem className={classes.listItem}>*/}
                {/*  <Button*/}
                {/*    href="#pablo"*/}
                {/*    className={classes.navLink}*/}
                {/*    onClick={e => e.preventDefault()}*/}
                {/*    color="transparent"*/}
                {/*  >*/}
                {/*    Products*/}
                {/*  </Button>*/}
                {/*</ListItem>*/}
                <ListItem className={classes.listItem}>
                  <Button
                    href="/signin"
                    className={classes.navLink}
                    //onClick={e => e.preventDefault()}
                    color="transparent"
                  >
                    Sign In
                  </Button>
                </ListItem>
              </List>
              <List className={classes.list + " " + classes.mlAuto}>
                <ListItem className={classes.listItem}>
                  <Button
                    color="transparent"
                    href="https://twitter.com/"
                    target="_blank"
                    className={classes.navLink + " " + classes.navLinkJustIcon}
                  >
                    <i className={"fab fa-twitter"} />
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                    color="transparent"
                    href="https://www.facebook.com/"
                    target="_blank"
                    className={classes.navLink + " " + classes.navLinkJustIcon}
                  >
                    <i className={"fab fa-facebook"} />
                  </Button>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                    color="transparent"
                    href="https://www.instagram.com/"
                    target="_blank"
                    className={classes.navLink + " " + classes.navLinkJustIcon}
                  >
                    <i className={"fab fa-instagram"} />
                  </Button>
                </ListItem>
              </List>
            </div>
          }
        />
        <div
          className={classes.pageHeader}
          style={{ backgroundImage: `url("${office2}")` }}
        >
          <div className={classes.conatinerHeader2}>
            <GridContainer>
              <GridItem
                xs={12}
                sm={8}
                md={8}
                className={classNames(
                  classes.mlAuto,
                  classes.mrAuto,
                  classes.textCenter
                )}
              >
                <h1 className={classes.title}>Tvoj virtuelni asistent za održavanje vozila</h1>
                <h4>
                  Smart Part aplikacija ti omogućava da na jednostavan način bez muke pronađeš
                  najpovoljnije delove za svoj automobil. Na tebi je samo da popuniš zahtev
                  i da odabereš najpovoljniju ponudu. Aplikacija će sve ostalo uraditi za tebe.
                </h4>
              </GridItem>
              {/*<GridItem*/}
              {/*  xs={12}*/}
              {/*  sm={10}*/}
              {/*  md={10}*/}
              {/*  className={classNames(classes.mlAuto, classes.mrAuto)}*/}
              {/*>*/}
              {/*  <Card raised className={classes.card}>*/}
              {/*    <CardBody formHorizontal>*/}
              {/*      <form>*/}
              {/*        <GridContainer>*/}
              {/*          <GridItem xs={12} sm={3} md={3}>*/}
              {/*            <CustomInput*/}
              {/*              id="name"*/}
              {/*              inputProps={{*/}
              {/*                placeholder: "Company name"*/}
              {/*              }}*/}
              {/*              formControlProps={{*/}
              {/*                fullWidth: true,*/}
              {/*                className: classes.formControl*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </GridItem>*/}
              {/*          <GridItem xs={12} sm={3} md={3}>*/}
              {/*            <CustomInput*/}
              {/*              id="email"*/}
              {/*              inputProps={{*/}
              {/*                placeholder: "Company email"*/}
              {/*              }}*/}
              {/*              formControlProps={{*/}
              {/*                fullWidth: true,*/}
              {/*                className: classes.formControl*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </GridItem>*/}
              {/*          <GridItem xs={12} sm={3} md={3}>*/}
              {/*            <CustomInput*/}
              {/*              id="password"*/}
              {/*              inputProps={{*/}
              {/*                placeholder: "Company password",*/}
              {/*                type: "password",*/}
              {/*                autoComplete: "off"*/}
              {/*              }}*/}
              {/*              formControlProps={{*/}
              {/*                fullWidth: true,*/}
              {/*                className: classes.formControl*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          </GridItem>*/}
              {/*          <GridItem xs={12} sm={3} md={3}>*/}
              {/*            <Button*/}
              {/*              block*/}
              {/*              color="primary"*/}
              {/*              className={classes.button}*/}
              {/*            >*/}
              {/*              Sign up*/}
              {/*            </Button>*/}
              {/*          </GridItem>*/}
              {/*        </GridContainer>*/}
              {/*      </form>*/}
              {/*    </CardBody>*/}
              {/*  </Card>*/}
              {/*</GridItem>*/}
            </GridContainer>
          </div>
        </div>
      </div>
      {/* HEADER 2 END */}

    </div>
  );
}
