import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Responsive, WidthProvider } from "react-grid-layout";
import { getOffer, setOffer } from "../../reduxStore/entities/offerDetails";
import Layout from "../../core/Layout";
import useLocalStorage from "../../hooks/useLocalStorage.js";
import { OFFER_DETAILS_LAYOUT_KEY, defaultLayout } from "../../common/OfferDetailsLayout";
import OfferBasicInfo from "./OfferBasicInfo";
import OfferHistory from "./OfferHistory";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { notificationTypes } from "../../variables/notificationTypes";
import OfferImages from "./OfferImages";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function OfferDetails(props) {
    const dispatch = useDispatch();
    const notifications = useNotificationMessages();
    const id = props.match.params.id;

    const [lsLayout, setLsLayout] = useLocalStorage(OFFER_DETAILS_LAYOUT_KEY, defaultLayout);
    const offer = useSelector((state) => state.offerDetails.offer);

    useEffect(() => {
        dispatch(getOffer(id));
        return () => {
            dispatch(setOffer(null));
        };
    }, []);

    useEffect(() => {
        const notificationListener = notifications.subscribe(notificationTypes.partOfferUpdated, handler);
        return () => {
            notificationListener.unsubscribe();
        };
    }, []);

    const handler = (message) => {
        dispatch(getOffer(id));
    };

    return (
        <Layout>
            {!!offer && offer.part_offer_id && (
                <ResponsiveGridLayout
                    className="layout"
                    cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                    rowHeight={30}
                    layouts={lsLayout}
                    onLayoutChange={(layout, layouts) => {
                        setLsLayout(layouts);
                    }}
                >
                    <div key="1">
                        <OfferBasicInfo offer={offer} />
                    </div>
                    <div key="2">
                        <OfferHistory offer={offer} />
                    </div>
                    <div key="3">
                        <OfferImages />
                    </div>
                </ResponsiveGridLayout>
            )}
        </Layout>
    );
}
