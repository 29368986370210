import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { authenticate, isAuth, setFirebaseToken } from "../../auth/helpers";
import { ToastContainer, toast } from "react-toastify";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import "react-toastify/dist/ReactToastify.min.css";
import { useDispatch } from "react-redux";
import { updateUser } from "../../reduxStore/entities/userDetails";
import signInStyle from "../../assets/jss/material-dashboard-react/components/signIn";
import headersStyle from "../../assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.js";
import { makeStyles, Typography } from "@material-ui/core";
import resources from "../../core/Localization/Localization";
import Parallax from "../../components/Parallax/Parallax";
import image from "../../assets/img/bg7.jpg";
import Header from "../../components/Header/Header";
import HeaderLinks from "../../components/Header/HeaderLinks";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import HeaderSection from "./Sections/HeaderSection";
const useStyles = makeStyles(signInStyle);

const Signin = ({ history }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    //state hooks
    const [values, setValues] = useState({
        email: "",
        password: "",
        google_auth: "",
        buttonText: resources.form.confirm,
    });

    const { email, password, google_auth, buttonText } = values;

    const handleChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const clickSubmit = (event) => {
        event.preventDefault();
        setValues({ ...values, buttonText: resources.form.confirming + "..." });
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API}/signin`,
            data: { email, password },
            headers: {
                "x-ignore-maintenance": true,
            },
        })
            .then((response) => {
                // save the response (user, token) localstorage/cookie
                authenticate(response);
                setFirebaseToken().then((token) => {
                    dispatch(updateUser({ firebase_token: token, userid: response.data.user.userid }));
                });

                setTimeout(() => {
                    setValues({ ...values, name: "", email: "", password: "", buttonText: resources.form.confirmed });
                    isAuth() ? history.push("/admin/dashboard") : history.push("/signin");
                }, 5000);
            })
            .catch((error) => {
                setValues({ ...values, buttonText: resources.form.confirm });
                console.log(error.response);
                toast.error(error.response?.data?.error);
            });
    };

    const signinForm = () => (
        <form
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    clickSubmit(e);
                }
            }}
        >
            <div className="form-group">
                <label className="text-muted">Email</label>
                <input onChange={handleChange("email")} value={email} type="email" className="form-control" />
            </div>

            <div className="form-group">
                <label className="text-muted">Password</label>
                <input onChange={handleChange("password")} value={password} type="password" className="form-control" />
            </div>
            <div className="form-group">
                <label className="text-muted">2FA</label>
                <input onChange={handleChange("google_auth")} value={google_auth} type="google_auth" className="form-control" />
            </div>

            <div>
                <Button variant="contained" color="primary" type="button" onClick={clickSubmit}>
                    {buttonText}
                </Button>
            </div>
        </form>
    );

    return (
        <>
            {/*<div className="col-md-6 offset-md-3">*/}
            {/*<Header*/}
            {/*    absolute*/}
            {/*    color="transparent"*/}
            {/*    brand="Smart Part d.o.o"*/}
            {/*    links={<HeaderLinks dropdownHoverColor="info" />}*/}
            {/*/>*/}
            <HeaderSection/>
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
            <Box className={classes.mainWrapper}>

                <Grid item xs={12} sm={12} md={3} xl={4}>
                    <Paper elevation={3}>
                        <Box className={classes.formWrapper}>
                            <ToastContainer />
                            {/* {isAuth() ? <Redirect to="/admin/dashboard" /> : null} */}
                            <Typography variant="h4" align="center" classNames={classes.title}>
                                Smartpart Login
                            </Typography>

                            {signinForm()}
                            <br />
                        </Box>
                    </Paper>
                </Grid>

            </Box>

            </div>
            {/*</div>*/}
        </>
    );
};

export default Signin;
