import { createSlice } from "@reduxjs/toolkit";

const partrequestImages = createSlice({
    name: "partrequestImages",
    initialState: {
        images: [],
        dataLoaded: false,
    },
    reducers: {
        getPartrequestImages(state, action) {
            return { ...state, dataLoaded: false };
        },
        setPartrequestImages(state, action) {
            const partrequestData = action.payload;
            return { ...state, images: partrequestData, dataLoaded: true };
        },
        deletePartrequestImage() {},
    },
});

export const { getPartrequestImages, setPartrequestImages, deletePartrequestImage } = partrequestImages.actions;

export default partrequestImages.reducer;
