import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
//@material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Menu, MenuItem } from "@material-ui/core";
import { Build } from "@material-ui/icons";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
// core components
import Card from "../../components/Card/Card";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomTextArea from "../../components/CustomTextArea/CustomTextArea.js";
import CustomSelect from "../../components/CustomSelect/CustomSelect.js";
import Button from "../../components/CustomButtons/Button.js";
//
//REFACTORE TO THE CONFIRM MODAL AFTER MERGE
//
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
//
// utils and helpers
import resources from "../../core/Localization/Localization.js";
import { requestModalsConfig, initialModalData } from "../../common/RequestDetailsLayout";
import { getRequestStatus, getRequestStatusClass } from "../../utils/getRequestStatus";
import { partRequestsStateOptions,partRequestsPartstateOptions } from "../../utils/mapRequestStates";
import { updatePartrequest } from "../../reduxStore/entities/partrequestDetails.js";
import { refundByTransactionId } from "../../reduxStore/entities/finance.js";
import { isAuth } from "../../auth/helpers.js";

const useStyles = makeStyles(dashboardStyle);

export default function RequestBasicInfo() {
    const dispatch = useDispatch();

    const classes = useStyles();
    const operator = isAuth();
    const partrequest = useSelector((s) => s.partrequestDetails.partrequest);

    const [basicInfo, setBasicInfo] = useState({ ...partrequest });
    const [openModal, setOpenModal] = React.useState(false);
    const [modalData, setModalData] = React.useState(initialModalData);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onActionConfirm = (control) => {
        setModalData(control);
        setOpenModal(!openModal);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onActionTrigger = (action) => {
        switch (action.name) {
            case "remove":
                setBasicInfo({ ...basicInfo, state: 11 });
                dispatch(updatePartrequest({ id: partrequest.part_request_id, state: 11 }));
                break;
            case "block":
                setBasicInfo({ ...basicInfo, state: 10 });
                dispatch(updatePartrequest({ id: partrequest.part_request_id, state: 10 }));
                break;
            case "success":
                setBasicInfo({ ...basicInfo, state: 12 });
                dispatch(updatePartrequest({ id: partrequest.part_request_id, state: 12 }));
                break;
            case "failure13":
                setBasicInfo({ ...basicInfo, state: 13 });
                dispatch(updatePartrequest({ id: partrequest.part_request_id, state: 13 }));
                break;
            case "failure14":
                setBasicInfo({ ...basicInfo, state: 14 });
                dispatch(updatePartrequest({ id: partrequest.part_request_id, state: 14 }));
                break;
            case "failure15":
                setBasicInfo({ ...basicInfo, state: 15 });
                dispatch(updatePartrequest({ id: partrequest.part_request_id, state: 15 }));
                break;
            case "failure16":
                setBasicInfo({ ...basicInfo, state: 16 });
                dispatch(updatePartrequest({ id: partrequest.part_request_id, state: 16 }));
                break;
            case "refund":
                dispatch(
                    refundByTransactionId({
                        transactionPayload: { id: partrequest.Transaction.transaction_id, refunded_by: operator.userid },
                        partRequestPayload: { id: partrequest.part_request_id },
                    })
                );
                break;

            default:
                break;
        }
        if (openModal) {
            setOpenModal(false);
        }
    };

    useEffect(() => {
        setBasicInfo({ ...partrequest });
    }, [partrequest]);

    return (
        !!partrequest &&
        !!partrequest.part_request_id && (
            <Card profile className={"user-profile-card"}>
                <CardAvatar profile>
                    <Build className={classes.profileIcon} />
                </CardAvatar>
                {![11, 12, 13, 14, 15, 16].includes(partrequest.state) ? (
                    partrequest.state !== 10 ? (
                        <Box className={classes.absoluteContainer}>
                            <Button
                                type={"button"}
                                color={"info"}
                                size={"sm"}
                                className={classes.buttonSeparator}
                                onClick={() => onActionConfirm(requestModalsConfig.remove)}
                            >
                                {resources.form.remove}
                            </Button>

                            {![3, 4].includes(partrequest.state) ? (
                                <Button type={"button"} color={"danger"} size={"sm"} onClick={() => onActionConfirm(requestModalsConfig.block)}>
                                    {resources.form.inDispute}
                                </Button>
                            ) : null}
                        </Box>
                    ) : (
                        <Box className={classes.absoluteContainer}>
                            <Button
                                type={"button"}
                                color={"info"}
                                size={"sm"}
                                className={classes.buttonSeparator}
                                onClick={() => onActionConfirm(requestModalsConfig.success)}
                            >
                                {resources.partRequests.success}
                            </Button>
                            <Button aria-controls={"menu-list-grow"} aria-haspopup="true" type={"button"} color={"danger"} size={"sm"} onClick={handleClick}>
                                {resources.partRequests.failure}
                            </Button>
                            <Menu id="menu-list-grow" anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={handleClose}>
                                <MenuItem onClick={() => onActionConfirm(requestModalsConfig.failure13)}>{resources.partRequests.state.thirteen}</MenuItem>
                                <MenuItem onClick={() => onActionConfirm(requestModalsConfig.failure14)}>{resources.partRequests.state.fourteen}</MenuItem>
                                <MenuItem onClick={() => onActionConfirm(requestModalsConfig.failure15)}>{resources.partRequests.state.fifteen}</MenuItem>
                                <MenuItem onClick={() => onActionConfirm(requestModalsConfig.failure16)}>{resources.partRequests.state.sixteen}</MenuItem>
                            </Menu>
                        </Box>
                    )
                ) : [13, 14, 15, 16].includes(partrequest.state) && partrequest.Transaction?.refunded === false ? (
                    <Box className={classes.absoluteContainer}>
                        <Button
                            type={"button"}
                            color={"success"}
                            size={"sm"}
                            className={classes.buttonSeparator}
                            onClick={() => onActionConfirm(requestModalsConfig.refund)}
                        >
                            {resources.finance.refund}
                        </Button>
                    </Box>
                ) : null}
                <CardBody profile>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6 className={classes.cardCategory}>
                                ID: {partrequest.part_request_id}
                                <span className={getRequestStatusClass(basicInfo.state)}>{getRequestStatus(basicInfo.state)}</span>
                            </h6>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={resources.partRequests.datagrid.title}
                                inputProps={{
                                    value: basicInfo.title,
                                    disabled: true,
                                }}
                                id="request-title"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={resources.partRequests.datagrid.created}
                                inputProps={{
                                    value: moment(basicInfo.createdAt).format("DD/MM/YYYY hh:mm"),
                                    disabled: true,
                                }}
                                id="request-created-at"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomSelect
                                labelText={resources.partRequests.datagrid.state}
                                inputProps={{
                                    value: basicInfo.state,
                                    disabled: true,
                                }}
                                id="request-state"
                                options={partRequestsStateOptions()}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        {/*//TODO<GridItem xs={12} sm={12} md={6}>*/}
                        {/*    <CustomSelect*/}
                        {/*        labelText={resources.partRequests.datagrid.partstate}*/}
                        {/*        inputProps={{*/}
                        {/*            value: basicInfo.partstate,*/}
                        {/*            disabled: true,*/}
                        {/*        }}*/}
                        {/*        id="request-partstate"*/}
                        {/*        options={partRequestsPartstateOptions()}*/}
                        {/*        formControlProps={{*/}
                        {/*            fullWidth: true,*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</GridItem>*/}
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={resources.partRequests.datagrid.address}
                                inputProps={{
                                    value: basicInfo.address,
                                    disabled: true,
                                }}
                                id="request-address"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                                labelText={resources.partRequests.datagrid.city}
                                inputProps={{
                                    value: basicInfo.city,
                                    disabled: true,
                                }}
                                id="request-city"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomTextArea
                                labelText={resources.partRequests.datagrid.description}
                                inputProps={{
                                    value: basicInfo.description,
                                    disabled: true,
                                }}
                                id="request-description"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        {basicInfo.ReportedIssue ? (
                            <>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText={resources.partRequests.datagrid.issue_reported}
                                        inputProps={{
                                            value: basicInfo.ReportedIssue.User.username,
                                            disabled: true,
                                        }}
                                        id="request-issue-reported-by"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText={resources.partRequests.datagrid.issue_reason}
                                        inputProps={{
                                            value: basicInfo.ReportedIssue.issue_reason,
                                            disabled: true,
                                        }}
                                        id="request-issue-title"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomTextArea
                                        labelText={resources.partRequests.datagrid.issue_solution}
                                        inputProps={{
                                            value: basicInfo.ReportedIssue.issue_solution,
                                            disabled: true,
                                        }}
                                        id="request-issue-solution"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                </GridItem>
                            </>
                        ) : null}
                    </GridContainer>
                    <ConfirmationModal
                        open={openModal}
                        handleClose={() => {
                            setOpenModal(!openModal);
                            setModalData(initialModalData);
                        }}
                        title={resources.partRequests[modalData.name]}
                        text={resources.partRequests[modalData.text]}
                        buttonLabel={resources.form[modalData.buttonLabel]}
                        onConfirmCallback={() => onActionTrigger(modalData)}
                    />
                </CardBody>
            </Card>
        )
    );
}
