import { createSlice } from "@reduxjs/toolkit";

const partrequests = createSlice({
    name: "partrequests",
    initialState: {
        partrequests: [],
        activepartrequests: [],
        selectedUsersPartrequests: [],
        dataLoaded: false,
        activeDataLoaded: false,
        selectedUsersDataLoaded: false,
    },
    reducers: {
        getPartrequests(state, action) {
            return { ...state, dataLoaded: false };
        },
        getActivePartrequests(state, action) {
            return { ...state, activeDataLoaded: false };
        },
        getSelectedUsersPartRequests(state, action) {
            return { ...state, selectedUsersDataLoaded: false };
        },

        setPartrequests(state, action) {
            const partrequestData = Object.values(action.payload);
            return {
                ...state,
                partrequests: partrequestData,
                dataLoaded: true,
            };
        },
        setActivePartrequests(state, action) {
            const data = Object.values(action.payload);
            return {
                ...state,
                activepartrequests: data,
                activeDataLoaded: true,
            };
        },
        setSelectedUsersPartrequests(state, action) {
            const data = Object.values(action.payload);
            return {
                ...state,
                selectedUsersPartrequests: data,
                selectedUsersDataLoaded: true,
            };
        },
        getPaginationPartrequests(state, action) {
            return { ...state, dataLoaded: false };
        },
        setPaginationPartrequests(state, action) {
            return {
                ...state,
                partrequests: action.payload.data,
                count: action.payload.count,
                dataLoaded: true,
            };
        },
    },
});

export const {
    getPartrequests,
    getActivePartrequests,
    getSelectedUsersPartRequests,
    setSelectedUsersPartrequests,
    setPartrequests,
    setActivePartrequests,
    getPaginationPartrequests,
    setPaginationPartrequests,
} = partrequests.actions;

export default partrequests.reducer;
