export const handleTableLoad = (cars) => {
    const preparedData = cars.map((car) => {
        return {
            id: car.user_car_id,
            make: car.Make?.name ? car.Make.name : "",
            model: car.Model?.name ? car.Model.name : "",
            generation: car.Generation?.name ? car.Generation.name : "",
            motor: car.Motor?.name ? car.Motor.name : "",
            title: car.title,
            vin: car.vin,
            owner: car.User?.username ? car.User.username : "Korisnik obrisan",
            createdAt: car.createdAt,
        };
    });
    return preparedData;
};
