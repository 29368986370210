import { toast } from "react-toastify";
import { call, put, take, fork, all } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
    requestGetUser,
    requestDeleteUser,
    requestUpdateUser,
    requestSendEmailVerification,
    requestSendPhoneVerification,
    requestGetUserCars,
    requestVerifyAsSeller,
    requestGetVerificationRequests,
    requestLogoutUser,
    requestBlockUser,
    requestUnblockUser,
    requestSetBetaUser,
    requestGetUserNotifications,
    requestGetUserFilters,
    requestSendUserSystemMessage,
    requestAddTokensToUser,
    requestCorrectUserTokens,
    requestVerifyAsSellerPlus,
} from "../requests/userDetails";
import {
    setUser,
    setUserCars,
    setVerificationRequest,
    setVerificationRequests,
    setUserNotifications,
    setUserFilters,
    setUserNotification,
    getUser,
    requestFailed,
} from "../../entities/userDetails";
import resources from "../../../core/Localization/Localization.js";

export function* handleGetUser(action) {
    try {
        const response = yield call(requestGetUser, action.payload);
        const { data } = response;
        yield put(setUser({ ...data }));
    } catch (error) {
        console.log(error);
        yield put(requestFailed());
    }
}

export function* handleDeleteUser(action) {
    try {
        const res = yield call(requestDeleteUser, action.payload);
        if (res) {
            yield put(push(`/admin/users`));
            toast.success(resources.deleteUser.success);
        }
    } catch (error) {
        console.log(error);
    }
}

export function* handleUpdateUser(action) {
    try {
        const response = yield call(requestUpdateUser, action.payload);
        if (response) {
            const { data } = response;
            yield put(setUser({ ...data }));
        }
    } catch (error) {
        console.log(error);
        yield put(requestFailed());
    }
}

export function* handleSendEmailVerification(action) {
    const res = yield call(requestSendEmailVerification, action.payload);
    if (res) {
        const { data } = res;
        yield put(setVerificationRequest(data));
        toast.success(resources.userSettings.sendEmailVerification);
    }
    return;
}

export function* handleSendPhoneVerification(action) {
    const res = yield call(requestSendPhoneVerification, action.payload);
    if (res) {
        const { data } = res;
        yield put(setVerificationRequest(data));
        toast.success(resources.userSettings.sendPhoneVerification);
    }
    return;
}

export function* handleGetUserCars(action) {
    try {
        const response = yield call(requestGetUserCars, action.payload);
        const { data } = response;
        yield put(setUserCars([...data]));
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetUserFilters(action) {
    try {
        const response = yield call(requestGetUserFilters, action.payload);
        const { data } = response;
        yield put(setUserFilters(data.data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleVerifyAsSeller(action) {
    const response = yield call(requestVerifyAsSeller, action.payload);
    if (response) {
        const { data } = response;
        yield put(setUser({ ...data }));
    }
}

export function* handleVerifyAsSellerPlus(action) {
    const response = yield call(requestVerifyAsSellerPlus, action.payload);
    if (response) {
        const { data } = response;
        yield put(setUser({ ...data }));
    }
}

export function* handleGetVerificationRequests(action) {
    const response = yield call(requestGetVerificationRequests, action.payload);
    if (response) {
        const { data } = response;
        yield put(setVerificationRequests([...data]));
    }
}

export function* handleGetUserNotifications(action) {
    try {
        const response = yield call(requestGetUserNotifications, action.payload);
        const { data } = response;
        yield put(setUserNotifications([...data.data]));
    } catch (error) {
        console.log(error);
    }
}

export function* handleLogoutUser(action) {
    const res = yield call(requestLogoutUser, action.payload);
    if (res && res.data && res.data.success) {
        toast.success(res.data.message);
    }
    if (res && res.data && !res.data.success) {
        toast.warn(res.data.message);
    }
    return;
}

export function* handleBlockUser(action) {
    const res = yield call(requestBlockUser, action.payload);
    if (res && res.data) {
        toast.success(resources.userAccess.userSuccessfullyBlocked);
        const { data } = res;
        yield put(setUser({ ...data }));
    }
    return;
}

export function* handleUnblockUser(action) {
    const res = yield call(requestUnblockUser, action.payload);
    if (res && res.data) {
        toast.success(resources.userAccess.userSuccessfullyUnblocked);
        const { data } = res;
        yield put(setUser({ ...data }));
    }
    return;
}

export function* handleSetBetaUser(action) {
    const response = yield call(requestSetBetaUser, action.payload);
    if (response) {
        const { data } = response;
        yield put(setUser({ ...data }));
    }
}

export function* handleSendUserSystemMessage(action) {
    const response = yield call(requestSendUserSystemMessage, action.payload);
    if (response) {
        const { data } = response;
        yield put(setUserNotification({ ...data }));
    }
}

export function* handleAddTokensToUser(action) {
    try {
        const initRes = yield call(requestAddTokensToUser, action.payload);
        if (initRes) {
            yield put(getUser(action.payload.userid));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* handleCorrectUserTokens(action) {
    try {
        const initRes = yield call(requestCorrectUserTokens, action.payload);
        if (initRes) {
            yield put(getUser(action.payload.userid));
        }
    } catch (error) {
        console.log(error);
    }
}
