const shallowObjectsCompare = (obj1, obj2) => {
    let diff = Object.keys(obj2).reduce((diff, key) => {
        if (obj1[key] === obj2[key]) return diff;
        return {
            ...diff,
            [key]: obj2[key],
        };
    }, {});
    return diff;
};
export default shallowObjectsCompare;

export const removeEmptyValues = (obj) => {
    return Object.keys(obj)
        .filter(function (k) {
            return obj[k] != null;
        })
        .reduce(function (acc, k) {
            acc[k] = obj[k];
            return acc;
        }, {});
};
export function areEqual() {
    if (!arguments) return;
    let toCompareArr = Object.values(arguments);
    for (let i = 0; i < toCompareArr.length; i++) {
        for (let j = 0; j < toCompareArr.length; j++) {
            if (JSON.stringify(toCompareArr[j]) !== JSON.stringify(toCompareArr[i])) return false;
        }
    }

    return true;
}
