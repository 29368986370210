import React from "react";
import { useSelector } from "react-redux";

import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import resources from "../../core/Localization/Localization";
import { mapInquiryToUI } from "../../common/sellerInquiryTabs";
import { Divider, Grid } from "@material-ui/core";
//styles
import useStyles from "../../assets/jss/material-dashboard-react/views/userInquiryStyle";

const UserInquiry = () => {
    const classes = useStyles();
    const inquiry = useSelector((x) => x.userDetails.user?.seller_inquiry);

    const columns = React.useMemo(() => {
        if (inquiry && Object.values(inquiry).length) return mapInquiryToUI(inquiry);
        return [];
    }, [resources, inquiry]);

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="info">
                <h4>{resources.userInquiry.inquiry}</h4>
            </CardHeader>
            <CardBody>
                {columns.map((data, index) => (
                    <div key={data.field} className={classes.wrapper}>
                        <Grid container direction="row" wrap="nowrap" justifyContent="center" alignItems="center" className={classes.data}>
                            <Grid item className={classes.dataTitle}>
                                {data.label}
                            </Grid>
                            <Grid item className={classes.dataBody}>
                                {data.value}
                            </Grid>
                        </Grid>
                        <Divider />
                    </div>
                ))}
            </CardBody>
        </Card>
    );
};

export default UserInquiry;
