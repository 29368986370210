import resources from "../core/Localization/Localization";
import moment from "moment";
import { divBy100, roundNumberToTwoDec } from "../utils/calculations";

export const REQUEST_OFFERS_HEADERS = () => [
    resources.partOffers.datagrid.id,
    resources.partOffers.datagrid.description,
    resources.partOffers.datagrid.price,
    resources.partOffers.datagrid.type,
    resources.partOffers.datagrid.status,
    resources.partOffers.datagrid.username,
    resources.partOffers.datagrid.created,
];

export const mapOffersInTableData = (offers) => {
    if (!offers) {
        return [];
    }
    const offersData = [...offers];
    offersData.sort((x, y) => {
        return x.status === 1 || x.status === 3 ? -1 : y.status === 1 || y.status === 3 ? 1 : 0;
    });

    return offersData.map((x) => {
        return [
            x.part_offer_id.toString(),
            x.description,
            roundNumberToTwoDec(divBy100(x.price)).toString(),
            x.type ? resources.partOffers.types[x.type] : "",
            x.status_label,
            x.User?.username ? x.User.username: "Korisnik obrisan",
            moment(x.createdAt).format("DD/MM/YYYY hh:mm"),
        ];
    });
};

export const colorizeRow = (param) => {
    if (param.includes(resources.partOffers.statuslabel.accepted) || param.includes(resources.partOffers.statuslabel.completed)) return true;
    else return false;
};
