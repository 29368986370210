import { useEffect } from "react";
import useNotificationMessages from "./hooks/useNotificationMessages";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

export const NotificationsProvider = () => {

    const firebaseConfig = {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
    
    const fapp = initializeApp(firebaseConfig);
    getMessaging(fapp);

    const notifications = useNotificationMessages();

    useEffect(() => {
        notifications.initGlobalHandler();
    }, []);

    return null;
}
