import { call, put } from "redux-saga/effects";
import getQueryWithPagination from "../../../utils/getQueryWithPagination";
import { setSelectedUserReviews } from "../../entities/reviews";
import { requestDeleteUserReview, requestGetUserReviewsByType } from "../requests/reviews";
import { setUser } from "../../entities/userDetails";
import { requestGetUser } from "../requests/userDetails";


export function* handleGetUserReviews(action) {
    try {
        const userid = action.payload.userid;
        const props = {
            ...action.payload
        };
        if (props.userid) delete props.userid;
        const response = yield call(requestGetUserReviewsByType, userid, action.payload);
        const { data } = response;
        yield put(setSelectedUserReviews({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handleDeleteUserReview(action) {
    try {
        yield call(requestDeleteUserReview, action.payload);
        const query = getQueryWithPagination({type: action.payload.type });
        const response = yield call(requestGetUserReviewsByType, action.payload.userid, query);
        const user = yield call(requestGetUser, action.payload.userid);
        const { data } = response;
        yield put(setSelectedUserReviews({ ...data }));
        yield put(setUser({ ...user.data }))
    } catch (error) {
        console.log(error);
    }
}
