import { customBodyRender, defaultCellStyle, defaultCustomBodyRender, defaultParentStyle, defaultWrapperStyle } from "./DataGridColumns";
import resources from "../../core/Localization/Localization";
import { timeRangeColumn } from "./customColumns/timeRangeColumn";


export const versionColumns = () => [
    {
        name: "id",
        label: "ID",
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "app_name",
        label: resources.versionList.datagrid.app_name,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "version",
        label: resources.versionList.datagrid.version,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "build_number",
        label: resources.versionList.datagrid.build_number,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "platform",
        label: resources.versionList.datagrid.platform,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "release_type",
        label: resources.versionList.datagrid.release_type,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "release_status",
        label: resources.versionList.datagrid.release_status,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        ...timeRangeColumn(),
    },
];
