import React, {useEffect} from "react";
// @material-ui/core components
import {makeStyles, withStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import avatar from "../../assets/img/faces/marc.jpg";
import Layout from "../../core/Layout";
import {getAcceptedNewOffers} from "../../reduxStore/entities/offers";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from "react-router-dom";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        /* fontSize: 14,*/
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const columns = [
    { id: 'part_offer_id', label: 'ID', minWidth: 100 },
    { id: 'title', label: 'title', minWidth: 100 },
    { id: 'description', label: 'description', minWidth: 170 },
    { id: 'type', label: 'type', minWidth: 170, },
    { id: 'price',label: 'price', minWidth: 170, },

];
function createData(part_offer_id,title, description , type, price) {

    return {part_offer_id,title, description , type, price};
}

const rows = [
    createData(1,'Imam ovaj deo', 'Najbolji za te pare', 'used', '100'),
    createData(2,'Imam ovaj deo', 'Najbolji za te pare', 'used', '100'),
    createData(3,'Imam ovaj deo', 'Najbolji za te pare', 'new', '100'),

];

const useStyles = makeStyles(styles);

export default function AcceptedOffers() {
    let history = useHistory();
    const offers = useSelector((state) => state.offers.acceptedNewOffers);
    const handleTableLoad= (offers)=>
    {
        const preparedData= offers.map(offer=>{
            return createData(offer.part_offer_id,offer.title,offer.description,offer.type,offer.price)
        });
        //console.log(rows);
        // console.log(preparedData);
        return preparedData;

    }
    const dataLoaded = useSelector((state) => state.offers.acceptedDataLoaded);
    const dispatch = useDispatch();
    const [rows,setRows] = React.useState(handleTableLoad(offers));
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    function handleClick(id) {
        history.push("/admin/offers/"+id);
    }


    useEffect(() => {

        const doasync= (async() =>{
            await dispatch(getAcceptedNewOffers());

            //  console.log(users);
        })

        doasync();


    }, [dispatch]);


    useEffect(()=>{

        setRows(handleTableLoad(offers));
    },[offers,dataLoaded])



    return (
        <Layout>
            <div>
                {dataLoaded? <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <StyledTableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                    <StyledTableCell>
                                                        <Button variant="contained" onClick={() =>handleClick(row.part_offer_id)} >Details</Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </GridItem>
                </GridContainer>:<div>Loading...</div>}
            </div>
        </Layout>
    );
}