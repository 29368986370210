import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { eventTopicRredirectRules } from "../variables/eventTopicRredirectRules";

const useNotificationMessages = () => {
    let history = useHistory();

    const initGlobalHandler = () => {
        navigator.serviceWorker.addEventListener("message", (message) => {
            const data = message.data;
            data.notification.payload = data.data;
            toast.success(`${data.notification.title}! ${data.notification.body}`);
            // handle redirects only if chrome notification was clicked
            if (data.messageType === "notification-clicked" && !!data.notification.payload) {
                const event = eventTopicRredirectRules.find((item) => item.topic === data.notification.payload.topic);
                if (event) event.handleRedirect(data.notification.payload, history);
            }
            publish(data.notification);
        });
    };

    /**
     *
     * @param {
     *  title: string,
     *  body:string,
     *  payload: {
     *      id: string,
     *      topic: string
     *  }} event
     */
    const publish = (event) => {
        console.log("PUBLISHED EVENT => ", event);
        window.postMessage(event);
    };

    const subscribe = (topic, handler) => {
        const messageEventHandler = (event) => { 
            subscriptionHandler(event, topic, handler);
        };

        const attachEventListener = () => {
            window.addEventListener("message", messageEventHandler);
        };
        const detachEventListener = () => {
            window.removeEventListener("message", messageEventHandler);
        };

        attachEventListener();
        return { unsubscribe: detachEventListener };
    };

    const subscriptionHandler = (event, topic, handler) => {
        if (!event.data || !event.data.payload) {
            return;
        }
        if (event.data.payload.topic === topic) {
            handler(event.data);
        }
    };

    return { initGlobalHandler, subscribe, publish };
};

export default useNotificationMessages;
