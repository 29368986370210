import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-evenly",
        overflow: "hidden",
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
    imageList: {
        display: "grid",
        gridGap: "10px",
        margin: "0 auto",
        transform: "translateZ(0)",
        [theme.breakpoints.down("xl")]: { gridTemplateColumns: "repeat(5, 1fr)" },
        [theme.breakpoints.down("lg")]: { gridTemplateColumns: "repeat(4, 1fr)" },
        [theme.breakpoints.down("md")]: { gridTemplateColumns: "repeat(3, 1fr)" },
        [theme.breakpoints.down("sm")]: { gridTemplateColumns: "repeat(2, 1fr)" },
        [theme.breakpoints.down("xs")]: { gridTemplateColumns: "repeat(1, 1fr)" },
    },
}));

export default useStyles;
