import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import CustomPhoneInput from "../../components/CustomPhoneInput/CustomPhoneInput.js";
import CustomSelect from "../../components/CustomSelect/CustomSelect.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Layout from "../../core/Layout";
import resources from "../../core/Localization/Localization.js";
import {UserRoleOptions, OperaterUserRoleOptions} from "../../common/UserRoleOptions";
import { createNewUser } from "../../reduxStore/entities/users.js";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import VersionCreateSchema from "./VersionCreateSchema";
import { getLocalStorage } from "../../auth/helpers.js";
import UserRoles from "../../auth/UserRoles.js";
import {
    appTypesOptions,
    platformTypesOptions,
    releaseStatusTypesOptions,
    releaseTypesOptions
} from "../../common/VersionOptions";
import {createVersion} from "../../reduxStore/entities/versions";

const useStyles = makeStyles();

export default function VersionCreate() {
    const classes = useStyles();
    const user = getLocalStorage("user");
    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(VersionCreateSchema),
    });

    const createNewVersion = (data) => {
        // Form is valid, submit is called
       // data.shop_name = data?.shop_name ? data.shop_name: null;
       // data.phone = data?.phone ? data.phone : null;
        data.description = data?.description ? data.description : "";
        data.supported_store_versions = [];
        dispatch(createVersion(data));
    };

    return (
        <Layout>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                        <Card>
                            <CardHeader color="info">
                                <h4 className={classes.cardTitleWhite}>{resources.createUser.createNewVersion}</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    {/*<GridItem xs={12} sm={12} md={4}>*/}
                                    {/*    <CustomInput*/}
                                    {/*        inputProps={{*/}
                                    {/*            ...register("app_name"),*/}
                                    {/*        }}*/}
                                    {/*        error={!!errors.app_name}*/}
                                    {/*        labelText={resources.createVersion.firstName}*/}
                                    {/*        id="app_name"*/}
                                    {/*        formControlProps={{*/}
                                    {/*            fullWidth: true,*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*    {errors?.app_name?.message && <p>{errors.app_name.message}</p>}*/}
                                    {/*</GridItem>*/}
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomSelect
                                            inputProps={{
                                                ...register("app_name"),
                                            }}
                                            error={!!errors.app_name}
                                            labelText={resources.createVersion.app_name}
                                            id="app_name"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            options={appTypesOptions}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("version"),
                                            }}
                                            error={!!errors.version}
                                            labelText={resources.createVersion.version}
                                            id="version"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.version?.message && <p>{errors.version.message}</p>}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("build_number"),
                                            }}
                                            error={!!errors.build_number}
                                            labelText={resources.createVersion.build_number}
                                            id="build_number"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.build_number?.message && <p>{errors.build_number.message}</p>}
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("commit_ref"),
                                            }}
                                            error={!!errors.commit_ref}
                                            labelText={resources.createVersion.commit_ref}
                                            id="commit_ref"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.commit_ref?.message && <p>{errors.commit_ref.message}</p>}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomSelect
                                            inputProps={{
                                                ...register("release_type"),
                                            }}
                                            error={!!errors.release_type}
                                            labelText={resources.createVersion.release_type}
                                            id="release_type"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            options={releaseTypesOptions}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomSelect
                                            inputProps={{
                                                ...register("platform"),
                                            }}
                                            error={!!errors.platform}
                                            labelText={resources.createVersion.platform}
                                            id="platform"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            options={platformTypesOptions}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("supported_store_versions"),
                                            }}
                                            error={!!errors.supported_store_versions}
                                            labelText={resources.createVersion.supported_store_versions}
                                            id="supported_store_versions"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.supported_store_versions?.message && <p>{errors.supported_store_versions.message}</p>}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomSelect
                                            inputProps={{
                                                ...register("release_status"),
                                            }}
                                            error={!!errors.release_status}
                                            labelText={resources.createVersion.release_status}
                                            id="release_status"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            options={releaseStatusTypesOptions}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <CustomInput
                                            inputProps={{
                                                ...register("description"),
                                            }}
                                            error={!!errors.description}
                                            labelText={resources.createVersion.description}
                                            id="description"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                        />
                                        {errors?.description?.message && <p>{errors.description.message}</p>}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="info" onClick={handleSubmit(createNewVersion)}>
                                    {resources.createVersion.createNewVersion}
                                </Button>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </Layout>
    );
}
