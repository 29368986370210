const createVersion = {
    en: {
        createVersion: {
            id: "ID",
            createdAt: "Created At",
            modifedAt: "Last updated",
            createNewVersion: "Create new Version",
            app_name: "Application name",
            version: "Version",
            build_number: "Build number",
            commit_ref: "Commit reference",
            release_type: "Release type",
            release_status: "Release status",
            platform: "Platform",
            supported_store_versions: "Supported versions",
            description: "Description",

            success: "Version created successfully",

            errors: { firstNameRequired: "Name is required", lastNameRequired: "Last name is required", emailRequired: "Email is required" },
        },
        deleteUser: {
            success: "User deleted successfully",
        },
    },
    rs: {
        createVersion: {
            id: "ID",
            createdAt: "Nalog kreiran",
            modifedAt: "Nalog dopunjen",
            createNewVersion: "Dodaj novu verziju",
            app_name: "Ime aplikacije",
            version: "Verzija",
            build_number: "Build broj",
            commit_ref: "Commit referenca",
            release_type: "Release tip",
            release_status: "Release status",
            platform: "Platforma",
            supported_store_versions: "Podržane verzije",
            description: "Opis",

            success: "Verzija uspešno kreirana",

            errors: { firstNameRequired: "Ime je obavezno", lastNameRequired: "Prezime je obavezno", emailRequired: "Email je obavezan" },
        },
        deleteUser: {
            success: "Korisnik uspešno obrisan",
        },
    },
};

export default createVersion;
