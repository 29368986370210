import { call, put, take } from "redux-saga/effects";
import {getVersionById, updateVersion} from "../requests/versionDetails";
import {setVersion,requestFailed} from "../../entities/versionDetails";




export function* handleUpdateVersion(action) {

    try {
        const response = yield call(updateVersion, action.payload);
        if (response) {
            const { data } = response;
            yield put(setVersion({ ...data }));
        }
    } catch (error) {
        console.log(error);
        yield put(requestFailed());
    }
}
export function* handleGetVersionById(action){

    try {
        const response = yield call(getVersionById, action.payload);
        const { data } = response;
        console.log(data);
        yield put(setVersion({ ...data }));
    } catch (error) {
        console.log(error);
        yield put(requestFailed());
    }
}