import api from "../../../api";
import { toast } from "react-toastify";

export const requestGetPartrequest = (id) => api.get(`/admin/partrequest/${id}`);

export const requestUpdatePartrequest = async (payload) => {
    try {
        return await api.put(`/admin/partrequests/${payload.id}`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};
