import { dangerColor, successColor, grayColor, defaultFont } from "../../material-dashboard-react.js";
const customTextAreaStyle = (theme) => ({
    root: {
        "& label": {
            ...defaultFont,
            color: grayColor[3],
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "1.42857",
            letterSpacing: "unset",
        },
        "& label.Mui-focused": {
            color: "#3F51B5",
        },
    },

    disabled: {
        "&:before": {
            backgroundColor: "transparent !important",
        },
    },
    labelRootError: {
        "& label": {
            color: dangerColor[0],
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: dangerColor[0],
        },
    },
    labelRootSuccess: {
        "& label": {
            color: successColor[0],
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: successColor[0],
        },
    },
    feedback: {
        position: "absolute",
        top: "18px",
        right: "0",
        zIndex: "2",
        display: "block",
        width: "24px",
        height: "24px",
        textAlign: "center",
        pointerEvents: "none",
    },
    marginTop: {
        marginTop: "24px",
    },
    formControl: {
        paddingBottom: "10px",
        margin: "20px 0 0 0",
        position: "relative",
        verticalAlign: "unset",
    },
});

export default customTextAreaStyle;
