/*eslint-disable*/
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import MenuIcon from "@material-ui/icons/Menu";
// core components
import RoleGate from "../../auth/RoleGate";
import resources from "../../core/Localization/Localization.js";

import styles from "../../assets/jss/material-dashboard-react/components/sidebarStyle.js";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { Badge, Box } from "@material-ui/core";

const useStyles = makeStyles(styles);

export default function SidebarAdmin(props) {
    const classes = useStyles();
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1;
    }
    const { color, logo, image, logoText, font } = props;
    const [routes, setRoutes] = useState([...props.routes]);
    const notifications = useNotificationMessages();
    const fontClass = font || "whiteFont";
    useEffect(() => {
        const listeners = [];

        for (let i = 0; i < routes.length; i++) {
            if (!routes[i].badgeTopics || !routes[i].badgeTopics.length) {
                continue;
            }

            for (let j = 0; j < routes[i].badgeTopics.length; j++) {
                listeners.push(
                    notifications.subscribe(routes[i].badgeTopics[j], () => {
                        const newRoutes = [...routes];
                        if (!newRoutes[i].badge) {
                            newRoutes[i].badge = 0;
                        }
                        newRoutes[i].badge++;
                        setRoutes(newRoutes);
                    })
                );
            }

            if (!!routes[i].badgeClearTopic) {
                listeners.push(
                    notifications.subscribe(routes[i].badgeClearTopic, () => {
                        const newRoutes = [...routes];
                        newRoutes[i].badge = 0;
                        setRoutes(newRoutes);
                    })
                );
            }
        }

        return () => {
            for (let i = 0; i < listeners.length; i++) {
                listeners[i].unsubscribe();
            }
        };
    }, []);

    var links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                var activePro = " ";
                var listItemClasses;

                listItemClasses = classNames({
                    [" " + classes[color]]: activeRoute(prop.layout + prop.path),
                });

                const whiteFontClasses = classNames({
                    [" " + classes[fontClass]]: activeRoute(prop.layout + prop.path),
                });
                return (
                    <RoleGate roles={prop.roles} key={prop.name}>
                        <NavLink to={prop.layout + prop.path} className={activePro + classes.item} activeClassName="active" key={key}>
                            <ListItem button className={classes.itemLink + listItemClasses}>
                                {typeof prop.icon === "string" ? (
                                    <Icon className={classNames(classes.itemIcon, whiteFontClasses)}>{prop.icon}</Icon>
                                ) : (
                                    <prop.icon className={classNames(classes.itemIcon, whiteFontClasses)} />
                                )}
                                <Badge color="primary">
                                    <ListItemText
                                        primary={resources.sideBar[prop.name]}
                                        className={classNames(classes.itemText, whiteFontClasses)}
                                        disableTypography={true}
                                    />
                                </Badge>
                            </ListItem>
                        </NavLink>
                    </RoleGate>
                );
            })}
            {props.open ? (
                <Box className={classes.item} onClick={props.handleDrawerToggle}>
                    <ListItem button className={classes.itemLink}>
                        <MenuOpenIcon className={classNames(classes.itemIcon, fontClass)} />
                        <Badge color="primary">
                            <ListItemText primary={resources.sideBar.close} className={classNames(classes.itemText, fontClass)} disableTypography={true} />
                        </Badge>
                    </ListItem>
                </Box>
            ) : (
                <Box className={classes.item} onClick={props.handleDrawerToggle}>
                    <ListItem button className={classes.itemLink}>
                        <MenuIcon className={classNames(classes.itemIcon, fontClass)} />
                        <Badge color="primary">
                            <ListItemText primary={resources.sideBar.open} className={classNames(classes.itemText, fontClass)} disableTypography={true} />
                        </Badge>
                    </ListItem>
                </Box>
            )}
        </List>
    );
    var brand = (
        <div className={classes.logo}>
            <a href="http://localhost" className={classNames(classes.logoLink)} target="_blank">
                <div className={classes.logoImage}>
                    <img src={logo} alt="logo" className={classes.img} />
                </div>
                {logoText}
            </a>
        </div>
    );
    return (
        <div key="left">
            <Drawer
                variant="persistent"
                anchor="left"
                open={props.open}
                transitionDuration={300}
                classes={{
                    paper: classNames(classes.drawerPaper),
                }}
                //onClose={props.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
            >
                {brand}
                <div className={classes.sidebarWrapper}>{links}</div>
                <div className={classes.version}><p>v1.0.6</p></div>
                {image !== undefined ? <div className={classes.background} style={{ backgroundImage: "url(" + image + ")" }} /> : null}
            </Drawer>
        </div>
    );
}

SidebarAdmin.propTypes = {
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
};
