import resources from "../core/Localization/Localization";

export const partOfferStatusOptions = {
    sent: 0,
    accepted: 1,
    declined: 2,
    completed: 3,
};

export const partOfferStatuses = () => {
    return {
        SENT: {
            value: 0,
            status: "sent",
            label: resources.partOffers.statuslabel.sent,
        },
        ACCEPTED: {
            value: 1,
            status: "accepted",
            label: resources.partOffers.statuslabel.accepted,
        },
        DECLINED: {
            value: 2,
            status: "declined",
            label: resources.partOffers.statuslabel.declined,
        },
        COMPLETED: {
            value: 3,
            status: "completed",
            label: resources.partOffers.statuslabel.completed,
        },
    };
};
