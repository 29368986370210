import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addOfferImages, deleteOfferImage, getOfferImages } from "../../reduxStore/entities/offerImages";
//components
import { CircularProgress, Grid } from "@material-ui/core";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import OfferImageList from "../../components/OfferImageList/OfferImageList";
import AddImages from "../../components/AddImages/AddImages";
//common and utils
import resources from "../../core/Localization/Localization";

const OfferImages = () => {
    const dispatch = useDispatch();
    const offer = useSelector((state) => state.offerDetails.offer);
    const images = useSelector((state) => state.offerImages.images);
    const loadData = useSelector((state) => !state.offerImages.dataLoaded);

    const handleDelete = (imageId) => {
        dispatch(deleteOfferImage({ imageId, offerId: offer.part_offer_id }));
    };

    const handleAddImages = (images) => {
        const formData = new FormData();
        formData.append("part_offer_id", offer.part_offer_id);
        images.forEach((file) => formData.append("images", file));

        dispatch(addOfferImages({ offerId: offer.part_offer_id, images: formData }));
    };

    useEffect(() => {
        if (offer.part_offer_id) dispatch(getOfferImages(offer.part_offer_id));
    }, [offer]);

    return (
        <Card className="user-profile-card position-relative">
            <CardHeader color="danger">
                <Grid container justify="flex-start" alignItems="center">
                    <h4>{resources.partOffers.sections.images}</h4>
                    {loadData && <CircularProgress color="inherit" size={24} style={{ marginLeft: 15, position: "relative", zIndex: 9 }} />}
                </Grid>
            </CardHeader>
            <CardBody>
                <OfferImageList images={images} handleDelete={handleDelete} />
            </CardBody>

            <AddImages
                disabled={images.length >= 3}
                disabledText={resources.partOffers.maxImagesMessage}
                limit={3 - images.length}
                handleAddImages={handleAddImages}
            />
        </Card>
    );
};

export default OfferImages;
