import api from "../../../api";


///api/admin/mobile-app-versions:
export const getVersions = () => api.get(`/admin/mobile-app-versions`);

export const createVersion = (data) => api.post(`/admin/mobile-app-versions`, data);


export const getPaginationVersions = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/mobile-app-versions/pagination-versions${query ? query : ""}`);
};


///admin/mobile-app-versions/:app_name/latest
export const getLatestVersionForAppName = (appName) => api.get(`/admin/mobile-app-versions/${appName}/latest`);