import React from "react";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import Table from "../../components/Table/Table.js";

export default function RevisionLogChanges(props) {
    const { historyLog } = props;

    const changes = [...historyLog.details.changes];

    const tableData = changes.map((x) => {
        return [x.path, x.document.lhs, x.document.rhs];
    });

    let mappedData = tableData.filter((x, i) => !!x[1] || !!x[2]);

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="success">
                <h4>Izmene</h4>
            </CardHeader>
            <CardBody className="overflow-auto">
                {/* <ul>
                    {changes.map((x) => {
                        return <li>{x.path}: {x.document.lhs} {'=>'} {x.document.rhs}</li>
                    })}
                </ul> */}

                <Table tableHeaderColor="success" tableHead={["Polje", "Stara vrednost", "Nova vrednost"]} tableData={mappedData} />
            </CardBody>
        </Card>
    );
}
