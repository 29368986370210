import { createSlice } from "@reduxjs/toolkit";

const finance = createSlice({
    name: "finance",
    initialState: {
        transactions: [],
        userTransactions: [],
        count: 0,
        dataLoaded: false,
    },
    reducers: {
        getTransactions() {},
        searchTransactions(state, action) {},
        getUserTransactions() {},
        refundByTransactionId() {},
        setTransactions(state, action) {
            console.log(action);
            return { ...state, transactions: action.payload.data, dataLoaded: true, count: action.payload.count };
        },
        setUserTransactions(state, action) {
            return { ...state, userTransactions: action.payload, dataLoaded: true };
        },
        setDataLoaded(state, action) {
            return { ...state, dataLoaded: action.payload };
        },
    },
});

export const { getTransactions, searchTransactions, getUserTransactions, setTransactions, setUserTransactions, setDataLoaded, refundByTransactionId } =
    finance.actions;

export default finance.reducer;
