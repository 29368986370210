import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import SectionFeatures from "./Sections/SectionFeatures";
import SectionHeader from "./Sections/SectionHeader";


const LandingPage = ({ history }) => {

    return (
        <>
            <SectionHeader/>
            <SectionFeatures/>
        </>
    );
};

export default LandingPage;
