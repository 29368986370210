import api from "../../../api";
import { toast } from "react-toastify";
import { NOTIFICATION_TYPE } from "../../../common/NotificationsTabs";

export const requestGetUser = (id) => api.get(`/admin/user/${id}`);

export const requestDeleteUser = async (id) => {
    try {
        return await api.delete(`/admin/users/${id}`);
    } catch (error) {
        // localization for errors?
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestUpdateUser = async (payload) => {
    try {
        return await api.put(`/admin/users/${payload.userid}`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestSendEmailVerification = async (payload) => {
    try {
        return await api.post(`/admin/send-email-verification`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestSendPhoneVerification = async (payload) => {
    try {
        return await api.post(`/admin/send-phone-verification`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestGetUserCars = (id) => api.get(`/admin/user/${id}/usercars`);

export const requestGetUserFilters = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/get-users-user-filters${query ? query : ""}`);
};

export const requestVerifyAsSeller = async (params) => {
    try {
        return await api.post(`/admin/users/${params.userid}/verify-as-seller`);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestVerifyAsSellerPlus = async (params) => {
    try {
        return await api.put(`/admin/users/${params.userid}/verify-as-seller-plus`);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestGetUserNotifications = (props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/user-system-messages${query ? query : ""}`);
};

export const requestGetVerificationRequests = (id) => api.get(`/admin/users/${id}/verification-requests`);

export const requestLogoutUser = (userid) => api.post(`/admin/logout`, { userid: userid });

export const requestBlockUser = (userid) => api.post(`/admin/block`, { userid: userid });

export const requestUnblockUser = (userid) => api.post(`/admin/unblock`, { userid: userid });

export const requestSetBetaUser = async (payload) => {
    try {
        return await api.put(`/admin/users/${payload.userid}/beta-tester`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestSendUserSystemMessage = async (payload) => {
    try {
        return await api.post(`/admin/send-system-message`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestAddTokensToUser = async (payload) => {
    try {
        return await api.put(`/admin/users/${payload.id}/add-token`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};

export const requestCorrectUserTokens = async (payload) => {
    try {
        return await api.post(`/admin/transactions/correction`, payload);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};
