import resources from "../../core/Localization/Localization";

export const TransactionTypes = { PAYIN: "payin", CHARGE: "charge", RESERVED: "reserved", CORRECTION: "correction" };

export const currencyTypes = {
    RSD: "RSD",
    EUR: "EUR",
};

export const tokenCorrectionTypes = {
    ADD: "add",
    REMOVE: "remove",
};

export const bonusCoefficientsOptions = [
    { value: 0, label: "0%" },
    { value: 5, label: "5%" },
    { value: 10, label: "10%" },
    { value: 15, label: "15%" },
    { value: 20, label: "20%" },
    { value: 25, label: "25%" },
    { value: 30, label: "30%" },
];

export const correctionOptions = () => [
    { value: "add", label: resources.finance.addTokens },
    { value: "remove", label: resources.finance.removeTokens },
];

export const refundedOptions = () => [
    { value: 1, label: resources.form.yes },
    { value: 0, label: resources.form.no },
];
