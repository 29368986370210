import { createMuiTheme } from "@material-ui/core";
import { responsiveFontSizes } from "@material-ui/core/styles";
import { grayColor } from "../../material-dashboard-react";

const defaultTheme = createMuiTheme();

const getMuiTheme = () =>
    createMuiTheme({
        overrides: {
            MUIDataTable: {
                root: {
                    backgroundColor: "#AAF",
                },
                paper: {
                    boxShadow: "none",
                },
            },
            MUIDataTableBodyRow: {
                root: {
                    backgroundColor: "#FFF",
                    cursor: "pointer",
                },
            },
            MuiTableCell: {
                head: {
                    padding: "16px 0px",
                    "&:after": {
                        content: '""',
                        margin: "auto 0",
                        position: "absolute",
                        left: 0,
                        bottom: 0,
                        top: 0,
                        right: 0,
                        //  width: "1em",
                        height: "24px",
                        borderLeft: "1px solid " + grayColor[3],
                        zIndex: -1,
                    },
                    "&:first-child": {
                        "&:after": {
                            borderLeft: "none",
                        },
                    },
                },
            },
            MUIDataTableHeadCell: {
                fixedHeader: {
                    backgroundColor: "#d9d9d9",
                    "&:hover": { backgroundColor: grayColor[3], borderRadius: "2px", color: grayColor[10] },
                },
                data: {
                    fontWeight: "600",
                    paddingLeft: "5px",
                    whiteSpace: "pre",
                },
                toolButton: {
                    "&:hover": { backgroundColor: grayColor[3] },
                },
            },
            MUIDataTablePagination: {
                root: {
                    [defaultTheme.breakpoints.down("sm")]: { display: "flex" },
                    [defaultTheme.breakpoints.down("xs")]: { marginBottom: 50 },
                },
            },
        },
    });
const datatableTheme = responsiveFontSizes(getMuiTheme());
export default datatableTheme;
