import React from "react";
//hooks
import { useSelector, useDispatch } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

// core components
import { updatePaymentSettings } from "../../reduxStore/entities/settings";

import CardBody from "../../components/Card/CardBody.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

//styles
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

//utils
import resources from "../../core/Localization/Localization";
import shallowObjectsCompare from "../../utils/shallowObjectsCompare";
import PaymentSettingsUpdateSchema from "./PaymentSettingsUpdateSchema";
import { isAuth } from "../../auth/helpers";
import { mapPaymentSettingsToUI, mapPaymentSettingsToAPI } from "../../utils/finance_service";

const useStyles = makeStyles(dashboardStyle);

export default function SettingsPayment(props) {
    const { handleSubmit, register, clearErrors, reset } = useForm({
        resolver: yupResolver(PaymentSettingsUpdateSchema),
    });
    const operator = isAuth();
    const classes = useStyles();
    const dispatch = useDispatch();

    const paymentSettings = useSelector((state) => state.settings.paymentSettings);

    const [editMode, setEditMode] = React.useState(false);
    const [currentPaymentSettings, setCurrentPaymentSettings] = React.useState(mapPaymentSettingsToUI({ ...paymentSettings }));

    const enableEditMode = () => {
        setEditMode(!editMode);
    };

    const handleUpdate = (data) => {
        let payload = shallowObjectsCompare(currentPaymentSettings, data);
        if (payload.updatedAt) delete payload.updatedAt;
        const updatedData = { ...payload, updatedby: operator.userid, payment_config_id: currentPaymentSettings.payment_config_id };
        dispatch(updatePaymentSettings(mapPaymentSettingsToAPI(updatedData)));
        enableEditMode();
    };

    const handleCancel = () => {
        enableEditMode();
        reset(mapPaymentSettingsToUI(paymentSettings));
        setCurrentPaymentSettings(mapPaymentSettingsToUI({ ...paymentSettings }));
        clearErrors();
    };

    React.useEffect(() => {
        reset(mapPaymentSettingsToUI(paymentSettings));
        setCurrentPaymentSettings(mapPaymentSettingsToUI(paymentSettings));
    }, [paymentSettings]);

    return (
        <>
            {editMode ? (
                <Box className={classes.absoluteContainer}>
                    <Button
                        type={"button"}
                        color={"info"}
                        size={"sm"}
                        className={classes.buttonSeparator}
                        onClick={handleSubmit(
                            (data) => {
                                handleUpdate(data);
                            },
                            (data) => console.log(data)
                        )}
                    >
                        {resources.form.save}
                    </Button>
                    <Button type={"button"} color={"danger"} size={"sm"} onClick={handleCancel}>
                        {resources.form.cancel}
                    </Button>
                </Box>
            ) : (
                <Button type={"button"} color={"info"} size={"sm"} className={classes.absoluteContainer} onClick={enableEditMode}>
                    {resources.form.edit}
                </Button>
            )}
            {paymentSettings.payment_config_id ? (
                <CardBody profile>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6 className={classes.cardCategory}>{resources.settings.paymentSettings}</h6>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("default_commission", {
                                        value: currentPaymentSettings.default_commission,
                                    }),
                                    disabled: !editMode,
                                    type: "number",
                                }}
                                labelText={resources.settings.defaultCommission + " %"}
                                id="default_commission"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("eur_to_rsd", {
                                        value: currentPaymentSettings.eur_to_rsd,
                                    }),
                                    disabled: !editMode,
                                }}
                                labelText={resources.settings.eurToRsd}
                                id="eur_to_rsd"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("token_to_rsd", {
                                        value: currentPaymentSettings.token_to_rsd,
                                    }),
                                    disabled: !editMode,
                                }}
                                labelText={resources.settings.tokenToRsd}
                                id="token_to_rsd"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                </CardBody>
            ) : null}
        </>
    );
}
