import axios from "axios";
import { getAuthToken, getAuthRefreshToken, isAuth, setAuthToken, signout, getFirebaseToken } from "./auth/helpers";

const tryGetAuthorizationToken = async () => {
    let token = getAuthToken();

    if (!token) {
        const user = isAuth();
        try {
            const newToken = await axios.request({
                method: "post",
                url: `${process.env.REACT_APP_API}/token`,
                data: {
                    refreshToken: getAuthRefreshToken(),
                    // userid: user.userid,
                },
            });
            token = newToken.data.token;
            setAuthToken(token);
        } catch (err) {
            signout(() => {
                window.location.href = "/signin";
            });
        }
    }
    return token;
};

const tryGetFirebaseToken = async () => {
    let firebase_token = getFirebaseToken();
    if (!firebase_token) {
        signout(() => {
            window.location.href = "/signin";
        });
        return false;
    }
    return firebase_token;
};

const tryGetRefreshToken = async () => {
    let auth_refresh_token = getAuthRefreshToken();
    if (!auth_refresh_token) {
        signout(() => {
            window.location.href = "/signin";
        });
        return false;
    }
    return auth_refresh_token;
};

const executeRequest = async (method, url, headers, data) => {
    const token = await tryGetAuthorizationToken();
    if (!tryGetFirebaseToken() || !tryGetRefreshToken()) {
        return;
    }
    method = method || "get";
    const options = {
        method: method,
        url: `${process.env.REACT_APP_API}${url}`,
        headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            "x-ignore-maintenance": true,
        },
    };

    if (data && ["post", "put", "patch"].indexOf(method.toLowerCase() !== -1)) {
        options.data = data;
    }

    return axios.request(options);
};

const getRequest = (url, headers) => {
    return executeRequest("get", url, headers);
};

const postRequest = (url, data, headers) => {
    return executeRequest("post", url, headers, data);
};

const putRequest = (url, data, headers) => {
    return executeRequest("put", url, headers, data);
};

const patchRequest = (url, data, headers) => {
    return executeRequest("patch", url, headers, data);
};

const deleteRequest = (url, headers) => {
    return executeRequest("delete", url, headers);
};

export default {
    get: getRequest,
    post: postRequest,
    put: putRequest,
    patch: patchRequest,
    delete: deleteRequest,
};
