import { createSlice } from "@reduxjs/toolkit";

const cars = createSlice({
    name: "cars",
    initialState: {
        cars: [],
        dataLoaded: false,
    },
    reducers: {
        getCars(state, action) {
            return { ...state, dataLoaded: false };
        },
        setCars(state, action) {
            const carData = Object.values(action.payload);
            return { ...state, cars: carData, dataLoaded: true };
        },
        getPaginationCars(state, action) {
            return { ...state, dataLoaded: false };
        },
        setPaginationCars(state, action) {
            return {
                ...state,
                cars: action.payload.data,
                count: action.payload.count,
                dataLoaded: true,
            };
        },
    },
});

export const { getCars, setCars, getPaginationCars, setPaginationCars } = cars.actions;

export default cars.reducer;
