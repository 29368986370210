import axios from "axios";

export function requestGetDashboard() {
    return axios.request({
        method: "get",
        url: `${process.env.REACT_APP_API}/admin/dashboard`,
        headers: {
            "x-ignore-maintenance": true,
        },
    });
}
