import { createSlice } from "@reduxjs/toolkit";
import { mapUserFinanceProps } from "../../utils/finance_service";

const userDetails = createSlice({
    name: "userDetails",
    initialState: {
        user: {},
        dataLoaded: true,
    },
    reducers: {
        getUser(state) {
            return { ...state, dataLoaded: false };
        },
        setUser(state, action) {
            const payload = action.payload;
            let userData = mapUserFinanceProps(payload);
            return { ...state, user: userData, dataLoaded: true };
        },
        deleteUser() {},
        updateUser(state) {
            return { ...state, dataLoaded: false };
        },
        sendEmailVerification() {},
        sendPhoneVerification() {},
        getUserCars() {},
        getUserFilters() {},
        getVerificationRequests() {},
        getUserNotifications() {},
        verifyAsSeller() {},
        verifyAsSellerPlus() {},
        setUserCars(state, action) {
            const userCars = action.payload;
            return { ...state, userCars: userCars };
        },
        setUserFilters(state, action) {
            const userFilters = action.payload;
            return { ...state, userFilters: userFilters };
        },
        setVerificationRequests(state, action) {
            const vRequests = action.payload;
            return { ...state, verificationRequests: vRequests };
        },
        setVerificationRequest(state, action) {
            const vr = action.payload;
            if (!vr) {
                return { ...state };
            }
            const vReqs = [...(state.verificationRequests || []), vr];
            return { ...state, verificationRequests: vReqs };
        },
        setUserNotifications(state, action) {
            const userNotifications = action.payload;
            return { ...state, userNotifications: userNotifications };
        },
        setUserNotification(state, action) {
            const notification = action.payload;
            if (!notification) {
                return { ...state };
            }
            const uNotifications = [...(state.userNotifications || []), notification];
            return { ...state, userNotifications: uNotifications };
        },
        logoutUser() {},
        blockUser() {},
        userUnblock() {},
        updateBetaUser() {},
        sendUserSystemMessage() {},
        addTokensToUser() {},
        correctUserTokens() {},
        requestFailed(state) {
            return { ...state, dataLoaded: true };
        },
    },
});

export const {
    getUser,
    setUser,
    deleteUser,
    updateUser,
    getUserCars,
    getUserFilters,
    verifyAsSeller,
    verifyAsSellerPlus,
    getUserNotifications,
    setUserCars,
    setUserFilters,
    sendEmailVerification,
    sendPhoneVerification,
    getVerificationRequests,
    setVerificationRequests,
    setVerificationRequest,
    setUserNotifications,
    setUserNotification,
    logoutUser,
    blockUser,
    userUnblock,
    updateBetaUser,
    sendUserSystemMessage,
    addTokensToUser,
    correctUserTokens,
    requestFailed,
} = userDetails.actions;

export default userDetails.reducer;
