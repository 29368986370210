
export const handleTableLoad = (versions) => {

    const preparedData = versions.map((version) => {
        return {
            id: version.mobile_app_version_id,
            app_name: version.app_name,
            version: version.version,
            build_number:version.build_number,
            platform: version.platform,
            release_type: version.release_type,
            release_status: version.release_status,
            createdAt: version.createdAt,
        };
    });
    return preparedData;
};
