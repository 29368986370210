import * as Yup from "yup";
import resources from "../../core/Localization/Localization.js";

const UserCreateSchema = Yup.object().shape({
    firstname: Yup.string().required(resources.form.errors.firstname),
    lastname: Yup.string().required(resources.form.errors.lastname),
    email: Yup.string().required(resources.form.errors.email),
    shop_name:Yup.string().optional(),
    password: Yup.string().required(resources.form.errors.password).min(6, resources.form.errors.passwordMin).max(40, resources.form.errors.passwordMax),
    repeatPassword: Yup.string()
        .required(resources.form.errors.repeatPassword)
        .oneOf([Yup.ref("password"), null], resources.form.errors.doesntMatchPassword),
    phone: Yup.string().optional(),
    role: Yup.string().required(resources.form.errors.role),
    city: Yup.string().optional(),
});

export default UserCreateSchema;
