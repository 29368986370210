import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// local components
import Layout from "../../core/Layout";
import { getPaginationUsers } from "../../reduxStore/entities/users";

import resources from "../../core/Localization/Localization.js";
import CustomDataGrid from "../../components/CustomDataGrid/CustomDataGrid2";
import { userColumns } from "../../common/DataGridColumns/Users";
import { handleTableLoad } from "../../common/UsersTabs";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { notificationTypes } from "../../variables/notificationTypes";

import filterUserRoles from "../../utils/filterUserRoles";

export default function Users() {
    const history = useHistory();
    const notifications = useNotificationMessages();
    const columns = React.useMemo(userColumns, [resources]);

    // Store hooks
    const users = useSelector((state) => state.users.users);
    const count = useSelector((state) => state.users.count);
    const dataLoaded = useSelector((state) => state.users.dataLoaded);
    const dispatch = useDispatch();

    // State hooks
    const [rows, setRows] = React.useState(handleTableLoad(users));
    const [totalRows, setTotalRows] = React.useState(count);
    const [currentFilters, setCurrentFilters] = React.useState(null);

    // React hooks
    useEffect(() => setTotalRows(count), [count]);
    useEffect(() => setRows(handleTableLoad(users)), [users, dataLoaded]);
    useEffect(() => {
        const notificationListener = notifications.subscribe(notificationTypes.userCreated, handler);
        return () => {
            notificationListener.unsubscribe();
        };
    }, [currentFilters]);

    const handler = (message) => {
        currentFilters ? dispatch(getPaginationUsers(currentFilters)) : dispatch(getPaginationUsers());
    };

    // Event handlers
    const onFiltersChanged = (filters) => doSearchUsers(filters);

    const onPageChange = (filters) => {
        doSearchUsers(filters);
    };

    const onRowClick = (rowData, rowMeta) => {
        if (rowMeta && rowMeta.dataIndex >= 0) {
            window.open("/admin/users/" + rows[rowMeta.dataIndex].id, "_blank");
        }
    };

    const onAddButtonClick = () => history.push("/admin/users/create");

    // Private (helper) functions
    const doSearchUsers = (filters) => {
        if (filters?.columnField === "role" && !!filters?.value) {
            filters.value = filterUserRoles(filters.value);
        }
        setCurrentFilters({ ...filters });
        dispatch(getPaginationUsers(filters));
        setRows(handleTableLoad(users));
    };

    return (
        <Layout showAddButton={true} onAdd={() => onAddButtonClick()}>
            <CustomDataGrid
                title={resources.userList.title}
                rows={rows}
                columns={columns}
                totalRows={totalRows}
                loading={!dataLoaded}
                handleRowClick={onRowClick}
                handleFilterChange={onFiltersChanged}
                handlePageChange={onPageChange}
                handleDataInit={doSearchUsers}
                ignoreUrl={false}

            />
        </Layout>
    );
}
