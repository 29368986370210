import { createSlice } from "@reduxjs/toolkit";

const dashboard = createSlice({
    name: "dashboard",
    initialState: {
        dashboard: {},
        dataLoaded: false,
        openDrawer: true,
    },
    reducers: {
        getDashboard() {},
        setDashboard(state, action) {
            const data = action.payload;
            return { ...state, dashboard: data, dataLoaded: true };
        },
        setOpenDrawer(state, action) {
            return { ...state, openDrawer: action.payload };
        },
    },
});

export const { getDashboard, setDashboard, setOpenDrawer } = dashboard.actions;

export default dashboard.reducer;
