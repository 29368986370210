import React from "react";
// @material-ui/core components
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import NotificationModal from "../../components/NotificationModal/NotificationModal";

// core components
import {
    notificationsTabs,
    notificationsColumns,
    mapNotificationsInTableData,
    NOTIFICATION_TYPE,
    initialModalData,
    notificationControl,
} from "../../common/NotificationsTabs.js";
import resources from "../../core/Localization/Localization.js";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getUserNotifications, sendUserSystemMessage } from "../../reduxStore/entities/userDetails.js";
// utils
import getQueryWithPagination from "../../utils/getQueryWithPagination";
import MappedTabs from "../../components/MappedTabs/MappedTabs.js";
import MessageModal from "../../components/MessageModal/MessageModal.js";
import { isAuth } from "../../auth/helpers.js";

export default function UserNotifications() {
    const dispatch = useDispatch();

    const user = useSelector((x) => x.userDetails.user);
    const userNotifications = useSelector((state) => state.userDetails.userNotifications);

    const [tableData, setTableData] = React.useState([]);
    const [openModal, setOpenModal] = React.useState(false);
    const [openMessageModal, setOpenMessageModal] = React.useState(false);
    const [modalData, setModalData] = React.useState(initialModalData);
    const [error, setError] = React.useState("");

    const tabs = React.useMemo(notificationsTabs, [resources]);
    const headers = React.useMemo(notificationsColumns, [resources]);

    const sectionColor = "success";

    const authUser = isAuth();

    React.useEffect(() => {
        dispatch(getUserNotifications(getQueryWithPagination({ userid: user.userid, type: NOTIFICATION_TYPE.push })));
        return () => {};
    }, []);

    React.useEffect(() => {
        setTableData(mapNotificationsInTableData(userNotifications));
    }, [userNotifications]);

    const handleTabChange = (e) => {
        const type = e === 0 ? NOTIFICATION_TYPE.push : NOTIFICATION_TYPE.email;
        dispatch(getUserNotifications(getQueryWithPagination({ userid: user.userid, type: type })));
    };

    const onActionConfirm = (control) => {
        setModalData(control);
        setOpenModal(!openModal);
    };

    const onActionTrigger = (action, title, message) => {
        const type = action.name === "send_push_notification" ? NOTIFICATION_TYPE.push : NOTIFICATION_TYPE.email;
        if (!title || !message) {
            setError(resources.userNotifications.errors.messageValidation);
            return;
        }
        dispatch(sendUserSystemMessage({ userid: user.userid, senderid: authUser.userid, type: type, title: title, message: message }));
        if (openModal) {
            setOpenModal(false);
        }
        setError("");
        setModalData({ ...initialModalData });
    };

    const onRowClick = (params) => {
        if (params.length) {
            setModalData({ ...modalData, title: params[0] || "", text: params[1] || "", createdAt: params[2] || "" });
            setOpenMessageModal(!openMessageModal);
        }
    };

    return (
        <>
            <CustomTabs
                className={"user-profile-card"}
                title={resources.userNotifications.title}
                headerColor={sectionColor}
                onTabChange={handleTabChange}
                tabs={MappedTabs(
                    tabs,
                    { tableHead: headers, tableData, onRowClick },
                    (s, i) => {
                        onActionConfirm(notificationControl[i]);
                    },
                    resources.userNotifications.send_notification,
                    true,
                    sectionColor
                )}
            />
            <MessageModal
                open={openMessageModal}
                handleClose={() => {
                    setOpenMessageModal(!openMessageModal);
                    setModalData(initialModalData);
                }}
                title={modalData.title}
                text={modalData.text}
                createdAt={modalData.createdAt}
                buttonLabel={resources.form.ok}
            />
            <NotificationModal
                open={openModal}
                handleClose={() => {
                    setOpenModal(!openModal);
                    setModalData(initialModalData);
                    setError("");
                }}
                title={resources.userNotifications[modalData.name]}
                text={resources.userNotifications[modalData.text]}
                buttonLabel={resources.userNotifications[modalData.buttonLabel]}
                onConfirmCallback={(titleText, message) => onActionTrigger(modalData, titleText, message)}
                error={error}
            />
        </>
    );
}
