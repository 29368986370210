import { createSlice } from "@reduxjs/toolkit";

const transactionDetails = createSlice({
    name: "transactionDetails",
    initialState: {
        transaction: {},
        dataLoaded: false,
    },
    reducers: {
        getTransaction() {},
        setTransaction(state, action) {
            const data = action.payload;
            return { ...state, transaction: data, dataLoaded: true };
        },
    },
});

export const { getTransaction, setTransaction } = transactionDetails.actions;

export default transactionDetails.reducer;
