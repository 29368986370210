import React, { useEffect } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getUser, setUser } from "../../reduxStore/entities/userDetails";

import UserRoles from "../../auth/UserRoles";
import useNotificationMessages from "../../hooks/useNotificationMessages";
import { notificationTypes } from "../../variables/notificationTypes";

import Layout from "../../core/Layout";
import AdminDetails from "./UserDetailsRender/AdminDetails";
import BuyerDetails from "./UserDetailsRender/BuyerDetails";
import SellerDetails from "./UserDetailsRender/SellerDetails";
import OperatorDetails from "./UserDetailsRender/OperatorDetails";

export default function UserDetails(props) {
    const id = props.match.params.id;
    const user = useSelector((state) => state.userDetails.user);

    const dispatch = useDispatch();
    const notifications = useNotificationMessages();

    const userDetailRender = (user) => {
        switch (user.role) {
            case UserRoles.ADMIN:
                return <AdminDetails />;
            case UserRoles.OPERATER:
                return <OperatorDetails />;
            case UserRoles.SELLER:
                return <SellerDetails />;
            case UserRoles.SELLER_PLUS:
                return <SellerDetails />;           
            case UserRoles.USER:
                return <BuyerDetails />;
            default:
                return null;
        }
    };

    useEffect(() => {
        dispatch(getUser(id));

        return () => {
            dispatch(setUser(null));
        };
    }, []);

    return <Layout>{user && user.userid ? userDetailRender(user) : null}</Layout>;
}
