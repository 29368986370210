import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTransaction } from "../../reduxStore/entities/transactionDetails";

import { Responsive, WidthProvider } from "react-grid-layout";
import Layout from "../../core/Layout";
import VersionBasicInfo from "./VersionBasicInfo";
import useLocalStorage from "../../hooks/useLocalStorage";
import { TRANSACTION_DETAILS_LAYOUT_KEY, defaultLayout } from "../../common/TransactionDetailsLayout";
import {getVersion, setVersion} from "../../reduxStore/entities/versionDetails";
import {setUser} from "../../reduxStore/entities/userDetails";


const ResponsiveGridLayout = WidthProvider(Responsive);

const VersionDetails = (props) => {
    const dispatch = useDispatch();
    const id = props.match.params.id;

    const [lsLayout, setLsLayout] = useLocalStorage(TRANSACTION_DETAILS_LAYOUT_KEY, defaultLayout);

    React.useEffect(() => {
        dispatch(getVersion(id));
        return () => {
            dispatch(setVersion(null));
        };
    }, []);

    return (
        <Layout>
            <ResponsiveGridLayout
                className="layout"
                cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                rowHeight={30}
                layouts={lsLayout}
                onLayoutChange={(layout, layouts) => {
                    setLsLayout(layouts);
                }}
            >
                <div key="1">
                    <VersionBasicInfo id={id}/>
                </div>
                {/* <div key="2">
                    <TransactionHistory />
                </div> */}
            </ResponsiveGridLayout>
        </Layout>
    );
};

export default VersionDetails;
