import React from "react";
//redux
import { useSelector } from "react-redux";

import UserBasicInfo from "../UserBasicInfo";
import UserVehicles from "../UserVehicles";
import UserRequests from "../UserRequests";
import UserSettings from "../UserSettings.js";
import UserNotifications from "../UserNotifications.js";
import UserHistory from "../UserHistory.js";
import UserStatistics from "../UserStatistics";

import { Responsive, WidthProvider } from "react-grid-layout";
import useLocalStorage from "../../../hooks/useLocalStorage.js";
import { USER_DETAILS_LAYOUT_KEY, userDetailsLayout } from "../../../common/UserDetailsLayout";
import UserReviews from "../UserReviews";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function BuyerDetails() {
    const user = useSelector((state) => state.userDetails.user);

    const [lsLayout, setLsLayout] = useLocalStorage(USER_DETAILS_LAYOUT_KEY, userDetailsLayout);

    return (
        <>
            {user && user.userid && (
                <ResponsiveGridLayout
                    className="layout"
                    cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                    rowHeight={30}
                    layouts={lsLayout}
                    onLayoutChange={(layout, layouts) => {
                        setLsLayout(layouts);
                    }}
                >
                    <div key="1">
                        <UserBasicInfo />
                    </div>
                    <div key="2">
                        <UserNotifications />
                    </div>
                    <div key="3">
                        <UserHistory />
                    </div>

                    <div key="4">
                        <UserSettings />
                    </div>

                    <div key="5">
                        <UserVehicles />
                    </div>

                    <div key="6">
                        <UserRequests />
                    </div>
                    <div key="7">
                        <UserStatistics />
                    </div>
                    <div key="8">
                        <UserReviews />
                    </div>
                </ResponsiveGridLayout>
            )}
        </>
    );
}
