import React from "react";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import { CloseOutlined, SkipNext, SkipPrevious } from "@material-ui/icons";
import resources from "../../core/Localization/Localization";
import CustomModal from "../CustomModal/CustomModal";
import useStyles from "../../assets/jss/material-dashboard-react/components/modalGalleryStyle";

const ModalGallery = ({ open, setOpen, images, index }) => {
    const classes = useStyles();
    const [active, setActive] = React.useState(0);

    const onNextHandler = () => {
        if (images && active === images.length - 1) setActive(0);
        else {
            setActive(active + 1);
        }
    };
    const onPrevoiusHandler = () => {
        if (images && active === 0) setActive(images.length - 1);
        else {
            setActive(active - 1);
        }
    };

    React.useEffect(() => {
        if (typeof index === "number") setActive(index);
    }, [index]);

    return (
        <CustomModal
            open={open}
            handleClose={(e) => {
                setOpen(false);
            }}
        >
            <Box className={classes.root}>
                <img src={images[active]} alt={""} className={classes.image} />
                <Tooltip title={resources.form.close}>
                    <IconButton size="medium" variant="contained" color="secondary" className={classes.closeButton} onClick={(e) => setOpen(false)}>
                        <CloseOutlined />
                    </IconButton>
                </Tooltip>
                <Tooltip title={resources.form.previous}>
                    <IconButton size="medium" variant="contained" color="secondary" className={classes.previousButton} onClick={onPrevoiusHandler}>
                        <SkipPrevious />
                    </IconButton>
                </Tooltip>
                <Tooltip title={resources.form.next}>
                    <IconButton size="medium" variant="contained" color="secondary" className={classes.nextButton} onClick={onNextHandler}>
                        <SkipNext />
                    </IconButton>
                </Tooltip>
            </Box>
        </CustomModal>
    );
};

export default ModalGallery;
