import UserRoles from "../auth/UserRoles";
import resources from "../core/Localization/Localization.js";

export const UserRoleOptions = [
    { value: UserRoles.ADMIN, label: resources.userRoles.admin },
    { value: UserRoles.OPERATER, label: resources.userRoles.operater },
    { value: UserRoles.SELLER, label: resources.userRoles.seller },
    { value: UserRoles.USER, label: resources.userRoles.customer },
];

export const OperaterUserRoleOptions = [
    { value: UserRoles.SELLER, label: resources.userRoles.seller },
    { value: UserRoles.USER, label: resources.userRoles.customer },
];

