import React, { useEffect, useState } from "react";

import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { useDispatch, useSelector } from "react-redux";
import { getUserHistory } from "../../reduxStore/entities/historylogs.js";
import resources from "../../core/Localization/Localization.js";
import moment from "moment";
import { defaultTableSize } from "../../common/UserDetailsLayout";
import { historyColumns } from "../../common/HistoryTabs.js";
import { useHistory } from "react-router-dom";

export default function UserHistory() {
    const history = useHistory();

    const user = useSelector((x) => x.userDetails.user);
    const userHistory = useSelector((x) => x.historyLogs.userHistory);
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState([]);

    const columns = React.useMemo(historyColumns, [resources]);

    useEffect(() => {
        dispatch(getUserHistory(user.userid));
    }, [user]);

    useEffect(() => {
        const uh = [...userHistory];
        if (uh && uh.length > 1) {
            uh.sort((x, y) => {
                if (x.createdAt > y.createdAt) {
                    return -1;
                }
                if (x.createdAt < y.createdAt) {
                    return 1;
                }
                return 0;
            });
        }
        setTableData(
            uh.map((x) => {
                return [
                    x.history_log_id + "",
                    resources.historyLogs[x.model.toLowerCase()][x.action.toLowerCase()],
                    resources.historyLogs.type[x.type.toLowerCase()],
                    moment(x.createdAt).format("DD/MM/YYYY hh:mm:ss"),
                ];
            })
        );
    }, [userHistory]);

    const onRowClick = (params) => {
        if (params.length) {
            history.push("/admin/history-logs/" + params[0]);
        }
    };

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="primary">
                <h4>{resources.historyLogs.header}</h4>
            </CardHeader>
            <CardBody>
                <Table
                    tableHeaderColor="primary"
                    tableHead={columns}
                    tableData={tableData}
                    handleClick={onRowClick}
                    paginationInfo={{
                        pageSize: defaultTableSize,
                        pageSizeOptions: [defaultTableSize],
                    }}
                />
            </CardBody>
        </Card>
    );
}
