import React from "react";
//redux
import { useSelector } from "react-redux";

import UserBasicInfo from "./../UserBasicInfo";
import UserNotifications from "./../UserNotifications.js";
import UserHistory from "./../UserHistory.js";

import { Responsive, WidthProvider } from "react-grid-layout";
import useLocalStorage from "../../../hooks/useLocalStorage.js";
import { ADMIN_DETAILS_LAYOUT_KEY, adminDetailsLayout } from "../../../common/UserDetailsLayout";

const ResponsiveGridLayout = WidthProvider(Responsive);

export default function AdminDetails() {
    const user = useSelector((state) => state.userDetails.user);

    const [lsLayout, setLsLayout] = useLocalStorage(ADMIN_DETAILS_LAYOUT_KEY, adminDetailsLayout);

    return (
        <>
            {user && user.userid && (
                <ResponsiveGridLayout
                    className="layout"
                    cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
                    rowHeight={30}
                    layouts={lsLayout}
                    onLayoutChange={(layout, layouts) => {
                        setLsLayout(layouts);
                    }}
                >
                    <div key="1">
                        <UserBasicInfo />
                    </div>
                    <div key="2">
                        <UserNotifications />
                    </div>
                    <div key="3">
                        <UserHistory />
                    </div>
                </ResponsiveGridLayout>
            )}
        </>
    );
}
