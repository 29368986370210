import React, {useEffect} from "react";

const QRPartner = () => {

    useEffect(()=>{
        const userAgent = window.navigator.userAgent.toLowerCase();
        if(userAgent.includes('android')){
            window.location.href = 'https://play.google.com/store/apps/details?id=app.smartpart.smartpartsellerapp';
        }else if(userAgent.includes('iphone') || userAgent.includes('ipad')){
            window.location.href = 'https://apps.apple.com/us/app/sp-partner/id1644367909';
        }else{
            window.location.href ='https://smartpart.app/';
        }


    },[]);

    return (
        <div>
            Redirecting...
        </div>
    );

}

export default QRPartner;