export const handleTableLoad = (partrequests) => {
    const preparedData = partrequests.map((partrequest) => {
        return {
            id: partrequest.part_request_id,
            title: partrequest.title,
            make: partrequest.Make?.name ? partrequest.Make.name : "",
            model: partrequest.Model?.name ? partrequest.Model.name : "",
            userid: partrequest.userid,
            username: partrequest.User?.username ? partrequest.User.username : "Korisnik obrisan",
            //TODO partstate:partrequest.partstate_label,
            state: partrequest.state_label,
            states: partrequest.status_label,
            createdAt: partrequest.createdAt,
            offers: partrequest.PartOffers.length,
        };
    });
    return preparedData;
};
