import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import Table from "../../components/Table/Table.js";
import Button from "../../components/CustomButtons/Button";
import { defaultTableSize } from "../../common/RequestDetailsLayout";
import { useSelector } from "react-redux";
import { colorizeRow, mapOffersInTableData, REQUEST_OFFERS_HEADERS } from "../../common/RequestDetailsTabs";
import resources from "../../core/Localization/Localization.js";

export default function RequestOffers(props) {
    const { openOffer } = props;
    const history = useHistory();

    const partrequest = useSelector((s) => s.partrequestDetails.partrequest);

    const [tableData, setTableData] = useState([]);
    const columns = React.useMemo(REQUEST_OFFERS_HEADERS, [resources]);

    useEffect(() => {
        setTableData(mapOffersInTableData(partrequest.PartOffers));
    }, [partrequest.PartOffers]);

    const handleCreateNew = () => {
        history.push("/admin/offers/create", { ...partrequest });
    };

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="warning">
                <h4>{resources.sideBar.offers}</h4>
            </CardHeader>
            <CardBody>
                <Table
                    colorizeRow={colorizeRow}
                    tableRowColor="success"
                    tableHeaderColor="warning"
                    tableHead={columns}
                    tableData={tableData}
                    paginationInfo={{
                        pageSize: defaultTableSize,
                        pageSizeOptions: [defaultTableSize],
                    }}
                    handleClick={openOffer}
                />
                {partrequest.state === 1 ? (
                    <Button color="warning" onClick={handleCreateNew}>
                        {resources.createOffer.createNewOffer}
                    </Button>
                ) : null}
            </CardBody>
        </Card>
    );
}
