import { call, put } from "redux-saga/effects";
import { requestGetFilterUsers, requestSearchUserFilters } from "../requests/filters";
import {
    setFilters, setFilterUsers
} from "../../entities/filters";



export function* handleSearchUserFilters(action) {
    try {
        const response = yield call(requestSearchUserFilters, action.payload);
        const { data } = response;
        yield put(setFilters(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleGetFilterUsers(action) {
    try {
        const response = yield call(requestGetFilterUsers, action.payload);
        const { data } = response;
        yield put(setFilterUsers(data));
    } catch (error) {
        console.log(error);
    }
}