import { call, put, take } from "redux-saga/effects";
import { setUserTransactions, setTransactions } from "../../entities/finance";
import { getPartrequest } from "../../entities/partrequestDetails";
import { requestGetUserTransactions, requestRefundByTransactionId, requestSearchTransactions } from "../requests/finance";

export function* handleGetUserTransactions(action) {
    try {
        const response = yield call(requestGetUserTransactions, action.payload);
        const data = [...response.data.data];
        yield put(setUserTransactions(data));
    } catch (error) {
        console.log(error);
    }
}

export function* handleSearchTransactions(action) {
    try {
        const response = yield call(requestSearchTransactions, action.payload);
        const { data } = response;
        yield put(setTransactions({ ...data }));
    } catch (error) {
        console.log(error);
    }
}

export function* handlerRefundByTransactionId(action) {
    try {
        const response = yield call(requestRefundByTransactionId, action.payload.transactionPayload);
        if (response) {
            yield put(getPartrequest(action.payload.partRequestPayload.id));
        }
    } catch (error) {
        console.log(error);
    }
}
