import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import resources from "../../core/Localization/Localization";
import { Box, Divider, Typography } from "@material-ui/core";
import { toPercentage } from "../../utils/calculations.js";
import { calculateRating, calculateTotalTraded } from "../../utils/calculateStatistics.js";
import UserRoles from "../../auth/UserRoles.js";
import { useEffect } from "react";
import { getUserTransactions } from "../../reduxStore/entities/finance.js";
import getQueryWithPagination from "../../utils/getQueryWithPagination.js";
import { TransactionTypes } from "../../common/transactions/config.js";

export default function UserStatistics() {
    const user = useSelector((s) => s.userDetails.user);
    const userFinance = useSelector((state) => state.finance.userTransactions);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserTransactions(getQueryWithPagination({ userid: user.userid, type: TransactionTypes.CHARGE })));
    }, []);

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="info">
                <h4>{resources.userStatistics.title}</h4>
            </CardHeader>
            <CardBody>
                {user.role === UserRoles.USER ? (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.positiveBuyer}</Typography>
                                <div>{user.positive_rated_as_buyer}</div>
                            </Box>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.negativeBuyer}</Typography>
                                <div>{user.negative_rated_as_buyer}</div>
                            </Box>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.buyerRating}</Typography>
                                <div>{calculateRating(user.positive_rated_as_buyer, user.negative_rated_as_buyer)}</div>
                            </Box>
                            <GridItem xs={12} sm={12} md={12}>
                                <Divider />
                            </GridItem>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.totalFinishedTrades}</Typography>
                                <div>{user.total_finished_trades}</div>
                            </Box>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.totalRequests}</Typography>
                                <div>{user.total_requests}</div>
                            </Box>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.buyerSuccessRating}</Typography>
                                <div>{toPercentage(user.total_finished_trades && user.total_requests ? user.total_finished_trades / user.total_requests : 0)}%</div>
                            </Box>
                        </GridItem>
                    </GridContainer>
                ) : user.role !== UserRoles.ADMIN ? (
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.positiveSeller}</Typography>
                                <div>{user.positive_rated_as_seller}</div>
                            </Box>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.negativeSeller}</Typography>
                                <div>{user.negative_rated_as_seller}</div>
                            </Box>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.sellerRating}</Typography>
                                <div>{calculateRating(user.positive_rated_as_seller, user.negative_rated_as_seller)}</div>
                            </Box>
                            <GridItem xs={12} sm={12} md={12}>
                                <Divider />
                            </GridItem>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.totalWins}</Typography>
                                <div>{user.total_wins}</div>
                            </Box>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.totalOffers}</Typography>
                                <div>{user.total_offers}</div>
                            </Box>
                            <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                <Typography>{resources.userStatistics.sellerSuccessRating}</Typography>
                                <div>{toPercentage(user.total_wins && user.total_offers ? user.total_wins / user.total_offers : 0)}%</div>
                            </Box>
                            {user.role === UserRoles.SELLER ? (
                                <Box className="d-flex align-items-center justify-between pt-3 pl-3 pr-3 pb-3">
                                    <Typography>{resources.userStatistics.totalTraded}</Typography>
                                    <div>{calculateTotalTraded(userFinance)}</div>
                                </Box>
                            ) : null}
                        </GridItem>
                    </GridContainer>
                ) : null}
            </CardBody>
        </Card>
    );
}
