import React from "react";
import { useSelector, useDispatch } from "react-redux";

import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import resources from "../../core/Localization/Localization.js";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

export default function RequestVehicleInfo(props) {
    const partrequest = useSelector((s) => s.partrequestDetails.partrequest);

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="info">
                <h4>{resources.userVehicles.subheader}</h4>
            </CardHeader>
            <CardBody>
                {!!partrequest && partrequest.part_request_id && (
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.make}
                                inputProps={{
                                    value: partrequest.Make?.name ? partrequest.Make.name : "",
                                    disabled: true,
                                }}
                                id="request-make"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.model}
                                inputProps={{
                                    value: partrequest.Model?.name ? partrequest.Model.name : "",
                                    disabled: true,
                                }}
                                id="request-model"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.generation}
                                inputProps={{
                                    value: partrequest.Generation?.name ? partrequest.Generation.name : "",
                                    disabled: true,
                                }}
                                id="request-generation"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={12} lg={6}>
                            <CustomInput
                                labelText={resources.userVehicles.datagrid.motor}
                                inputProps={{
                                    value: partrequest.Motor?.name ? partrequest.Motor.name : "",
                                    disabled: true,
                                }}
                                id="request-motor"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={12} lg={6}>
                            <CustomInput
                                labelText={resources.partRequests.datagrid.vin}
                                inputProps={{
                                    value: partrequest.vin,
                                    disabled: true,
                                }}
                                id="request-vin"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                            <CustomInput
                                labelText={resources.partRequests.datagrid.ownerid}
                                inputProps={{
                                    value: partrequest.userid,
                                    disabled: true,
                                }}
                                id="request-ownerid"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            <Link href={"/admin/users/"+partrequest.userid} target="_blank">
                                <Typography variant="body1">
                                    Profil vlasinka
                                </Typography>
                            </Link>
                        </GridItem>
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                            <CustomInput
                                labelText={resources.partRequests.datagrid.ownername}
                                inputProps={{
                                    value: partrequest.User?.username || "",
                                    disabled: true,
                                }}
                                id="request-ownername"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    </GridContainer>
                )}
            </CardBody>
        </Card>
    );
}
