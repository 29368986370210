import { divBy100, roundNumberToTwoDec } from "./calculations";

export const calculateRating = (x, y) => {
    if (!x && !y) return (0).toFixed(2);
    if (!x && y > 0) return (1).toFixed(2);
    return ((x * 5 + y) / (x + y)).toFixed(2);
};

export const calculateTotalTraded = (transactions) => {
    let total = 0;
    transactions.forEach(t => total += t.amount);
    return roundNumberToTwoDec(divBy100(total), 0);
}