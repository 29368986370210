import { CircularProgress, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import filtersStyle from "../../assets/jss/material-dashboard-react/views/filtersStyle";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import GridContainer from "../../components/Grid/GridContainer";
import Layout from "../../core/Layout";
import resources from "../../core/Localization/Localization";
import { getFilters } from "../../reduxStore/entities/filters";

const filterTypes = {
    MAKE: "make",
    MODEL: "model",
    GENERATION: "generation",
};

const defaultQueryData = {
    offset: 0,
    limit: 500,
};

const useStyles = makeStyles(filtersStyle);

export default function Filters() {
    const dispatch = useDispatch();
    const classes = useStyles();

    // Store hooks
    const filters = useSelector((state) => state.filters.filters);
    const dataLoaded = useSelector((state) => state.filters.dataLoaded);
    const title = resources.userFilters.title;

    // State hooks
    const [selectedMake, setSelectedMake] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);
    const [folder, setFolder] = useState(filterTypes.MAKE);
    const [searchText, setSearchText] = useState("");
    const [timer, setTimer] = useState(null);

    // LifeCycle hooks
    useEffect(() => {
        dispatch(getFilters({ ...defaultQueryData, type: filterTypes.MAKE }));

        return () => {};
    }, []);

    const onFolderNavigationClick = (item) => {
        const queryData = {
            ...defaultQueryData,
        };
        const newType = item.type ? item.type : filterTypes.MAKE;
        if (item.id) queryData.id = item.id;
        if (newType !== filterTypes.MODEL) setSelectedMake(null);

        dispatch(getFilters({ ...queryData, type: newType }));
        setSelectedModel(null);
        setFolder(newType);
        setSearchText("");
    };

    const onItemClick = (item, e) => {
        e.stopPropagation();
        if (folder === filterTypes.GENERATION) return;
        const queryData = {
            ...defaultQueryData,
        };
        queryData.type = folder === filterTypes.MAKE ? filterTypes.MODEL : filterTypes.GENERATION;

        if (folder === filterTypes.MAKE) {
            queryData.type = filterTypes.MODEL;
            queryData.id = item.id_car_make;
            setSelectedMake({ id: item.id_car_make, name: item.name, type: queryData.type });
        } else {
            queryData.type = filterTypes.GENERATION;
            queryData.id = item.id_car_model;
            setSelectedModel({ id: item.id_car_model, name: item.name, type: queryData.type });
        }

        dispatch(getFilters(queryData));
        setFolder(queryData.type);
        setSearchText("");
    };

    const handleChange = (e) => {
        setSearchText(e.target.value);
        const query = {
            ...defaultQueryData,
            name: e.target.value,
        };
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            if (folder === filterTypes.MAKE) {
                dispatch(getFilters({ ...query, type: filterTypes.MAKE }));
            }
            if (folder === filterTypes.MODEL) {
                dispatch(getFilters({ ...query, type: filterTypes.MODEL, id: selectedMake.id }));
            }
            if (folder === filterTypes.GENERATION) {
                dispatch(getFilters({ ...query, type: filterTypes.GENERATION, id: selectedModel.id }));
            }
        }, 500);
        setTimer(newTimer);
    };

    const onSellersClick = (item, e) => {
        e.stopPropagation();
        const id = item[`id_car_${folder}`];
        window.open(`/admin/userfilters/${item.name.toLowerCase()}/users?${folder}=${id}`, "_blank");
    };

    return (
        <Layout>
            <Card>
                <CardHeader color="rose">
                    <div className={classes.folderNavigationWrapper}>
                        <button className={classes.folderNavigationButton} onClick={() => onFolderNavigationClick(title)}>
                            <Typography variant="h6">{title}</Typography>
                        </button>
                        {selectedMake ? (
                            <>
                                <Typography variant="h6" className={classes.divider}>
                                    {"/"}
                                </Typography>
                                <button className={classes.folderNavigationButton} onClick={() => onFolderNavigationClick(selectedMake)}>
                                    <Typography variant="h6">{selectedMake.name}</Typography>
                                </button>
                            </>
                        ) : null}
                        {selectedModel ? (
                            <>
                                <Typography variant="h6" className={classes.divider}>
                                    {"/"}
                                </Typography>
                                <button className={classes.folderNavigationButton} disabled={true}>
                                    <Typography variant="h6">{selectedModel.name}</Typography>
                                </button>
                            </>
                        ) : null}
                    </div>
                </CardHeader>
                <CardBody>
                    <div className={classes.inputContainer}>
                        <input
                            onChange={handleChange}
                            style={{ width: "20%" }}
                            value={searchText}
                            className="form-control"
                            placeholder={resources.tableTextLabels.toolbar.search}
                        />
                    </div>
                    <GridContainer spacing={2}>
                        {dataLoaded && filters ? (
                            filters.map((item, index) => {
                                return (
                                    <Grid item xs={2} sm={2} md={3} key={index}>
                                        <button className={classes.gridItem} onClick={(e) => onItemClick(item, e)}>
                                            <Typography className={classes.itemText}>
                                                {item.name} ({item.count})
                                            </Typography>
                                            {item.users?.length > 0 && (
                                                <a className={classes.sellerCount} onClick={(e) => onSellersClick(item, e)}>
                                                    {item.users.length}
                                                </a>
                                            )}
                                        </button>
                                    </Grid>
                                );
                            })
                        ) : (
                            <CircularProgress color="inherit" size={24} className={classes.loader} />
                        )}
                    </GridContainer>
                </CardBody>
            </Card>
        </Layout>
    );
}
