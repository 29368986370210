import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// local components
import Layout from "../../core/Layout";

import resources from "../../core/Localization/Localization.js";
import CustomDataGrid from "../../components/CustomDataGrid/CustomDataGrid2";
import { historyLogColumns } from "../../common/DataGridColumns/HistoryLogs";
import { searchHistoryLogs } from "../../reduxStore/entities/historylogs";
import mapHistoryLogs from "../../utils/mapHistoryLogs";

export default function HistoryLogs() {
    const history = useHistory();
    const dispatch = useDispatch();
    // Store hooks
    const historyLogs = useSelector((state) => state.historyLogs.historyLogs);
    const count = useSelector((state) => state.historyLogs.count);
    const dataLoaded = useSelector((state) => state.historyLogs.dataLoaded);

    // State hooks
    const [rows, setRows] = React.useState(mapHistoryLogs(historyLogs));
    const [totalRows, setTotalRows] = React.useState(count);

    const columns = React.useMemo(historyLogColumns, [resources]);

    // React hooks
    useEffect(() => setTotalRows(count), [count]);

    useEffect(() => setRows(mapHistoryLogs(historyLogs)), [historyLogs, count]);

    // Event handlers
    const onFiltersChanged = (filters) => doSearchHistoryLogs(filters);

    const onPageChange = (filters) => {
        doSearchHistoryLogs(filters);
        setRows(mapHistoryLogs(historyLogs));
    };

    const onRowClick = (rowData, rowMeta) => {
        if (rowMeta && rowMeta.dataIndex >= 0) {
            window.open("/admin/history-logs/" + rows[rowMeta.dataIndex].id, "_blank");
        }
    };

    // Private (helper) functions
    const doSearchHistoryLogs = (filters) => {
        dispatch(searchHistoryLogs(filters));
    };

    return (
        <Layout>
            <CustomDataGrid
                title={resources.historyLogs.header}
                rows={rows}
                columns={columns}
                totalRows={totalRows}
                loading={!dataLoaded}
                handleRowClick={onRowClick}
                handleFilterChange={onFiltersChanged}
                handlePageChange={onPageChange}
                handleDataInit={doSearchHistoryLogs}
                ignoreUrl={false}
            />
        </Layout>
    );
}
