

const filterUsersTableStyle = {
    usersTable: {
        border: "1px solid #ccc",
        borderRadius: "4px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        overflow: "auto",
        borderCollapse: "collapse",
        width: '100%',
    },
    th: {
        padding: "8px",
        textAlign: "center",
        fontWeight: "bold",
        backgroundColor: "#eee",
    },
    td: {
        padding: "8px",
        textAlign: "center",
        "&:first-child": {
            borderLeft: "1px solid #ccc",
        },
        "&:last-child": {
            borderRight: "1px solid #ccc",
        }
    },

    tr: {
        "&:nth-of-type(even)": {
            backgroundColor: "#f2f2f2"
        },
        "&:hover": {
            backgroundColor: "#ddd",
        }
    }
};

export default filterUsersTableStyle;