import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { Formik, Form, Field } from "formik";
import { LinearProgress } from "@material-ui/core";
import { TextField } from "formik-material-ui";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Layout from "../../core/Layout";
//redux
import { useSelector, useDispatch } from "react-redux";
import { getPartrequest } from "../../reduxStore/entities/partrequestDetails";
import Button from "@material-ui/core/Button";
import { createNewOffer } from "../../reduxStore/entities/offers";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
    createOfferForm: {
        flex: 1,
        align: "center",
        padding: "20px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    },
    formField: {
        margin: "20px",
    },
};
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        /* fontSize: 14,*/
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const columns = [
    { id: "naziv", label: "Naziv", minWidth: 100 },
    { id: "vrednost", label: "Vrednost", minWidth: 100, align: "center" },
];

const columnsOffer = [
    { id: "part_offer_id", label: "ID", minWidth: 100 },
    { id: "title", label: "title", minWidth: 100 },
    { id: "description", label: "description", minWidth: 170 },
    { id: "type", label: "type", minWidth: 170 },
    { id: "price", label: "price", minWidth: 170 },
];

function createData(naziv, vrednost) {
    return { naziv, vrednost };
}
function createDataOffer(part_offer_id, title, description, type, price) {
    return { part_offer_id, title, description, type, price };
}

const makeRows = [
    createData("ID", "1"),
    createData("Ime", "Pera"),
    createData("Prezime", "Peric"),
    createData("email", "pera@peric.com"),
    createData("uloga", "admin"),
    createData("nivo verifikacije", "0"),
];

const useStyles = makeStyles(styles);

export default function RequestCreateOffer(props) {
    let history = useHistory();
    const id = props.match.params.id;

    const partrequest = useSelector((state) => state.partrequestDetails.partrequest);
    console.log(partrequest);
    const classes = useStyles(styles);

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const dataLoaded = useSelector((state) => state.partrequestDetails.dataLoaded);
    const dispatch = useDispatch();
    const [rows, setRows] = React.useState([]);

    const offers = partrequest.PartOffers;
    console.log(offers);
    const [rowsOffers, setRowsOffers] = React.useState([]);

    const populateTable = (partrequest) => {
        const rows = [];
        rows.push(createData("ID", partrequest.part_request_id));
        rows.push(createData("Naslov", partrequest.title));
        rows.push(createData("Opis", partrequest.description));
        rows.push(createData("Marka", partrequest.Make.name));
        rows.push(createData("Model", partrequest.Model.name));
        rows.push(createData("Generacija", partrequest.Generation.name));
        rows.push(createData("Motor", partrequest.Motor.name));
        rows.push(createData("Stanje", partrequest.state));
        rows.push(createData("Podnet", partrequest.createdAt));
        return rows;
    };
    const handleTableLoad = (offers) => {
        const preparedData = offers.map((offer) => {
            return createDataOffer(offer.part_offer_id, offer.title, offer.description, offer.type, offer.price);
        });
        //console.log(rows);
        // console.log(preparedData);
        return preparedData;
    };
    function handleClick(id) {
        history.push("/admin/offers/" + id);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        const doasync = async () => {
            await dispatch(getPartrequest(id));
        };
        doasync();
    }, [dispatch]);

    useEffect(() => {
        console.log(partrequest);
        if (dataLoaded) {
            setRows(populateTable(partrequest));
            setRowsOffers(handleTableLoad(offers));
        }
    }, [partrequest, dataLoaded]);

    return (
        <Layout>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <StyledTableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === "number" ? column.format(value) : value}
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </GridItem>
                </GridContainer>
            </div>
            <div>
                Slanje ponude:
                <Formik
                    initialValues={{
                        title: "novi deo",
                        description: "novi deo iz radnje",
                        price: 0,
                        part_request_id: id,
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.description) {
                            errors.description = "Morate uneti opis";
                        } else if (!values.price) {
                            errors.price = "Morate uneti cenu";
                        }
                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                        setTimeout(() => {
                            setSubmitting(false);
                            // console.log(values);
                            dispatch(createNewOffer(values));
                            // alert(JSON.stringify(values, null, 2));
                        }, 500);
                    }}
                >
                    {({ submitForm, isSubmitting }) => (
                        <GridItem xs={12} sm={12} md={12}>
                            <Paper className={classes.createOfferForm}>
                                <Form>
                                    <Field className={classes.formField} component={TextField} name="description" type="text" label="Opis ponude" multiline />

                                    <Field className={classes.formField} component={TextField} type="number" label="Price" name="price" />

                                    <Button className={classes.formField} variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>
                                        Submit
                                    </Button>
                                    {isSubmitting && <LinearProgress />}
                                </Form>
                            </Paper>
                        </GridItem>
                    )}
                </Formik>
            </div>

            <div>Primljene Ponude:</div>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columnsOffer.map((column) => (
                                                <StyledTableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell> </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowsOffers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columnsOffer.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <StyledTableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === "number" ? column.format(value) : value}
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                    <StyledTableCell>
                                                        <Button variant="contained" onClick={() => handleClick(row.part_offer_id)}>
                                                            Details
                                                        </Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </GridItem>
                </GridContainer>
            </div>
        </Layout>
    );
}
