import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardAvatar from "../../components/Card/CardAvatar.js";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomInput from "../../components/CustomInput/CustomInput";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

import resources from "../../core/Localization/Localization.js";

import moment from "moment";
import { divBy100, roundNumberToTwoDec } from "../../utils/calculations.js";
import { correctionOptions } from "../../common/transactions/config.js";

const useStyles = makeStyles(dashboardStyle);

const TransactionBasicInfo = () => {
    const classes = useStyles();

    const transaction = useSelector((state) => state.transactionDetails.transaction);

    return transaction ? (
        <Card className="user-profile-card">
            <CardAvatar profile>
                <MonetizationOnIcon className={classes.profileIcon} />
            </CardAvatar>
            <CardBody>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h6 className={classes.cardId}>ID: {transaction.transaction_id}</h6>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                            labelText={resources.finance.datagrid.user}
                            inputProps={{
                                value: transaction?.User?.username || "",
                                disabled: true,
                            }}
                            id="transaction-username"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                        <CustomInput
                            labelText={resources.finance.datagrid.type}
                            inputProps={{
                                value: resources.finance.type[transaction.type] || "",
                                disabled: true,
                            }}
                            id="transaction-type"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput
                            labelText={resources.finance.paymentBasis.paymentBasis}
                            inputProps={{
                                value: transaction.payment_basis || "",
                                disabled: true,
                            }}
                            id="transaction-payment-basis"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                        <CustomInput
                            labelText={resources.finance.datagrid.amount}
                            inputProps={{
                                value: transaction.amount ? Math.round(divBy100(transaction.amount)) : "",
                                disabled: true,
                            }}
                            id="transaction-amount"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                        <CustomInput
                            labelText={resources.finance.datagrid.currencyAmount}
                            inputProps={{
                                value: transaction.currency_amount ? roundNumberToTwoDec(divBy100(transaction.currency_amount)) : "",
                                disabled: true,
                            }}
                            id="transaction-currency_amount"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                        <CustomInput
                            labelText={resources.finance.datagrid.currency}
                            inputProps={{
                                value: transaction.currency || "",
                                disabled: true,
                            }}
                            id="transaction-currency"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                        <CustomInput
                            labelText={resources.finance.datagrid.bonusCoefficient}
                            inputProps={{
                                value: transaction.bonus_coefficient ? transaction.bonus_coefficient + "%" : "0 %",
                                disabled: true,
                            }}
                            id="transaction-bonus-coefficient"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                        <CustomInput
                            labelText={resources.finance.datagrid.refunded}
                            inputProps={{
                                value: transaction.refunded ? resources.form.yes : resources.form.no,
                                disabled: true,
                            }}
                            id="transaction-refunded"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={3}>
                        <CustomInput
                            labelText={resources.finance.datagrid.refundedAmount}
                            inputProps={{
                                value: transaction.refunded_amount ? roundNumberToTwoDec(divBy100(transaction.refunded_amount)) : 0,
                                disabled: true,
                            }}
                            id="transaction-refunded_amount"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                    {transaction.correction_type ? (
                        <GridItem xs={12} sm={4} md={3}>
                            <CustomInput
                                labelText={resources.finance.datagrid.correctionType}
                                inputProps={{
                                    value: correctionOptions().filter((x) => x.value === transaction.correction_type)[0]?.label || "",
                                    disabled: true,
                                }}
                                id="transaction-correction_type"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    ) : null}
                    {transaction.correction_reason ? (
                        <GridItem xs={12} sm={4} md={3}>
                            <CustomInput
                                labelText={resources.finance.datagrid.correctionReason}
                                inputProps={{
                                    value: transaction.correction_reason || "",
                                    disabled: true,
                                }}
                                id="transaction-correction_reason"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                    ) : null}
                    <GridItem xs={12} sm={4} md={4}>
                        <CustomInput
                            labelText={resources.finance.datagrid.createdAt}
                            inputProps={{
                                value: transaction.createdAt ? moment(transaction.createdAt).format("DD/MM/YYYY hh:mm") : "",
                                disabled: true,
                            }}
                            id="transaction-createdAt"
                            formControlProps={{
                                fullWidth: true,
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </CardBody>
        </Card>
    ) : null;
};

export default TransactionBasicInfo;
