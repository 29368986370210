import React from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";

function CustomTablePagination(props) {
    const { count, page, rowsPerPage, onPageChange } = props;
    const totalPages = Math.ceil(count / rowsPerPage);
    const [current, setCurrent] = useState({ from: 0, to: 0 });

    useEffect(() => {
        if (!count) return;
        if (!page) {
            setCurrent({ from: 1, to: 5 });
        } else {
            setCurrent({ from: rowsPerPage * page + 1, to: rowsPerPage * (page + 1) });
        }
    }, [page, count]);

    return (
        <div class="MuiTablePagination-root">
            <div class="MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters" style={{ justifyContent: "flex-end" }}>
                <div class="MuiTablePagination-spacer"></div>
                <p class="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit" style={{ marginRight: 10 }}>
                    {current.from}-{current.to} of {count}
                </p>
                <div class="MuiTablePagination-actions">
                    <button
                        disabled={page === 0}
                        class={`MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit ${page === 0 && "Mui-disabled Mui-disabled"}`}
                        onClick={(event) => onPageChange(event, page - 1)}
                    >
                        <span class="MuiIconButton-label">
                            <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
                            </svg>
                        </span>
                    </button>
                    <button
                        disabled={page === totalPages - 1}
                        class={`MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit ${page === totalPages - 1 && "Mui-disabled Mui-disabled"}`}
                        onClick={(event) => onPageChange(event, page + 1)}
                    >
                        <span class="MuiIconButton-label">
                            <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
                            </svg>
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
}

CustomTablePagination.propTypes = {
    count: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
};

export default CustomTablePagination;
