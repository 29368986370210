import resources from "../core/Localization/Localization";

export const sellerInquiryTypes = {
    companyAge: "companyAge",
    guarantee: "guarantee",
    monthlyAmount: "monthlyAmount",
    description: "description",
};

export const sellerInquiryColumns = () => [
    { label: resources.userInquiry.companyAge, field: sellerInquiryTypes.companyAge },
    { label: resources.userInquiry.monthlyAmount, field: sellerInquiryTypes.monthlyAmount },
    { label: resources.userInquiry.guarantee, field: sellerInquiryTypes.guarantee },
    { label: resources.userInquiry.description, field: sellerInquiryTypes.description },
];

export const mapInquiryToUI = (inquiry) => {
    const data = [];
    sellerInquiryColumns().forEach((item, index) => {
        if (inquiry[item.field]) item.value = inquiry[item.field];
        data.push(item);
    });
    return data;
};
