import React from "react";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
// @material-ui/core components
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardAvatar from "../../components/Card/CardAvatar.js";
import CardBody from "../../components/Card/CardBody.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import CustomSelect from "../../components/CustomSelect/CustomSelect.js";
import CustomPhoneInput from "../../components/CustomPhoneInput/CustomPhoneInput.js";
//utils
import resources from "../../core/Localization/Localization.js";
import PersonIcon from "@material-ui/icons/Person";
import UserUpdateSchema from "../UserCreate/UserUpdateSchema.js";
import { updateUser } from "../../reduxStore/entities/userDetails";
import { getCountryList } from "../../reduxStore/entities/country";
import shallowObjectsCompare from "../../utils/shallowObjectsCompare.js";
import UserRoles from "../../auth/UserRoles";
import { toPercentage } from "../../utils/calculations.js";

const useStyles = makeStyles(dashboardStyle);

export default function UserBasicInfo() {
    const {
        handleSubmit,
        control,
        formState: { errors },
        register,
        clearErrors,
        reset,
    } = useForm({
        resolver: yupResolver(UserUpdateSchema),
    });
    const classes = useStyles();
    const dispatch = useDispatch();

    const user = useSelector((x) => x.userDetails.user);
    const countryList = useSelector((state) => state.country.country);
    if (!countryList.length) {
        dispatch(getCountryList());
    }

    const [editMode, setEditMode] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState({ ...user });
    const [country, setCountry] = React.useState(user.country || "");

    const enableEditMode = () => {
        setEditMode((prev) => !prev);
    };

    const handleUpdate = (data) => {
        let payload = shallowObjectsCompare({ ...currentUser }, data);
        if (payload?.seller_commission) {
            payload.seller_commission /= 100;
        }
        const updatedData = { userid: currentUser.userid, ...payload };
        dispatch(updateUser(updatedData));
        setEditMode((prev) => !prev);
    };

    const handleCancel = () => {
        setEditMode((prev) => !prev);
        reset(user);
        setCountry(user.country || "");
        setCurrentUser({ ...user });
        clearErrors();
    };

    const getUserStatus = () => {
        if (user.banned === 1) {
            return resources.userInfo.blocked;
        }
        return resources.userInfo.active;
    };

    const getUserStatusClass = () => {
        if (user.banned === 1) {
            return "info-label warning";
        }
        return "info-label success";
    };

    return (
        user &&
        user.userid && (
            <Card profile className={"user-profile-card"}>
                <CardAvatar profile>
                    <PersonIcon className={classes.profileIcon} />
                </CardAvatar>
                {editMode ? (
                    <Box className={classes.absoluteContainer}>
                        <Button type={"button"} color={"info"} size={"sm"} className={classes.buttonSeparator} onClick={handleSubmit(handleUpdate)}>
                            {resources.form.save}
                        </Button>
                        <Button type={"button"} color={"danger"} size={"sm"} onClick={handleCancel}>
                            {resources.form.cancel}
                        </Button>
                    </Box>
                ) : (
                    <Button type={"button"} color={"info"} size={"sm"} className={classes.absoluteContainer} onClick={enableEditMode}>
                        {resources.form.edit}
                    </Button>
                )}
                <CardBody profile>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <h6 className={classes.cardCategory}>
                                {resources.userRoles[user.role]}
                                <span className={getUserStatusClass()}>{getUserStatus()}</span>
                            </h6>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={3} sm={4} md={1}>
                            <CustomInput
                                inputProps={{
                                    value: user.userid,
                                    disabled: true,
                                }}
                                labelText={resources.createUser.id}
                                id="user-id"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={9} sm={8} md={3}>
                            <CustomInput
                                inputProps={{
                                    ...register("email", {
                                        value: currentUser.email,
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.email}
                                labelText={resources.createUser.email}
                                id="email-address"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.email?.message && <p>{errors.email.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("firstname", {
                                        value: currentUser.firstname,
                                    }),
                                    disabled: !editMode,
                                }}
                                labelText={resources.createUser.firstName}
                                id="first-name"
                                error={!!errors.firstname}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.firstname?.message && <p>{errors.firstname.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("lastname", {
                                        value: currentUser.lastname,
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.lastname}
                                labelText={resources.createUser.lastName}
                                id="lastname"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.lastname?.message && <p>{errors.lastname.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("shop_name", {
                                        value: currentUser.shop_name,
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.shop_name}
                                labelText={resources.createUser.shop_name}
                                id="shop_name"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                            {errors?.shop_name?.message && <p>{errors.shop_name.message}</p>}
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: moment(user.createdAt).format("DD/MM/YYYY hh:mm"),
                                    disabled: true,
                                }}
                                labelText={resources.createUser.createdAt}
                                id="created-at"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={6} md={4}>
                            <CustomInput
                                inputProps={{
                                    value: moment(user.updatedAt).format("DD/MM/YYYY hh:mm"),
                                    disabled: true,
                                }}
                                labelText={resources.createUser.modifedAt}
                                id="updated-at"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Controller
                                control={control}
                                name="phone"
                                defaultValue=""
                                inputRef={register("phone", {
                                    value: currentUser.phone || "",
                                })}
                                render={(props) => (
                                    <CustomPhoneInput
                                        inputProps={{
                                            type: "phone",
                                            disabled: !editMode,
                                        }}
                                        value={props.field.value}
                                        onChange={(x) => {
                                            props.field.onChange(x);
                                        }}
                                        labelText={resources.createUser.phone}
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                )}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("address", {
                                        value: currentUser.address || "",
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.address}
                                labelText={resources.createUser.address}
                                id="address"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                                inputProps={{
                                    ...register("city", {
                                        value: currentUser.city || "",
                                    }),
                                    disabled: !editMode,
                                }}
                                error={!!errors.city}
                                labelText={resources.createUser.city}
                                id="city"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            {countryList && !!countryList.length && (
                                <CustomSelect
                                    inputProps={{
                                        ...register("country", {
                                            value: currentUser.country || "",
                                        }),
                                        value: country,
                                        disabled: !editMode,
                                        onChange: (event) => {
                                            setCountry(event.target.value);
                                        },
                                    }}
                                    error={!!errors.country}
                                    labelText={resources.createUser.country}
                                    id="country"
                                    formControlProps={{
                                        fullWidth: true,
                                    }}
                                    options={countryList}
                                />
                            )}
                        </GridItem>
                        {user.role === UserRoles.SELLER ? (

                                <GridItem xs={12} sm={12} md={4}>
                                    <CustomInput
                                        inputProps={{
                                            ...register("seller_commission", {
                                                value: currentUser.seller_commission ? toPercentage(currentUser.seller_commission, 0) : "",
                                            }),
                                            disabled: !editMode,
                                        }}
                                        labelText={resources.createUser.sellerCommission + "  %"}
                                        id="seller_commission"
                                        error={!!errors.seller_commission}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                </GridItem>

                        ) : null}
                    </GridContainer>

                </CardBody>
            </Card>
        )
    );
}
