const getOfferStatusClass = (status) => {
  switch (status) {
    case 0:
      return "info-label warning";
    case 1:
      return "info-label success";
    case 2:
      return "info-label danger";

    default:
      return "info-label success";
  }
};

export default getOfferStatusClass;
