import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import * as XLSX from "xlsx";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/Card/Card";
import CardBody from "../../components/Card/CardBody";
import CardHeader from "../../components/Card/CardHeader";
import Layout from "../../core/Layout";
import { getFilterUsers } from "../../reduxStore/entities/filters";
import FilterUsersTable from "./FilterUsersTable";
import filtersStyle from "../../assets/jss/material-dashboard-react/views/filtersStyle";

const useStyle = makeStyles(filtersStyle);

const FilterUsers = (props) => {
    const dispatch = useDispatch();
    const classes = useStyle();

    const [key, value] = props.location.search.substring(1).split("=");
    const title = props.match.params.name;

    // Store hooks
    const users = useSelector((state) => state.filters.users);
    const dataLoaded = useSelector((state) => state.filters.dataLoaded);

    // Lifecycle hooks
    useEffect(() => {
        dispatch(
            getFilterUsers({
                type: key,
                id: +value,
            })
        );

        return () => {};
    }, []);

    const handleExport = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(users);
        XLSX.utils.book_append_sheet(wb, ws, title);
        XLSX.writeFile(wb, `Prodavci-${title}.xlsx`);
    };
    return (
        <Layout>
            <Card>
                <CardHeader color={"rose"}>
                    <Typography variant="h6">{title}</Typography>
                </CardHeader>
                <CardBody>
                    <div className={classes.exportContainer}>
                        <button className={classes.exportButton} onClick={handleExport}>
                            Export
                        </button>
                    </div>

                    {dataLoaded ? (
                        <>{users?.length > 0 && <FilterUsersTable users={users} />}</>
                    ) : (
                        <CircularProgress color="inherit" size={24} className={classes.loader} />
                    )}
                </CardBody>
            </Card>
        </Layout>
    );
};

export default FilterUsers;
