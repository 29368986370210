import { createSlice } from "@reduxjs/toolkit";

const offerDetails = createSlice({
    name: "offerDetails",
    initialState: {
        offer: {},
        dataLoaded: false,
    },
    reducers: {
        getOffer() {},
        setOffer(state, action) {
            const offerData = action.payload;
            return { ...state, offer: offerData, dataLoaded: true };
        },
        updateOffer() {},
    },
});

export const { getOffer, setOffer, updateOffer } = offerDetails.actions;

export default offerDetails.reducer;
