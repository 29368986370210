import { createSlice } from "@reduxjs/toolkit";

const settings = createSlice({
    name: "settings",
    initialState: {
        paymentSettings: {},
        maintenanceSettings: {},
        configurationSettings: {},
        dataLoaded: false,
    },
    reducers: {
        getPaymentSettings() {},
        updatePaymentSettings() {},
        logoutAllUsers() {},
        setPaymentSettings(state, action) {
            const data = action.payload;
            return { ...state, paymentSettings: { ...data }, dataLoaded: true };
        },
        scheduleMaintenanceMode() {},
        callEmergencyMaintenanceMode() {},
        getMaintenanceSettings() {},
        updateMaintenanceSettings() {},
        setMaintenanceSettings(state, action) {
            const data = action.payload;
            return { ...state, maintenanceSettings: { ...data }, dataLoaded: true };
        },
        getConfigurationSettings() {},
        updateConfigurationSettings() {},
        setConfigurationSettings(state, action) {
            const data = action.payload;
            return { ...state, configurationSettings: { ...data }, dataLoaded: true };
        },
    },
});

export const {
    getPaymentSettings,
    setPaymentSettings,
    updatePaymentSettings,
    logoutAllUsers,
    scheduleMaintenanceMode,
    callEmergencyMaintenanceMode,
    getMaintenanceSettings,
    updateMaintenanceSettings,
    setMaintenanceSettings,
    getConfigurationSettings,
    updateConfigurationSettings,
    setConfigurationSettings,
} = settings.actions;

export default settings.reducer;
