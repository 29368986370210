import cookie from "js-cookie";
import moment from "moment";
import { deleteToken, getMessaging, getToken } from "firebase/messaging";

// set in cookie
export const setCookie = (key, value, expirationDate) => {
    if (window !== "undefined") {
        cookie.set(key, value, {
            expires: expirationDate || 1,
        });
    }
};
// remove from cookie
export const removeCookie = (key) => {
    if (window !== "undefined") {
        cookie.remove(key, {
            expires: 1,
        });
    }
};
// get from cookie such as stored token
// will be useful when we need to make request to server with token
export const getCookie = (key) => {
    if (window !== "undefined") {
        return cookie.get(key);
    }
};
// set in localstorage
export const setLocalStorage = (key, value) => {
    if (window !== "undefined") {
        localStorage.setItem(key, JSON.stringify(value));
    }
};
// set in localstorage
export const getLocalStorage = (key) => {
    if (window !== "undefined") {
        return JSON.parse(localStorage.getItem(key));
    }
};
// remove from localstorage
export const removeLocalStorage = (key) => {
    if (window !== "undefined") {
        localStorage.removeItem(key);
    }
};
// authenticate user by passing data to cookie and localstorage during signin
export const authenticate = (response) => {
    setAuthToken(response.data.token);
    setAuthRefreshToken(response.data.refreshToken);
    setLocalStorage("user", response.data.user);
};

export const setFirebaseToken = async () => {
    const msging = getMessaging();
    const token = await getToken(msging);
    setCookie("firebase_token", token, moment().add(1, "day").toDate());
    return token;
}

export const getFirebaseToken = () => {
    return getCookie("firebase_token");
}

export const setAuthToken = (token) => {
    setCookie("token", token, moment().add(3, "m").toDate());
};

export const getAuthToken = (token) => {
    return getCookie("token");
};

export const setAuthRefreshToken = (refreshToken) => {
    setCookie("refresh_token", refreshToken, moment().add(1, "day").toDate());
};

export const getAuthRefreshToken = (token) => {
    return getCookie("refresh_token");
};

// access user info from localstorage
// TODO: refactor this to go through store
export const isAuth = () => {
    if (window !== "undefined") {
        // const cookieChecked = getCookie("token");
        // if (cookieChecked) {
            if (localStorage.getItem("user") !== "undefined") {
                //   console.log(JSON.parse(localStorage.getItem('user')));
                return JSON.parse(localStorage.getItem("user"));
            } else {
                return false;
            }
        // }
    }
};

export const signout = (next) => {
    removeCookie("token");
    removeLocalStorage("user");
    const msging = getMessaging();
    deleteToken(msging);
    removeCookie("firebase_token");
    next();
};

export const updateUser = (response, next) => {
    console.log("UPDATE USER IN LOCALSTORAGE HELPERS", response);
    if (typeof window !== "undefined") {
        let auth = JSON.parse(localStorage.getItem("user"));
        auth = response.data;
        localStorage.setItem("user", JSON.stringify(auth));
    }
    if (next) next();
};

export const getAuthHeader = () => {
    const token = getCookie("token");
    if (!token) {
        return null;
    }
    return `Bearer ${token}`;
};
