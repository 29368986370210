import { call, put } from "redux-saga/effects";
import { setCountryList } from "../../entities/country";
import { requestGetCountryList } from "../requests/country";


export function* handleGetCountryList(action) {
    try {
        const response = yield call(requestGetCountryList);
        const { data } = response;
        yield put(setCountryList(data));
    } catch (error) {
        console.log(error);
    }
}
