import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxHeight: "100vh",
        minHeight: "30vh",
        minWidth: "40vw",
        width: "100%",
        [theme.breakpoints.down("md")]: {
            minWidth: "60vw",
        },
        [theme.breakpoints.down("xs")]: {
            minWidth: "90vw",
        },
    },
    image: {
        objectFit: "contain",
        width: "100%",
    },
    icon: {
        color: "white",
    },
    closeButton: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    nextButton: {
        position: "absolute",
        top: "50%",
        right: "0px",
    },
    previousButton: {
        position: "absolute",
        top: "50%",
        left: "0px",
    },
}));

export default useStyles;
