export const appTypes = {
    SELLER_APP: "seller",
    BUYER_APP: "user",
};
export const releaseTypes = {
    OTA: 'ota',
    STORE: 'store',
};

export const platformTypes = {
    IOS: 'iOS',
    ANDROID: 'android',
};

export const releaseStatusTypes = {
    PENDING: 'pending',
    RELEASED: 'released',
};