import React, { useEffect } from "react";
import { BrowserRouter, Router, Switch, Route, Redirect, browserHistory } from "react-router-dom";
import App from "./App";
import Signup from "./auth/Signup";
import Signin from "./views/Signin/Signin";
import TermsAndConditions from "./auth/TermsAndConditions";
import Eula from "./auth/Eula";
import Activate from "./auth/Activate";
import Profile from "./core/Profile";
import Test from "./core/Test";
import PrivateRoute from "./auth/PrivateRoute";
import Forgot from "./auth/Forgot";
import Reset from "./auth/Reset";
import DashboardPage from "./views/Dashboard/Dashboard.js";
import UsersPage from "./views/Users/Users";
import VersionsPage from "./views/Versions/Versions"
import UserDetails from "./views/UserDetails/UserDetails";
import OfferDetails from "./views/OfferDetails/OfferDetails";
import OfferCreate from "./views/OfferCreate/OfferCreate";
import RequestsPage from "./views/Requests/Requests";
import ActiveRequests from "./views/ActiveRequests/ActiveRequests";
import RequestDetails from "./views/RequestDetails/RequestDetails";
import OffersPage from "./views/Offers/Offers";
import CreditPage from "./views/Credit/Credit";
import SettingsPage from "./views/Settings/Settings";
import NotificationsPage from "./views/Notifications/Notifications";
import OperatersPage from "./views/Operaters/Operaters";
import SentOffers from "./views/SentOffers/SentOffers";
import AcceptedOffers from "./views/AcceptedOffers/AcceptedOffers";
import RequestCreateOffer from "./views/RequestCreateOffer/RequestCreateOffer";
import Cars from "./views/Cars/Cars";
import { isAuth } from "./auth/helpers";
import Forbidden from "./auth/Forbidden";
import UserRoles from "./auth/UserRoles";
import UserCreate from "./views/UserCreate/UserCreate";
import HistoryLogs from "./views/HistoryLogs/HistoryLogs";
import HistoryLogDetails from "./views/HistoryLogDetails/HistoryLogDetails";
import CarDetails from "./views/CarDetails/CarDetails";
import FilterDetails from "./views/FiltersDetails/FilterDetails";
import TransactionsPage from "./views/Transactions/Transactions";
import TransactionDetails from "./views/TransactionDetails/TransactionDetails";
import Filters from "./views/Filters/Filters";
import FilterUsers from "./views/Filters/FilterUsers";
import LandingPage from "./views/LandingPage/LandingPage";
import QR from "./views/QR/QR";
import QRPartner from "./views/QRPartner/QRPartner";
import VersionCreate from "./views/VersionCreate/VersionCreate";
import VersionDetails from "./views/VersionDetails/VersionDetails";
import Support from "./views/Support/Support";


const Routes = () => {
    return (
        <Switch>
            <Route exact path="/">
                {isAuth() ? <Redirect to="/admin/dashboard" /> : <Redirect to="/landing" />}
            </Route>
            <PrivateRoute path="/home" exact component={App} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/landing" exact component={LandingPage} />
            <Route path={"/support"} exact component={Support} />
            <Route path="/signin" exact component={Signin} />
            <Route path="/terms-and-conditions" exact component={TermsAndConditions} />
            <Route path="/eula" exact component={Eula} />
            <Route path="/QR" exact component={QR} />
            <Route path="/QRPartner" exact component={QRPartner} />
            <Route path="/test" exact component={Test} />
            {/*Rute iz menija*/}
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/users/create" exact component={UserCreate} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/versions/create" exact component={VersionCreate} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER, UserRoles.USER]} path="/admin/dashboard" exact component={DashboardPage} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/users" exact component={UsersPage} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/requests" exact component={RequestsPage} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/offers" exact component={OffersPage} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/offers/create" exact component={OfferCreate} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/credit" exact component={CreditPage} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/settings" exact component={SettingsPage} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/versions" exact component={VersionsPage} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/transactions" exact component={TransactionsPage} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/transactions/:id" exact component={TransactionDetails} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/notifications" exact component={NotificationsPage} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/operaters" exact component={OperatersPage} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/users/:id" exact component={UserDetails} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/versions/:id" exact component={VersionDetails} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/offers/:id" exact component={OfferDetails} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/requests/:id" exact component={RequestDetails} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/activerequests" exact component={ActiveRequests} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/sentoffers" exact component={SentOffers} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/acceptedoffers" exact component={AcceptedOffers} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/requestcreateoffer/:id" exact component={RequestCreateOffer} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/usercars" exact component={Cars} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/userfilters" exact component={Filters} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/history-logs" exact component={HistoryLogs} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/history-logs/:id" exact component={HistoryLogDetails} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/usercars/:id" exact component={CarDetails} />
            <PrivateRoute roles={[UserRoles.ADMIN]} path="/admin/userfilters/:id" exact component={FilterDetails} />
            <PrivateRoute roles={[UserRoles.ADMIN, UserRoles.OPERATER]} path="/admin/userfilters/:name/users" exact component={FilterUsers} />
            {/*Ostale rute*/}

            <Route path="/auth/activate/:token" exact component={Activate} />
            <PrivateRoute path="/profile" exact component={Profile} />
            <Route path="/auth/password/forgot" exact component={Forgot} />
            <Route path="/auth/password/reset/:token" exact component={Reset} />
            <Route path="/makes" exact component={App} />
            <Route path="/forbidden" exact component={Forbidden} />
        </Switch>
    );
};

export default Routes;
