import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getPaginationCars } from "../../reduxStore/entities/cars";
import Layout from "../../core/Layout";
import resources from "../../core/Localization/Localization.js";
import CustomDataGrid from "../../components/CustomDataGrid/CustomDataGrid2";
import { carColumns } from "../../common/DataGridColumns/Cars";
import { handleTableLoad } from "../../common/CarsTableData";

export default function Cars() {
    let history = useHistory();
    const dispatch = useDispatch();

    // Store hooks
    const cars = useSelector((state) => state.cars.cars);
    const count = useSelector((state) => state.cars.count);
    const dataLoaded = useSelector((state) => state.cars.dataLoaded);

    // State hooks
    const [rows, setRows] = React.useState(handleTableLoad(cars));
    const [totalRows, setTotalRows] = React.useState(count);

    // React hooks
    const columns = React.useMemo(carColumns, [resources]);

    // LifeCycle hooks
    useEffect(() => setTotalRows(count), [count]);
    useEffect(() => setRows(handleTableLoad(cars)), [cars, dataLoaded]);

    // Event handlers
    const onFiltersChanged = (filters) => doSearchCars(filters);

    const onPageChange = (filters) => {
        doSearchCars(filters);
        setRows(handleTableLoad(cars));
    };

    const onRowClick = (rowData, rowMeta) => {
        if (rowMeta && rowMeta.dataIndex >= 0) {
            window.open("/admin/usercars/" + rows[rowMeta.dataIndex].id, "_blank");
        }
    };

    // Private (helper) functions
    const doSearchCars = (filters) => {
        dispatch(getPaginationCars(filters));
    };

    return (
        <Layout>
            <CustomDataGrid
                title={resources.userVehicles.header}
                rows={rows}
                columns={columns}
                totalRows={totalRows}
                loading={!dataLoaded}
                handleRowClick={onRowClick}
                handleFilterChange={onFiltersChanged}
                handlePageChange={onPageChange}
                handleDataInit={doSearchCars}
                ignoreUrl={false}
            />
        </Layout>
    );
}
