import { createSlice } from "@reduxjs/toolkit";

const partrequestDetails = createSlice({
    name: "partrequestDetails",
    initialState: {
        partrequest: {},
        dataLoaded: false,
    },
    reducers: {
        getPartrequest() {},
        setPartrequest(state, action) {
            const partrequestData = action.payload;
            return { ...state, partrequest: partrequestData, dataLoaded: true };
        },
        updatePartrequest() {},
    },
});

export const { getPartrequest, setPartrequest, updatePartrequest } =
    partrequestDetails.actions;

export default partrequestDetails.reducer;
