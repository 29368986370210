import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import featuresStyle from "../../../assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js";


const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.container}>
        {/* Feature 2 START */}
        <div className={classes.features2}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.titleLeft}>Tehnička podrška</h2>
              <h5 className={classes.descriptionLeft}>
                Ukoliko imate bilo kakve probleme ili sugestije u vezi sa radom aplikacije možete nas kontaktirati na
                support@smartpart.app
              </h5>
            </GridItem>
            <GridItem
                xs={12}
                sm={12}
                md={12}
                className={
                    classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                }
            >
              <h2 className={classes.titleLeft}>Brisanje naloga</h2>
              <h5 className={classes.descriptionLeft}>
                Ukoliko želite da obrišete nalog to možete uraditi tako što ćete sa email adrese sa kojom ste kreirali nalog poslati poruku na: <br/>
                brisanje.naloga@smartpart.app<br/>
                Vaš nalog i podaci će biti obrisani u roku od 90 dana.
              </h5>
            </GridItem>

          </GridContainer>

        </div>


      </div>

    </div>
  );
}
