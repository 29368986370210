import moment from "moment";
import resources from "../../../core/Localization/Localization";
import { getDateString } from "../../../utils/date_service";
import { getCurrentTimeInString } from "../../../utils/settings_service";
import { FormGroup, FormLabel, TextField } from "@material-ui/core";
import { defaultCustomBodyRender } from "../DataGridColumns";

export const timeRangeColumn = () => ({
    name: "createdAt",
    label: resources.form.createdAt,
    options: {
        filter: true,
        filterType: "custom",
        customFilterListOptions: {
            render: (v) => {
                if (v[0] && v[1]) {
                    if (v[0] === "1970-01-01T00:00") {
                        return `${resources.form.createdAt}: ${resources.form.to + " "}${getDateString(v[1], false)}`;
                    }
                    if (v[1].slice(1, 10) === getCurrentTimeInString().slice(1, 10)) {
                        return `${resources.form.createdAt}: ${resources.form.from + " "}${getDateString(v[0], false)}`;
                    }
                    return `${resources.form.createdAt}: ${getDateString(v[0], false)} - ${getDateString(v[1], false)}`;
                }
                return [];
            },
        },
        filterOptions: {
            names: [],
            logic(date, filters) {
                if (filters[0] && filters[1]) {
                    return filters;
                } else if (!filters[0] && !filters[1]) {
                    return false;
                } else if (filters[0] && !filters[1]) {
                    filters[1] = getCurrentTimeInString().slice(1, 10);
                    return filters;
                } else if (filters[1] && !filters[0]) {
                    filters[0] = "1970-01-01T00:00";
                    return filters;
                }
                return [];
            },

            display: (filterList, onChange, index, column) => {
                return (
                    <div>
                        <FormLabel> {resources.partRequests.datagrid.created}</FormLabel>
                        <FormGroup row>
                            <TextField
                                type="date"
                                label={resources.form.from}
                                InputLabelProps={{ shrink: true }}
                                value={filterList[index][0]}
                                onChange={(event) => {
                                    filterList[index][0] = event.target.value;
                                    if (!filterList[index][1]) filterList[index][1] = getCurrentTimeInString();
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: "45%", marginRight: "10%" }}
                            />
                            <TextField
                                type="date"
                                label={resources.form.to}
                                InputLabelProps={{ shrink: true }}
                                value={filterList[index][1]}
                                onChange={(event) => {
                                    filterList[index][1] = event.target.value;
                                    if (filterList[index][0] && !filterList[index][1]) filterList[index][0] = "1970-01-01T00:00";
                                    onChange(filterList[index], index, column);
                                }}
                                style={{ width: "45%" }}
                            />
                        </FormGroup>
                    </div>
                );
            },
        },
        customBodyRender: (val) => defaultCustomBodyRender(moment(val).format("DD/MM/YYYY HH:mm")),
    },
});
