import React from "react";
// @material-ui/core components
import {makeStyles, withStyles} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import avatar from "../../assets/img/faces/marc.jpg";
import Layout from "../../core/Layout";
import CustomTabs from "../../components/CustomTabs/CustomTabs";
import BugReport from "@material-ui/icons/BugReport";
import Tasks from "../../components/Tasks/Tasks";
import {bugs, server, website} from "../../variables/general";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        /* fontSize: 14,*/
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const columns = [
    { id: 'userid', label: 'userid', minWidth: 100 },
    { id: 'username', label: 'username', minWidth: 170 },
    {
        id: 'firstname',
        label: 'Firstname',
        minWidth: 170,


    },
    {
        id: 'lastname',
        label: 'Lastname',
        minWidth: 170,


    },
    {
        id: 'email',
        label: 'email',
        minWidth: 170,


    },
];
function createData(userid, username , firstname, lastname, email) {

    return { userid, username , firstname, lastname, email };
}

const rows = [
    createData('1', 'pera123', 'pera', 'peric', 'pera@peric.com'),
    createData('2', 'djura123', 'djura', 'djuric', 'djura@djuric.com'),
    createData('3', 'mika123', 'mika', 'mikic', 'mika@mikic.com'),
    createData('4', 'laza123', 'laza', 'lazic', 'laza@lazic.com'),
    createData('5', 'zika123', 'zika', 'zikic', 'zika@zikic.com'),
    createData('6', 'ana123', 'ana', 'anic', 'ana@anic.com'),
    createData('7', 'dana123', 'dana', 'danic', 'dana@danic.com'),
    createData('8', 'sana123', 'sana', 'sanic', 'sana@sanic.com'),
    createData('9', 'tana123', 'tana', 'tanic', 'tana@tanic.com'),
    createData('10', 'lana123', 'lana', 'lanic', 'lana@lanic.com'),
    createData('11', 'aca123', 'aca', 'acic', 'aca@acic.com'),
    createData('12', 'daca123', 'daca', 'dacic', 'daca@dacic.com'),
    createData('13', 'joca123', 'joca', 'jocic', 'joca@jocic.com'),
    createData('14', 'doca123', 'doca', 'docic', 'doca@docic.com'),
    createData('15', 'moca123', 'moca', 'mocic', 'moca@mocic.com'),
];

const useStyles = makeStyles(styles);

export default function Operaters() {
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Layout>
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                        <CustomTabs
                            title="Tasks:"
                            headerColor="primary"
                            tabs={[
                                {
                                    tabName: "Bugs",
                                    tabIcon: BugReport,
                                    tabContent: (
                                        <Tasks
                                            checkedIndexes={[0, 3]}
                                            tasksIndexes={[0, 1, 2, 3]}
                                            tasks={bugs}
                                        />
                                    )
                                },
                                {
                                    tabName: "Website",
                                    tabIcon: Code,
                                    tabContent: (
                                        <Tasks
                                            checkedIndexes={[0]}
                                            tasksIndexes={[0, 1]}
                                            tasks={website}
                                        />
                                    )
                                },
                                {
                                    tabName: "Server",
                                    tabIcon: Cloud,
                                    tabContent: (
                                        <Tasks
                                            checkedIndexes={[1]}
                                            tasksIndexes={[0, 1, 2]}
                                            tasks={server}
                                        />
                                    )
                                }
                            ]}
                        />
                    </GridItem>

                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Paper className={classes.root}>
                            <TableContainer className={classes.container}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                            <StyledTableCell></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                            return (
                                                <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <StyledTableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                                            </StyledTableCell>
                                                        );
                                                    })}
                                                    <StyledTableCell>
                                                        <Button variant="contained"  >Details</Button>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </GridItem>
                </GridContainer>
            </div>
        </Layout>
    );
}