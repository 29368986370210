import { createSlice } from "@reduxjs/toolkit";


const reviews = createSlice({
    name: "reviews",
    initialState: {
        reviews: [],
        dataLoaded: false,
    },
    reducers: {
        getSelectedUserReviews() {},
        deleteUserReview() {},

        setSelectedUserReviews(state, action) {
            const data = action.payload;
            return {
                ...state,
                selectedUsersReviews: data.rows,
                count: data.count
            }
        }
    }
});

export const {
    getSelectedUserReviews,
    setSelectedUserReviews,
    deleteUserReview,
} = reviews.actions;

export default reviews.reducer;
