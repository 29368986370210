import { Box } from "@material-ui/core";
import React from "react";
import useStyles from "../../assets/jss/material-dashboard-react/components/customImageListStyles";
import RequestImage from "../RequestImage/RequestImage";

const RequestImageList = ({ images, handleDelete }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box className={classes.imageList}>
                {images?.length
                    ? images.map((image, index) => (
                          <RequestImage
                              handleDelete={() => handleDelete(image.part_request_image_id)}
                              image={image}
                              key={image.part_request_image_id}
                              index={index}
                          />
                      ))
                    : null}
            </Box>
        </div>
    );
};

export default RequestImageList;
