// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import SettingsIcon from "@material-ui/icons/Settings";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import HistoryIcon from "@material-ui/icons/History";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard.js";

import Settings from "./views/Settings/Settings";
import Offers from "./views/Offers/Offers";
import Requests from "./views/Requests/Requests";
import ActiveRequests from "./views/ActiveRequests/ActiveRequests";
import Users from "./views/Users/Users";
import SentOffers from "./views/SentOffers/SentOffers";
import AcceptedOffers from "./views/AcceptedOffers/AcceptedOffers";
import Cars from "./views/Cars/Cars";
import UserRoles from "./auth/UserRoles.js";
import HistoryLogs from "./views/HistoryLogs/HistoryLogs.js";
import { notificationTypes } from "./variables/notificationTypes.js";
import Transactions from "./views/Transactions/Transactions.js";
import Filters from "./views/Filters/Filters.js";
import Versions from "./views/Versions/Versions";

const dashboardRoutes = [
    {
        path: "/dashboard",
        name: "dashboard",

        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin",
        roles: [UserRoles.ALL],
    },
    {
        path: "/users",
        name: "users",

        icon: Person,
        component: Users,
        layout: "/admin",
        roles: [UserRoles.ADMIN, UserRoles.OPERATER],
    },
    {
        path: "/requests",
        name: "requests",

        icon: "content_paste",
        component: Requests,
        layout: "/admin",
        roles: [UserRoles.ADMIN, UserRoles.OPERATER],

        badgeTopics: [notificationTypes.partRequestCreated, notificationTypes.partRequestStateUpdate, notificationTypes.partOfferCreated],
        badgeClearTopic: notificationTypes.partRequestsRead,
    },
    {
        path: "/offers",
        name: "offers",

        icon: LibraryBooks,
        component: Offers,
        layout: "/admin",
        roles: [UserRoles.ADMIN, UserRoles.OPERATER],

        badgeTopics: [notificationTypes.partOfferCreated],
        badgeClearTopic: notificationTypes.partOffersRead,
    },

    // {
    //     path: "/activerequests",
    //     name: "Aktivni Zahtevi",

    //     icon: "content_paste",
    //     component: ActiveRequests,
    //     layout: "/admin",
    //     roles: [UserRoles.ADMIN, UserRoles.OPERATER]
    // },
    // {
    //     path: "/sentoffers",
    //     name: "Poslate Ponude",

    //     icon: LibraryBooks,
    //     component: SentOffers,
    //     layout: "/admin",
    //     roles: [UserRoles.ADMIN, UserRoles.OPERATER]
    // },
    // {
    //     path: "/acceptedoffers",
    //     name: "Prihvaćene Ponude",

    //     icon: LibraryBooks,
    //     component: AcceptedOffers,
    //     layout: "/admin",
    //     roles: [UserRoles.ADMIN, UserRoles.OPERATER]
    // },
    {
        path: "/usercars",
        name: "cars",

        icon: DirectionsCarIcon,
        component: Cars,
        layout: "/admin",
        roles: [UserRoles.ADMIN, UserRoles.OPERATER],
    },
    {
        path: "/userfilters",
        name: "garage",

        icon: LocalParkingIcon,
        component: Filters,
        layout: "/admin",
        roles: [UserRoles.ADMIN, UserRoles.OPERATER],
    },
    {
        path: "/transactions",
        name: "transactions",

        icon: MonetizationOnIcon,
        component: Transactions,
        layout: "/admin",
        roles: [UserRoles.ADMIN, UserRoles.OPERATER],
    },
    {
        path: "/history-logs",
        name: "historyLogs",

        icon: HistoryIcon,
        component: HistoryLogs,
        layout: "/admin",
        roles: [UserRoles.ADMIN, UserRoles.OPERATER],
    },
    /*{
        path: "/operaters",
        name: "Operater",

        icon: Person,
        component: Operaters,
        layout: "/admin"
    },*/
    {
        path: "/versions",
        name: "versions",

        icon: SettingsBackupRestoreIcon,
        component: Versions,
        layout: "/admin",
        roles: [UserRoles.ADMIN],
    },
    {
        path: "/settings",
        name: "settings",

        icon: SettingsIcon,
        component: Settings,
        layout: "/admin",
        roles: [UserRoles.ADMIN, UserRoles.OPERATER],
    },
];

export default dashboardRoutes;
