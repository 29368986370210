import { call, put } from "redux-saga/effects";
import { setCar } from "../../entities/carDetails";
import { requestGetCar } from "../requests/carDetails";

export function* handleGetCar(action) {
    try {
        const response = yield call(requestGetCar, action.payload);
        const { data } = response;
        yield put(setCar({ ...data }));
    } catch (error) {
        console.log(error);
    }
}
