import React from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
//styles
import dashboardStyle from "../../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
//common
import { timeOptions } from "../../../variables/time";
// core components
import Button from "../../../components/CustomButtons/Button.js";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput.js";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal.js";

//utils
import resources from "../../../core/Localization/Localization";
import { isAuth } from "../../../auth/helpers.js";
import { updateConfigurationSettings } from "../../../reduxStore/entities/settings.js";
import shallowObjectsCompare from "../../../utils/shallowObjectsCompare.js";
import { checkForErrors } from "../../../utils/settings_service.js";

const useStyles = makeStyles(dashboardStyle);

const RequestsAndOffers = ({ editMode }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const operator = isAuth();

    const configurationSettings = useSelector((state) => state.settings.configurationSettings);

    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [settings, setSettings] = React.useState(null);
    const [errors, setErrors] = React.useState(null);

    const handleCheckErrors = () => {
        let checkedErrors = checkForErrors(settings);
        if (Object.keys(checkedErrors).length) {
            setErrors({ ...checkedErrors });
            setShowConfirmModal(false);
            return;
        }
        setShowConfirmModal(true);
    };

    const handleConfirm = () => {
        let data = shallowObjectsCompare(configurationSettings, settings);
        let payload = { ...data, updatedby: operator.userid, settings_config_id: configurationSettings.settings_config_id };

        dispatch(updateConfigurationSettings(payload));
        setErrors({});
        setShowConfirmModal(false);
    };
    const handleTakeValue = (e) => {
        setSettings({ ...settings, [e.target.name]: e.target.value });
    };
    const handleReset = () => {
        setSettings({ ...configurationSettings });
        setErrors({});
    };

    React.useEffect(() => {
        setErrors(null);
        setSettings({ ...configurationSettings });
    }, [configurationSettings]);

    return settings ? (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <Box className={classes.sectionHeaderWrapper}>
                        <Typography className={classes.sectionHeader}>{resources.settings.selectOfferTime}</Typography>
                    </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                        id="part_request_selecting_offer_time_value"
                        formControlProps={{ fullWidth: true }}
                        labelText={resources.settings.timeValue}
                        inputProps={{
                            name: "part_request_selecting_offer_time_value",
                            disabled: !editMode,
                            type: "number",
                            value: settings.part_request_selecting_offer_time_value || "",
                            onChange: (e) => {
                                handleTakeValue(e);
                            },
                        }}
                        error={!!errors?.part_request_selecting_offer_time_value}
                    />
                    {errors?.part_request_selecting_offer_time_value ? <p className="text-danger">{errors.part_request_selecting_offer_time_value}</p> : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                        id="part_request_selecting_offer_time_part"
                        formControlProps={{ fullWidth: true }}
                        labelText={resources.settings.timeUnit}
                        labelProps={{ shrink: true }}
                        options={timeOptions()}
                        inputProps={{
                            name: "part_request_selecting_offer_time_part",
                            disabled: !editMode,
                            type: "number",
                            value: settings.part_request_selecting_offer_time_part || "",
                            onChange: (e) => {
                                handleTakeValue(e);
                            },
                        }}
                    />
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                    <Box className={classes.sectionHeaderWrapper}>
                        <Typography className={classes.sectionHeader}>{resources.settings.acceptOffersTime}</Typography>
                    </Box>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                        id="part_request_accepting_offers_time_value"
                        formControlProps={{ fullWidth: true }}
                        labelText={resources.settings.timeValue}
                        inputProps={{
                            name: "part_request_accepting_offers_time_value",
                            disabled: !editMode,
                            value: settings.part_request_accepting_offers_time_value || "",
                            onChange: (e) => {
                                handleTakeValue(e);
                            },
                        }}
                        error={!!errors?.part_request_accepting_offers_time_value}
                    />
                    {errors?.part_request_accepting_offers_time_value ? <p className="text-danger">{errors.part_request_accepting_offers_time_value}</p> : null}
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <CustomSelect
                        id="part_request_accepting_offers_time_part"
                        formControlProps={{ fullWidth: true }}
                        labelText={resources.settings.timeUnit}
                        labelProps={{ shrink: true }}
                        options={timeOptions()}
                        inputProps={{
                            name: "part_request_accepting_offers_time_part",
                            disabled: !editMode,
                            value: settings.part_request_accepting_offers_time_part || "",
                            onChange: (e) => {
                                handleTakeValue(e);
                            },
                        }}
                    />
                </GridItem>
            </GridContainer>
            {editMode ? (
                <Box className={classes.buttonContainer}>
                    <Box className={classes.confirmButton}>
                        <Button type={"button"} color={"info"} size={"sm"} className={classes.buttonSeparator} onClick={handleCheckErrors}>
                            {resources.form.save}
                        </Button>
                    </Box>
                    <Box className={classes.confirmButton}>
                        <Button type={"button"} color={"danger"} size={"sm"} className={classes.buttonSeparator} onClick={handleReset}>
                            {resources.form.cancel}
                        </Button>
                    </Box>
                </Box>
            ) : null}
            <ConfirmationModal
                open={showConfirmModal}
                handleClose={() => {
                    setShowConfirmModal(false);
                }}
                buttonLabel={resources.form.confirm}
                text={resources.settings.messages.confirmPartRequestTimeChange}
                onConfirmCallback={handleConfirm}
            />
        </>
    ) : null;
};

export default RequestsAndOffers;
