import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTokensToUser, correctUserTokens } from "../../reduxStore/entities/userDetails";

// core components
import MappedTabs from "../../components/MappedTabs/MappedTabs";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import Button from "../../components/CustomButtons/Button";
import UserFinanceModal from "./UserFinanceModal";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import UserFinanceCredit from "./UserFinanceCredit";
import ExposureIcon from "@material-ui/icons/Exposure";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";

//common
import resources from "../../core/Localization/Localization.js";
import { USER_FINANCE_HEADERS, financeHistoryTabs, mapFinanceHistoryInTableData } from "../../common/UserDetailsTabs";

// utils
import getQueryWithPagination from "../../utils/getQueryWithPagination";
import { getUserTransactions } from "../../reduxStore/entities/finance";
import { mapAddTokensPayload } from "../../utils/finance_service";
import { isAuth } from "../../auth/helpers";
import { currencyTypes, TransactionTypes } from "../../common/transactions/config";
import { Box } from "@material-ui/core";
import UserFinanceCorrectionModal from "./UserFinanceCorrectionModal";

export default function UserFinance() {
    const user = useSelector((s) => s.userDetails.user);
    const history = useHistory();
    const dispatch = useDispatch();
    const operator = isAuth();
    const initialAddVal = { userid: user.userid, updatedby: operator.userid, currency: currencyTypes.RSD };
    const initialCorrectionVal = { userid: user.userid, correctedby: operator.userid };
    // Store hooks
    const userFinance = useSelector((state) => state.finance.userTransactions);

    // State hooks
    const [tableData, setTableData] = React.useState([]);
    const [showTokenModal, setShowTokenModal] = React.useState(false);
    const [showCorrectionModal, setShowCorrectionModal] = React.useState(false);
    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [tokensToAdd, setTokensToAdd] = React.useState({ ...initialAddVal });
    const [tokensToCorrect, setTokensToCorrect] = React.useState({ ...initialCorrectionVal });
    const [activeHeader, setActiveHeader] = React.useState("");

    // React hooks
    const headers = React.useMemo(() => USER_FINANCE_HEADERS(activeHeader), [activeHeader]);
    const tabs = React.useMemo(financeHistoryTabs, [resources]);

    useEffect(() => {
        setTableData(mapFinanceHistoryInTableData(userFinance));
    }, [userFinance, user]);

    // Event handlers
    const handleTabChange = (e) => {
        switch (e) {
            case 0:
                setActiveHeader(resources.finance.credit);
                break;
            case 1:
                setActiveHeader(tabs[0].name);
                dispatch(getUserTransactions(getQueryWithPagination({ userid: user.userid, type: TransactionTypes.PAYIN })));
                break;
            case 2:
                setActiveHeader(tabs[1].name);
                dispatch(getUserTransactions(getQueryWithPagination({ userid: user.userid, type: TransactionTypes.RESERVED })));
                break;
            case 3:
                setActiveHeader(tabs[2].name);
                dispatch(getUserTransactions(getQueryWithPagination({ userid: user.userid, type: TransactionTypes.CHARGE })));
                break;
            case 4:
                setActiveHeader(tabs[3].name);
                dispatch(getUserTransactions(getQueryWithPagination({ userid: user.userid, type: TransactionTypes.CORRECTION })));
                break;
            default:
                break;
        }
    };
    const handleViewMore = (sStates) => {
        history.push(`/admin/transactions/`, sStates);
    };

    const onRowClick = (params) => {
        if (params.length) {
            history.push("/admin/transactions/" + params[0]);
        }
    };

    const handleToggleModal = () => {
        setShowTokenModal(!showTokenModal);
    };

    const handleToggleCorrectionModal = () => {
        setShowCorrectionModal(!showCorrectionModal);
    };

    const handleAddTokens = (tokens) => {
        setTokensToAdd({ ...tokensToAdd, ...tokens });
        setShowConfirmModal(!showConfirmModal);
    };
    const handleCorrect = (tokens) => {
        setTokensToCorrect({ ...tokensToCorrect, ...tokens });
        setShowConfirmModal(!showConfirmModal);
    };

    const handleOnConfirmCallbackAddTokens = () => {
        const payload = mapAddTokensPayload(tokensToAdd);
        dispatch(addTokensToUser(payload));
        setTokensToAdd({ ...initialAddVal });
        setShowTokenModal(false);
        setShowConfirmModal(false);
    };

    const handleOnConfirmCallbackCorrection = () => {
        const payload = { ...tokensToCorrect };
        payload.amount = parseInt(payload.amount);
        dispatch(correctUserTokens(payload));
        setTokensToCorrect({ ...initialCorrectionVal });
        setShowCorrectionModal(false);
        setShowConfirmModal(false);
    };

    return (
        <>
            <CustomTabs
                className={"user-profile-card"}
                title={resources.finance.title}
                headerColor="danger"
                onTabChange={handleTabChange}
                tabs={[
                    {
                        tabName: resources.finance.credit,
                        tabContent: (
                            <>
                                <UserFinanceCredit reserved={user.reserved} available={user.available} />
                                <Box className="d-flex justify-content-between mt-5">
                                    <Button color="danger" onClick={handleToggleModal}>
                                        <Box className="d-flex align-items-center">
                                            <LibraryAddIcon className="mr-3" />
                                            <span>{resources.finance.addTokens}</span>
                                        </Box>
                                    </Button>
                                    <Button color="danger" onClick={handleToggleCorrectionModal}>
                                        <Box className="d-flex align-items-center">
                                            <ExposureIcon className="mr-3" />
                                            <span>{resources.finance.correctTokens}</span>
                                        </Box>
                                    </Button>
                                </Box>
                            </>
                        ),
                    },
                    ...MappedTabs(tabs, { tableHead: headers, tableData, onRowClick }, handleViewMore, resources.form.viewMore, true, "danger"),
                ]}
            />
            <UserFinanceModal
                open={showTokenModal}
                handleClose={() => {
                    setTokensToAdd({ ...initialAddVal });
                    handleToggleModal();
                }}
                handleAdd={handleAddTokens}
            />
            <UserFinanceCorrectionModal
                open={showCorrectionModal}
                handleClose={() => {
                    setTokensToCorrect({ ...initialCorrectionVal });
                    handleToggleCorrectionModal();
                }}
                handleCorrect={handleCorrect}
            />
            <ConfirmationModal
                open={showConfirmModal}
                handleClose={() => {
                    setShowConfirmModal(false);
                }}
                buttonLabel={resources.form.confirm}
                text={showTokenModal ? resources.finance.confirmAddTokensText : resources.finance.confirmCorrectTokensText}
                onConfirmCallback={showTokenModal ? handleOnConfirmCallbackAddTokens : showCorrectionModal ? handleOnConfirmCallbackCorrection : null}
            />
        </>
    );
}
