// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';
//
// ReactDOM.render(<App />, document.getElementById('root'));
//
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Routes";
import "./assets/css/material-dashboard-react.css";
import "./assets/css/custom.css";
import "../node_modules/react-grid-layout/css/styles.css";
import "../node_modules/react-resizable/css/styles.css";
import { Provider } from "react-redux";
import { store, history } from "./reduxStore/configureStore";
import { ConnectedRouter } from "connected-react-router";
import { NotificationsProvider } from "./NotificationsProvider";

const rootElement = document.getElementById("root");
const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate;
renderMethod(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <NotificationsProvider />
            <Routes />
        </ConnectedRouter>
    </Provider>,
    rootElement
);
/*if (rootElement.hasChildNodes()) {
    hydrate(<Routes />, rootElement);
} else {
    render(<Routes />, rootElement);
}*/
