import { defaultCustomBodyRender } from "./DataGridColumns";
import resources from "../../core/Localization/Localization";
import { timeRangeColumn } from "./customColumns/timeRangeColumn";

export const carColumns = () => [
    {
        name: "id",
        label: resources.userVehicles.datagrid.id,

        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "make",
        label: resources.userVehicles.datagrid.make,

        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "model",
        label: resources.userVehicles.datagrid.model,

        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "generation",
        label: resources.userVehicles.datagrid.generation,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "motor",
        label: resources.userVehicles.datagrid.motor,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "title",
        label: resources.userVehicles.datagrid.title,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "vin",
        label: resources.userVehicles.datagrid.vin,

        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        name: "owner",
        label: resources.userVehicles.datagrid.owner,
        options: {
            filter: true,
            sort: true,
            filterType: "textField",
            customBodyRender: defaultCustomBodyRender,
        },
    },
    {
        ...timeRangeColumn()
    },
];
