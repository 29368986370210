import React, {useEffect} from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Tasks from "../../components/Tasks/Tasks.js";
import CustomTabs from "../../components/CustomTabs/CustomTabs.js";
import Danger from "../../components/Typography/Danger.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardIcon from "../../components/Card/CardIcon.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Layout from '../../core/Layout';
import { bugs, website, server } from "../../variables/general.js";

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart
} from "../../variables/charts.js";

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import {useDispatch, useSelector} from "react-redux";
import {getDashboard} from "../../reduxStore/entities/dashboard";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const dashboard = useSelector((state) => state.dashboard.dashboard);
  const dataLoaded = useSelector((state) => state.dashboard.dataLoaded);
  const dispatch = useDispatch();

  useEffect(() => {
    const doasync= (async() =>{
      await dispatch(getDashboard());
    })
    doasync();
  }, [dispatch]);
/*
  useEffect(()=>{
    console.log(offer)
    setRows(populateTable(offer))
  },[dashboard,dataLoaded]);
   { totalUsers: 6, totalUser24h: 6,
    totalPartrequests24h: 27, totalSuccessfulPartrequests24h: 27,
     totalPartrequestsEndedWithoutOffers24h: 27, totalPartrequestsWinnerNotSelected24h: 27,
      newPartsSold24h: 27, usedPartsSold24h: 27 }
  */

  return (

    <Layout>
    <div>
      <GridContainer>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Zahtevi </p>
              <h3 className={classes.cardTitle}>{dashboard.newPartsSold24h} kom </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Zahtevi 24h </p>
              <h3 className={classes.cardTitle}>{dashboard.usedPartsSold24h} kom</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>people</Icon>
              </CardIcon>
              <p className={classes.cardCategory}> Ukupno korisnika</p>
              <h3 className={classes.cardTitle}>
                {dashboard.totalUsers}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#tuki" onClick={e => e.preventDefault()}>
                  Detaljnije
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Novih korisnika 24h</p>
              <h3 className={classes.cardTitle}>+{dashboard.totalUser24h}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Automobili</p>
              <h3 className={classes.cardTitle}>{dashboard.totalPartrequests24h}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Automobili 24h</p>
              <h3 className={classes.cardTitle}>{dashboard.totalSuccessfulPartrequests24h}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Ponude</p>
              <h3 className={classes.cardTitle}>{dashboard.totalPartrequestsEndedWithoutOffers24h}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Ponude 24h</p>
              <h3 className={classes.cardTitle}>{dashboard.totalPartrequestsWinnerNotSelected24h}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>

      </GridContainer>
      {/*<GridContainer>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="info">*/}
      {/*        <ChartistGraph*/}
      {/*            className="ct-chart"*/}
      {/*            data={dailySalesChart.data}*/}
      {/*            type="Line"*/}
      {/*            options={dailySalesChart.options}*/}
      {/*            listener={dailySalesChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Broj korisnika 7 dana</h4>*/}
      {/*        <p className={classes.cardCategory}>*/}
      {/*          <span className={classes.successText}>*/}
      {/*            <ArrowUpward className={classes.upArrowCardCategory} /> 25%*/}
      {/*          </span>{" "}*/}
      {/*          vise nego prethodne nedelje.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> updated 4 minutes ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="success">*/}
      {/*        <ChartistGraph*/}
      {/*            className="ct-chart"*/}
      {/*            data={dailySalesChart.data}*/}
      {/*            type="Line"*/}
      {/*            options={dailySalesChart.options}*/}
      {/*            listener={dailySalesChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Prodato novih 7 dana</h4>*/}
      {/*        <p className={classes.cardCategory}>*/}
      {/*          <span className={classes.successText}>*/}
      {/*            <ArrowUpward className={classes.upArrowCardCategory} /> 15%*/}
      {/*          </span>{" "}*/}
      {/*          vise.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> updated 4 minutes ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="success">*/}
      {/*        <ChartistGraph*/}
      {/*            className="ct-chart"*/}
      {/*            data={dailySalesChart.data}*/}
      {/*            type="Line"*/}
      {/*            options={dailySalesChart.options}*/}
      {/*            listener={dailySalesChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Prodato polovnih 7 dana</h4>*/}
      {/*        <p className={classes.cardCategory}>*/}
      {/*          <span className={classes.successText}>*/}
      {/*            <ArrowUpward className={classes.upArrowCardCategory} /> 85%*/}
      {/*          </span>{" "}*/}
      {/*          vise.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> updated 4 minutes ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="success">*/}
      {/*        <ChartistGraph*/}
      {/*            className="ct-chart"*/}
      {/*            data={dailySalesChart.data}*/}
      {/*            type="Line"*/}
      {/*            options={dailySalesChart.options}*/}
      {/*            listener={dailySalesChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Daily Sales</h4>*/}
      {/*        <p className={classes.cardCategory}>*/}
      {/*          <span className={classes.successText}>*/}
      {/*            <ArrowUpward className={classes.upArrowCardCategory} /> 55%*/}
      {/*          </span>{" "}*/}
      {/*          increase in today sales.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> updated 4 minutes ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="success">*/}
      {/*        <ChartistGraph*/}
      {/*            className="ct-chart"*/}
      {/*            data={dailySalesChart.data}*/}
      {/*            type="Line"*/}
      {/*            options={dailySalesChart.options}*/}
      {/*            listener={dailySalesChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Daily Sales</h4>*/}
      {/*        <p className={classes.cardCategory}>*/}
      {/*          <span className={classes.successText}>*/}
      {/*            <ArrowUpward className={classes.upArrowCardCategory} /> 55%*/}
      {/*          </span>{" "}*/}
      {/*          increase in today sales.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> updated 4 minutes ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="warning">*/}
      {/*        <ChartistGraph*/}
      {/*            className="ct-chart"*/}
      {/*            data={emailsSubscriptionChart.data}*/}
      {/*            type="Bar"*/}
      {/*            options={emailsSubscriptionChart.options}*/}
      {/*            responsiveOptions={emailsSubscriptionChart.responsiveOptions}*/}
      {/*            listener={emailsSubscriptionChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Email Subscriptions</h4>*/}
      {/*        <p className={classes.cardCategory}>Last Campaign Performance</p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> campaign sent 2 days ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="danger">*/}
      {/*        <ChartistGraph*/}
      {/*            className="ct-chart"*/}
      {/*            data={completedTasksChart.data}*/}
      {/*            type="Line"*/}
      {/*            options={completedTasksChart.options}*/}
      {/*            listener={completedTasksChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Completed Tasks</h4>*/}
      {/*        <p className={classes.cardCategory}>Last Campaign Performance</p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> campaign sent 2 days ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*</GridContainer>*/}
      {/*<GridContainer>*/}
      {/*  <GridItem xs={12} sm={6} md={3}>*/}
      {/*    <Card>*/}
      {/*      <CardHeader color="warning" stats icon>*/}
      {/*        <CardIcon color="warning">*/}
      {/*          <Icon>content_copy</Icon>*/}
      {/*        </CardIcon>*/}
      {/*        <p className={classes.cardCategory}>Used Space</p>*/}
      {/*        <h3 className={classes.cardTitle}>*/}
      {/*          49/50 <small>GB</small>*/}
      {/*        </h3>*/}
      {/*      </CardHeader>*/}
      {/*      <CardFooter stats>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <Danger>*/}
      {/*            <Warning />*/}
      {/*          </Danger>*/}
      {/*          <a href="#pablo" onClick={e => e.preventDefault()}>*/}
      {/*            Get more space*/}
      {/*          </a>*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={6} md={3}>*/}
      {/*    <Card>*/}
      {/*      <CardHeader color="success" stats icon>*/}
      {/*        <CardIcon color="success">*/}
      {/*          <Store />*/}
      {/*        </CardIcon>*/}
      {/*        <p className={classes.cardCategory}>Revenue</p>*/}
      {/*        <h3 className={classes.cardTitle}>$34,245</h3>*/}
      {/*      </CardHeader>*/}
      {/*      <CardFooter stats>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <DateRange />*/}
      {/*          Last 24 Hours*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={6} md={3}>*/}
      {/*    <Card>*/}
      {/*      <CardHeader color="danger" stats icon>*/}
      {/*        <CardIcon color="danger">*/}
      {/*          <Icon>info_outline</Icon>*/}
      {/*        </CardIcon>*/}
      {/*        <p className={classes.cardCategory}>Fixed Issues</p>*/}
      {/*        <h3 className={classes.cardTitle}>75</h3>*/}
      {/*      </CardHeader>*/}
      {/*      <CardFooter stats>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <LocalOffer />*/}
      {/*          Tracked from Github*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={6} md={3}>*/}
      {/*    <Card>*/}
      {/*      <CardHeader color="info" stats icon>*/}
      {/*        <CardIcon color="info">*/}
      {/*          <Accessibility />*/}
      {/*        </CardIcon>*/}
      {/*        <p className={classes.cardCategory}>Followers</p>*/}
      {/*        <h3 className={classes.cardTitle}>+245</h3>*/}
      {/*      </CardHeader>*/}
      {/*      <CardFooter stats>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <Update />*/}
      {/*          Just Updated*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*</GridContainer>*/}
      {/*<GridContainer>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="success">*/}
      {/*        <ChartistGraph*/}
      {/*          className="ct-chart"*/}
      {/*          data={dailySalesChart.data}*/}
      {/*          type="Line"*/}
      {/*          options={dailySalesChart.options}*/}
      {/*          listener={dailySalesChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Daily Sales</h4>*/}
      {/*        <p className={classes.cardCategory}>*/}
      {/*          <span className={classes.successText}>*/}
      {/*            <ArrowUpward className={classes.upArrowCardCategory} /> 55%*/}
      {/*          </span>{" "}*/}
      {/*          increase in today sales.*/}
      {/*        </p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> updated 4 minutes ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="warning">*/}
      {/*        <ChartistGraph*/}
      {/*          className="ct-chart"*/}
      {/*          data={emailsSubscriptionChart.data}*/}
      {/*          type="Bar"*/}
      {/*          options={emailsSubscriptionChart.options}*/}
      {/*          responsiveOptions={emailsSubscriptionChart.responsiveOptions}*/}
      {/*          listener={emailsSubscriptionChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Email Subscriptions</h4>*/}
      {/*        <p className={classes.cardCategory}>Last Campaign Performance</p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> campaign sent 2 days ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={4}>*/}
      {/*    <Card chart>*/}
      {/*      <CardHeader color="danger">*/}
      {/*        <ChartistGraph*/}
      {/*          className="ct-chart"*/}
      {/*          data={completedTasksChart.data}*/}
      {/*          type="Line"*/}
      {/*          options={completedTasksChart.options}*/}
      {/*          listener={completedTasksChart.animation}*/}
      {/*        />*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <h4 className={classes.cardTitle}>Completed Tasks</h4>*/}
      {/*        <p className={classes.cardCategory}>Last Campaign Performance</p>*/}
      {/*      </CardBody>*/}
      {/*      <CardFooter chart>*/}
      {/*        <div className={classes.stats}>*/}
      {/*          <AccessTime /> campaign sent 2 days ago*/}
      {/*        </div>*/}
      {/*      </CardFooter>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*</GridContainer>*/}
      {/*<GridContainer>*/}
      {/*  <GridItem xs={12} sm={12} md={6}>*/}
      {/*    <CustomTabs*/}
      {/*      title="Tasks:"*/}
      {/*      headerColor="primary"*/}
      {/*      tabs={[*/}
      {/*        {*/}
      {/*          tabName: "Bugs",*/}
      {/*          tabIcon: BugReport,*/}
      {/*          tabContent: (*/}
      {/*            <Tasks*/}
      {/*              checkedIndexes={[0, 3]}*/}
      {/*              tasksIndexes={[0, 1, 2, 3]}*/}
      {/*              tasks={bugs}*/}
      {/*            />*/}
      {/*          )*/}
      {/*        },*/}
      {/*        {*/}
      {/*          tabName: "Website",*/}
      {/*          tabIcon: Code,*/}
      {/*          tabContent: (*/}
      {/*            <Tasks*/}
      {/*              checkedIndexes={[0]}*/}
      {/*              tasksIndexes={[0, 1]}*/}
      {/*              tasks={website}*/}
      {/*            />*/}
      {/*          )*/}
      {/*        },*/}
      {/*        {*/}
      {/*          tabName: "Server",*/}
      {/*          tabIcon: Cloud,*/}
      {/*          tabContent: (*/}
      {/*            <Tasks*/}
      {/*              checkedIndexes={[1]}*/}
      {/*              tasksIndexes={[0, 1, 2]}*/}
      {/*              tasks={server}*/}
      {/*            />*/}
      {/*          )*/}
      {/*        }*/}
      {/*      ]}*/}
      {/*    />*/}
      {/*  </GridItem>*/}
      {/*  <GridItem xs={12} sm={12} md={6}>*/}
      {/*    <Card>*/}
      {/*      <CardHeader color="warning">*/}
      {/*        <h4 className={classes.cardTitleWhite}>Employees Stats</h4>*/}
      {/*        <p className={classes.cardCategoryWhite}>*/}
      {/*          New employees on 15th September, 2016*/}
      {/*        </p>*/}
      {/*      </CardHeader>*/}
      {/*      <CardBody>*/}
      {/*        <Table*/}
      {/*          tableHeaderColor="warning"*/}
      {/*          tableHead={["ID", "Name", "Salary", "Country"]}*/}
      {/*          tableData={[*/}
      {/*            ["1", "Dakota Rice", "$36,738", "Niger"],*/}
      {/*            ["2", "Minerva Hooper", "$23,789", "Curaçao"],*/}
      {/*            ["3", "Sage Rodriguez", "$56,142", "Netherlands"],*/}
      {/*            ["4", "Philip Chaney", "$38,735", "Korea, South"]*/}
      {/*          ]}*/}
      {/*        />*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </GridItem>*/}
      {/*</GridContainer>*/}
    </div></Layout>
  );
}
