const settings = {
    en: {
        settings: {
            settings: "Settings",
            paymentSettings: "Payment settings",
            maintenanceSettings: "Maintenance settings",
            configurationSettings: "Configuration settings",
            defaultCommission: "Default commission",
            eurToRsd: "Exchange rate EUR to RSD",
            tokenToRsd: "Exchange rate Credits to RSD",
            logout: "Logout",
            logoutUsers: "Logout all users",
            stopUserSingUp: "Stop users from Sign Up",
            stopUserSignIn: "Stop users from Login",
            disableNotifications: "Disable send notifications",
            disablePartOffers: "Disable create new part offers",
            disablePartRequests: "Disable create new part requests",
            timeValue: "Time value",
            timeUnit: "Time unit",
            selectOfferTime: "Part request select offer time",
            acceptOffersTime: "Part request accept offers time",
            scheduleMaintenanceTime: "Schedule next maintenance mode",
            scheduleMaintenanceMessage: "Message for next maintenance mode",
            emergencyMaintenance: "Activate emergency maintenance mode",
            resetMaintenanceToDefault: "Reset maintenance options to default values",
            messages: {
                confirmUsersLogout: "Are you sure you want to logout all users?",
                logoutUsersSuccess: "Users logged out successfully",
                logoutUsersFailure: "Users logged out failed",
                confirmStopSignUp: "Are you sure you want to stop the sign up option for the users?",
                confirmStopSignIn: "Are you sure you want to stop the login option for the users?",
                confirmPartRequestTimeChange: "Are you sure you want to change part requests time boundaries?",
                confirmPartRequestDisable: "Are you sure you want to disable creating new part requests?",
                confirmPartOffersDisable: "Are you sure you want to disable creating new part offers?",
                confirmDisableNotifications: "Are you sure you want to disable notifications?",
                configurationSettingsUpdated: "Configuration settings updated successfully",
                maintenanceSettingsUpdated: "Maintenance settings updated successfully",
                confirmScheduleMaintenanceMode: "Are you sure you want to schedule maintenance mode?",
                confirmEmergencyMaintenance: "Are you sure you want to set immediate maintenance mode?",
                confirmResetMaintenance: "Are you sure you want to reset maintenance options to default values?",
                emergencyMaintenanceSuccess: "Maintenance mode successfully established",
                updatePaymentSettingsSuccess: "Payment settings updated successfully",
            },
        },
    },
    rs: {
        settings: {
            settings: "Podešavanja",
            paymentSettings: "Podešavanja plaćanja",
            maintenanceSettings: "Podešavanja održavanja",
            configurationSettings: "Podešavanja konfiguracije",
            defaultCommission: "Podrazumevana provizija",
            eurToRsd: "Kursna razlika EUR u RSD",
            tokenToRsd: "Kursna razlika Krediti u RSD",
            logout: "Izloguj",
            logoutUsers: "Izloguj sve korisnike",
            stopUserSingUp: "Obustavi kreiranje novih korisnika",
            stopUserSignIn: "Obustavi prijavljivanje korisnika",
            disableNotifications: "Obustavi slanje notifikacija",
            disablePartOffers: "Obustavi kreiranje novih ponuda",
            disablePartRequests: "Obustavi kreiranje novih zahteva za delove",
            timeValue: "Količina vremena",
            timeUnit: "Jedinica vremena",
            selectOfferTime: "Vreme za odabiranje ponude na zahtev",
            acceptOffersTime: "Vreme za prihvatanje ponuda na zahtev",
            scheduleMaintenanceTime: "Zakazivanje sledećeg sistemskog održavanja",
            scheduleMaintenanceMessage: "Poruka zakazanog sistemskog održavanja",
            emergencyMaintenance: "Pokreni režim za održavanje",
            resetMaintenanceToDefault: "Resetuj opcije za održavanje na podrazumevane vrednosti",
            messages: {
                confirmUsersLogout: "Da li ste sigurni da želite da izlogujete sve korisnike?",
                logoutUsersSuccess: "Svi korisnici su uspešno izlogovani",
                logoutUsersFailure: "Korisnici nisu uspešno izlogovani",
                confirmStopSignUp: "Da li ste sigurni da želite da obustavite korisnicima mogućnost kreiranja naloga?",
                confirmStopSignIn: "Da li ste sigurni da želite da obustavite korisnicima mogućnost prijavljivanja?",
                confirmPartRequestTimeChange: "Da li ste sigurni da želite da promenite vremenska ograničenja za korisničke zahteve?",
                confirmPartRequestDisable: "Da li ste sigurni da želite da onemogućite kreiranje novih zahteva?",
                confirmPartOffersDisable: "Da li ste sigurni da želite da onemogućite kreiranje novih ponuda?",
                confirmDisableNotifications: "Da li ste sigurni da želite da onemogućite notifikacije?",
                configurationSettingsUpdated: "Konfiguracione promene su uspešno ažurirane",
                maintenanceSettingsUpdated: "Promene održavanja su uspešno ažurirane",
                confirmScheduleMaintenanceMode: "Da li ste sigurni da želite da zakažete režim za održavanje?",
                confirmEmergencyMaintenance: "Da li ste sigurni da želite da odmah uključite režim održavanja?",
                confirmResetMaintenance: "Da li ste sigurni da želite da resetujete opcije održavanja na početne vrednosti?",
                emergencyMaintenanceSuccess: "Režim održavanja uspešno uspostavljen",
                updatePaymentSettingsSuccess: "Opcije plaćanja su uspešno ažurirane",
            },
        },
    },
};

export default settings;
