import { successColor, whiteColor, grayColor, hexToRgb, primaryColor, dangerColor } from "../../material-dashboard-react.js";

const dashboardStyle = {
    successText: {
        color: successColor[0],
    },
    upArrowCardCategory: {
        width: "16px",
        height: "16px",
    },
    profileIcon: {
        padding: "10px",
        width: "100%",
        height: "100%",
        maxWidth: "130px",
        maxHeight: "130px",
        background: "#fff",
        color: "#5C8BCD",
    },
    stats: {
        color: grayColor[0],
        display: "inline-flex",
        fontSize: "12px",
        lineHeight: "22px",
        "& svg": {
            top: "4px",
            width: "16px",
            height: "16px",
            position: "relative",
            marginRight: "3px",
            marginLeft: "3px",
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            top: "4px",
            fontSize: "16px",
            position: "relative",
            marginRight: "3px",
            marginLeft: "3px",
        },
    },
    cardCategory: {
        color: grayColor[0],
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "10px",
        marginBottom: "10px",
    },
    cardCategoryWhite: {
        color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitle: {
        color: grayColor[2],
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: grayColor[1],
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    cardTitleWhite: {
        color: whiteColor,
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: grayColor[1],
            fontWeight: "400",
            lineHeight: "1",
        },
    },
    cardId: {
        color: grayColor[0],
        fontSize: "14px",
        marginTop: "0",
        paddingTop: "10px",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        textDecoration: "none",
        textAlign: "center",
    },
    sectionHeaderWrapper: {
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        paddingBottom: "10px",
    },
    sectionHeader: {
        display: "flex",
        alignItems: "flex-end",
        margin: "10px 0",
        color: grayColor[0],
        textTransform: "uppercase",
        fontSize: "0.8rem",
        height: "100%",
        width: "100%",
        paddingBottom: "10px",
        borderBottom: "1px solid " + grayColor[4],
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 20,
    },
    confirmButton: {
        display: "flex",
        position: "relative",
    },
    absoluteContainer: {
        position: "absolute",
        left: 10,
        top: 10,
        zIndex: 3,
    },
    relativeContainer: {
        position: "relative",
    },
    buttonSeparator: {
        marginRight: 10,
    },
    divider: {
        marginTop: 50,
        marginBottom: 50,
    },
    addImageSection: {
        marginTop: "1rem",
        marginBottom: "1rem",
        position: "relative",
        minHeight: "120px",
        borderBottom: "1px solid " + grayColor[4],
    },
    addImgTitle: { color: grayColor[0], textTransform: "uppercase", fontSize: "0.8rem", marginBottom: "0.5rem" },
    removeImgs: {
        backgroundColor: dangerColor[0],
        color: "#fff",
        position: "absolute",
        bottom: "0px",
        right: "0px",
        "&:hover": {
            backgroundColor: dangerColor[0],
            color: "#fff",
            opacity: 0.7,
        },
    },
};

export default dashboardStyle;
