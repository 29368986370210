import api from "../../../api";
import { toast } from "react-toastify";

export const requestGetUserReviewsByType = (userid, props) => {
    const query = `?${new URLSearchParams(props).toString()}`;
    return api.get(`/admin/users/${userid}/reviews${query ? query : ""}`);
};

export const requestDeleteUserReview = async (payload) => {
    try {
        return await api.delete(`/admin/users/${payload.userid}/reviews/${payload.type}/${payload.review_id}`);
    } catch (error) {
        toast.error(error.response.data.error);
        return false;
    }
};
