const UserRoles = {
    ALL: "*",
    ADMIN: "admin",
    OPERATER: "operater",
    SELLER: "seller",
    USER: "user",
    SELLER_PLUS: "seller_plus",
};

export default UserRoles;
