import { OPERATOR_DETAILS_LAYOUT_KEY, operatorDetailsLayout } from "./OperatorDetailsLayout";
import { SELLER_DETAILS_LAYOUT_KEY, sellerDetailsLayout } from "./SellerDetailsLayout";
import { USER_DETAILS_LAYOUT_KEY, userDetailsLayout } from "./UserDetailsLayout";
import { ADMIN_DETAILS_LAYOUT_KEY, adminDetailsLayout } from "./AdminDetailsLayout";

const defaultTableSize = 5;

export {
    operatorDetailsLayout,
    OPERATOR_DETAILS_LAYOUT_KEY,
    sellerDetailsLayout,
    SELLER_DETAILS_LAYOUT_KEY,
    userDetailsLayout,
    USER_DETAILS_LAYOUT_KEY,
    adminDetailsLayout,
    ADMIN_DETAILS_LAYOUT_KEY,
    defaultTableSize,
};
