// IMPORTANT - if there are changes inside the defaultLayout object, REQUEST_DETAILS_LAYOUT_KEY "V#" value needs to be incremented!!!

const REQUEST_DETAILS_LAYOUT_KEY = "REQUEST_DETAILS_LAYOUT_V6";
const userBoxHeight = 14;
const defaultLayout = {
    lg: [
        {
            i: "1",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
            static: true,
        },
        {
            i: "2",
            w: 6,
            h: userBoxHeight,
            x: 6,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 5,
            maxW: 12,
            static: true,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
        {
            i: "4",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
        {
            i: "5",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
    ],
    md: [
        {
            i: "1",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
            static: true,
        },
        {
            i: "2",
            w: 6,
            h: userBoxHeight,
            x: 6,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
            static: true,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
        {
            i: "4",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
        {
            i: "5",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
    ],
    sm: [
        {
            i: "1",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
            static: true,
        },
        {
            i: "2",
            w: 6,
            h: userBoxHeight,
            x: 6,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
            static: true,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
        {
            i: "4",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
        {
            i: "5",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 4,
            maxW: 12,
        },
    ],
    xs: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 2,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
        {
            i: "4",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 3,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
        {
            i: "5",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 4,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
    ],
    xxs: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 2,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
        {
            i: "4",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 3,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
        {
            i: "5",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 4,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 12,
            maxW: 12,
            static: true,
        },
    ],
};
const defaultTableSize = 5;

const requestModalsConfig = {
    remove: {
        name: "remove",
        buttonLabel: "confirm",
        buttonColor: "warning",
        text: "resolvePartRequestRemoveText",
    },
    block: {
        name: "block",
        buttonLabel: "confirm",
        buttonColor: "rose",
        text: "resolvePartRequestBlockText",
    },
    success: {
        name: "success",
        buttonLabel: "confirm",
        buttonColor: "warning",
        text: "resolvePartRequestSuccessText",
    },
    failure13: {
        name: "failure13",
        buttonLabel: "confirm",
        buttonColor: "rose",
        text: "resolvePartRequestFailure13Text",
    },
    failure14: {
        name: "failure14",
        buttonLabel: "confirm",
        buttonColor: "rose",
        text: "resolvePartRequestFailure14Text",
    },
    failure15: {
        name: "failure15",
        buttonLabel: "confirm",
        buttonColor: "rose",
        text: "resolvePartRequestFailure15Text",
    },
    failure16: {
        name: "failure16",
        buttonLabel: "confirm",
        buttonColor: "rose",
        text: "resolvePartRequestFailure16Text",
    },
    refund: {
        name: "refund",
        buttonLabel: "confirm",
        buttonColor: "info",
        text: "resolvePartRequestRefund",
    },
};

const initialModalData = {
    title: "",
    text: "",
    buttonLabel: "",
};

export { REQUEST_DETAILS_LAYOUT_KEY, defaultLayout, defaultTableSize, requestModalsConfig, initialModalData };
