import moment from "moment";
import { defaultCustomBodyRender } from "./DataGridColumns";
import resources from "../core/Localization/Localization.js";
import { TransactionTypes } from "./transactions/config.js";

export const defaultPageSize = 25;

export const defaultSearch = { offset: 0, limit: defaultPageSize };

export const transactionColumns = (type) => {
    let columns = [
        {
            field: "id",
            headerName: resources.finance.datagrid.transactionId,
            flex: 1,
            headerClassName: "data-grid__head",
        },
        {
            field: "user",
            headerName: resources.finance.datagrid.user,
            flex: 2,
            headerClassName: "data-grid__head",
        },
        {
            field: "amount",
            headerName: resources.finance.datagrid.amount,
            flex: 1.5,
            headerClassName: "data-grid__head",
        },
        {
            field: "currencyAmount",
            headerName: resources.finance.datagrid.currencyAmount,
            flex: 1.5,
            headerClassName: "data-grid__head",
        },
        {
            field: "currency",
            headerName: resources.finance.datagrid.currency,
            flex: 1,
            headerClassName: "data-grid__head",
        },
    ];

    switch (type) {
        case TransactionTypes.PAYIN:
            columns = columns.concat([]);
            break;
        case TransactionTypes.CORRECTION:
            columns = columns.concat([
                {
                    field: "correctionType",
                    headerName: resources.finance.datagrid.correctionType,
                    flex: 1.5,
                    headerClassName: "data-grid__head",
                },
            ]);
            break;

        default:
            columns = columns.concat([
                {
                    field: "partOfferId",
                    headerName: resources.finance.datagrid.partOfferId,
                    flex: 1.5,
                    headerClassName: "data-grid__head",
                },
                {
                    field: "refunded",
                    headerName: resources.finance.datagrid.refunded,
                    flex: 1.5,
                    headerClassName: "data-grid__head",
                },
                {
                    field: "refundedAmount",
                    headerName: resources.finance.datagrid.refundedAmount,
                    flex: 1.5,
                    headerClassName: "data-grid__head",
                },
            ]);
            break;
    }
    columns.push({
        field: "createdAt",
        headerName: resources.finance.datagrid.createdAt,
        flex: 2,
        headerClassName: "data-grid__head",
        valueGetter: (params) => moment(params.row.createdAt).format("DD/MM/YYYY hh:mm"),
    });

    return columns;
};
