import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

import { getUserFilters } from "../../reduxStore/entities/userDetails.js";
import { defaultTableSize } from "../../common/UserDetailsLayout";
import { mapUserFiltersInTableData, USER_FILTERS_HEADERS } from "../../common/UserDetailsTabs";
import resources from "../../core/Localization/Localization.js";
import getQueryWithPagination from "../../utils/getQueryWithPagination";

export default function UserFilters() {
    const history = useHistory();

    const [tableData, setTableData] = useState([]);

    const user = useSelector((x) => x.userDetails.user);
    const userFilters = useSelector((state) => state.userDetails.userFilters);
    const dispatch = useDispatch();

    const columns = React.useMemo(USER_FILTERS_HEADERS, [resources]);

    useEffect(() => {
        dispatch(getUserFilters(getQueryWithPagination({ userid: user.userid })));
    }, [user]);

    useEffect(() => {
        setTableData(mapUserFiltersInTableData(userFilters));
    }, [userFilters]);

    const onRowClick = (params) => {
        if (params.length) {
            history.push("/admin/userfilters/" + params[0]);
        }
    };

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="info">
                <h4>{resources.userFilters.header}</h4>
            </CardHeader>
            <CardBody>
                <Table
                    tableHeaderColor="info"
                    tableHead={columns}
                    tableData={tableData}
                    handleClick={onRowClick}
                    paginationInfo={{
                        pageSize: defaultTableSize,
                        pageSizeOptions: [defaultTableSize],
                    }}
                />
            </CardBody>
        </Card>
    );
}
