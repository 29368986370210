import React from "react";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { Box, Typography, Switch } from "@material-ui/core";

// core components
import Button from "../../../components/CustomButtons/Button.js";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import ConfirmationModal from "../../../components/ConfirmationModal/ConfirmationModal.js";

//utils
import resources from "../../../core/Localization/Localization";
import { logoutAllUsers, updateMaintenanceSettings } from "../../../reduxStore/entities/settings.js";
import { isAuth } from "../../../auth/helpers.js";
import { areEqual } from "../../../utils/shallowObjectsCompare.js";

const MaintenanceUsers = ({ editMode }) => {
    const dispatch = useDispatch();
    const operator = isAuth();

    const maintenanceSettings = useSelector((state) => state.settings.maintenanceSettings);

    const [showConfirmModal, setShowConfirmModal] = React.useState(false);
    const [settings, setSettings] = React.useState(null);
    const [modalCaller, setModalCaller] = React.useState("");
    const [modalMessage, setModalMessage] = React.useState("");

    React.useLayoutEffect(() => {
        setSettings({ ...maintenanceSettings });
    }, [maintenanceSettings]);

    const handleClick = (caller) => {
        switch (caller) {
            case "logout":
                setModalMessage(resources.settings.messages.confirmUsersLogout);
                setModalCaller("logout");
                break;
            case "signup_disabled":
                setModalMessage(resources.settings.messages.confirmStopSignUp);
                setModalCaller("signup_disabled");
                break;
            case "login_disabled":
                setModalMessage(resources.settings.messages.confirmStopSignIn);
                setModalCaller("login_disabled");
                break;

            default:
                break;
        }
        if (caller) setShowConfirmModal(true);
    };

    const handleConfirm = (caller) => {
        switch (caller) {
            case "logout":
                dispatch(logoutAllUsers());
                break;
            case "signup_disabled":
                dispatch(
                    updateMaintenanceSettings({
                        maintenance_config_id: settings.maintenance_config_id,
                        signup_disabled: !settings.signup_disabled,
                        updatedby: operator.userid,
                    })
                );

                break;
            case "login_disabled":
                dispatch(
                    updateMaintenanceSettings({
                        maintenance_config_id: settings.maintenance_config_id,
                        login_disabled: !settings.login_disabled,
                        updatedby: operator.userid,
                    })
                );
                break;

            default:
                break;
        }
        setModalMessage("");
        setModalCaller("");
        setShowConfirmModal(false);
    };

    return settings ? (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Box className="d-flex align-items-center justify-between pt-3 pb-3">
                        <Typography>{resources.settings.logoutUsers}</Typography>
                        <Button
                            color="danger"
                            size="sm"
                            name="logout"
                            onClick={(e) => {
                                handleClick(e.target.name);
                            }}
                        >
                            <span
                                name="logout"
                                onClick={(e) => {
                                    handleClick("logout");
                                }}
                            >
                                {resources.settings.logout}
                            </span>
                        </Button>
                    </Box>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Box className="d-flex align-items-center justify-between pt-3 pb-3">
                        <Typography>{resources.settings.stopUserSingUp}</Typography>
                        <Switch
                            color="primary"
                            size="medium"
                            name="signup_disabled"
                            checked={settings.signup_disabled || false}
                            onClick={(e) => {
                                handleClick(e.target.name);
                            }}
                        />
                    </Box>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                    <Box className="d-flex align-items-center justify-between pt-3 pb-3">
                        <Typography>{resources.settings.stopUserSignIn}</Typography>
                        <Switch
                            color="primary"
                            size="medium"
                            name="login_disabled"
                            checked={settings.login_disabled || false}
                            onClick={(e) => {
                                handleClick(e.target.name);
                            }}
                        />
                    </Box>
                </GridItem>
                <ConfirmationModal
                    open={showConfirmModal}
                    handleClose={() => {
                        setModalMessage("");
                        setShowConfirmModal(false);
                    }}
                    buttonLabel={resources.form.confirm}
                    text={modalMessage}
                    onConfirmCallback={() => handleConfirm(modalCaller)}
                />
            </GridContainer>
        </>
    ) : null;
};

export default React.memo(
    MaintenanceUsers,
    (prevProps, nextProps) => areEqual(prevProps.maintenanceSettings, nextProps.maintenanceSettings) && prevProps.editMode === nextProps.editMode
);
