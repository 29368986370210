import * as Yup from "yup";
import resources from "../../core/Localization/Localization.js";

const OfferUpdateSchema = Yup.object().shape({
    description: Yup.string().required(resources.form.errors.offer.description),
    status: Yup.number().required(resources.form.errors.offer.status),
    price: Yup.string().required(resources.form.errors.offer.price),
    type: Yup.string().required(resources.form.errors.offer.type),
});

export default OfferUpdateSchema;
