// IMPORTANT - if there are changes inside the defaultLayout object, HISTORY_LOG_DETAILS_LAYOUT and REVISION_LOG_DETAILS_LAYOUT "V#" value needs to be incremented!!!

const HISTORY_LOG_DETAILS_LAYOUT = "HISTORY_LOG_DETAILS_LAYOUT_V4";
const REVISION_LOG_DETAILS_LAYOUT = "REVISION_LOG_DETAILS_LAYOUT_V4";
const userBoxHeight = 14;
const defaultLayout = {
    lg: [
        {
            i: "1",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            minW: 6,
            maxW: 12,
        },
        {
            i: "2",
            w: 6,
            h: userBoxHeight,
            x: 6,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
        {
            i: "3",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
        {
            i: "4",
            w: 6,
            h: userBoxHeight,
            x: 6,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
    ],
    md: [
        {
            i: "1",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            minW: 6,
            maxW: 12,
        },
        {
            i: "2",
            w: 6,
            h: userBoxHeight,
            x: 6,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
        {
            i: "3",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
        {
            i: "4",
            w: 6,
            h: userBoxHeight,
            x: 6,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
    ],
    sm: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            minW: 6,
            maxW: 12,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
        {
            i: "3",
            w: 6,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 2,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
        {
            i: "4",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 3,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            minW: 6,
            maxW: 12,
        },
    ],
    xs: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            minW: 6,
            maxW: 12,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            minW: 6,
            maxW: 12,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 2,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            minW: 6,
            maxW: 12,
        },
        {
            i: "4",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 3,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            minW: 6,
            maxW: 12,
        },
    ],
    xxs: [
        {
            i: "1",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: 0,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            isResizable: true,
            minW: 6,
            maxW: 12,
        },
        {
            i: "2",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            minW: 6,
            maxW: 12,
        },
        {
            i: "3",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 2,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            minW: 6,
            maxW: 12,
        },
        {
            i: "4",
            w: 12,
            h: userBoxHeight,
            x: 0,
            y: userBoxHeight * 3,
            maxH: userBoxHeight,
            minH: userBoxHeight,
            isDraggable: false,
            minW: 6,
            maxW: 12,
        },
    ],
};
const defaultTableSize = 5;

export { HISTORY_LOG_DETAILS_LAYOUT, REVISION_LOG_DETAILS_LAYOUT, defaultLayout, defaultTableSize };
