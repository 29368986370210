import React from "react";
import { Box, Typography } from "@material-ui/core";
import CustomModal from "../CustomModal/CustomModal";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-dashboard-react/components/confirmModalStyle";
import resources from "../../core/Localization/Localization.js";

const useStyles = makeStyles(modalStyle);

export default function ConfirmationModal(props) {
    const { open, handleClose, title, text, buttonLabel, onConfirmCallback } = props;

    const classes = useStyles();

    return (
        <CustomModal open={open} onClose={handleClose}>
            <Box className={classes.root}>
                <Box className={classes.titleContainer}>
                    <Typography className={classes.titleText}>{title}</Typography>
                </Box>
                <Box className={classes.bodyContainer}>
                    <Typography className={classes.bodyText}>{text}</Typography>
                </Box>
                <Box className={classes.footerContainer}>
                    <Button type={"button"} size={"sm"} className={classes.button} onClick={handleClose}>
                        {resources.cancel}
                    </Button>
                    <Button type={"button"} color={"info"} size={"sm"} onClick={onConfirmCallback}>
                        {buttonLabel}
                    </Button>
                </Box>
            </Box>
        </CustomModal>
    );
}
