import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const createQueryData = (history) => {
  if (history?.location?.search) {
    try {
      const search = history.location.search.substring(1);
      const query = JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
      return query;
    } catch (error) {
      return {};
    }
  }
}

export const useQueryData = () => {
  const history = useHistory();

  const [queryData, setQueryData] = React.useState(createQueryData(history));
  const [queryString, setQueryString] = React.useState("");

  useEffect(() => {
    const query = createQueryData(history);
    setQueryData(query);
  }, [history, queryString]);

  const updateQuery = (payload) => {
    const query = `?${new URLSearchParams(payload).toString()}`;
    history.replace(query);
    setQueryString(query);
  };

  return { queryData, queryString, updateQuery };
}