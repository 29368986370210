import React from "react";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import ReactJson from "react-json-view";

export default function ActionLogBasicInfo(props) {

    const { historyLog } = props;

    const actionInfo = { ...historyLog.details };
    delete actionInfo.props;
    delete actionInfo.result;

    return (
        <Card className={"user-profile-card"}>
            <CardHeader color="rose">
                <h4>Http data</h4>
            </CardHeader>
            <CardBody>
                <ReactJson src={actionInfo} displayDataTypes={false} />
            </CardBody>
        </Card>
    );
}