const userStatistics = {
    en: {
        userStatistics: {
            title: "Statistics",
            positiveBuyer: "Positively rated as buyer",
            negativeBuyer: "Negatively rated as buyer",
            buyerRating: "Buyer rating",
            positiveSeller: "Positively rated as seller",
            negativeSeller: "Negatively rated as seller",
            sellerRating: "Seller rating",
            totalFinishedTrades: "Total number of finished trades",
            totalRequests: "Total number of requests",
            totalOffers: "Total number of offers",
            totalWins: "Total number of wins",
            buyerSuccessRating: "Buyer success rating (finished trades / total requests)",
            sellerSuccessRating: "Seller success rating",
            totalTraded: "Total token spent",
        },
    },
    rs: {
        userStatistics: {
            title: "Statistika",
            positiveBuyer: "Broj pozitivnih ocena kao kupac",
            negativeBuyer: "Broj negativnih ocena kao kupac",
            buyerRating: "Rejting kupca",
            positiveSeller: "Broj pozitivnih ocena kao prodavac",
            negativeSeller: "Broj negativnih ocena kao prodavac",
            sellerRating: "Rejting prodavca",
            totalFinishedTrades: "Ukupan broj završenih trgovina",
            totalRequests: "Ukupan broj zahteva",
            totalOffers: "Ukupan broj ponuda",
            totalWins: "Ukupan broj pobeđenih ponuda",
            buyerSuccessRating: "Uspešnost kupca (završene trgovine / broj zahteva)",
            sellerSuccessRating: "Uspešnost prodavca",
            totalTraded: "Ukupno potrošenog kredita",
        },
    },
};

export default userStatistics;
