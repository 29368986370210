import React from "react";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CustomModal from "../../components/CustomModal/CustomModal";
import MessageModal from "../../components/MessageModal/MessageModal.js";
import DoneAllIcon from "@material-ui/icons/DoneAll";
//styles
import addImagesStyle from "../../assets/jss/material-dashboard-react/components/addImages";
// utils and common
import resources from "../../core/Localization/Localization";
import { Close } from "@material-ui/icons";

const AddImages = (props) => {
    const classes = addImagesStyle();

    let inputRef = React.useRef(null);
    const [imagesToAdd, setImagesToAdd] = React.useState([]);
    const [preview, setPreview] = React.useState([]);
    const [openPreviewModal, setOpenPreviewModal] = React.useState(false);
    const [openErrorModal, setOpenErrorModal] = React.useState(false);

    // handlers

    const handleClick = React.useCallback(() => {
        if (!props.disabled) inputRef.current.click();
        else {
            setOpenErrorModal(true);
        }
    }, [inputRef, props.disabled]);

    const handleChange = () => {
        //we check if number of image to upload is exceeded
        //if YES we show error modal with message how many images can user upload
        if (props?.limit && props.limit - Object.values(inputRef.current.files).length < 0) {
            clearRefValue();
            setOpenErrorModal(true);
        }
        //if NO, we set image files to state and open modal to preview them and send images to server
        else {
            let selectedFiles = Object.values(inputRef.current.files);
            setImagesToAdd(selectedFiles);
            setOpenPreviewModal(true);
        }
    };

    const handleClose = () => {
        if (preview.length) {
            preview.map((img) => URL.revokeObjectURL(img));
        }
        clearRefValue();
        setPreview([]);
        setOpenPreviewModal(false);
    };

    const handleRemoveChosen = (chosenIndex) => {
        // take the index from blobs and remove the added file from file array at the same index - useEffect will render blobs again
        let filteredImagesToAdd = imagesToAdd.filter((img, index) => index !== chosenIndex);
        setImagesToAdd(filteredImagesToAdd);
        if (filteredImagesToAdd.length < 1) {
            clearRefValue();
            setOpenPreviewModal(false);
        }
    };

    // helpers
    const clearRefValue = () => {
        inputRef.current.value = null;
    };

    // lifecycle hooks

    React.useEffect(() => {
        if (imagesToAdd.length) {
            setPreview(imagesToAdd.map((img) => URL.createObjectURL(img)));
        }
        return () => {
            preview.map((img) => URL.revokeObjectURL(img));
        };
    }, [imagesToAdd]);

    return (
        <>
            <Box className={props.classes || "absolute bottom-right m-3"}>
                <Tooltip title={resources.form.images.addMultiple}>
                    <IconButton
                        size={"medium"}
                        onClick={handleClick}
                        style={{ backgroundColor: props.color || "" }}
                        className={[classes.addIconButton, `${props.disabled ? classes.disabled : " "}`]}
                    >
                        <AddIcon className={classes.addIcon} />
                        <input
                            type="file"
                            name="img"
                            id="img-input"
                            multiple
                            accept="image/png, image/jpeg, image/jpg"
                            ref={inputRef}
                            onChange={handleChange}
                            className={classes.addFilesInput}
                        />
                    </IconButton>
                </Tooltip>
            </Box>

            <MessageModal
                open={openErrorModal}
                handleClose={() => setOpenErrorModal(false)}
                text={props.disabled ? props.disabledText : props.limit ? `${resources.partOffers.limitImagesMessage} ${props.limit}` : ""}
                buttonLabel={resources.form.ok}
            />

            <CustomModal open={openPreviewModal} handleClose={handleClose}>
                <Box className={classes.galleryContainer}>
                    <Grid container justify="space-around" alignItems="flex-start" wrap="wrap" className={classes.previewWrapper}>
                        {preview.map((img, index) => (
                            <Grid item key={img + index} className={classes.imgContainer}>
                                <img src={img} width={300} alt={index + "img to choose"} />
                                <Tooltip title={resources.form.remove}>
                                    <IconButton
                                        className={classes.closeButton}
                                        onClick={() => {
                                            handleRemoveChosen(index);
                                        }}
                                    >
                                        <Close className={classes.closeIcon} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Tooltip title={resources.form.confirm}>
                    <IconButton
                        className={classes.confirmButton}
                        onClick={() => {
                            props.handleAddImages(imagesToAdd);
                            handleClose();
                        }}
                    >
                        <DoneAllIcon className={classes.confirmIcon} />
                    </IconButton>
                </Tooltip>
            </CustomModal>
        </>
    );
};

export default AddImages;
